
import React, { Component } from 'react'
import IconSVG from "../../utilities/FrontFunctions/IconSVG"
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isNotEmpty } from "../../utilities/FrontFunctions/AoxFrontFunctions"

class SectionSocialNetwork extends Component {

	render() {
		
		var oFacebookUrl = this.props.sitecoreContext.route?.fields["Facebook Url"];
		var oInstagramUrl = this.props.sitecoreContext.route?.fields["Instagram Url"];
		var oYoutubeUrl = this.props.sitecoreContext.route?.fields["Youtube Url"];

		var sFacebookUrl = (oFacebookUrl != null ) ? oFacebookUrl.value.href : "";
		var sInstagramUrl = (oInstagramUrl != null ) ? oFacebookUrl.value.href : "";
		var sYoutubeUrl = (oYoutubeUrl != null ) ? oFacebookUrl.value.href : "";

		return (
			<section className="section_social-network">
				<h2>{this.props.t('BrandSectionSocialNetworkTitle')}</h2>
				{<ul>
					{ isNotEmpty(sFacebookUrl) && <li key="facebook"><a href={sFacebookUrl} target="_blank" rel="noopener noreferrer"><IconSVG icon='facebook' /></a></li>}
					{ isNotEmpty(sInstagramUrl) && <li key="instagram"><a href={sInstagramUrl} target="_blank" rel="noopener noreferrer"><IconSVG icon='instagram' /></a></li>}
					{ isNotEmpty(sYoutubeUrl) && <li key="youtube"><a href={sYoutubeUrl} target="_blank" rel="noopener noreferrer"><IconSVG icon='youtube' /></a></li>}
				</ul>}
			</section>
		)
	}
}

export default withTranslation()(withSitecoreContext()(SectionSocialNetwork));
