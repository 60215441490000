import React, { Component } from 'react'
import LazyLoad from 'react-lazyload'
import { withSitecoreContext, Image, RichText, Text, Link } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { ConditionalWrapper } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions"

class ObjPushCustomFull extends Component {
	render() {
		const { pushContent } = this.props;
		return (
			this.props.fields || pushContent 
			? <article className={`obj_push-custom var_full${
				this.props.fields ? (this.props.fields["Dark mode"].value ? ' var_dark' : '') : (pushContent && pushContent.darkMode.boolValue ? ' var_dark ' : '')}${
				this.props.fields ? (this.props.fields["Push width"].value ? ' var_' + this.props.fields["Push width"].value : '') : (pushContent && pushContent.pushWidth.value ? ' var_' + pushContent.pushWidth.value : '')}${
				this.props.fields ? (this.props.fields["Push Align"].value ? ' var_' + this.props.fields["Push Align"].value : '') : (pushContent && pushContent.pushAlign.value ? ' var_' + pushContent.pushAlign.value : '')}${
				this.props.fields ? (this.props.fields["Push Custom"].value ? ' var_' + this.props.fields["Push Custom"].value : '') : ''}`}>
				<ConditionalWrapper 
					condition={this.props.fields ? (this.props.fields["Push url"] && this.props.fields["Push url"].value && this.props.fields["Push url"].value.href !=="") : (pushContent && pushContent.pushUrl.value && pushContent.pushUrl.value !=="" )}
					wrapper={children => 
					<Link field={this.props.fields ? (this.props.fields["Push url"].value) : (pushContent && pushContent.pushUrl.value && pushContent.pushUrl.jss )}>
						{children}
						<div className="ctn_cta">
							<Text className={`obj_cta${this.props.fields ? (this.props.fields["Dark mode"].value ? ' reverse' : '') : (pushContent && pushContent.darkMode.boolValue ? ' reverse' : '')}`} 
								field={this.props.fields ? (this.props.fields["Push CTA"]) : (pushContent ? pushContent.pushCTA.jss : "")} tag="div"/>
						</div>
					</Link>}>
					
					<div className="ctn_text">
						<RichText field={this.props.fields ? this.props.fields["Push before title"] : (pushContent.pushBeforeTitle ? pushContent.pushBeforeTitle.jss : {})} tag="h4"/>
						<RichText field={this.props.fields ? this.props.fields["Push title"] : (pushContent.pushTitle ? pushContent.pushTitle.jss : {})} tag="h2"/>
						<h2>
							<LazyLoad>
								<picture>
									<source srcSet={this.props.fields ? this.props.fields["Image title"].value.src : (pushContent && pushContent.imageTitle ? pushContent.imageTitle.jss.value.src : "")} media="(max-width:767px)"/>
									<Image media={this.props.fields ? this.props.fields["Image title"].value : (pushContent && pushContent.imageTitle ? pushContent.imageTitle.jss : "")}/>
								</picture>
							</LazyLoad>
						</h2>
						<RichText field={this.props.fields ? this.props.fields["Push subtitle"] : (pushContent.pushSubtitle ? pushContent.pushSubtitle.jss : {})} tag="h3"/>
						<RichText field={this.props.fields ? this.props.fields["Push content"] : (pushContent.pushContent ? pushContent.pushContent.jss : {})} tag="p" />
					</div>
					
					<figure>
						<LazyLoad>
							<picture>
								<source srcSet={this.props.fields ? this.props.fields["Image mobile"].value.src : (pushContent ? pushContent.imageMobile.jss.value.src : "")} media="(max-width:767px)"/>
								<Image media={this.props.fields ? this.props.fields["Image"].value : (pushContent ? pushContent.image.jss : "")}/>
							</picture>
						</LazyLoad>
					</figure>
				</ConditionalWrapper>
			</article>
			: "ObjPushCustomFull : " + this.props.t('NoContentToMap')
		)
	}
}

export default withTranslation()(withSitecoreContext()(ObjPushCustomFull));