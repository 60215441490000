import React, { Component } from 'react'  
import { withTranslation } from 'react-i18next';
import { withSitecoreContext, Text, RichText } from '@sitecore-jss/sitecore-jss-react'; 
import { GetLogoList } from '../../utilities/FrontFunctions/AoxFrontFunctions'

class ExpiredConfirmationLink extends Component {
	constructor(props) {
		super(props)
		this.state = {      
			logoList: GetLogoList()
		}
	}
 
  componentDidMount() { 
    window.scrollTo(0,0)    
	} 
	render() {
    const { PageContact } = this.props.fields.data;
    const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
     
    return (
     
      <section className="section_login">
				<div className="str_container">

          <header>		
            { oCurrentItemFields && <RichText field={oCurrentItemFields["Page Title"]} tag="h1" /> }
          </header>

          <div className="ctn_login">
            <div className="ctn_col col_100">
              { oCurrentItemFields && <Text field={oCurrentItemFields["Page SubTitle"]} tag="h2" /> }

              <div className="obj_form"> 
                  { oCurrentItemFields && <RichText className="t_mention mb_30" tag="p" field={oCurrentItemFields["Page Text"]}></RichText> }
                  <div className="ctn_cta">
                    <a className="obj_cta" href={PageContact && PageContact.url}>{this.props.t('ExpiredConfirmationLink_ContactUs')}</a>
                  </div>	 
              </div>
            </div>
          </div>

          <ul className="ctn_logos">
            {this.state.logoList.map((logoItem, index) => (
              <li key={index} className={"var_" + logoItem.id}>
                <img src={logoItem.image} alt={logoItem.label}/>
              </li>	
            ))}
          </ul>

				</div>
			</section>
		)
	}
}

export default withTranslation()(withSitecoreContext()(ExpiredConfirmationLink));