import { withSitecoreContext, Text, Image } from '@sitecore-jss/sitecore-jss-react';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload'
import Slider from "react-slick"
import { scroller } from 'react-scroll'

import { isMobile, matchHeight } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions"
import { getApiDatas, postApiDatas } from "../../utilities/FrontFunctions/AoxFrontFunctions"
import { trackGA4PageView, trackGA4Event } from '../../utilities/FrontFunctions/AoxTrackingFunctions'
import ObjSectionFormation from "../ObjExpertise/ObjSectionFormation"
import ModalFormationsFav from "../Modal/ModalFormationsFav"

class PageFormationExpertise extends Component {
  	constructor(props) {
		super(props)
		this.state = {
			isInitialised: false, 
			apiDatas:[],
		}
		// this.handleWindowResizeTabsListFormation = this.handleWindowResizeTabsListFormation.bind(this)
		// this.throttleWindowResizeTabsListFormation = throttle(this.handleWindowResizeTabsListFormation, 200)
		
		this.switchTab = this.switchTab.bind(this)
		this.hideLevel2 = this.hideLevel2.bind(this)
		this.showLevel2 = this.showLevel2.bind(this)
		//this.refreshHeight = this.refreshHeight.bind(this)
		this.refreshTab = this.refreshTab.bind(this)
		this.scrollTo = this.scrollTo.bind(this)

		// button fav
		this.handleButtonFav = this.handleButtonFav.bind(this)

		// tracking
		this.bPushTrackPage0_Done = true;
		this.bPushTrackPage1_Done = false;
	}
	componentDidMount() {
		getApiDatas(`/apiaox/Call/PageFormation?sBrandCode=${this.props.fields.data?.CurrentTraining.getbrand.codeAbbrege.value}`).then((apiDatas) => { this.setState({ apiDatas:apiDatas }) })
		
		matchHeight('.obj_carousel.article-carousel h3')
		window.addEventListener('resize', this.throttleWindowResizeTabsListFormation)
		this.switchTab(0)
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.throttleWindowResizeTabsListFormation)
	}
	// refreshHeight(){
	// 	heightAuto('.section_page-liste .ctn_page-liste h3')
	// 	if(document.body.clientWidth === 768) equalHeight('.section_page-liste .ctn_page-liste h3', 2)
	// 	if(document.body.clientWidth > 768) equalHeight('.section_page-liste .ctn_page-liste h3', 3)
	// }
	refreshTab(){
		//this.refreshHeight()
		window.dispatchEvent(new Event('scroll'))

		this.state.isInitialised && this.scrollTo()
		this.setState({ isInitialised: true })
	}
	hideLevel2(){
		var $subTabItemLevel1 = document.querySelector(".obj_tabs-list .ctn_row .lvl-1"),
			$subTabItemLevel2 = document.querySelectorAll(".obj_tabs-list .ctn_row .lvl-2")

		$subTabItemLevel1.classList.add("active", "fade-in")
		$subTabItemLevel2.forEach(subTabItemLevel2 => {
			subTabItemLevel2.classList.remove("active")
		})

		this.refreshTab()
	}
	showLevel2(buttonId){
		if( buttonId !== undefined ){
			var $subTabItemLevel1 = document.querySelector(".obj_tabs-list .ctn_row .lvl-1")

			$subTabItemLevel1.classList.remove("active")
			document.querySelector("#" + buttonId).classList.add("active", "fade-in")

			this.refreshTab()
		}
	}
	switchTab(index){
		var $listItem = document.querySelectorAll(".ctn_nav-tabs li"),
			$tabItem = document.querySelectorAll(".obj_tabs-list .ctn_row")

		$listItem.forEach(listItem => listItem.classList.remove("active"))
		$listItem[index].classList.add("active")
		$tabItem.forEach(tabItem => tabItem.classList.remove("active", "fade-in"))
		$tabItem[index].classList.add("active", "fade-in")

		this.hideLevel2()

		// tracking
		if(typeof window.trackDatas !== 'undefined'){
			var trackData = window.trackDatas;
			if(index===0){
				trackData.pageCategory = 'training list first range trainings';
				trackGA4PageView(trackData).then((result) => { 	
					this.bPushTrackPage0_Done = result;
				});
			} else if(index===1) {
				trackData.pageCategory = 'training list other trainings';
				trackGA4PageView(trackData).then((result) => { 	
					this.bPushTrackPage1_Done = result;
				});
			}
		}
	}
	// handleWindowResizeTabsListFormation(){
	// 	this.refreshHeight()
	// }
	scrollTo(){
		scroller.scrollTo('obj-tabs-list', {
			smooth: true,
			offset: isMobile() ? -140 : -90
		})
	}

	handleButtonFav(id) {
		let newApiDatas = this.state.apiDatas;
		const indexInTrainingFavorites = newApiDatas.TrainingsFavorites.findIndex(o => o.Training_ItemTraining_Id === id); // Copiez l'élément que vous souhaitez modifier

		if (indexInTrainingFavorites === -1) {
			//ajouter favori
			document.body.classList.add('is-loading')
            trackGA4Event(window.trackDatas, "training_add_favorite", "training", "training add favorite", "star icon");
			postApiDatas(`/apiaox/Call/AddTrainingFavorite?sIdTraining=${id}`, {})
			.then((res) => {
				document.body.classList.remove('is-loading')
				if (!res.IsValid) { alert(res.Message) }
				else {
					newApiDatas.TrainingsFavorites.push({ "Training_ItemTraining_Id": id });
					this.setState({ apiDatas: newApiDatas })
					if(!localStorage.getItem('dontShowAgainModalMAJFormationsFav')){
						this.ModalFormationsFav.openModal();
					}
				}
			});
		} else {
			//supprimer favori
			document.body.classList.add('is-loading')
			postApiDatas(`/apiaox/Call/RemoveTrainingFavorite?sIdTraining=${id}`, {})
			.then((res) => {
				document.body.classList.remove('is-loading')
				if (!res.IsValid) { alert(res.Message) }
				else {
					newApiDatas.TrainingsFavorites.splice(indexInTrainingFavorites, 1);
					this.setState({ apiDatas: newApiDatas })
					this.ModalFormationsFav.closeModal();
				}
			});
		}
	}

    //Affichage d'une liste d'item à partir d'une liste de SitecoreData de training
	getSectionFormation(scDataTrainings, mini){
		return scDataTrainings.map((scDataTraining, index) => {
            //regroupement des apiData du training ("Trainings", "UserBrandTrainings", "TrainingsFavorites") en 1 objet
			let apiDataTraining = this.state.apiDatas.Trainings?.find(o => o.Training_ItemTraining_Id.replace(/-/gi, "").toUpperCase() === scDataTraining.id);
			if(apiDataTraining){
				Object.assign(	apiDataTraining, 
								this.state.apiDatas.UserBrandTrainings.find(o => o.Training_ItemTraining_Id === apiDataTraining.Training_ItemTraining_Id), 
								{ isFavorite:this.state.apiDatas.TrainingsFavorites.some(o => o.Training_ItemTraining_Id === apiDataTraining.Training_ItemTraining_Id)}
				);
			}

			if (mini) {
				return <div key={index} className="item">
					<ObjSectionFormation scDataTraining={scDataTraining} apiDataTraining={apiDataTraining} mini={mini} handleButtonFav={this.handleButtonFav}/>
				</div>
			}
			else {
				return <ObjSectionFormation key={index} scDataTraining={scDataTraining} apiDataTraining={apiDataTraining} mini={mini} handleButtonFav={this.handleButtonFav}/>
			}
		}) 
	}

	render() {
		const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
		const scDatas = this.props.fields.data.CurrentTraining;
		const apiDatas = this.state.apiDatas;

		const sliderSettings = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: 4,
			slidesToScroll: 1,
			// infinite: this.state.miniParcoursList.length > 3 ? true : false
			responsive: [
				{
					breakpoint: 960,
					settings: {
						slidesToShow: 2,
						// infinite: this.state.pushList.length > 2 ? true : false
						infinite: false
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 2,
						infinite: false
					}
				}
			]
		}

		return (
			<>
				{ oCurrentItemFields && 
					<Text className="obj_title" field={oCurrentItemFields["Page Title"]} tag="h1"></Text> 
				}

				<section className="section_page-liste">
					<div className="str_container">
						<div className="obj_tabs-list" name="obj-tabs-list">
							<ul className="ctn_nav-tabs">
								{scDatas.MisEnAvant && scDatas.MisEnAvant[0].Trainings.length > 0 && 
									<li><button type="button" onClick={() => this.switchTab(0)}>{scDatas.MisEnAvant[0].pageTitle.value}</button></li>
								}
								<li><button type="button" onClick={() => this.switchTab(1)}>{this.props.t('Formation_AutresFormations')}</button></li>
							</ul>
							
							{/* SectionFormationDashboard */}
							{apiDatas.UserBrandExpertise &&
								<section className="section_formation-dashboard">
									<div className={"obj_bloc_formation " + apiDatas.UserBrandExpertise.Brand_CssClass}>
										<div className="obj_header_formation">
											<h3 className="t_title">Mon Expertise <strong>{apiDatas.UserBrandExpertise.Brand_Name}</strong></h3>
											<div className="t_tag"><span>{apiDatas.UserBrandExpertise.Score} <em>PTS</em></span></div>

											<div className="obj_progress-bar-expertise">
												<span><strong><em>{apiDatas.UserBrandExpertise.UserTrainingsOnLine_Completed} </em></strong>{apiDatas.UserBrandExpertise.UserTrainingsOnLine_Completed>1 ? "Formations réalisées":"Formation réalisée"}</span>

												<div className={"obj_progress-bar " + apiDatas.UserBrandExpertise.Brand_CssClass}>
													<div className="ctn_steps">
														{Array.from(Array(apiDatas.UserBrandExpertise.Brand_NbTrainingsOnLine), (e, i) => {
															if (i < apiDatas.UserBrandExpertise.UserTrainingsOnLine_Completed) {
																return <div key={i} className="step is-complete"></div>
															}
															return <div key={i} className="step"></div>
														})}
													</div>
												</div>
											</div>
										</div>

									</div>
								</section>
							}
							
							{/* SectionFormationMiniParcours */}
							{scDatas.MiniParcours?.length > 0 && scDatas.MiniParcours[0].Trainings.length > 0 &&
								<section className="obj_section section_mini_parcours">
									<div className="ctn_section">
										<div className="ctn_title">
											<h2 className="t_subtitle"><strong>{scDatas.MiniParcours[0].pageTitle.value}</strong></h2>
										</div>

										{	scDatas.MiniParcours[0].Trainings.length > sliderSettings.slidesToShow
											? <Slider className="obj_carousel obj_carrousel_mini_parcours" {...sliderSettings}>{ this.getSectionFormation(scDatas.MiniParcours[0].Trainings, true) }</Slider>
											: <div className="obj_carousel obj_carrousel_mini_parcours">{ this.getSectionFormation(scDatas.MiniParcours[0].Trainings, true) }</div>
										}
									</div>
								</section>
							}

							{/* MisEnAvant */}
							<section className="ctn_row obj_section section_list_formation">
								<div className="ctn_sub-row">
									{scDatas.MisEnAvant?.length > 0 && scDatas.MisEnAvant[0].Trainings.length > 0 && this.getSectionFormation(scDatas.MisEnAvant[0].Trainings, false)}
								</div>
							</section>

							{/* TrainingSegment & Trainings */}
							<section className="ctn_row obj_section section_list_formation">
								<div className="ctn_sub-row lvl-1">
									{scDatas.TrainingSegment?.length > 0 && scDatas.TrainingSegment.map((SegmentItem, index) => (
										<article key={index} className='ctn_page-liste'>
											<button type="button" data-id={"sub_" + SegmentItem.id} onClick={() => this.showLevel2("sub_" + SegmentItem.id)}>
												<LazyLoad>
													<figure className="fade-in">
														<Image field={SegmentItem.pageImage.jss}></Image>
													</figure>
												</LazyLoad>
												<Text field={SegmentItem.pageTitle.jss} tag="h3"></Text>
											</button>
										</article>
									))}

									{scDatas.Trainings?.length > 0 && this.getSectionFormation(scDatas.Trainings, false)}
								</div>

								{scDatas.TrainingSegment && scDatas.TrainingSegment.map((SegmentItem, index) => (
									<div key={index} className="ctn_sub-row lvl-2" id={"sub_" + SegmentItem.id}>
										<div className="obj_back-page">
											<button className="bt_back-page" type="button" onClick={() => this.hideLevel2()}>{this.props.t('retour')}</button>
										</div>
										<Text field={SegmentItem.pageTitle.jss} tag="h3"></Text>
										{SegmentItem.Trainings?.length > 0 && this.getSectionFormation(SegmentItem.Trainings, false)}
									</div>
								))}
							</section>
						</div>

						{/* TODO SGU message  */}
						<ModalFormationsFav 
							onRef={ref => (this.ModalFormationsFav = ref)} 
							modalTitle=""
							modalText="Vous venez d'ajouter cette formation à votre liste de favoris"
							modalCtaText="Retrouver mes favoris"
						/>
					</div>
				</section>
			</>
		)
	}
}

export default withTranslation()(withSitecoreContext()(PageFormationExpertise));