import React, { Component } from 'react';
import { Text, RichText, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import LazyLoad from 'react-lazyload'

class PageBrands extends Component {
	render() {
    
		const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
		
		const { BrandList } = this.props.fields.data;
 
		return (
			<section className="section_marques">
				<div className="str_container">
					
					<div className="ctn_top">
						{ oCurrentItemFields && <RichText field={oCurrentItemFields["Page Title"]} tag="h1"></RichText> }
					</div>
 
					<ul className="ctn_list-marques">
						{ BrandList.children.slice(5).map((marqueListItem, index) => (
							<li key={index} className="ctn_item-marques">
								<a href={marqueListItem.url}>
									<figure className="ctn_img">
										<LazyLoad>
											<picture className="fade-in">
												<Image field={marqueListItem.logoBrandsPage.jss}></Image>
											</picture>
										</LazyLoad>
									</figure>
									<strong>
                    					<Text field={marqueListItem.pageTitle.jss}></Text>
                  					</strong>
								</a>
							</li>
						))}
					</ul>

				</div>
			</section>
		)
	}
}

export default withSitecoreContext()(PageBrands);
