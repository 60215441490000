import React, { Component } from 'react'  
import { withTranslation } from 'react-i18next';
import { withSitecoreContext, Text, RichText } from '@sitecore-jss/sitecore-jss-react'; 
import axios from 'axios';  
import config from '../../temp/config';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom' 
import { GetLogoList } from '../../utilities/FrontFunctions/AoxFrontFunctions'

class NouveauCompte extends Component {
	constructor(props) {
		super(props)
		this.state = {
      redirect: false,
      redirectExpiredToken: false,
			serverError: false,
			formSubmitted: false,
			logoList: GetLogoList()
		}
	}
 
  componentDidMount() { 
    window.scrollTo(0,0)
    var oParam = queryString.parse(window.location.search);
    
    if(oParam.length === 0){
      this.setState({
        redirect: true
      }); 
    }else{
      if(oParam.prt  === undefined){
        this.setState({
          redirect: true
        }); 
      }else{
        if(oParam.prt === null){
          this.setState({
            redirect: true
          }); 
        }
      }
    }
    
    if(!this.state.redirect){
    // add loader
    document.body.classList.add('is-loading')
 
    axios.post(`${config.sitecoreApiHost}/apiaox/Call/RequestNewAccount`, 
      queryString.stringify({ 
        NewAccountToken : oParam.nat,
      }), 
      {
        headers : {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      }).then((result) => {
        this.setState({
          redirect: !result.data.Value.ErrorExpiredToken,
          redirectExpiredToken: result.data.Value.ErrorExpiredToken
        }); 
         
        document.body.classList.remove('is-loading')
      })
      .catch((err) => {
        console.log(err)
         
        this.setState({
          redirect: true
        }); 
         
        document.body.classList.remove('is-loading')
      });  
    }
    
	} 
	render() {
    const { PageExpiredConfirmation } = this.props.fields.data;
    const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
     
    if (this.state.redirectExpiredToken){
      return <Redirect to={ PageExpiredConfirmation != null && PageExpiredConfirmation.url} />;
    }

    if (this.state.redirect){
      return <Redirect to="/" />;
    }

    return (
     
      <section className="section_login">
				<div className="str_container">

        <header>
						<h6 className="logo-caf">
							<img src={require("../../assets/images/v_logo-caf_2022.svg")} alt={this.props.t('AltLogo')}/>
						</h6>
            { oCurrentItemFields && <RichText field={oCurrentItemFields["Page Title"]} tag="h1" /> }
					</header>

					<div className="ctn_login">
						<div className="ctn_col col_100">
              { oCurrentItemFields && <Text field={oCurrentItemFields["Page SubTitle"]} tag="h2" /> }

							<div className="obj_form"> 
                  <p className="t_mention mb_30">{this.props.t('NouveauCompte_PriseEnCompte')}</p>
                  <div className="ctn_cta">
                    <a className="obj_cta" href="/">{this.props.t('mdppage_retour')}</a>
                  </div>	 
              </div>
						</div>
					</div>

					<ul className="ctn_logos">
						{this.state.logoList.map((logoItem, index) => (
							<li key={index} className={"var_" + logoItem.id}>
								<img src={logoItem.image} alt={logoItem.label}/>
							</li>	
						))}
					</ul>

				</div>
			</section>
		)
	}
}

export default withTranslation()(withSitecoreContext()(NouveauCompte));