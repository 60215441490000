import React, { Component, useState } from "react";
import DefaultModal2 from '../Modal/DefaultModal2'

import { isMobile } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions";
import { getApiDatas, postApiDatas } from "../../utilities/FrontFunctions/AoxFrontFunctions";

class PageEventMyMembers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMobile: isMobile(),
			apiDatas: [],
            modalConfirmDeleteText: ""
		};
	}
	componentDidMount() {
		getApiDatas(`/apiaox/Event/GetDataPageMesMembres`).then((apiDatas) => {
			this.setState({ apiDatas: apiDatas });
		});

		document.body.classList.add("pg_challenge-detail");
		window.scrollTo(0, 0);
	}
	componentWillUnmount() {
		document.body.classList.remove("pg_challenge-detail");
	}
	toggleDeleted = (e) => {
        var id = e.currentTarget.id.slice(17);
        this.setState({ selectedUserToDelete:id });
        var userData = this.state.apiDatas.Membres.find(o => o.Id == id);
        this.state.modalConfirmDeleteText = userData.Name + " : " + userData.Mail;
        this.ModalConfirmDelete.openModal();
	};
	userDeleted = (e) => {
		document.body.classList.add("is-loading");
		postApiDatas("/apiaox/Challenge/PostUserEnable", {
			UserId: this.state.selectedUserToDelete,
			Enable: false,
		}).then((res) => {
			document.body.classList.remove("is-loading");
			getApiDatas(`/apiaox/Event/GetDataPageMesMembres`).then((apiDatas) => {
				this.setState({ apiDatas: apiDatas });
			});
			if (!res.IsValid) {
				alert(res.Message);
			}
		});
	};
	render() {
		const apiDatas = this.state.apiDatas;
        const ROLE_TITULAIRE = "TITULS"; 

		let tableHeaders = [
			{ name: "", field: "id", sortable: false },
			{ name: "Prénom/Nom", field: "Name", sortable: true },
			{ name: "E-Mail", field: "Mail", sortable: true },
			{ name: "Rôle", field: "RoleName", sortable: true },
			{ name: "Membre Actif", field: "IsDeleted", sortable: false }
		];

		const TableHeader = ({ headers, handleSort, sortedField, sortDirection }) => {
			return (
				<div className="obj_table-header">
					{headers.map((header, index) => (
						<div
							key={index}
							className={`header-cell ${header.sortable ? "sortable" : ""}${header.field === sortedField && sortDirection === 1 ? " active" : ""}`}
							onClick={() => handleSort(header.field)}
						>
							<span dangerouslySetInnerHTML={{ __html: header.name }} />
						</div>
					))}
				</div>
			);
		};

		const TableRow = ({ rowData }) => {
			return (
				<React.Fragment>
					<div className={`table-row`}>
                        <div className="row-cell"></div>
                        <div className="row-cell">{rowData.Name}</div>
						<div className="row-cell">{rowData.Mail}</div>
						<div className="row-cell">{rowData.RoleName}</div>
						<div className="row-cell">
                            {   
                                rowData.RoleCode === ROLE_TITULAIRE
                                ? <input className="tgl tgl-light" id={`checkbox_deleted_${rowData.Id}`} type="checkbox" checked={!rowData.IsDeleted} disabled={true} />
                                : <input className="tgl tgl-light" id={`checkbox_deleted_${rowData.Id}`} type="checkbox" defaultChecked={!rowData.IsDeleted} onChange={(e) => this.toggleDeleted(e)} />
                            }
							<label className="tgl-btn" htmlFor={`checkbox_deleted_${rowData.Id}`}></label>
						</div>
					</div>
				</React.Fragment>
			);
		};

		const Table = ({ tableData, headers }) => {
			const [sortedField, setSortedField] = useState("");
			const [sortDirection, setSortDirection] = useState(1);

			const handleSort = (field) => {
				setSortedField(field);
				setSortDirection(sortDirection === 1 ? -1 : 1);
			};

			const sortedTableData = tableData.sort((a, b) => {
				if (!sortedField) return 0;
				if (a[sortedField] > b[sortedField]) {
					return sortDirection;
				} else if (a[sortedField] < b[sortedField]) {
					return -sortDirection;
				}
				return 0;
			});

			return (
				<div className="obj_tableau-beneficiaire">
					<TableHeader headers={headers} handleSort={handleSort} sortedField={sortedField} sortDirection={sortDirection} />
					{sortedTableData.map((rowData) => (
						<TableRow key={rowData.id} rowData={rowData}/>
					))}
				</div>
			);
		};

		return (
			<>
				<section className="obj_section section_challenges">
					<div className="ctn_section">
						<div className="ctn_title">
							<h1 className="t_title">Mes collaborateurs</h1>
						</div>

						{(apiDatas.User?.RoleCode !== ROLE_TITULAIRE || !apiDatas.Membres) ? (
							<></>
						) : !this.state.isMobile ? (
							<Table tableData={apiDatas.Membres} headers={tableHeaders} />
						) : (
							<div class="obj_tableau-beneficiaire">
								{apiDatas.Membres.map((membre, index) => (
									<div class={`table-row${this.state.activeMenuGain ? " is-expanded" : ""}`} key={index}>
										<div class="table-header">
											<div class="row-cell">{membre.Name}</div>
										</div>
										<div class="table-ctn-row">
											<div class="header-cell">E-mail</div>
											<div class="row-cell">{membre.Mail}</div>
										</div>
										<div class="table-ctn-row">
											<div class="header-cell">Rôle</div>
											<div class="row-cell">{membre.RoleName}</div>
										</div>
                                        <div class="table-ctn-row">
                                            <div class="header-cell">Actif</div>
                                            <div class="row-cell">
                                                {   
                                                    membre.RoleCode === ROLE_TITULAIRE
                                                    ? <input class="tgl tgl-light" id={`checkbox_deleted_${membre.Id}`} type="checkbox" checked={membre.IsDeleted} disabled={true} />
                                                    : <input class="tgl tgl-light" id={`checkbox_deleted_${membre.Id}`} type="checkbox" defaultChecked={membre.IsDeleted} onChange={(e) => this.toggleDeleted(e)} />
                                                }
                                                <label class="tgl-btn" for={`checkbox_deleted_${membre.Id}`}></label>
                                            </div>
                                        </div>
									</div>
								))}
							</div>
						)}

                        <DefaultModal2
                            onRef={ref => (this.ModalConfirmDelete = ref)}
                            titleText="Confirmez vous la suppression de ce compte ?"
                            mainText = {this.state.modalConfirmDeleteText}
                            handleAction={this.userDeleted}
                            actionText="confirmer"
                        />

					</div>
				</section>
			</>
		);
	}
}

export default PageEventMyMembers;
