import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext, Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react'; 
import SectionComments from '../AllPages/SectionComments'
import LazyLoad from 'react-lazyload'

class PageActiviteConseil extends Component {
	render() {
         
    const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
    const {CurrentArticleReview, CurrentArticle } = this.props.fields.data; 
    
		return (
			 <> 
          {CurrentArticle && <input type="hidden" id="idref" value={CurrentArticle.idref}></input>}
          { oCurrentItemFields && <RichText className="obj_title-big" field={oCurrentItemFields["Page SubTitle"]} tag="h1"></RichText> }

          <article className={`obj_push-article fade-in ${CurrentArticle.getbrand.brandCssClass.value}`}>
            <div className="ctn_push-article">
              { oCurrentItemFields && <figure className="ctn_img">						 
                  <LazyLoad>
                    <Image className="fade-in" field={oCurrentItemFields["Page Image"]} />
                  </LazyLoad>					 
              </figure> }
              <div className="ctn_text">
                  { oCurrentItemFields && <Text field={oCurrentItemFields["Page Title"]} tag="h2" /> }
                  { oCurrentItemFields && <RichText className="t_main" field={oCurrentItemFields["Page Text"]} tag="p" /> }
              </div>
            </div>
          </article>  

			    <h2 className="obj_title">{this.props.t('VotreReponse')}</h2>
          <section className="section_article"> 
				    <div className="str_container">
              { oCurrentItemFields && <SectionComments  CurrentArticleReview={CurrentArticleReview} 
                                UserCanAddReview={oCurrentItemFields["User can add Reviews"] && oCurrentItemFields["User can add Reviews"].value}
                                legalFileForContest={oCurrentItemFields["Legal Contest"]?.value} 
                                hideCommentContent={oCurrentItemFields["Hide Content"]?.value}/> }
            </div>
          </section>
      </>
		)
	}
}

export default withTranslation()(withSitecoreContext()(PageActiviteConseil));