import React, { Component } from 'react'
import LazyLoad from 'react-lazyload'
import { withSitecoreContext, Text, Link, Image, RichText} from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';

import IconSVG from "../../utilities/FrontFunctions/IconSVG"

class SectionPushContact extends Component {
	render() {

		const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
		
		return (
			<section className="section_push-contact">
				<div className="ctn_text">
					{oCurrentItemFields && <div>
						{oCurrentItemFields["Contact Name"]?.value && 
            				<RichText field={oCurrentItemFields["Contact Name"]} tag="h2"/>
						}
						{oCurrentItemFields["Contact Phone"]?.value && 
							<h3>
								<IconSVG icon="phone" />
								<Text field={oCurrentItemFields["Contact Phone"]} tag=""/>
							</h3>
						}
						<RichText field={oCurrentItemFields["Contact Text"]} tag="p"/>
						<div className="ctn_cta">
							<a className="obj_cta var_picto var_email" href={oCurrentItemFields["Contact Url"] && oCurrentItemFields["Contact Url"].value.href}>
								<IconSVG icon="email" width="21" height="17" />
								{oCurrentItemFields["Contact Url"]?.value.text}
							</a>
						</div>
					</div>}
				</div>
				<figure className="ctn_img">
          			{ oCurrentItemFields && <Link field={oCurrentItemFields["Contact Url"]?.value}>
						<LazyLoad>
							<picture>
                				<source srcSet={oCurrentItemFields["Contact Image Mobile"]?.value.src} media="(max-width:767px)"/>
                				<Image media={oCurrentItemFields["Contact Image"]?.value}/>
							</picture>
						</LazyLoad>
					</Link>}
				</figure>
			</section>
		)
	}
}

export default withTranslation()(withSitecoreContext()(SectionPushContact));