import React, { Component } from 'react'

class ObjEntryHeroHome extends Component {
	render() {
		const {scDatas, apiDatas} = this.props;

		return (<>
			<div className="obj_entry_hero_home" id="tutorial_step_3">
				{scDatas && apiDatas.User && <div className="ctn_entry_hero_home">
					{scDatas.PageEvents?.url && <div className="obj_entry">
						<a href={scDatas.PageEvents.url}>						
							<div className="obj_entry_picto">
								{apiDatas.User.Event_NbRegistered > 0 && <span>{apiDatas.User.Event_NbRegistered}</span>}
								<img src={require("../../assets/images/i_entry_event.svg")} alt=""/>
							</div>
							<h3 className="t_title">Mes événements</h3>
							<ul className='obj_entry_list_info'>
								{	apiDatas.User?.Event_NbRegistered <= 0
									? <li className="is_empty"><span>Pas d'événement</span></li>
									: apiDatas.User.Event_NbRegistered === 1
										? <li><span>Vous êtes inscrit à {apiDatas.User.Event_NbRegistered} événement à venir</span></li>
										: <li><span>Vous êtes inscrit à {apiDatas.User.Event_NbRegistered} événements à venir</span></li>
								}
							</ul>
						</a>
					</div>}

					{scDatas.PageChallenges?.url && apiDatas.User.IsChallenge && <div className="obj_entry">
						<a href={scDatas.PageChallenges.url}>						
							<div className="obj_entry_picto">
								{ (apiDatas.User.Participation_NbToDeclare + apiDatas.User.Participation_NbToDisPatch > 0) && <span>{apiDatas.User.Participation_NbToDeclare + apiDatas.User.Participation_NbToDisPatch}</span>}
								<img src={require("../../assets/images/i_entry_challenge.svg")} alt=""/>
							</div>
							<h3 className="t_title">Mes Challenges</h3>
							<ul className='obj_entry_list_info'>
								{ 	(apiDatas.User.Participation_NbToDeclare + apiDatas.User.Participation_NbToDisPatch === 0)
									? <li className="is_empty"><span>Pas de challenges</span></li>
									: <>
										{	apiDatas.User.Participation_NbToDeclare > 0 && <li>
											<span>{	apiDatas.User.Participation_NbToDeclare===1
													?`${apiDatas.User.Participation_NbToDeclare} challenge à déclarer`
													:`${apiDatas.User.Participation_NbToDeclare} challenges à déclarer`
											}</span>
										</li> }
										{	apiDatas.User.Participation_NbToDisPatch > 0 && <li>
											<span>{	apiDatas.User.Participation_NbToDisPatch===1
													?`${apiDatas.User.Participation_NbToDisPatch} challenge à répartir`
													:`${apiDatas.User.Participation_NbToDisPatch} challenges à répartir`
											}</span>
										</li> }
									</>
								}
							</ul>
						</a>
					</div>}

					{scDatas.PageExpertise?.url && <div className="obj_entry">
						<a href={scDatas.PageExpertise.url}>						
							<div className="obj_entry_picto">
								<img src={require("../../assets/images/i_entry_expertise.svg")} alt=""/>
							</div>
							<h3 className="t_title">Mon Expertise</h3>
							<ul className='obj_entry_list_info'>
								<li><span>Score : {apiDatas.User.Score===0?"0pt":`${apiDatas.User.Score}pts`}</span></li>
							</ul>
						</a>
					</div>}

				</div>}
			</div>
		</>)
	}
}

export default ObjEntryHeroHome