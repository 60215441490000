import config from '../../temp/config';
import axios from 'axios'; 
import queryString from 'query-string';


export const isNotEmpty = (sValue) => {
	if(typeof sValue !== 'undefined'){
		return (sValue.length !== 0)
	}

	return false;
}

export function GetStatusFromScore(score){
	if( score < 25 ) return 'bronze';
	if( score >= 25 && score < 50 ) return 'argent';
	if( score >= 50 && score < 75 ) return 'or';
	if( score >= 75 ) return 'experte';
}

export const isAppMobile = () => {
	if(typeof window !== 'undefined'){

		// var oParam = queryString.parse(window.location.search);
		// if(oParam.mobileApp !== undefined){
		// 	return true;
		// }
		if (window.ReactNativeWebView!== undefined) {
			return true;
		}

		return false;
	}

	return false;
}

export function GetFriendlyUrl(oLink) {
	
	//GeneralLink field, media links and alwaysIncludeServerUrl - very broken
	//https://www.bezzant.dev/post/sitecore-jss-generallink-field-media-server-url/
	
	var sAbsoluteUrl = oLink.jss.value.href;
	var sRelativeUrl = oLink.url;

	var sFriendlyUrl = oLink.jss.value.href;

	if (sAbsoluteUrl.toString().indexOf("/sitecore/media-library/") > -1)
	{
		// eslint-disable-next-line
		var sRootUrl = sAbsoluteUrl.toString().replace(/^(.*\/\/[^\/?#]*).*$/,"$1");

		sFriendlyUrl = sRootUrl + sRelativeUrl;		
	}

	return sFriendlyUrl;
}

export function GetLogoList(){
	return [
		{ id: 'derm-expertise', label: 'Derm\'Expertise', image: require("../../assets/images/v_logo_derm-expertise_crop.png") },
		{ id: 'lrp', label: 'La Roche-Posay', image: require("../../assets/images/v_logo_lrp_crop.png")  },
		{ id: 'cerave', label: 'Cerave', image: require("../../assets/images/v_logo_cerave_crop.png") },
		{ id: 'vichy', label: 'Vichy', image: require("../../assets/images/v_logo_vichy_crop.png") },
		{ id: 'skinc', label: 'Skinceuticals', image: require("../../assets/images/v_logo_skinc_crop.png") },
		{ id: 'biotherm', label: 'Biotherm', image: require("../../assets/images/v_logo_biotherm_crop.png") },
		{ id: 'sciencemd', label: 'ScienceMD', image: require("../../assets/images/v_logo_sciencemd_crop.png") }
	];
}

export function GetListExpertise(oUser) {	
	return (oUser != null) ? [
		...(oUser.expertiselrp ? [{ label: 'Élite La Roche-Posay', image: require("../../assets/images/v_logo_vip-lrp.png")}] : []),
		...(oUser.expertisecerave ? [{ label: 'Élite Cerave', image: require("../../assets/images/v_logo_vip-cerave.png")}] : []),
		...(oUser.expertisevichy ? [{ label: 'Vip vichy', image: require("../../assets/images/v_logo_vip-vichy.png")}] : []),
		...(oUser.expertiseskinceuticals ? [{ label: 'Skincercle Skinceuticals', image: require("../../assets/images/v_logo_vip-skinceuticals.png")}] : []),
		...(oUser.expertisebiotherm ? [{ label: 'Élite Biotherm', image: require("../../assets/images/v_logo_vip-biotherm.png")}] : [])
	  ] : [];
}

export class ListProgressionBrands {
	constructor() {
		this.m_oListProgressionBrands = [];
	}	

	addBrand(oBrand) {
		this.m_oListProgressionBrands.push(oBrand);
	}

	getBrandFromClass(sCssClass) {
		var nIndexBrand = this.m_oListProgressionBrands.findIndex(b => b.CssClass === sCssClass);

		return (nIndexBrand >= 0) ? this.m_oListProgressionBrands[nIndexBrand] : null;
	}

	getBrandFromBody(sCssBodyClass) {
		
		var nIndexBrand = -1;

		this.m_oListProgressionBrands.forEach( (oBrand, index) => { 
			
			if (sCssBodyClass.includes(oBrand.CssClass))
			{
				nIndexBrand = index;				
			}
		} );

		return (nIndexBrand >= 0) ? this.m_oListProgressionBrands[nIndexBrand] : null;
	}
}

export function ConvertToListProgressionBrands(oConcatListProgressionBrands) {
	
	var oListProgressionBrands = new ListProgressionBrands();
	
	if (oConcatListProgressionBrands == null)
	{
		return oListProgressionBrands;
	}

	oConcatListProgressionBrands.forEach( (i, index) => {
			
		try
		{		
			var oBrand = {};
			
			var oDetailsBrand = i.split('||');

			if (oDetailsBrand.length >= 6)
			{		
				oBrand.CssClass = oDetailsBrand[0];
				oBrand.Label = oDetailsBrand[1];
				
				oBrand.AllTrainingExpertCount = isNaN(Number(oDetailsBrand[2])) ? 0 : Number(oDetailsBrand[2]);
				oBrand.TrainingDoCount = isNaN(Number(oDetailsBrand[3])) ? 0 : Number(oDetailsBrand[3]);
				oBrand.ExpertPourcentage = isNaN(Number(oDetailsBrand[4])) ? 0 : Number(oDetailsBrand[4]);
				
				oBrand.PageFormationUrl = oDetailsBrand[5];

				oBrand.Status = GetStatusFromScore(oBrand.ExpertPourcentage);;

				oListProgressionBrands.addBrand(oBrand);
			}
		}
		catch (error) {
			//Marque suivante
		}
	});

	return oListProgressionBrands;
}

export const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;


export function isDateValid(dateStr) {
    return !isNaN(new Date(dateStr));
}




// fonctions ajoutées challenges, events, expertise
export function urlToHost(url){
	return config.sitecoreApiHost + url;
}

//TODO SGU : pas pu reprendre la biblio d'Etienne; à réessayer ?
export async function getApiDatas(url) {	//retourne directement le Json de data
	let jsonApiDatas = [];

	try {
		const result = await axios.get(
			urlToHost(url), 
			{ withCredentials:true }
		);

		if (!result.data.IsValid) {
			console.log(result.data.Message);
		} else {
			let stringify = JSON.stringify(result.data.Value);
			let replace = stringify.replaceAll("\\r\\n", "<br>");
			jsonApiDatas = JSON.parse(replace);
		}
	} catch (err) {
		alert(err);
	}

	return jsonApiDatas;
}

export async function postApiDatas(url, datas){	//retourne un CAF_API.Models.Return
	let resultApiDatas = { IsValid:false, Message:"Error"}

	try {
		const result = await axios.post(
			urlToHost(url), 
			queryString.stringify(datas), 
			{withCredentials: true, headers : {Accept: 'application/json','Content-Type': 'application/x-www-form-urlencoded',}}
		)

		resultApiDatas = result.data
	} catch (err) {
		console.log(err);
	}

	return resultApiDatas
}

export async function apiDownload(urlDownload, filename) {
    axios
    .get(
        urlToHost(urlDownload), 
        { responseType: 'blob', withCredentials:true, }
    )
    .then(response => {
        const link = document.createElement('a');
    
        link.href = window.URL.createObjectURL(
            new Blob([response.data])
        );

        // Tell the browser to download, not render, the file.
        link.setAttribute('download', 'report.xlsx');

        // Place the link in the DOM.
        document.body.appendChild(link);

        // Make the magic happen!
        link.click();
    });
}


export function dayRemaining(sDate) {
	if(!isDateValid(sDate)){
		return -1;
	}

    const dateNow = new Date();dateNow.setHours(0);dateNow.setMinutes(0);dateNow.setSeconds(0);dateNow.setMilliseconds(0);
    const dateTo = new Date(sDate);

    if(dateNow > dateTo){
        return -1;
    }

    return Math.ceil((dateTo.getTime() - dateNow.getTime()) / (1000 * 3600 * 24));
}

export function dayFrom(sDate) {
	if(!isDateValid(sDate)){
		return -1;
	}

    const dateNow = new Date();dateNow.setHours(0);dateNow.setMinutes(0);dateNow.setSeconds(0);dateNow.setMilliseconds(0);
    const dateTo = new Date(sDate);

    if(dateNow < dateTo){
        return -1;
    }

    return Math.ceil((dateNow.getTime() - dateTo.getTime()) / (1000 * 3600 * 24));
}

export function dateProgression(sDateStart, sDateEnd) {
	if(!isDateValid(sDateStart) || !isDateValid(sDateEnd)){
		return 0;
	}

    const dateStart = new Date(sDateStart);
    const dateEnd = new Date(sDateEnd);
    const dateNow = new Date();

    if (dateStart >= dateEnd || dateNow <= dateStart) {
        return 0;
    }
    return Math.min(Math.round((dateNow - dateStart) * 100 / (dateEnd - dateStart)), 100);
}

export function strDateToFr(strDate) {
	//strDate => "18/09/2023"
	if(!isDateValid(strDate)){
		return "";
	}

	return new Intl.DateTimeFormat('fr-Fr').format(new Date(strDate));
}

export function strDateToFrLong(strDate) {
	//strDate => "Lundi 18 Septembre 2023"
	if(!isDateValid(strDate)){
		return "";
	}

	return new Intl.DateTimeFormat('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).format(new Date(strDate));
}