import React from "react";

import { urlToHost } from "../../utilities/FrontFunctions/AoxFrontFunctions"

function ObjExpertiseClassement({ ranking, user }) {
    //ranking est une liste de jusqu'à 5 rank
    //2 user peuvent avoir un même rank
    //le userconnecté doit être affiché en priorité, ce qui n'est pas garantie par l'apiData
    //=> correctif
    let newRanking = ranking;
    if(user.Rank>0){
        //faire de la place pour le user
        if(ranking.some(o => o.FrontOfficeUser_Id === user.FrontOfficeUser_Id)){
            newRanking = ranking.filter(o => o.FrontOfficeUser_Id !== user.FrontOfficeUser_Id);
        } else {
            newRanking = ranking.slice(0, ranking.length-1);
        }
        
        //insertion du user en 1er de son rank
        let indexInsert = newRanking.findIndex(o => o.Rank >= user.Rank)
        if(indexInsert<0){ indexInsert = newRanking.length; }
        newRanking.splice(indexInsert, 0, user);
    }

    return (
        <div className="obj_lst_classement_exp">
            { newRanking.map((rank, index) => (
                <div key={index} className={`item_classement_exp${ rank.FrontOfficeUser_Id !== user.FrontOfficeUser_Id ? `` : ` current${rank.Evolution_Rank==='Up' ? ` var_up` : rank.Evolution_Rank==='Down' ? ` var_down` : ``}`}`}>
                    <picture className="ctn_image">
                        {	(rank.FrontOfficeUser_PhotoUrl && rank.FrontOfficeUser_PhotoUrl!==null)
                            ?<img src={urlToHost(rank.FrontOfficeUser_PhotoUrl)} alt={rank.FrontOfficeUser_FirstName ? rank.FrontOfficeUser_FirstName : ""}/>
                            :<img src={require("../../assets/images/v_avatar.png")} alt={rank.FrontOfficeUser_FirstName ? rank.FrontOfficeUser_FirstName : ""}/>
                        }
                    </picture>
                    <div className="ctn_text">
                        <div className="t_place_exp">
                            {rank.Rank < 1 ? <>-</> : rank.Rank === 1 ? (<>1<sup>er</sup></>) : (<>{rank.Rank}<sup>è</sup></>)}
                        </div>
                        <div className="t_name_exp">
                            {rank.FrontOfficeUser_Id === user.FrontOfficeUser_Id ? user.FrontOfficeUser_FirstName : rank.FrontOfficeUser_FirstName}
                        </div>
                        <div className="t_nbre_points_exp">{rank.Score<0 ? '-' : rank.Score}<em>Pts</em></div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default ObjExpertiseClassement;