export const setStyleFonts = () => {

	const brands = [
		{name: 'lrp', light: 'LocatorWeb-Light', regular: 'LocatorWeb-Regular', bold: 'LocatorWeb-Bold'},
		{name: 'vichy', light: 'FuturaPT-Light', regular: 'FuturaPT-Book', bold: 'FuturaPT-Bold'},
		{name: 'sanoflore', light: 'GillSans-Light', regular: 'GillSans-Regular', bold: 'GillSans-Bold'},
		{name: 'cerave', light: 'DinPro-Light', regular: 'DinPro-Regular', bold: 'DinPro-Bold'},
		{name: 'skinc', light: 'FuturaBT-Light', regular: 'FuturaBT-Book', bold: 'FuturaBT-Medium'},
		{name: 'biotherm', light: 'FuturaBT-Light', regular: 'FuturaBT-Book', bold: 'FuturaBT-Medium'},
		{name: 'sciencemd', light: 'LocatorWeb-Light', regular: 'LocatorWeb-Regular', bold: 'LocatorWeb-Bold'},
		{name: 'derm-expertise', light: 'Montserrat-Light', regular: 'Montserrat-Regular', bold: 'Montserrat-Bold'},
	];

	brands.forEach(brand => {
		const styleFonts = document.createElement('style');
		const name = brand.name;
		const light = brand.light;
		const regular = brand.regular;
		const bold = brand.bold;

		const styleFontsContent = [
			// section_wide-carousel
			".section_wide-carousel .obj_carousel .ctn_banner.var_"+ name +" .ctn_text h2,",
			".section_wide-carousel .obj_carousel .ctn_banner.var_"+ name +" .ctn_text h3,",
			".section_wide-carousel .obj_carousel .ctn_banner.var_"+ name +" .ctn_text p{",
			"	font-family:"+ light +";",
			"	}",
			".section_wide-carousel .obj_carousel .ctn_banner.var_"+ name +" .ctn_text h2 strong,",
			".section_wide-carousel .obj_carousel .ctn_banner.var_"+ name +" .ctn_text h4,",
			".section_wide-carousel .obj_carousel .ctn_banner.var_"+ name +" .ctn_text p strong{",
			"	font-family:"+ bold +";",
			"	}",
			// obj_push-custom
			".var_"+ name +" .obj_push-custom.var_full .ctn_text h3,",
			".var_"+ name +" .obj_push-custom.var_full .ctn_text h4,",
			".var_"+ name +" .obj_push-custom.var_full .ctn_text p{",
			"	font-family:"+ light +";",
			"	}",
			".var_"+ name +" .obj_push-custom.var_thumb h2,",
			".var_"+ name +" .obj_push-custom.var_full .ctn_text h2,",
			".var_"+ name +" .obj_push-custom.var_full .ctn_text p strong{",
			"	font-family:"+ bold +";",
			"	}",
			// obj_push-article
			".var_"+ name +" .obj_push-article .ctn_text h2{",
			"	font-family:"+ bold +";",
			"	}",
			// section_push-contact
			".var_"+ name +" .section_push-contact .ctn_text h2{",
			"	font-family:"+ bold +";",
			"	}",
			// section_social-network
			".var_"+ name +" .section_social-network h2{",
			"	font-family:"+ light +";",
			"	}",
			// section_article
			".var_"+ name +" .section_article .ctn_article h1{",
			"	font-family:"+ bold +";",
			"	}",
			// section_article-carousel
			".var_"+ name +" .section_article-carousel .ctn_banner h3{",
			"	font-family:"+ bold +";",
			"	}",
			".var_"+ name +" .section_article-carousel h2{",
			"	font-family:"+ light +";",
			"	}",
			// section_product-detail
			".var_"+ name +" .section_product-detail .ctn_top-detail h1{",
			"	font-family:"+ light +";",
			"	}",
			".var_"+ name +" .section_product-detail .ctn_top-detail h1 strong,",
			".var_"+ name +" .section_product-detail .ctn_top-detail h2,",
			".var_"+ name +" .section_product-detail .ctn_desc-product h3{",
			"	font-family:"+ bold +";",
			"	}",
			// section_product-carousel
			".var_"+ name +" .section_product-carousel h2{",
			"	font-family:"+ bold +";",
			"	}",
			// obj_push-product
			".var_"+ name +" .obj_push-product .t_title,",
			".var_"+ name +" .obj_push-product .t_title-routine{",
			"	font-family:"+ light +";",
			"	}",
			".var_"+ name +" .obj_push-product .t_title strong,",
			".var_"+ name +" .obj_push-product .t_title-routine::before{",
			"	font-family:"+ bold +";",
			"	}",
			// obj_title
			".var_"+ name +" .obj_title{",
			"	font-family:"+ bold +";",
			"	}",
			// section_page-liste
			".var_"+ name +" .section_page-liste .ctn_page-liste h3,",
			".var_"+ name +" .section_page-liste .ctn_row > h2 strong{",
			"	font-family:"+ bold +";",
			"	}",
			".var_"+ name +" .section_page-liste .ctn_row > h2{",
			"	font-family:"+ light +";",
			"	}",
			// section_formation-dashboard
			".var_"+ name +" .section_formation-dashboard .ctn_formation-dashboard h2{",
			"	font-family:"+ bold +";",
			"	}",
			".var_"+ name +" .section_formation-dashboard .ctn_formation-dashboard .t_status{",
			"	font-family:"+ bold +";",
			"	}",
			// section_header-ingredients
			".var_"+ name +" .section_header-ingredients .ctn_header-ingredients figure figcaption{",
			"	font-family:"+ regular +";",
			"	}",
			// section_liste-ingredients
			".var_"+ name +" .section_liste-ingredients .obj_liste-ingredients .ctn_text h2{",
			"	font-family:"+ bold +";",
			"	}",
			// section_detail-ingredients
			".var_"+ name +" .section_detail-ingredients h1,",
			".var_"+ name +" .section_detail-ingredients h2{",
			"	font-family:"+ bold +";",
			"	}",
			// obj_banner-product
			".var_"+ name +" .obj_banner-product .ctn_text h1,",
			".var_"+ name +" .obj_banner-product .ctn_text h2,",
			".var_"+ name +" .section_range-liste .ctn_range-liste > h3 strong{",
			"	font-family:"+ bold +";",
			"	}",
			".var_"+ name +" .section_range-liste .ctn_range-liste > h3{",
			"	font-family:"+ light +";",
			"	}",
			// obj_banner-routine
			".var_"+ name +" .obj_banner-routine h1{",
			"	font-family:"+ regular +";",
			"	}",
			// section_page-liste
			".var_"+ name +" .section_mini_parcours .obj_formation_mini_parcours h3{",
			"	font-family:"+ bold +";",
			"	}",
			".var_"+ name +" .section_page-liste .ctn_row > h2{",
			"	font-family:"+ light +";",
			"	}",
		].join('\n');

		document.head.appendChild(styleFonts);
		styleFonts.setAttribute('type', 'text/css');
		styleFonts.append(styleFontsContent);
	});
}

export default setStyleFonts;