import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

class ObjTitle extends React.Component {
	render() {	
		return (
      <RichText className="obj_title" field={this.props.fields["Push Title"]} tag="h2"/>
    )
  }
}

export default ObjTitle;
