import React, { Component } from 'react'
import { Image } from '@sitecore-jss/sitecore-jss-react';
import LazyLoad from 'react-lazyload'

class ObjBrandHighlightedHeroHome extends Component {
	constructor(props) {
		super(props)
		this.state = {
		}
	}
	render() {
		const {scFields, scDatas} = this.props;
		const brandItem = scDatas.BrandList?.children[0];

		return (
			<div className="obj_brand_highlighted_hero" id="tutorial_step_2">
				{ brandItem && <a href={brandItem.url}>
					<div className="ctn_row">
						<div className="ctn_logo">
							<LazyLoad>
								<picture className="fade-in">
									{brandItem.logoHome && <Image field={brandItem.logoHome.jss}></Image>}
								</picture>
							</LazyLoad>
							<p className="t_title">Mes modules</p>
						</div>

						<div className="ctn_text">
							{scFields && <p dangerouslySetInnerHTML={{__html: scFields["Page Text"]?.value}}/>}
						</div>
					</div>
				</a> }
			</div>
		)
	}
}

export default ObjBrandHighlightedHeroHome