import React, { Component } from 'react'
import Modal from 'react-modal'
import { throttle, isMobile } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions"

const customStyles = {
	overlay : {
		zIndex			: '99999',
		backgroundColor	: 'rgba(0,0,0,0.6)'
	},
	content : {
		position	: 'fixed',
		top			: '50%',
		left		: '50%',
		right		: 'auto',
		bottom		: 'auto',
		transform	: 'translate(-50%, -50%)',
		outline		: '0'
	}
}

Modal.setAppElement('#root')

class ModalProduitConcernes extends Component {
	constructor() {
		super()
		this.state = {
			modalIsOpen: false,
			isMobile: isMobile()
		}
		this.handleWindowResizeMainHeader = this.handleWindowResizeMainHeader.bind(this)
		this.throttleWindowResizeMainHeader = throttle(this.handleWindowResizeMainHeader, 200)
		this.openModal = this.openModal.bind(this)
		this.closeModal = this.closeModal.bind(this)
	}
	componentDidMount() {
		this.props.onRef(this)
	}
	componentWillUnmount() {
		this.props.onRef(null)
	}
	openModal() {
		this.setState({modalIsOpen: true})
	}
	closeModal() {
		this.setState({modalIsOpen: false})
	}
	handleWindowResizeMainHeader() {
		this.setState({
			isMobile: isMobile()
		})
	}

	render() {
		return (
			<>
				<Modal
					isOpen={this.state.modalIsOpen}
					onRequestClose={this.closeModal}
					style={customStyles}
					className="obj_popin popin_produits-concernes"
					closeTimeoutMS={300}
					contentLabel="Modal"
				>
					<button className="bt_close" onClick={this.closeModal}></button>
					<div className="ctn_popin">
						<div class="ctn_title">
							<p class="t_title">Liste des produits concernés</p>
						</div>

						{!this.state.isMobile
							?<div className="obj_table-produit-concernes">
								<div className="ctn_row-header">
									<p>gencod</p>
									<p>libellé produit</p>
									<p>contenance</p>
								</div>

								{ this.props.produits.map((produit, index) => (
									<div className="ctn_row" key={index}>
										<p>{produit.Product_Ean}</p>
										<p>{produit.Product_Name}</p>
										<p>{produit.Product_Capicity}</p>
									</div>
								))}
							</div>	
							:<div className="obj_table-produit-concernes">
								{this.props.produits.map((produit, index) => (			
									<div key={index} className="ctn_row">
										<p className="ctn_row-title">{produit.Product_Name}</p>
										<div className="ctn_row">
											<p className="t_title">Gencod</p>
											<p>{produit.Product_Ean}</p>
										</div>
										<div className="ctn_row">
											<p className="t_title">contenance</p>
											<p>{produit.Product_Capicity}</p>
										</div>
									</div>
								))}
							</div>
						}
					</div>
				</Modal>
			</>
		)
	}
}

export default ModalProduitConcernes