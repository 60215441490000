import React, { Component } from 'react' 
import { withTranslation } from 'react-i18next';

class ObjBackPage extends Component {
	render() {
        
        const { item } = this.props.fields.data; 

		return (
			<div className="obj_back-page">
				<a className="bt_back-page" href={item.parent.url}>{this.props.t('Retour')}</a>
			</div>
		)
	}
}

export default withTranslation()(ObjBackPage);