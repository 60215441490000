import React from 'react';
import { Placeholder, VisitorIdentification, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { loadReCaptcha } from 'react-recaptcha-v3'
import ObjBackToTop from './components/AllPages/ObjBackToTop'; 
import MetaSitecore from  './components/AllPages/MainMeta'; 
import { withTranslation } from 'react-i18next';
import { isAppMobile } from "./utilities/FrontFunctions/AoxFrontFunctions"

// CSS
//import './assets/css/AOX.css'
import './assets/css/index.css'
import { setStyleColors } from './assets/css/colors'
import { setStyleFonts } from './assets/css/fonts'

class Layout extends React.Component { 

  componentDidMount() {
		// recaptcha v3
		loadReCaptcha(this.props.t('recaptcha_sitekey'));

    setStyleColors();
    setStyleFonts();
	}
  render() {
  
  return (
    <React.Fragment>
      
      <MetaSitecore />
      <VisitorIdentification />
 
      <Placeholder name="main_header" rendering={this.props.route} /> 

      <ObjBackToTop />
      
      <main className={`main_container ${isAppMobile() ? "mobileApp" : "" }`}>
        <Placeholder name="main_container" rendering={this.props.route} />
      </main>

      <Placeholder name="main_footer" rendering={this.props.route} /> 
 
    </React.Fragment>
  )}
};

//export default Layout;
export default withTranslation()((withSitecoreContext()(Layout)));
