import React from 'react';
import {GetStatusFromScore} from '../../utilities/FrontFunctions/AoxFrontFunctions'
import IconSVG from "../../utilities/FrontFunctions/IconSVG"

class ObjExpertiseBrand extends React.Component {
	render() {	
        const { pourcentage, levelexpertanciennete } = this.props; 
		return (            
            <>
            {pourcentage >=0 && 
                <>
                    <p className="t_percent">{pourcentage}<span>%</span></p>
                    <div className={"obj_star-score var_" + GetStatusFromScore(Number(pourcentage))}>
                        <IconSVG icon="star" />
                        <p className="t_year">{levelexpertanciennete}</p>
                        <p className="t_status">{GetStatusFromScore(Number(pourcentage))}</p>
                    </div>
                </>
            }
            {pourcentage === -9999 &&
                <p className="t_percent">-</p>
            }
            </>
        )
    }
}

export default ObjExpertiseBrand;

