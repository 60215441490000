import React from "react"
import { IconsSVG } from "../../assets/images/icon-sprite"

const IconSVG = props => {
	const { icon } = props
	return (
		<svg className={props.className ? props.className : ''} viewBox={IconsSVG[icon].viewBox} width={props.width ? props.width : IconsSVG[icon].width} height={props.height ? props.height : IconsSVG[icon].height} dangerouslySetInnerHTML={{__html: IconsSVG[icon].svgCode}} />
	)
}

export default IconSVG