import { withSitecoreContext, Image, RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { GetFriendlyUrl } from "../../utilities/FrontFunctions/AoxFrontFunctions";
import IconSVG from "../../utilities/FrontFunctions/IconSVG";

class PageProduct extends Component {
	handleClickPush(TrainingItem) {
		window.open(GetFriendlyUrl(TrainingItem.trainingUrl));
	}

	render() {
		const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
		const { RelatedTrainings } = this.props.fields.data;

		return (
			<section className="section_product-detail">
				<div className="str_container">
					<div className="ctn_top-detail">
						<div className="ctn_col col_image">
							{oCurrentItemFields && (
								<figure>
									<Image field={oCurrentItemFields["Page Image"]}></Image>

									{oCurrentItemFields["Capacity"]?.value && (
										<figcaption>
											<IconSVG icon="tube" />
											<RichText field={oCurrentItemFields["Capacity"]}></RichText>
										</figcaption>
									)}
								</figure>
							)}
							{RelatedTrainings && RelatedTrainings.trainingPush && RelatedTrainings.trainingPush.targetItems && (
								<ul>
									{RelatedTrainings.trainingPush.targetItems.map((pushTrainingItem, index) => (
										<li key={index}>
											<button
												type="button"
												onClick={() => {
													this.handleClickPush(pushTrainingItem);
												}}
											>
												<div className="ctn_img">
													<Image field={pushTrainingItem.pageImage.jss}></Image>
												</div>
												<Text field={pushTrainingItem.pageTitle.jss} tag="span"></Text>
											</button>
										</li>
									))}
								</ul>
							)}
						</div>
						<div className="ctn_col col_title">
							{/*<h1><strong>Lipikar</strong> <br/>Baume <em>AP+<strong>M</strong></em></h1>*/}
							{oCurrentItemFields && <RichText tag="h1" field={oCurrentItemFields["Page Title"]}></RichText>}
							{oCurrentItemFields && <RichText tag="h2" field={oCurrentItemFields["Page SubTitle"]}></RichText>}
						</div>
						<div className="ctn_col col_text">
							{oCurrentItemFields && oCurrentItemFields["Plus produit"]?.value && (
								<div className="ctn_desc-product var_plus">
									<h3>{this.props.t("PageProduct_LePlusProduit")}</h3>
									<RichText tag="p" field={oCurrentItemFields["Plus produit"]}></RichText>
								</div>
							)}
							{oCurrentItemFields && oCurrentItemFields["Pour qui"]?.value && (
								<div className="ctn_desc-product">
									<h3>{this.props.t("PageProduct_PourQui")}</h3>
									<RichText field={oCurrentItemFields["Pour qui"]}></RichText>
								</div>
							)}
						</div>
					</div>

					{oCurrentItemFields && oCurrentItemFields["Benefices"]?.value && (
						<div className="ctn_detail">
							<div className="ctn_desc-product">
								<h3>{this.props.t("PageProduct_BeneficesActions")}</h3>
								<RichText field={oCurrentItemFields["Benefices"]}></RichText>
							</div>
						</div>
					)}

					{oCurrentItemFields && oCurrentItemFields["Resultats"]?.value && (
						<div className="ctn_detail">
							<div className="ctn_desc-product var_grey">
								<h3>{this.props.t("PageProduct_Resultats")}</h3>
								<RichText field={oCurrentItemFields["Resultats"]}></RichText>
							</div>
						</div>
					)}

					{oCurrentItemFields && oCurrentItemFields["Texture"]?.value && (
						<div className="ctn_detail">
							<div className="ctn_desc-product var_texture">
								<h3>{this.props.t("PageProduct_TextureApplications")}</h3>
								<RichText field={oCurrentItemFields["Texture"]}></RichText>
								{oCurrentItemFields["Texture Image"] && <Image field={oCurrentItemFields["Texture Image"]}></Image>}
							</div>
						</div>
					)}

					{oCurrentItemFields && oCurrentItemFields["Composition"]?.value && (
						<div className="ctn_detail">
							<div className="ctn_desc-product var_composition">
								<h3>{this.props.t("PageProduct_Composition")}</h3>
								<RichText field={oCurrentItemFields["Composition"]}></RichText>
							</div>
						</div>
					)}
				</div>
			</section>
		);
	}
}

export default withTranslation()(withSitecoreContext()(PageProduct));
