import React, { Component } from "react";
import { Text, RichText, Image, Link, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { throttle, sectionFullWidth } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions";
import { withTranslation } from "react-i18next";
import Slider from "react-slick";
import LazyLoad from "react-lazyload";
import InfosModalMAJ from "../Modal/InfosModalMAJ";

class PageHomeCAF extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pushsToShow: 6,
			pushsInterval: 6,
		};
		this.showMorePushs = this.showMorePushs.bind(this);
		this.refreshCarouselHeight = this.refreshCarouselHeight.bind(this);
		this.handleWindowResizeWideCarousel = this.handleWindowResizeWideCarousel.bind(this);
		this.throttleWindowResizeWideCarousel = throttle(this.handleWindowResizeWideCarousel, 100);
		this.handleWindowResizeBrandSelection = this.handleWindowResizeBrandSelection.bind(this);
		this.throttleWindowResizeBrandSelection = throttle(this.handleWindowResizeBrandSelection, 100);
		this.sectionBrandSelection = React.createRef();
	}
	showMorePushs() {
		this.setState((prevState) => ({
			pushsToShow: prevState.pushsToShow + this.state.pushsInterval,
		}));
	}
	componentDidMount() {
		window.addEventListener("resize", this.throttleWindowResizeWideCarousel);
		window.addEventListener("resize", this.throttleWindowResizeBrandSelection);
		window.dispatchEvent(new Event("resize"));

		if (
			this.props.fields.data != null &&
			this.props.fields.data.CurrentUser != null &&
			this.props.fields.data.CurrentUser.needcontactinformation != null &&
			this.props.fields.data.CurrentUser.needcontactinformation === true
		) {
			// ouverture popin Info
			this.InfosModalMAJ.openModal();
		}
	}
	componentWillUnmount() {
		window.removeEventListener("resize", this.throttleWindowResizeBrandSelection);
		window.removeEventListener("resize", this.throttleWindowResizeWideCarousel);
	}
	refreshCarouselHeight() {
		var wideCarousel = document.querySelector(".wide-carousel"),
			slickList = wideCarousel.querySelector(".slick-list"),
			carouselHeight = wideCarousel.clientHeight;
		slickList.style.height = carouselHeight + "px";
	}
	handleWindowResizeWideCarousel() {
		this.refreshCarouselHeight();
	}
	handleWindowResizeBrandSelection() {
		sectionFullWidth(this.sectionBrandSelection.current);
	}
	render() {
		const settings = {
			lazyLoad: true,
			autoplay: true,
			autoplaySpeed: 5000,
			dots: true,
			infinite: true,
			speed: 750,
			slidesToShow: 1,
			slidesToScroll: 1,
		};
		const settingsBrands = {
			lazyLoad: true,
			autoplay: true,
			autoplaySpeed: 2500,
			fade: true,
			dots: false,
			infinite: true,
			speed: 750,
			slidesToShow: 1,
			slidesToScroll: 1,
			pauseOnHover: false,
		};

		const { BrandList, DataForHomeCAF, AccueilCafMarques } = this.props.fields.data;

		const oCurrentItemFields = this.props.sitecoreContext.route?.fields;

		return (
			<>
				<section className="section_brand-selection var_2023" ref={this.sectionBrandSelection}>
					<div className="str_container">
						<ul>
							{BrandList.children.slice(0, 5).map((marqueListItem, index) => (
								<li key={index} className={marqueListItem.brandCssClass.jss.value}>
									<a href={marqueListItem.url}>
										<figure>
											<LazyLoad>
												<picture className="fade-in">
													<source srcSet={marqueListItem.logoSliderMobile.jss.value.src} media="(max-width:767px)" />
													<Image field={marqueListItem.logoSlider.jss}></Image>
												</picture>
											</LazyLoad>
										</figure>
										<div className="ctn_logo">
											<Image field={marqueListItem.logo.jss}></Image>
										</div>
									</a>
								</li>
							))}
							{AccueilCafMarques && BrandList.children.length > 5 && (
								<li className="var_others">
									<div className="brands_carousel_container">
										<Slider className="obj_carousel obj_brand-carousel" {...settingsBrands}>
											{BrandList.children.slice(5).map((marqueListCarouselItem, index) => (
												<div key={index} className={"var_" + marqueListCarouselItem.id}>
													<a href={AccueilCafMarques.url}>
														<figure>
															<LazyLoad>
																<picture className="fade-in">
																	<source srcSet={marqueListCarouselItem.logoSliderMobile.jss.value.src} media="(max-width:767px)" />
																	<Image field={marqueListCarouselItem.logoSlider.jss}></Image>
																</picture>
															</LazyLoad>
														</figure>
													</a>
												</div>
											))}
										</Slider>

										<a href={AccueilCafMarques.url}>
											<div className="ctn_cta-others">
												<div>
													<span></span>
													{this.props.t("ShowMoreOtherBrands")}
												</div>
											</div>
										</a>
									</div>
								</li>
							)}
						</ul>
					</div>
				</section>

				{oCurrentItemFields && <Text className="obj_title" field={oCurrentItemFields["Page SubTitle"]} tag="h1" />}

				<section className="section_wide-carousel">
					<div className="str_container">
						<Slider className="obj_carousel wide-carousel" {...settings}>
							{DataForHomeCAF.getFirstBrandBanners.map((bannerItem, index) => (
								<div key={index}>
									<div
										className={`ctn_banner fade-in${bannerItem.darkMode.boolValue ? " var_dark" : ""}
                                                    ${bannerItem.styleBrand.targetItem ? bannerItem.styleBrand.targetItem.brandCssClass.value : ""}
                                                    ${bannerItem.align ? " var_" + bannerItem.align.value : ""}`}
										bannerid={bannerItem.id}
									>
										{bannerItem.template.id === "31C956382BE1472095B3E8D7A9E8C60D" && (
											<div className="ctn_text">
												{bannerItem.ontitle.jss && <RichText field={bannerItem.ontitle.jss} tag="h4" />}
												{bannerItem.title.jss && <RichText field={bannerItem.title.jss} tag="h2" />}
												{bannerItem.posttitle.jss && <RichText field={bannerItem.posttitle.jss} tag="h3" />}
												{bannerItem.content.jss && <RichText field={bannerItem.content.jss} tag="p" />}
											</div>
										)}
										<picture>
											<source srcSet={bannerItem.imageMobile.jss.value.src} media="(max-width:767px)" />
											<Image field={bannerItem.image.jss}></Image>
										</picture>
										<Link className={`obj_cta${bannerItem.darkMode.boolValue ? " reverse" : ""}`} field={bannerItem.link.jss} />;
									</div>
								</div>
							))}
						</Slider>
					</div>
				</section>

				<InfosModalMAJ onRef={(ref) => (this.InfosModalMAJ = ref)} modalUrlInfosPeros={this.props.fields.data.PageInfoPerso.url} />
			</>
		);
	}
}

export default withTranslation()(withSitecoreContext()(PageHomeCAF));