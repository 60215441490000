import React, { Component } from 'react'
import { Image } from '@sitecore-jss/sitecore-jss-react';

import ObjBrandsCarouselHero from './ObjBrandsCarouselHero'

class ObjBrandHeroHome extends Component {
	constructor(props) {
		super(props)
		this.state = {
		}
		this.ObjBrandHeroHome = React.createRef()
	}
	componentDidMount() {
		window.addEventListener('resize', this.throttleWindowResizeBrandSelection)
		window.dispatchEvent(new Event('resize'))
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.throttleWindowResizeBrandSelection)
	}

	render() {
		const {scDatas} = this.props;

		return (
			<div className="obj_brand_hero_home" id="tutorial_step_1">
				<div className="ctn_brand_hero_home">
					<ul>
						{ scDatas.BrandList?.children.slice(1, 5).map((brandItem, index) => 
							<li key={index} className={"obj_item_brand_hero " + brandItem.brandCssClass.jss.value}>
								<a href={brandItem.url}>
									<div className="ctn_text">
										<div className="ctn_logo">
											<Image field={brandItem.logoHome.jss}></Image>
										</div>
										<p className="t_title">Mes modules</p>
									</div>
								</a>
							</li>
						)}
						<li className="obj_item_brand_other_hero">
							<ObjBrandsCarouselHero scDatas={scDatas}/>
						</li>
					</ul>
				</div>
			</div>
		)
	}
}

export default ObjBrandHeroHome