import React, { Component } from 'react'
import { Link } from '@sitecore-jss/sitecore-jss-react';  
import { withTranslation } from 'react-i18next'
import IconSVG from "../../utilities/FrontFunctions/IconSVG";
import { isAppMobile } from "../../utilities/FrontFunctions/AoxFrontFunctions"

class MainFooter extends Component {
	
	constructor(props) {
		super(props)
  
		this.state = {
			isAppMobile: isAppMobile()			
		}
	}

	render() {
		if (this.state.isAppMobile)
		{
			return <></>
		}	

        const { loading, MainNavCaf, MainNavCafExternal, navCompte, SecondNavMobile, mainNavFooter, AccueilCaf, CurrentUser } = this.props.fields.data;

		return (<> 
			{CurrentUser && 
				<footer className="main_footer">
					<div className="str_container">
						<div className="ctn_top">
							<h6 className="logo-caf">
								{!loading &&  
									<a href={AccueilCaf && AccueilCaf.url}>
										<img src={require("../../assets/images/v_logo-pa.svg")} alt={this.props.t('AltLogo')}/>
									</a>
								}
							</h6>
							<nav className="main_nav-footer">
								<ul>
									<li><strong>{this.props.t('FooterTitleNavMarque')}</strong></li>
									{!loading && [...MainNavCaf.children, ...(MainNavCafExternal?.children?.length>0 ? MainNavCafExternal.children: [])].map((navMarqueItem, index) => (
										<li key={index}>
											<Link field={navMarqueItem.destination.jss}></Link> 
										</li>
									))}
								</ul>
								<ul id="tutorial_step_5">
									{!loading &&  mainNavFooter.children.map((mainNavFooterItem, index) => (
										(mainNavFooterItem.id!=="750FF9D7498B47BD872027F28413A4EB" || CurrentUser?.isChallenge)
										&& <li key={index} className="var_bold">
											<Link field={mainNavFooterItem.destination.jss}>
                                                {mainNavFooterItem.destination.text}
                                                {mainNavFooterItem.cSSClass.value == "facebook" && <IconSVG className="i_facebook-circle" icon="facebook_circle" />}
                                            </Link>
										</li>
									))}
								</ul>
								<ul>
									<li><strong>{this.props.t('FooterTitleNavCompte')}</strong></li>
									{!loading && navCompte.children.map((navCompteItem, index) => (
										<li key={index}><Link field={navCompteItem.destination.jss}></Link></li>
									))}
								</ul>
							</nav>
						</div>
						<div className="ctn_bottom">
							<nav className="second_nav-footer">
								<ul>
									{!loading &&  SecondNavMobile.children.map((secondNavFooterItem, index) => (
										<li key={index} className="var_bold">
											<Link field={secondNavFooterItem.destination.jss}></Link>
										</li>
									))}								
									<li> 
										
										<button id="ot-sdk-btn" className="ot-sdk-show-settings">Gestion des cookies</button>
									</li>
								</ul>
							</nav>
							<p className="t_mentions">{this.props.t('FooterMention')}</p>
						</div>
					</div>
				</footer>
			}
			</>
		)
	}
}

export default withTranslation()(MainFooter)