import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'; 
import queryString from 'query-string';

import { getApiDatas } from "../../utilities/FrontFunctions/AoxFrontFunctions"

import ObjRetour from "../Obj/ObjRetour";
import ObjEventDetail from '../ObjEvent/ObjEventDetail';

class PageEventDetail extends Component {
	constructor(props) {
		super(props)
		this.state = {
			apiDatas:[],
			idSession:null,
		}
	}
	componentDidMount() {
		var oParams = queryString.parse(window.location.search);
		if(oParams.id !== undefined){
			getApiDatas(`/apiaox/Event/GetDataPageEventDetail?id=${oParams.id}`)
			.then((apiDatas) => { this.setState({ apiDatas:apiDatas, idSession:oParams.idSession }) })
		}

		document.body.classList.add('pg_formation_detail')
		window.scrollTo(0,0);
  	}
	componentWillUnmount() {
		document.body.classList.remove('pg_formation_detail')
	}
	render() {
		const scDatas = this.props.fields.data;
		const apiDatas = this.state.apiDatas;
        const Event = apiDatas?.Event;

		return (<>
			<ObjRetour/>
			
			<section className="obj_section section_formation_detail">
				<div className="ctn_section">
					{ Event && <>
						<div className="ctn_title">
							<h1 className="t_title">
								{Event.Event_Title}
							</h1>
						</div>

						<div className="ctn_row">
							<ObjEventDetail event={Event} eventSessionId={this.state.idSession} user={scDatas.CurrentFOU}/>
						</div>
					</>}
				</div>
			</section>
		</>)
	}
}

export default withTranslation()(PageEventDetail)