import React, { Component } from 'react' 
import { Text, Image, withSitecoreContext, RichText } from '@sitecore-jss/sitecore-jss-react'  
import { withTranslation } from 'react-i18next';

class PageIngredient extends Component {
	render() {

    const oCurrentItemFields = this.props.sitecoreContext.route?.fields;  
    const { CurrentIngredient } = this.props.fields.data;  

		return (
      <section className="section_detail-ingredients">
				{ oCurrentItemFields && <div className="str_container">

					<div className="ctn_header-ingredients">
						<figure className="ctn_image">
							<Image field={oCurrentItemFields["Page Image"]}></Image>
						</figure>
						<div className="ctn_text">
              <Text field={oCurrentItemFields["Page Title"]} tag="h1"></Text>
              <RichText field={oCurrentItemFields["Page Text"]} tag="p"></RichText>
						</div>
					</div>

          {oCurrentItemFields["Proprietes cosmetiques"] && oCurrentItemFields["Proprietes cosmetiques"].value && 
            <div className="ctn_proprietes-ingredients">
              <h2>{this.props.t('Ingredient_Titre_ProprietesCosmetiques')}</h2>
              <RichText field={oCurrentItemFields["Proprietes cosmetiques"]}></RichText>
            </div>
          }
					
          {CurrentIngredient && CurrentIngredient.children && CurrentIngredient.children.length > 0 &&
					<div className="ctn_identite-ingredients">
                <h2>{this.props.t('Ingredient_Titre_CarteIdentiteBotanique')}</h2>
                <div className="ctn_table-ingredients">
                  {CurrentIngredient && 
                    CurrentIngredient.children.map((elem, index) => (
                      <div className="ctn_row" key={index}>
                        <Text field={elem.key.jss} tag="p"></Text>
                        <Text field={elem.value.jss} tag="p"></Text>
                      </div>
                    ))} 
                </div>
					</div>
          }

					<div className="ctn_bienfaits-ingredients">
            {oCurrentItemFields["Bienfaits"] && oCurrentItemFields["Bienfaits"].value &&
              <div className="ctn_col col_left">
                <h2>{this.props.t('Ingredient_Titre_Bienfaits')}</h2>
                <RichText field={oCurrentItemFields["Bienfaits"]}></RichText>
              </div>
            }
            {oCurrentItemFields["Botanique"] && oCurrentItemFields["Botanique"].value &&
              <div className="ctn_col col_right">
                <h2>{this.props.t('Ingredient_Titre_Botanique')}</h2>
                <RichText field={oCurrentItemFields["Botanique"]}></RichText>
              </div>
            }
					</div>

          </div> }
			</section>
		)
	}
}

export default withTranslation()(withSitecoreContext()(PageIngredient));