import React, { Component } from 'react'

class FilterInput extends Component {
	constructor(props) {
		super(props)
		this.state = {
			value: '',
		}
		this.handleReset = this.handleReset.bind(this)
	}
	handleReset(){
		this.setState({
			value: '',
		}, () =>{
			this.props.onChange(this.state.value)
		})
	}
	render() {
		return(
			<div className="obj_filter-input">
				{this.props.label &&
					<label className="t_filter-input">{this.props.label}</label>
				}
				<div className="ctn_input">
					<input
						type="text"
						id={this.props.id}
						name={this.props.name}
						placeholder={this.props.placeholder}
						onChange={(e) => {
							this.setState({
								value: e.target.value,
							}, () => {
								this.props.onChange(this.state.value)
							})
						}}
						value={this.state.value}
					/>
					{this.state.value !== '' &&
						<button className="bt_reset-input" type="button" onClick={this.handleReset}></button>
					}
				</div>
			</div>
		)
	}
}

export default FilterInput
