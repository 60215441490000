import React, { Component } from 'react' 
import { withTranslation } from 'react-i18next';
import { withSitecoreContext, Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react'; 
import VideoModal from '../Modal/VideoModal'
import config from '../../temp/config';
import IconSVG from "../../utilities/FrontFunctions/IconSVG"
import SectionComments from '../AllPages/SectionComments'
import { Link as ScrollLink } from 'react-scroll'

import { isMobile, matchHeight } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions"
import Slider from "react-slick"
 
import Moment from 'react-moment';  
import axios from 'axios'; 

class PageArticle extends Component{
	constructor(props) {
		super(props)
		this.state = {
			UrlCallLikes: `${config.sitecoreApiHost}/apiaox/Call/LikePost?sc_apikey=${config.sitecoreApiKey}`,  
			serverError: false, 
			likeIsSubmitting: false,
			
			reviewToShow: 2,
			reviewInterval: 2,
			responseToShow: 2,
			responseInterval: 2,
			//reviewList: []
		} 
		// button like
		this.handleButtonLike = this.handleButtonLike.bind(this)
		// window resize
		//this.throttleWindowResizeSectionArticle = throttle(this.handleWindowResizeSectionArticle, 100) 
		// event video modal
		this.openVideoModal = this.openVideoModal.bind(this)
	}
	componentDidMount(){
		// add and dispatch window resize
		window.addEventListener('resize', this.throttleWindowResizeSectionArticle)
		window.dispatchEvent(new Event('resize'))

		matchHeight('.obj_carousel.article-carousel h3')
	}
	componentWillUnmount(){
		// remove window resize
		window.removeEventListener('resize', this.throttleWindowResizeSectionArticle)
	} 
	handleButtonLike() {

		// submit
		this.setState({
			likeIsSubmitting: true
		})
 
		axios({ 
			url:this.state.UrlCallLikes,
			method: 'POST',
			data : {"idref": document.getElementById("idref").value},
			withCredentials: true, 
		  })
		.then(res => 
		 {	 										
			this.setState({
				likeIsSubmitting: false
			}) 
			
			if(res.data.Value){
				var tabBtnLike = document.getElementsByClassName("bt_like-article");
				for(var i = 0; i < tabBtnLike.length; i++)
				{
					if(res.data.Value.CodeReturn > 0){
						tabBtnLike[i].classList.add("is-checked")
					}else if(res.data.Value.CodeReturn < 0){
						tabBtnLike[i].classList.remove("is-checked")
					}
				}		
				
				document.getElementById("nbLikes_Article").innerHTML = res.data.Value.NewValue;
			}
		})
		.catch(err => {
			this.setState({
				likeIsSubmitting: false
			})
		}); 
	} 
	setCommentServerError(list, item, index) {
		list[index].serverError = true
		this.setState({ list })
	} 
	openVideoModal() {
		this.VideoModal.openModal()
	}
	render() {
		const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
		
		var {CurrentArticleReview, currentuserproperty, CurrentArticle, ParenPage, CurrentArticleForRelatedPosts } = this.props.fields.data; 
		
		const settingsSliderArticle = {
			slidesToShow: 3,
			slidesToScroll: 1,
			infinite: CurrentArticleForRelatedPosts && CurrentArticleForRelatedPosts.relatedPosts.targetItems.length > 3 ? true : false,
			responsive: [
				{
					breakpoint: 960,
					settings: {
						slidesToShow: 2,
						infinite: CurrentArticleForRelatedPosts && CurrentArticleForRelatedPosts.relatedPosts.targetItems.length > 2 ? true : false
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						infinite: true
					}
				}
			]
		}

		return (
			<>
				{oCurrentItemFields && !(oCurrentItemFields["Video Only"] && oCurrentItemFields["Video Only"]?.value) && 
					<div className="obj_back-page">
						<a className="bt_back-page" href={ParenPage && ParenPage.goto.url}>{this.props.t('Retour')}</a>
					</div>
				}

				<section className="section_article"> 
					<div className="str_container">
						{CurrentArticle && <input type="hidden" id="idref" value={CurrentArticle.idref}></input>}

						{oCurrentItemFields && <div className={`ctn_article${oCurrentItemFields["Video Url"]?.value !=="" ? ' var_video' : ''}`}>
							<div className="ctn_header">
								<RichText field={oCurrentItemFields["Page SubTitle"]} tag="h3"></RichText>
								<RichText field={oCurrentItemFields["Page Title"]} tag="h1"></RichText>
								{oCurrentItemFields && !(oCurrentItemFields["Video Only"] && oCurrentItemFields["Video Only"]?.value) &&
									<div className="ctn_bottom">
										
										<div className="t_author-date">
											{oCurrentItemFields["Article Author"] && oCurrentItemFields["Article Author"]?.value && 	
												<figure><img src={require("../../assets/images/v_avatar.png")} alt={oCurrentItemFields["Article Author"]}/></figure>
											}
											<p>
												{oCurrentItemFields["Article Author"] && oCurrentItemFields["Article Author"]?.value && 
													<strong>{this.props.t('ArticlePar')} <Text field={oCurrentItemFields["Article Author"]}></Text>,&nbsp;</strong> 
												}
												{oCurrentItemFields["Article Date"] && oCurrentItemFields["Article Date"]?.value && oCurrentItemFields["Article Date"]?.value !== "0001-01-01T00:00:00Z" && 
												<>
													{this.props.t('ArticleLe')} <Moment format={this.props.t('FormatDateArticle')}>{oCurrentItemFields["Article Date"]?.value}</Moment>
												</>
												}
											</p>
										</div>
										
										<ul className="obj_likes-comments">
											{currentuserproperty && 
												<li className="ctn_like-article">
													<button className={`bt_like-article${ currentuserproperty.likethispage ? ' is-checked' : ''}`} 
															type="button" onClick={this.handleButtonLike} 
															disabled={this.state.likeIsSubmitting ? 'disabled' : ''}>
														<IconSVG icon="heart" width="19" height="16" />
														<span id="nbLikes_Article">{oCurrentItemFields["Likes"]?.value != null && oCurrentItemFields["Likes"]?.value !=="" ? oCurrentItemFields["Likes"]?.value : 0}</span> <span>{this.props.t('ArticleLike')}</span>
													</button>
												</li>
											}
											{oCurrentItemFields["User can add Reviews"] && oCurrentItemFields["User can add Reviews"]?.value &&
												<li className="ctn_comment-article">
													<ScrollLink
														className="bt_comment-article"
														to="bgComment"
														smooth={true}
														offset={isMobile() ? -110 : -60}
													>
														<IconSVG icon="dialog" width="20" height="17" />
														{CurrentArticleReview && CurrentArticleReview.reviewsCount} <span>{CurrentArticleReview && CurrentArticleReview.reviewsCount > 1 ? this.props.t('ArticleCommentaires') : this.props.t('ArticleCommentaire') }</span>
													</ScrollLink>
												</li>
											}
										</ul>
									</div>
								}
							</div>

							<figure className="ctn_img" onClick={this.openVideoModal}>
								<picture>
									<source srcSet={oCurrentItemFields["Page Image Mobile"]?.value.src} media="(max-width:767px)"/>
									<Image field={oCurrentItemFields["Page Image"]}></Image>
								</picture>
							</figure>

							{ oCurrentItemFields["Video Url"]?.value !== "" &&
								<VideoModal
									onRef={ref => (this.VideoModal = ref)}
									videoUrl={oCurrentItemFields["Video Url"]?.value}
									videoAutoplay={1}
									videoAutomodal={oCurrentItemFields && oCurrentItemFields["Video Only"] && oCurrentItemFields["Video Only"]?.value}
								/>
							}

							<div className="ctn_text">
								<RichText field={oCurrentItemFields["Page Text"]}></RichText>
								<RichText className="t_mention" field={oCurrentItemFields["Disclaimer"]}></RichText>
								{currentuserproperty && !(oCurrentItemFields["Video Only"] && oCurrentItemFields["Video Only"]?.value) && 
									<div className="ctn_like-article">
										<button className={`bt_like-article${ currentuserproperty.likethispage ? ' is-checked' : ''}`} 
												type="button" onClick={this.handleButtonLike} 
												disabled={this.state.likeIsSubmitting ? 'disabled' : ''}>
											<IconSVG className="i_heart" icon="heartstroke" width="24" height="20" />
											<IconSVG className="i_heart-hover" icon="heart" width="24" height="20" />
											{this.props.t('ArticleVousAvezAimez')}
										</button>
									</div>
								}
							</div>
						</div>}
						
						{oCurrentItemFields && oCurrentItemFields["User can add Reviews"] && oCurrentItemFields["User can add Reviews"]?.value && !(oCurrentItemFields["Video Only"] && oCurrentItemFields["Video Only"]?.value) && 
							<SectionComments
											CurrentArticleReview={CurrentArticleReview} 
											UserCanAddReview={oCurrentItemFields["User can add Reviews"] && oCurrentItemFields["User can add Reviews"]?.value} 
											legalFileForContest={oCurrentItemFields["Legal Contest"]?.value}
											hideCommentContent={oCurrentItemFields["Hide Content"]?.value} />
							}
					</div>
				</section>
				
				{CurrentArticleForRelatedPosts && CurrentArticleForRelatedPosts.relatedPosts && CurrentArticleForRelatedPosts.relatedPosts.targetItems
					&& CurrentArticleForRelatedPosts.relatedPosts.targetItems.length > 0 &&
					<section className="section_article-carousel">
						<div className="str_container">
							<h2>{this.props.t('DecouvrirLesAutresSujets')}</h2>
							<Slider className="obj_carousel article-carousel obj_push-carousel" {...settingsSliderArticle}>
								{CurrentArticleForRelatedPosts && CurrentArticleForRelatedPosts.relatedPosts && CurrentArticleForRelatedPosts.relatedPosts.targetItems &&
									CurrentArticleForRelatedPosts.relatedPosts.targetItems.map((pushItem, index) => (
									<div key={index}>
										<div className={`ctn_banner${
											pushItem.videoUrl && pushItem.videoUrl.jss && pushItem.videoUrl.jss.value !=="" ? ' var_video' : ''}${
											pushItem.trainingPushPlayButton && pushItem.trainingPushPlayButton.boolValue ? ' var_video' : ''}`}>
											<a href={pushItem.url}>
												<figure>
													<Image field={pushItem.pageImagePush.jss}></Image>
												</figure>
												<Text field={pushItem.pageTitle.jss} tag="h3"></Text>
												<ul className="obj_likes-comments">
													<li>
														<IconSVG icon="heart" width="19" height="16" />
														{pushItem.likes.value !=="" ? pushItem.likes.value : 0} <span>{this.props.t('ArticleLike')}</span>
													</li>
													{pushItem.userCanAddReviews.boolValue && 
														<li>
															<IconSVG icon="dialog" width="20" height="17" />
															{pushItem.reviewsCount} <span>{pushItem.reviewsCount > 2 ?this.props.t('ArticleCommentaires') : this.props.t('ArticleCommentaire') }</span>
														</li>
													}
												</ul>
											</a>
										</div>
									</div>
									))}
							</Slider>
						</div>
					</section>
				}
			</>
		)
	}
}

export default withTranslation()(withSitecoreContext()(PageArticle));