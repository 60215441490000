import React, { Component } from 'react'
import { Image } from '@sitecore-jss/sitecore-jss-react';
import LazyLoad from 'react-lazyload'
import Slider from "react-slick"

class ObjBrandsCarouselHero extends Component {
	constructor(props) {
		super(props)
		this.state = {
		}
	}
	render() {
		const {scDatas} = this.props;
		const settings = {
			lazyLoad: true,
			autoplay: true,
			autoplaySpeed: 2500,
			fade: true,
			dots: false,
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1
		}
		return (

			<div className="obj_brands_carousel_hero">

				<div className="ctn_cta_brands_carousel_hero">
					<a href={scDatas.PageAutresMarques.url}>
						<p className="t_title">Les autres<br /> marques L'Oréal</p>
					</a>
				</div>

				<Slider className="obj_carousel carousel_other_hero" {...settings}>
					{ scDatas.BrandList?.children.slice(5).map((brandItem, index) => 
						<div key={index} className={brandItem.brandCssClass.jss.value}>
							<a href={scDatas.PageAutresMarques.url}>
								<LazyLoad>
									<picture className="ctn_logo fade-in">
										<Image field={brandItem.logoHome.jss}></Image>
									</picture>
								</LazyLoad>
							</a>
						</div>
					)}
				</Slider>

			</div>
		)
	}
}

export default ObjBrandsCarouselHero