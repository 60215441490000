import React, { Component } from 'react';
import { withSitecoreContext, Text, RichText } from '@sitecore-jss/sitecore-jss-react';

class PageMentionContent extends Component {
	render() {
    	const oCurrentItemFields = this.props.sitecoreContext.route?.fields;

		return (
			<section className="section_mentions">
				<div className="str_container">					
					<div className="ctn_top">
           				{ oCurrentItemFields && <Text field={oCurrentItemFields["Page Title"]} tag="h1"></Text>}
					</div>
					
					{ oCurrentItemFields && <RichText field={oCurrentItemFields["Page Text"]} tag="div" className="ctn_main"></RichText>}					
				</div>
			</section>
		)
	}
}

export default withSitecoreContext()(PageMentionContent);
