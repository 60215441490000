import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";
import moment from "moment";

import { dayFrom } from "../../utilities/FrontFunctions/AoxFrontFunctions";
import IconSVG from "../../utilities/FrontFunctions/IconSVG";

class ObjSectionFormation extends Component {
	constructor(props) {
		super(props);
		this.handleRunFormation = this.handleRunFormation.bind(this);
	}

	handleRunFormation() {
		if (this.props.scDataTraining.trainingUrl.url !== "" && this.props.scDataTraining.trainingDateAvailable.dateValue < new Date()) {
			document.location.href = this.props.scDataTraining.trainingUrl.url;
		}
	}

	render() {
		const scDataTraining = this.props.scDataTraining;
		const apiDataTraining = this.props.apiDataTraining;

		var bTrainingNotAvailable = scDataTraining.trainingDateAvailable.dateValue > new Date();
		var bTrainingDone = !bTrainingNotAvailable && apiDataTraining?.UserTraining_Completed;
		var bTrainingInProgress = !bTrainingNotAvailable && apiDataTraining?.UserTraining_InProgress;
		var bDoTraining = !bTrainingNotAvailable && !bTrainingInProgress && !bTrainingDone;
		var bIsNew = !bTrainingNotAvailable && dayFrom(scDataTraining.trainingDateAvailable.dateValue) < 10; //TODO SGU voir specifications pour cette valeur + siteParam
		var sDateTraining = moment(scDataTraining.trainingDateAvailable.dateValue).format(this.props.t("FormatDateFormation"));

		return (
			<article
				className={`${this.props.mini ? "obj_formation_mini_parcours" : "ctn_page-liste"}${bTrainingDone ? " is-done" : ""}${bTrainingInProgress ? " is-progress" : ""}${
					bTrainingNotAvailable ? " is-not-available" : ""
				}`}
			>
				<button type="button" onClick={this.handleRunFormation}>
					<div className={this.props.mini ? "ctn_image" : "ctn_page-liste-visuel"}>
						<LazyLoad>
							<figure className={this.props.mini ? "" : "fade-in"}>
								<Image field={this.props.scDataTraining.pageImage.jss} />
							</figure>
						</LazyLoad>

						<div className="obj_cta-formation">
							{bTrainingDone && this.props.t("Formation_RevoirLaFormation")}
							{bTrainingInProgress && this.props.t("Formation_FormationInProgress")}
							{bDoTraining && this.props.t("Formation_EffectuerLaFormation")}
							{bTrainingNotAvailable && (
								<>
									{" "}
									{this.props.t("Formation_DisponibleLe")} {sDateTraining}{" "}
								</>
							)}
							<div className="obj_cta-formation-picto"></div>
						</div>
					</div>
				</button>

				<div className="ctn_text">
					{!bTrainingNotAvailable && bIsNew && (
						<p className="t_tag">
							<span>Nouveau</span>
						</p>
					)}
					<Text field={this.props.scDataTraining.pageTitle.jss} tag="h3" />

					{apiDataTraining && (
						<>
							<div className="obj_nb_view">
								<IconSVG icon="eye" width="20" height="13" />
								<span>{apiDataTraining.Training_NbViews < 1 ? "0 vue" : apiDataTraining.Training_NbViews === 1 ? "1 vue" : `${apiDataTraining.Training_NbViews} vues`}</span>
							</div>

							<button
								className={`bt_fav-formation${apiDataTraining.isFavorite ? " is-checked" : ""}`}
								type="button"
								onClick={() => this.props.handleButtonFav(apiDataTraining.Training_ItemTraining_Id)}
								disabled={bTrainingNotAvailable ? "disabled" : ""}
							>
								<IconSVG icon="star" width="15" height="14" />
							</button>
						</>
					)}
				</div>
			</article>
		);
	}
}

export default withTranslation()(ObjSectionFormation);
