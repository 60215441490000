import React, { Component } from 'react'
import { Text, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload'

class PageIndex extends Component {
	render() {
    const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
    
    const { LstPageList } = this.props.fields.data;  
    
		return (
			<>
				{oCurrentItemFields && <Text className="obj_title" tag="h1" field={oCurrentItemFields["Page Title"]}></Text>}

				<section className="section_page-liste"> 
				  <div className="str_container"> 
            <section className="ctn_row">
                <div className="ctn_sub-row">
                  { LstPageList.children.map((PageList, index) => (

                    <article key={index} className="ctn_page-liste">
                      <a href={PageList.url}>
                        <LazyLoad>
												  <figure className="fade-in">
                            <Image field={PageList.pageImage.jss}></Image>
                          </figure>
                        </LazyLoad>
                        <Text className="obj_title" tag="h3" field={PageList.pageTitle.jss}></Text>
                      </a>
                    </article>
                  ))}
                </div>
              </section>             
					</div>
				</section>
			</>
		)
	}
}
export default withTranslation()(withSitecoreContext()(PageIndex));
