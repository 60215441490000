import React, { useEffect } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import anime from 'animejs/lib/anime.es.js';

import { isMobile } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions"
import { badges } from '../../data/badges';

const ObjCanvasBadgeExpertise = ({badge, newBadge}) => {

	// lifecycle
	useEffect(() => {
	}, []);

	// three
	useEffect(() => {
		
		// global var
		let badgeModel;

		// set canvas
		const canvas = document.querySelector('#canvas');
		const renderer = new THREE.WebGLRenderer({
			canvas,
			antialias: true,
			alpha: true,
		});

		// set camera
		const fov = 45;
		const aspect = 2;
		const near = 0.1;
		const far = 100;
		const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
		camera.position.set(0, 0, 8);

		// set scene
		const scene = new THREE.Scene();

		// import model
		{
			const roomLoader = new RGBELoader();
			roomLoader.load(require('../../assets/images/room.hdr'), (roomHdr) => {

				roomHdr.mapping = THREE.EquirectangularReflectionMapping;

				const manager = new THREE.LoadingManager();
				manager.onLoad = () => {
					document.body.classList.add('three-is-ready');
				};

				const loader = new GLTFLoader(manager);

				loader.load(badges.find((item) => item.type === badge.Badge_Type)?.model, function(gltf){

					badgeModel = gltf.scene;
					scene.add(badgeModel);

					let envMapIntensity = 5;

					let metalMaterialOptions = {
						envMap: roomHdr,
						envMapIntensity: envMapIntensity,
						roughness: 0,
						metalness: 1,
						ior: 2.33,
						reflectivity: 1,
					};
					let mateMaterialOptions = {
						envMap: roomHdr,
						envMapIntensity: envMapIntensity,
					};

					scene.traverse((obj) => {
						if(obj.name === '1-Forme' || obj.name === '4-Cache' || obj.name === '5-Cache'){
							obj.material = new THREE.MeshPhysicalMaterial(metalMaterialOptions);
							obj.material.color.setHex(badges.find((item) => item.type === badge.Badge_Type)?.theme.find((item) => item.theme === badge.Badge_Theme)?.modelColor1);
						}
						if(obj.name === '2-Fond' || obj.name === '6-Cross'){
							obj.material = new THREE.MeshPhysicalMaterial(mateMaterialOptions);
							obj.material.color.setHex(badges.find((item) => item.type === badge.Badge_Type)?.theme.find((item) => item.theme === badge.Badge_Theme)?.modelColor2);
						}
					});

					if( scene.getObjectByName('3-Image') !== undefined ){
						const textureImage = badges.find((item) => item.type === badge.Badge_Type)?.theme.find((item) => item.theme === badge.Badge_Theme)?.logo;

						scene.getObjectByName('3-Image').material = new THREE.MeshPhysicalMaterial({
							envMap: roomHdr,
							envMapIntensity: envMapIntensity,
							map: new THREE.TextureLoader().load(textureImage),
							side: THREE.FrontSide,
						});
						scene.getObjectByName('3-Image').material.map.flipY = false;
						scene.getObjectByName('3-Image').material.map.colorSpace = THREE.SRGBColorSpace;
					}

					badgeModel.scale.set(0, 0, 0);
					
					const duration = 2000;

					setTimeout(() => {
						anime({
							targets: [badgeModel.scale],
							x: 1, y: 1, z: 1,
							duration: duration,
							easing: 'easeOutQuint',
						});
						if( newBadge ){
							anime({
								targets: [badgeModel.rotation],
								y: Math.PI * 4,
								duration: duration,
								easing: 'easeOutQuint',
							});
						}
						setTimeout(() => {
							anime({
								targets: [badgeModel.rotation],
								y: Math.PI * 2,
								duration: duration * 4,
								easing: 'linear',
								loop: true,
								direction: newBadge ? 'reverse' : 'normal',
							});
						}, newBadge ? duration : 0);
					}, 800);
					
				});

			});
		}

		// render
		const resizeRendererToDisplaySize = (renderer) => {
			const canvas = renderer.domElement;
			const pixelRatio = window.devicePixelRatio;
			const width = canvas.clientWidth * pixelRatio;
			const height = canvas.clientHeight * pixelRatio;
			const needResize = canvas.width !== width || canvas.height !== height;
			if (needResize) {
				renderer.setSize(width, height, false);
			}
			return needResize;
		}

		const render = () => {
			if( resizeRendererToDisplaySize(renderer) ){
				const canvas = renderer.domElement;
				camera.aspect = canvas.clientWidth / canvas.clientHeight;
				camera.updateProjectionMatrix();
			}
			renderer.render(scene, camera);
			requestAnimationFrame(render);
		}
		requestAnimationFrame(render);

	}, [badge.Badge_Theme, badge.Badge_Type, newBadge]);

	// styles
	const styles = {
		obj_canvas: {
			position: 'relative',
			width: '100%',
			height: 0,
			paddingBottom: isMobile() ? '75%' : '50%',
		},
		canvas: {
			position: 'absolute',
			left: 0, top: 0,
			width: isMobile() ? 'var(--aox-280px)' : 'var(--aox-480px)' ,
			height: isMobile() ? 'var(--aox-280px)' : 'var(--aox-480px)' 
		},
	}

	return(
		<div style={styles.obj_canvas}>
			<canvas id="canvas" style={styles.canvas}></canvas>
		</div>
	)
}

export default ObjCanvasBadgeExpertise;