import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'; 
import config from '../../temp/config';

import axios from 'axios'; 
import queryString from 'query-string';
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { CircularProgressbar } from 'react-circular-progressbar'
import AnimatedProgressProvider from '../../utilities/FrontFunctions/Provider/AnimatedProgressProvider'
import DefaultModal from '../Modal/DefaultModal'
import ModalContentChallengeARepartir from './ModalContentChallengeARepartir';

class ObjAsideChallengeARepartir extends Component {
		state = {
			//liste des users selectionnables (popin)
			
			userList: this.props.membres.map((membre) => ({
				Id:membre.Id,
				value: 'user_'+membre.Id, 
				Name: membre.Name, 
				gain: 0, 
				percent: 0, 
				GainYearTotal: membre.GainYearTotal,
				IsRepartitable: membre.IsRepartitable,
			})),	
			isUserListVisible: false,
			//liste des users sélectionnés (formulaire)
			userSelectedList: [],	
			reste_repartir: this.props.participation.Participation_Gain,
			montant_total: this.props.participation.Participation_Gain
		}

		colorsList = ['#86469E', '#CA93DE', '#035797', '#79BDF2', '#1EA5A5', '#66CEDA', '#7AAE5E', '#ACDE93']

	componentDidMount() {
	}

	refreshGraphPercent(userSelectedList){
		let cumulPercent = 0;
		userSelectedList.forEach(userItem => {
			cumulPercent += (userItem.gain * 100) / this.state.montant_total;
			userItem.percent = cumulPercent;
		});
	}

	handleSelect(userItem){
		if(userItem.IsRepartitable){
			this.setState({
				userSelectedList: [...this.state.userSelectedList, {...userItem,gain:0, percent:0}],
			});
        }
	}

	handleDelete(formItem, setFieldValue){

		setFieldValue(formItem.value, 0);

		let newuserSelectedList = this.state.userSelectedList.filter((item) => item !== formItem);
		this.refreshGraphPercent(newuserSelectedList);

		this.setState({
			userSelectedList: newuserSelectedList,
			reste_repartir: this.state.montant_total - newuserSelectedList.reduce((sum, item) => sum + item.gain, 0)
		});
	}

	showUsersList(){
		this.setState({
			isUserListVisible: true,
		});
	}

	hideUsersList(){
		this.setState({
			isUserListVisible: false,
		});
	}

	handleInput(e, formItem, setFieldValue){
		let userIndex = this.state.userSelectedList.findIndex(item => item === formItem);
		let inputGain = Number(e.target.value)?Number(e.target.value):0;

		if (inputGain!== this.state.userSelectedList[userIndex].gain){
			const sumRepartitionOthers = this.state.userSelectedList.reduce((sum, item, index) => sum + ((index!==userIndex)?item.gain:0), 0);
			//si l'ajout du gain dépasse le total attribuable, on le réévalue au reste attribuable
			if (sumRepartitionOthers + inputGain > this.state.montant_total){
				inputGain = this.state.montant_total - sumRepartitionOthers;
				e.target.value = inputGain;
			}

			setFieldValue(formItem.value, e.target.value);
	
			let newuserSelectedList = this.state.userSelectedList;
			newuserSelectedList[userIndex].gain = inputGain;
			this.refreshGraphPercent(newuserSelectedList);

			this.setState({
				userSelectedList: newuserSelectedList,
				reste_repartir: this.state.montant_total - sumRepartitionOthers - inputGain
			});
		}
	}
	
	render() {
		return (
			<div className="obj_repartition-gains">
				<div className="ctn_form">

					<Formik
						initialValues={this.state.userSelectedList.reduce((acc,item)=>{
							acc[item.value]=null
							return acc
						},{"ChallengeId": this.props.participation.Challenge.Challenge_Id})}

						validationSchema={Yup.object().shape(
							this.state.userSelectedList.reduce((acc,item)=>{
								acc[item.value]=Yup.number().required()
								return acc
						},{"ChallengeId": Yup.number().required()})
					
						)}
						validate={() => {
							const errors = {};
							
							if ((this.state.userSelectedList.reduce((sum, item) => sum + item.gain, 0) !== this.state.montant_total)) {
								errors.sumError = true;
							}
							return errors;
						}}
						onSubmit={(values) => {
							document.body.classList.add('is-loading') 

							let postDatas = {};
							let index = 0;
							Object.entries(values).forEach((val) => {
								if(val[0].startsWith("user_")){
									postDatas[`Repartitions[${index}].UserId`] = val[0].slice("user_".length);
									postDatas[`Repartitions[${index}].Montant`] = val[1];
									index++;
								} else {
									postDatas[val[0]] = val[1];
								}
							})
							
							axios.post(
								`${config.sitecoreApiHost}/apiaox/Challenge/PostChallengeRepartition`, 
								queryString.stringify(postDatas), 
								{withCredentials: true, headers : {Accept: 'application/json','Content-Type': 'application/x-www-form-urlencoded',}}
							)
							.then((result) => {
								document.body.classList.remove('is-loading')
								this.setState({
									formSubmitted: result.data.IsValid,
									apiError: !result.data.IsValid,
									apiErrorMsg: result.data.ExceptionMessage,
								})
								if(result.data.IsValid){
									this.ModalRepartitionTransmise.openModal();
							 	}
							})
							.catch((err) => {
								document.body.classList.remove('is-loading') 
								this.setState({
									apiError: true,
									apiErrorMsg : this.props.t('DefaultServerError'),
								}) 
							});
						}}
					>
						{ ({ handleSubmit, setFieldValue, errors, setErrors, touched, submitCount }) => (
							<>
								<form onSubmit={handleSubmit}>
									<div className="obj_form">
										<h3 className="t_title">Participants et répartitions des gains</h3>

										<div className="ctn_graph_gains">
											<div className="obj_circle-progress-gains">
												<AnimatedProgressProvider valueEnd={100}>
													{value => {
														return (
															<CircularProgressbar
																value={value}
																circleRatio={0.5}
																strokeWidth={10}
																styles={{
																	root: {zIndex: 1},
																	path: {stroke: '#000'},
																}}
															/>
														)
													}}
												</AnimatedProgressProvider>

												{this.state.userSelectedList.map((userItem, index) => (
													<AnimatedProgressProvider valueEnd={userItem.percent} key={index}>
														{value => {
															return (
																<CircularProgressbar
																	value={value}
																	circleRatio={0.5}
																	strokeWidth={10}
																	styles={{
																		root: {zIndex: this.state.userSelectedList.length + 1 - index},
																		path: {stroke: this.colorsList[index % 8]},
																	}}
																/>
															)
														}}
													</AnimatedProgressProvider>
												))}
											</div>
											<div className="ctn_graph_infos_gains">
												<span className="t_desc">Reste à répartir</span>
												<span className="t_gain">{this.state.reste_repartir}€</span>
												<span className="t_total">Montant total : {this.state.montant_total}€</span>
											</div>
										</div>

										<div className="ctn_list_participants_gains">
											{this.state.userSelectedList.map((formItem, index) => (
												<div className="item_participants_gains" key={index}>
													<picture className="participant_picture" style={{borderColor: this.colorsList[index % 8]}}>
														<img src={require('../../assets/images/v_avatar-xl.png')} alt=""/>
													</picture>
													<div className="participant_infos">
														<span className="t_name">{formItem.Name}</span>
														<span className="t_desc">Gains cumulés : {formItem.GainYearTotal}€</span>
													</div>
													<div className="participant_gain">
														<Field
															className={errors[formItem.value] && touched[formItem.value] && 'error'}
															as="input"
															type="text"
															name={formItem.value}
															placeholder="Gain"
															onChange={(e) => this.handleInput(e, formItem, setFieldValue)}
														/>
													</div>
													<div className="participant_delete">
														<button type="button" className="btn_delete" onClick={() => this.handleDelete(formItem, setFieldValue)}>x</button>
													</div>
												</div>
											))}
										</div>

										<div className="ctn_list_participants_gains">
											<div className="obj_list_participants_select">
												<button
													type="button"
													className="bt_list_participants_select"
													onClick={() => this.showUsersList()}
												>
													Ajouter un bénéficiaire
													<span className="i_plus"></span>
												</button>
												{this.state.isUserListVisible &&
													<ModalContentChallengeARepartir
													membres={this.state.userList}
													usersSelected={this.state.userSelectedList}
													onAdd={(item) => this.handleSelect(item)}
													onClose={() => this.hideUsersList()}
													/>
												}
											</div>
										</div>

										<p className="t_mention">La totalité des gains doit être répartie pour pouvoir valider.</p>

										<div className="ctn_cta">
											<button className="obj_cta" type="submit">Valider</button>
										</div>
										
										{
										
										submitCount>0 && (this.state.userSelectedList.reduce((sum, item) => sum + item.gain, 0) !== this.state.montant_total) &&
											<p className="t_error mb_30">Répartition incomplète</p>
										}

										{this.state.apiError && 
											<p className="t_error mb_30 fade-in">{this.state.apiErrorMsg}</p>
										}
									</div>
								</form>

								<DefaultModal
									onRef={ref => (this.ModalRepartitionTransmise = ref)}
									onClose={() => window.location.reload(false)}
									htmlContent={
										<>
											<p className="t_title">Votre répartition a bien été transmise.</p>
											<p className="t_main"></p>
											<div className="ctn_cta">
												<button className="obj_cta" type="button" onClick={() => this.ModalRepartitionTransmise.closeModal()}>fermer</button>
											</div>
										</>
									}
								/>
							</>
						)}
					</Formik>

				</div>
			</div>
		)
	}
}

export default withTranslation()(ObjAsideChallengeARepartir)