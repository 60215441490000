import { withSitecoreContext, Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'; 
import LazyLoad from 'react-lazyload'

class PageRanges extends Component {
	render() {
    const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
       
    const { LstGammes } = this.props.fields.data; 
		return (
			<> 
			{ oCurrentItemFields && <Text className="obj_title" field={oCurrentItemFields["Page Title"]} tag="h1"></Text> }
			<section className="section_page-liste">
				<div className="str_container">
        <div className="obj_tabs-list">
          <section className="ctn_row active">
            <div className="ctn_sub-row">
              {LstGammes && LstGammes.children && LstGammes.children.map((catalogueItem, index) => (
                <article key={index} className="ctn_page-liste">
                  <a href={catalogueItem.url}>
                    <LazyLoad>
												<figure className="fade-in">
                          <Image field={catalogueItem.pageImage.jss}></Image>
                        </figure>
                    </LazyLoad>
                    <RichText tag="h3" field={catalogueItem.pageTitle.jss}></RichText>
                  </a>
                </article>
              ))}
            </div>
          </section>
         </div>
				</div>
			</section>
			</>
		)
	}
}

export default withTranslation()(withSitecoreContext()(PageRanges));