import React from "react";

function ObjProgressBarExpertise({ step, stepstotal }) {
  return (
  <div className="obj_progress-bar">
    <div className="ctn_steps">
      {Array.from(Array(stepstotal), (e, i) => {
        if (i < step) {
          return <div key={i} className="step is-complete"></div>;
        }
        return <div key={i} className="step"></div>;
      })}
    </div>
  </div>
  );
}

export default ObjProgressBarExpertise;
