import { withSitecoreContext, Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'; 
import Helmet from 'react-helmet'; 

class PageProductSegments extends Component {
	render() {
    const oCurrentItemFields = this.props.sitecoreContext.route?.fields;

    const { LstSousSegment } = this.props.fields.data; 
    const { bodyAttributes } = Helmet.peek();  
    
    var sClassForPage ="";
    if(oCurrentItemFields && oCurrentItemFields["Banner Image"] && Object.keys(oCurrentItemFields["Banner Image"].value).length !== 0){
      sClassForPage = bodyAttributes.class + " var_banner-white ";
    }   

		return (
      <>
      	<React.Fragment>
					<Helmet>			 
						{(sClassForPage !== "") 
						  && (<body className={sClassForPage} />) }		   
					</Helmet>
				</React.Fragment>

        <section className="section_product-liste">
          <div className="str_container">

          <section className="obj_banner-product">
            <div className="ctn_text">
              { oCurrentItemFields && !oCurrentItemFields["Hide Title"]?.value && <Text field={oCurrentItemFields["Page Title"]} tag="h1"></Text> }
              { oCurrentItemFields && <Text field={oCurrentItemFields["Page SubTitle"]} tag="h2"></Text> }
            </div>
            { oCurrentItemFields && <figure>
              {oCurrentItemFields["Banner Image"] && Object.keys(oCurrentItemFields["Banner Image"].value).length !== 0 ?
                <picture>
                  <source srcSet={oCurrentItemFields["Banner Image Mobile"]?.value.src} media="(max-width:767px)"/>
                  <Image field={oCurrentItemFields["Banner Image"]}></Image>
                </picture>
              :
                <picture>
                  <source srcSet={require('../../assets/images/v_banner-product_mob.png')} media="(max-width:767px)"/>
                  <img src={require('../../assets/images/v_banner-product.png')} alt={oCurrentItemFields["Page Title"]}/>
                </picture>
              }
            </figure> }
          </section>
            
            <section className="section_range-liste">

              {LstSousSegment && LstSousSegment.children.map((rangeItem, index) => (

                <div key={index} className="ctn_range-liste">

                  <RichText field={rangeItem.titleSegment.jss} tag="h3"></RichText>

                  {rangeItem.productsSegment && rangeItem.productsSegment.targetItems && rangeItem.productsSegment.targetItems.map((productItem, index) => (

                    <article key={index} className="obj_push-product">
                      <a href={productItem.url}>
                        <figure>
                          <Image field={productItem.pageImageSmall.jss}></Image>
                        </figure>
                        <RichText field={productItem.pageTitle.jss} tag="h4" className="t_title"></RichText>
                        <RichText field={productItem.pageSubTitle.jss} tag="p" className="t_desc"></RichText>
                      </a>
                    </article>
                  ))}
                </div>
              ))}						
            </section>
          </div>
        </section>
      </>
		)
	}
}

export default withTranslation()(withSitecoreContext()(PageProductSegments));