import { isAppMobile } from "../../utilities/FrontFunctions/AoxFrontFunctions"

export async function PushDataLayerUserId(sUserId) {
	
	try {
		if (typeof window === 'undefined') 
		{
			return false;
		}

		if(sUserId !== undefined){
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				'uiUser': sUserId
			});	
			
			return true;
		}
	} 
	catch (error) {
		return false;
	}
	
	return false;
}

async function textToSha256Text(message) {
	const msgBuffer = new TextEncoder().encode(message); // convertir la chaîne en buffer
	const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer); // générer le hachage SHA256
	const hashArray = Array.from(new Uint8Array(hashBuffer)); // convertir le buffer en tableau d'octets
	const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convertir le tableau en chaîne hexadécimale
	return hashHex;
}

//YFL : La fonction reverse() ne marche pas test
function reverseArray(arr) {
	var newArray = [];
	for (var i = arr.length - 1; i >= 0; i--) {
		newArray.push(arr[i]);
	}
	return newArray;
}

export async function getGA4WindowDatas(dataUser, dataPageInfo, dataBreadCrumb){
	var pageCategory = ""
    if(dataPageInfo){
        pageCategory = dataPageInfo.trackingCategory.jss.value;
    }
	if(!pageCategory && dataBreadCrumb?.ancestors){
		//voir le fichier de taggage pour les valeurs
		const TemplateToPageCategory  = {
			'Login page': 'authentication',
			'Register Page': 'register',
			'Expired Confirmation Page': 'expired confirmation',
			'New Password Page': 'new password',
			'Request Password Page': 'forgot password',
			'MonCompte Page': 'my account',
			'MesInformationsPersonnelles Page': 'my personal info',
			'Contact Page C': 'contact',
			'Contact Page NC': 'contact',
			'Mentions Page': 'Mentions',
			'Error Page': 'error',
			'Home Page': 'homepage',
			'Brands Page': 'all brands',
			'Brand Page': 'brand',
			'Brand Article Page': 'article',
			'PageArticle': 'article',
			'PageSegments': 'segment',
			'ProductRanges': 'range',
			'ProductSegments': 'product list',
			'Product Page': 'product',
			'Training Page': 'formation',
			'Trainings': 'training list first range trainings',
			//'Trainings': 'training list other trainings',	//utilisé localement à la page trainings
			'Concours Page': 'contest list',
			'Privilege Page New Version': 'private',
			'Privilege Page': 'private',
			'PageIndex': 'PageIndex',
			'PageListe': 'PageSubList',
			'PageSubListe': 'PageSubList',
			'Ingredient List Page': 'ingredient list',
			'Ingredient Page': 'ingredient page',
		}
		pageCategory = TemplateToPageCategory.hasOwnProperty(dataBreadCrumb.ancestors[0].template.name)?TemplateToPageCategory[dataBreadCrumb.ancestors[0].template.name]:'';
	}

	//source optionnelle suivant la page
	const SourceSegment = (dataBreadCrumb?.ancestors)?dataBreadCrumb.ancestors.find(i => i.template.name==='ProductSegments'):undefined;
	const SourceRange = (dataBreadCrumb?.ancestors)?dataBreadCrumb.ancestors.find(i => i.template.name==='ProductRanges'):undefined;
	const SourceProduct = (dataBreadCrumb?.ancestors)?dataBreadCrumb.ancestors.find(i => i.template.name==='Product Page'):undefined;
	const SourceBreadCrumbReverseAncestors = (dataBreadCrumb?.ancestors)?reverseArray(dataBreadCrumb.ancestors):undefined;

	return {
		// user_data.uiUser : Id utilisateur généré lors de la création du compte
		'uiUser' : dataUser?dataUser.userkey:''
		// user_data.uiEmailId : Email hash généré quand il n'est pas possible de générer un id
		, 'uiEmailId' : dataUser?(await textToSha256Text(dataUser.email)):''
		// user_data.uiLoggedStatus : Statut du visiteur : logged ou not logged				
		, 'uiLoggedStatus' : dataUser?'logged':'not logged'

		//URL complet de la page de provenance
		,'referrer' : window.document.baseURI
		// Type/template de page
		,'pageCategory' : pageCategory
		// Indique le niveau 1 de l'écran
		,'category' : (pageCategory==="homepage")?"accueil":((SourceBreadCrumbReverseAncestors && SourceBreadCrumbReverseAncestors.length > 1)?SourceBreadCrumbReverseAncestors[1].name:'')
		// Indiquer le niveau 2 de l'écran    
		,'subCategory' :  (SourceBreadCrumbReverseAncestors && SourceBreadCrumbReverseAncestors.length > 2)?SourceBreadCrumbReverseAncestors[2].name:''
		// Indiquer le type de website : main, mini site, testing, app
		,'siteTypeLevel' : ( process.env.NODE_ENV !== "production" || window.document.baseURI.includes('.dev.') || window.document.baseURI.includes('.test.') || window.document.baseURI.includes('https://cm-'))?'testing':'main'
		// Langue de la page. ISO 639-1
		,'language' : 'fr'			
		// Pays. ISO 3166
		,'country' : 'fr'			
		// Indiquer l'abbréviation de la marque parmi la liste suivante :LRP,  CER, VIC, SKIN, SAN, DEC, BIO, MED, DER
		,'brand' : (dataPageInfo?.getbrand)?dataPageInfo.getbrand.codeAbbrege.value:''
		// Equivalent du fil d'Ariane
		,'breadcrumb' : (SourceBreadCrumbReverseAncestors)?SourceBreadCrumbReverseAncestors.map((oBreadCrumbItem) => (oBreadCrumbItem.pageTitle.jss?.value.replace( /(<([^>]+)>)/ig, ''))?oBreadCrumbItem.name:'').join(" > "):''
		// Méthode de connexion, social ou regular => regular
		,'method' : 'regular'
		// Catégorie du produit
		,'productCategory' : (SourceSegment)?SourceSegment.name:''
		// Gamme du produit
		,'productRange' : (SourceRange)?SourceRange.name:''
		// Nom du produit
		,'productName' : (SourceProduct)?SourceProduct.name:''

		//Mobile
		,'screen_name' : (dataBreadCrumb && dataBreadCrumb.ancestors)?dataBreadCrumb.ancestors[0].name:''
		,'screen_class' : (dataBreadCrumb && dataBreadCrumb.ancestors)?dataBreadCrumb.ancestors[0].url:''
	}
}

function getGA4UserData(datas){
	const userData =  {};
	["uiUser", "uiEmailId", "uiLoggedStatus"].forEach((property) => {userData[property] = datas[property]});
	return userData;
}
function getGA4PageData(datas){
	const pageData =  {};
	["referrer", "pageCategory", "category", "subCategory", "siteTypeLevel", "language", "country", "brand", "breadcrumb", "method", "productCategory", "productRange", "productName"].forEach((property) => {pageData[property] = datas[property]});
	return pageData;
}
function getGA4EventData(datas){
	const eventData =  {};
	["eventCategory", "eventAction", "eventLabel"].forEach((property) => {eventData[property] = datas[property]});
	return eventData;
}
export async function addGA4SessionData(datas){
	["siteTypeLevel", "language", "country", "uiUser", "uiEmailId", "uiLoggedStatus"].forEach((property) => {window.sessionStorage.setItem(property, datas[property])});
	return true;
}

export async function trackGA4PageView(datas){
	if (datas && datas.pageCategory && datas.pageCategory!==''){
		const dataPageView = datas;
		dataPageView.eventType = 'page_data';
		dataPageView.eventName = 'page_view';
		return trackGA4(dataPageView);
	}
	return false;
}

export async function trackGA4Event(datas, eventName, eventCategory, eventAction, eventLabel){
	if (datas && datas.pageCategory && datas.pageCategory!==''){
		const dataEvent = datas;
		dataEvent.eventType = 'event_data';
		dataEvent.eventName = eventName;
		dataEvent.eventCategory = eventCategory;
		dataEvent.eventAction = eventAction;
		dataEvent.eventLabel = eventLabel;
		return trackGA4(dataEvent);
	}
	return false;
}

async function trackGA4(datas){
	try {
		if (typeof window === 'undefined' || typeof datas === 'undefined') {
			return false;
		}
		if(isAppMobile()){
			const PageDataMobile = getGA4PageData(datas)
			PageDataMobile.screen_name = datas.screen_name;
			PageDataMobile.screen_class = datas.screen_class;

			window.ReactNativeWebView.postMessage('analytics|' + JSON.stringify({
				'event': datas.eventName
				,'user_data': getGA4UserData(datas)
				,'page_data': PageDataMobile
				,'event_data': getGA4EventData(datas)
			}));
		} else {
			window.dataLayer = window.dataLayer || [];
			if (datas.eventType){
				if(datas.eventType === 'page_data'){
					window['dataLayer'].push({[datas.eventType]:null});
					window['dataLayer'].push({
						'event': datas.eventName
						,'user_data': getGA4UserData(datas)
						,'page_data': getGA4PageData(datas)
					});
				} else if(datas.eventType === 'event_data'){
					window['dataLayer'].push({[datas.eventType]:null});
					window['dataLayer'].push({
						'event': datas.eventName
						,'event_data': getGA4EventData(datas)
					});
				}
			}
		}
		return true;
	} catch (error) {
		return false;
	}
}