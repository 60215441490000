import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import Helmet from 'react-helmet';

class MetaSitecore extends React.Component { 
    render() {

        const oCurrentItemFields = this.props.sitecoreContext.route?.fields;

        if (oCurrentItemFields == null)
        {
          return <></>;
        }
     
        const regex = /(<([^>]+)>)/ig;

        return (
            
              <React.Fragment>
                <script type="javascript" dangerouslySetInnerHTML={{ __html: oCurrentItemFields["JavaScript On Page"].value}}></script>

                <Helmet>
                    {/* <!-- OneTrust Cookies Consent Notice start for partneracademy.lorealdermatologicalbeauty.fr --> */}
                    {/* TODO SGU code pour version test */}
                    <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charset="UTF-8" data-domain-script="f103c721-83c9-4513-a061-fff69cc3cffc" ></script>
                    <script type="text/javascript">
                    {`function OptanonWrapper() { }`}
                    </script>
                    {/* <!-- OneTrust Cookies Consent Notice end for partneracademy.lorealdermatologicalbeauty.fr --> */}

                    {/*Google Tag Manager - #mantimox-id=9253   */} 
                    <script type="text/javascript">
                    {`
                      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                      })(window,document,'script','dataLayer','GTM-K22D6HF');                    
                      `}
                    </script>               

                    {/*Global site tag (gtag.js) - Google Analytics
                    <script src={`https://www.googletagmanager.com/gtag/js?id=${this.props.t('GoogleGTMCode')}`}></script>
                    <script type="text/javascript">
                    {`
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());

                      gtag('config', '${this.props.t('GoogleGTMCode')}');
                      `}
                    </script>    */}

                    {/*Meta*/}
                    <title>
                        { (oCurrentItemFields["Meta Title"].value ? oCurrentItemFields["Meta Title"].value.replace(regex, '') : 
                            oCurrentItemFields["Page Title"].value ? oCurrentItemFields["Page Title"].value.replace(regex, '') : 
                            this.props.t('Meta_DefaultTitle').replace(regex, ''))} {this.props.t('Meta_PostTitle')}
                    </title>
                    <meta name="Meta Description" content={(oCurrentItemFields["Meta Description"].value ? 
                                                              oCurrentItemFields["Meta Description"].value : 
                                                              this.props.t('Meta_DefaultDescription')) } />
                    {(oCurrentItemFields["Meta keywords"].value) &&   <meta name="keywords" content={(oCurrentItemFields["Meta keywords"].value)} />}
                    {(oCurrentItemFields["Meta Generator"].value) &&  <meta name="generator" content={(oCurrentItemFields["Meta Generator"].value)} />}
                    {(oCurrentItemFields["Meta Author"].value) &&     <meta name="author" content={(oCurrentItemFields["Meta Author"].value)} />}
                    {(oCurrentItemFields["Meta Copyright"].value) &&  <meta name="copyright" content={(oCurrentItemFields["Meta Copyright"].value)} />}
                    {(oCurrentItemFields["Meta Robots"].value) &&     <meta name="robots" content={(oCurrentItemFields["Meta Robots"].value)} />}
                    {(oCurrentItemFields["Meta GoogleBot"].value) &&  <meta name="GoogleBot" content={(oCurrentItemFields["Meta GoogleBot"].value)} />}
                    {(oCurrentItemFields["Meta Google"].value) &&     <meta name="Google" content={(oCurrentItemFields["Meta Google"].value)} />}
                    
                    {/*Facebook*/}
                    <meta name="og:type" content={(oCurrentItemFields["og Type"].value ? oCurrentItemFields["og Type"].value : this.props.t('Meta_DefaultOgType'))} />
                    <meta name="og:locale" content={this.props.sitecoreContext.language} />
                    <meta name="og:title" content={(oCurrentItemFields["og Title"].value ? oCurrentItemFields["og Title"].value :
                                                      oCurrentItemFields["Meta Title"].value ? oCurrentItemFields["Meta Title"].value.replace(regex, '') : 
                                                        oCurrentItemFields["Page Title"].value ? oCurrentItemFields["Page Title"].value.replace(regex, '') : 
                                                          this.props.t('Meta_DefaultTitle').replace(regex, ''))} />
                    <meta name="og:description" content={oCurrentItemFields["og Description"].value ? oCurrentItemFields["og Description"].value :
                                                          oCurrentItemFields["Meta Description"].value ? 
                                                            oCurrentItemFields["Meta Description"].value : 
                                                            this.props.t('Meta_DefaultDescription') } />
                    
                    {Object.keys(oCurrentItemFields["og Image"].value).length !== 0 &&  
                      <meta name="og:image" content={(oCurrentItemFields["og Image"].value.src)} />}
                    
                    {Object.keys(oCurrentItemFields["og Image"].value).length === 0 && Object.keys(oCurrentItemFields["Page Image"].value).length !== 0 &&
                      <meta name="og:image" content={(oCurrentItemFields["Page Image"].value.src)} />}
                                  
                    {/*Twitter*/}
                    <meta name="twitter:card" content={(oCurrentItemFields["twitter Card"].value ? oCurrentItemFields["twitter Card"].value : this.props.t('Meta_DefaultTwitterCard'))} />
                    <meta name="twitter:creator" content={(oCurrentItemFields["twitter Creator"].value ? oCurrentItemFields["twitter Creator"].value : this.props.t('Meta_DefaultTwitterCreator'))} />
                    <meta name="twitter:title" content={(oCurrentItemFields["twitter Title"].value ? oCurrentItemFields["twitter Title"].value :
                                                      oCurrentItemFields["Meta Title"].value ? oCurrentItemFields["Meta Title"].value.replace(regex, '') : 
                                                        oCurrentItemFields["Page Title"].value ? oCurrentItemFields["Page Title"].value.replace(regex, '') : 
                                                          this.props.t('Meta_DefaultTitle').replace(regex, ''))} />
                    <meta name="twitter:description" content={oCurrentItemFields["twitter Description"].value ? oCurrentItemFields["twitter Description"].value :
                                                          oCurrentItemFields["Meta Description"].value ? 
                                                            oCurrentItemFields["Meta Description"].value : 
                                                            this.props.t('Meta_DefaultDescription') } />
                    
                    {Object.keys(oCurrentItemFields["twitter Image"].value).length !== 0 && 
                      <meta name="twitter:image" content={(oCurrentItemFields["twitter Image"].value.src)} />}

                    {Object.keys(oCurrentItemFields["twitter Image"].value).length === 0 && Object.keys(oCurrentItemFields["Page Image"].value).length !== 0 &&
                      <meta name="twitter:image" content={(oCurrentItemFields["Page Image"].value.src)} />}
                  
                </Helmet>
                
              </React.Fragment>
           
        )
    }
}

export default withTranslation()(withSitecoreContext()(MetaSitecore)); 