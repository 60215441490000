import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { getApiDatas } from "../../utilities/FrontFunctions/AoxFrontFunctions";

class PageFAQ extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openedQuestions: {},
			apiDatas: [],
			initDataOK: false,
		};
	}
	componentDidMount() {
		getApiDatas(`/apiaox/Call/GetDataPageFaq`).then((apiDatas) => {
			this.setState({ apiDatas: apiDatas });
			if (apiDatas?.Content) {
				this.setState({ initDataOK: true });
			}
		});

		document.body.classList.add('pg_faq')
		window.scrollTo(0,0)
	}
	componentWillUnmount() {
		document.body.classList.remove('pg_faq')
	}

	// Méthode pour basculer l'état d'expansion d'une question
	toggleQuestion = (categoryIndex, questionIndex) => {
		const openedQuestions = { ...this.state.openedQuestions };
		const key = `${categoryIndex}-${questionIndex}`; // Utilise un identifiant unique pour chaque question
		openedQuestions[key] = !openedQuestions[key]; // Inverse l'état d'expansion de la question
		this.setState({ openedQuestions });
	};

	render() {
		const apiDatas = this.state.apiDatas;

		return (
			<>
				<section className="obj_section section_faq">
					<div className="ctn_section">
						<div className="ctn_title">
							<h1 className="t_title">Questions fréquentes</h1>
						</div>

						{this.state.initDataOK &&
							apiDatas.Content.map((category, categoryIndex) => (
								<div key={categoryIndex} className="ctn_accordion">
									<div className="ctn_title">
										<h2 className="t_title">{category.Title}</h2>
									</div>

									{category.Questions.map((questionItem, questionIndex) => (
										<div key={questionIndex} className={`obj_accordion${this.state.openedQuestions[`${categoryIndex}-${questionIndex}`] ? " is_expanded" : ""}`}>
											<button className="obj_accordion-header" onClick={() => this.toggleQuestion(categoryIndex, questionIndex)}>
												<h3 className="t_title">{questionItem.Title}</h3>
												<div className="obj_accordion_toggle-icon"></div>
											</button>

											<div className="obj_accordion-content">
												<div className="ctn_text">
													<p dangerouslySetInnerHTML={{ __html: questionItem.Desc }}></p>
												</div>
											</div>
										</div>
									))}
								</div>
							))}

						<div className="ctn_accordion">
							<p>
								Pour toute autre question, vous pouvez contacter la hotline par téléphone au 09.70.25.11.40 ou par email à{" "}
								<a href="mailto:partneracademy_loreal@tessi.fr" target="_blank" rel="noopener noreferrer">
									partneracademy_loreal@tessi.fr
								</a>{" "}
								ou votre visiteur pharmaceutique
							</p>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default withTranslation()(PageFAQ);
