import React, { Component } from 'react'
import { RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next';
import Helmet from 'react-helmet';  
import  { Redirect } from 'react-router-dom'

class PageError extends Component {
  componentDidMount() {  
    setTimeout(() => {  
      if(document.getElementById("btRedirect") != null) {
        window.location.replace(document.getElementById("btRedirect").href);   
      }
    }, 10000)
	} 
	render() {
    if (this.props.sitecoreContext.route == null)
    {
      return <></>;
    }    

		const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
    const { AccueilCaf, CurrentUser } = this.props.fields.data;
    
    if(CurrentUser == null){ 
        return <Redirect to='/'  />
    }

		return (
      
       <>
        <Helmet>
          {(this.props.sitecoreContext.itemId === "9131bcd5-5e41-4590-b43d-ff683596a1ce") ?
              <body className="pg_no-acces" />
            :
              <body className="pg_404" />
          }
        </Helmet>
			
        {(this.props.sitecoreContext.itemId === "9131bcd5-5e41-4590-b43d-ff683596a1ce") ?
          <>
            <section className="section_no-acces">
              <div className="str_container"> 
                <div className="ctn_main">
                  <div className="ctn_paragraph var_align-center"> 
                    { oCurrentItemFields && <RichText tag="h2"  field={oCurrentItemFields["Page Title"]} /> }
                    { oCurrentItemFields && <RichText field={oCurrentItemFields["Page Text"]} tag="p"></RichText> }
                    <div className="ctn_cta">
                      <a className="obj_cta" id="btRedirect" href={AccueilCaf && AccueilCaf.url}>{this.props.t('404RetourAccueil')}</a>
                    </div> 
                  </div>
                </div> 
              </div>
            </section>
          </>
        :
          <> 
            { oCurrentItemFields && <RichText tag="h2" className="obj_title" field={oCurrentItemFields["Page Title"]} />}
            <div className="ctn_cta">
              <a className="obj_cta" id="btRedirect" href={AccueilCaf && AccueilCaf.url}>{this.props.t('404RetourAccueil')}</a>
            </div>
          </>
        } 
			</>
		)
	}
}
export default withTranslation()(withSitecoreContext()(PageError));
