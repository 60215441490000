import React, { Component } from 'react'
import Modal from 'react-modal' 
import { withTranslation } from 'react-i18next'; 

const customStyles = {
	overlay : {
		zIndex			: '99999',
		backgroundColor	: 'rgba(0,0,0,0.6)'
	},
	content : {
		position	: 'fixed',
		top			: '50%',
		left		: '50%',
		right		: 'auto',
		bottom		: 'auto',
		transform	: 'translate(-50%, -50%)',
		outline		: '0'
	}
}

Modal.setAppElement('#root')

class PhoneMandatoryModal extends Component {
	constructor() {
		super()
		this.state = {
			modalIsOpen: false
		}
		this.openModal = this.openModal.bind(this)
		this.closeModal = this.closeModal.bind(this)
	}
	componentDidMount() {
		this.props.onRef(this)
	}
	componentWillUnmount() {
		this.props.onRef(null)
	}
	openModal() {
		this.setState({modalIsOpen: true})
	}
	closeModal() {
		this.setState({modalIsOpen: false})
	}
	render() {
		return (
			<div>
				<Modal
					isOpen={this.state.modalIsOpen}
					onRequestClose={this.closeModal}
					style={customStyles}
					className="obj_popin"
					closeTimeoutMS={300}
					contentLabel="Modal"
				>
					<button className="bt_close" onClick={this.closeModal}></button>
					<div className="ctn_popin">
						<p className="t_title">{this.props.t('MettreaJourMesInformations')}</p>
						<p className="t_main">{this.props.t('NousVousInvitonsARemplirPhone')}</p>
						<p className="ctn_cta">
							<a className='obj_cta' 
                                href={this.props.modalUrlInfosPeros + "#GoToAddress"}
               				>
								{this.props.t('MettreaJourMesInformations')}
							</a>
						</p>
					</div>
				</Modal>
			</div>
		)
	}
}
export default  withTranslation()(PhoneMandatoryModal)