import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'; 

import { throttle, isMobile, isNotAtTop } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions"
import { getApiDatas } from "../../utilities/FrontFunctions/AoxFrontFunctions"

import ObjPushChallenge from '../ObjChallenge/ObjPushChallenge';
import ObjAsideChallengeGains from './ObjAsideChallengeGains';

class PageChallenges extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isMobile: isMobile(),
			pushsToShow: 5,
			pushsInterval: 3,
			showMoreText: "Plus de challenges",
			apiDatas:[],
			activeBrands:[],
			activeStatuts:[],
			participationsFiltered:[],
		}

		this.handleWindowResizeMainHeader = this.handleWindowResizeMainHeader.bind(this)
		this.throttleWindowResizeMainHeader = throttle(this.handleWindowResizeMainHeader, 200)
    	this.handleWindowScrollMainHeader = this.handleWindowScrollMainHeader.bind(this)
		this.throttleWindowScrollMainHeader = throttle(this.handleWindowScrollMainHeader, 200)
	}
	componentDidMount() {
		getApiDatas(`/apiaox/Challenge/GetDataPageChallenge`)
		.then((apiDatas) => {
			this.setState({ apiDatas:apiDatas })
			if(apiDatas.Participations){ this.filterReset() }
		})

		document.body.classList.add('pg_challenge')
		window.addEventListener('resize', this.throttleWindowResizeMainHeader)
		window.addEventListener('scroll', this.throttleWindowScrollMainHeader)
		window.scrollTo(0,0)
  	}
  	componentWillUnmount() {
		document.body.classList.remove('pg_challenge')

		window.removeEventListener('resize', this.throttleWindowResizeMainHeader)
		window.removeEventListener('scroll', this.throttleWindowScrollMainHeader)
	}
	handleWindowResizeMainHeader() {
		this.setState({
			isMobile: isMobile()
		})
	}
	handleWindowScrollMainHeader() {
		this.setState({
			modeMini: isNotAtTop()
		})
	}
	showMorePushs = () => {
		this.setState(prevState => ({
			pushsToShow: prevState.pushsToShow + this.state.pushsInterval
		}))
	}
	filterStatut = (statusCode) => {
		const index = this.state.activeStatuts.indexOf(statusCode);
		let newActiveStatuts = this.state.activeStatuts;
		if (index === -1) {
			newActiveStatuts.push(statusCode);
		} else {
			newActiveStatuts.splice(index, 1);
		}
		this.setState({
			activeStatuts: newActiveStatuts,
		}, function() {
			this.filterChallenges();
		})
	}
	filterBrand = (brandCode) => {
		const index = this.state.activeBrands.indexOf(brandCode);
		let newActiveBrands = this.state.activeBrands;
		if (index === -1) {
			newActiveBrands.push(brandCode);
		} else {
			newActiveBrands.splice(index, 1);
		}
		this.setState({
			activeBrands: newActiveBrands,
		}, function() {
			this.filterChallenges();
		})
	}
	filterReset = () => {
		this.setState({
			activeStatuts: [ "STATE_TYPE_TO_DECLARE", "STATE_TYPE_TO_DISPATCH" ],
			activeBrands: [],
		}, function() {
			this.filterChallenges();
		})
	}
	filterChallenges(){
		this.setState({
			pushsToShow:5,
			participationsFiltered:this.state.apiDatas.Participations
				.filter((part) => {return this.state.activeStatuts.length < 1 || this.state.activeStatuts.includes(part.ParticipationStateType_Code);})
				.filter((part) => {return this.state.activeBrands.length < 1 || this.state.activeBrands.includes(part.Challenge.Brand.Brand_Code);})
		})
	}
	render() {
		const apiDatas = this.state.apiDatas
		const { PageChallengeDetail } = this.props.fields.data;

		return (<>
			<section className="obj_section section_challenges">
				<div className="ctn_section">
					<div className="ctn_title">
						<h1 className="t_title">Challenges</h1>
					</div>
				
					<div className="ctn_row">
						<section className="ctn_filter">
							{ 	!this.state.isMobile 
								?<div className="obj_filter-challenge">
									<div className="obj_filters_header">
										<h3 className="t_title">Filtre(s)</h3>
										<div className="obj_remove-filter">
											<button className="obj_header_exp" onClick={() => this.filterReset()}>Tout réinitialiser</button>
										</div>
									</div>

									{apiDatas.States &&
										<div className="obj_filter-list-item">
											<div className="t_title">Par statut</div>
											<ul className="obj_filter-list-container">
												{apiDatas.States.map((statut, index) => (
													<li key={index}>
														<div className="ctn_form-element">
															<input
																type="checkbox"
																id={statut.ParticipationStateType_Code}
																name={statut.ParticipationStateType_Code}
																value={statut.ParticipationStateType_Code}
																onChange={() => this.filterStatut(statut.ParticipationStateType_Code)}
																checked={this.state.activeStatuts.indexOf(statut.ParticipationStateType_Code) > -1}
															/>
															<label htmlFor={statut.ParticipationStateType_Code}>
																{statut.ParticipationStateType_Name}
															</label>
														</div>
													</li>
												))}
											</ul>
										</div>
									}

									{apiDatas.Brands &&
										<div className="obj_filter-list-item">
											<div className="t_title">Par marque</div>
											<ul className="obj_filter-list-container">
												{apiDatas.Brands.map((brand, index) => (
													<li key={index}>
														<div className="ctn_form-element">
															<input
																type="checkbox"
																id={brand.Brand_Code}
																name={brand.Brand_Code}
																value={brand.Brand_Code}
																onChange={() => this.filterBrand(brand.Brand_Code)}
																checked={this.state.activeBrands.indexOf(brand.Brand_Code) > -1}
															/>
															<label htmlFor={brand.Brand_Code}>
																{brand.Brand_Name}
															</label>
														</div>
													</li>
												))}
											</ul>
										</div>
									}
								</div>
								:<>
									<div className={`obj_filter-challenge${this.state.active ? ' is-expanded' : ''}`}>
										<div className="obj_filters_header">
											<h3 className="t_title">Filtrer</h3>
											<div className="obj_remove-filter">
												<button className="obj_header_exp" onClick={() => this.filterReset()}>Tout réinitialiser</button>
											</div>
										</div>

										<div className="ctn_row">
											{apiDatas.States && 
												<div className="obj_filter-list-item">
													<div className="t_title">Par statut</div>
													<ul className="obj_filter-list-container">
														{apiDatas.States.map((statut, index) => (
															<li key={index}>
																<div className="ctn_form-element">
																	<input
																		type="checkbox"
																		id={statut.ParticipationStateType_Code}
																		name={statut.ParticipationStateType_Code}
																		value={statut.ParticipationStateType_Code}
																		onClick={() => this.filterStatut(statut.ParticipationStateType_Code)}
																		checked={this.state.activeStatuts.indexOf(statut.ParticipationStateType_Code) > -1}
																	/>
																	<label htmlFor={statut.ParticipationStateType_Code}>
																		{statut.ParticipationStateType_Name}
																	</label>
																</div>
															</li>
														))}
													</ul>
												</div>
											}

											{apiDatas.Brands && 
												<div className="obj_filter-list-item">
													<div className="t_title">Par marque</div>
													<ul className="obj_filter-list-container">
														{apiDatas.Brands.map((brand, index) => (
															<li key={index}>
																<div className="ctn_form-element">
																	<input
																		type="checkbox"
																		id={brand.Brand_Code}
																		name={brand.Brand_Code}
																		value={brand.Brand_Code}
																		onClick={() => this.filterBrand(brand.Brand_Code)}
																		checked={this.state.activeBrands.indexOf(brand.Brand_Code) > -1}
																	/>
																	<label htmlFor={brand.Brand_Code}>
																		{brand.Brand_Name}
																	</label>
																</div>
															</li>
														))}
													</ul>
												</div>
											}
										</div>
									</div>

									<div className="obj_filter-challenge-menu">
										<button className="obj_btn_filter-challenge" onClick={ () => this.setState({active: !this.state.active}) }>
											{/* <!-- is-expanded --> */}
										</button>
									</div>
								</>
							}
						</section>

						<section className="ctn_list_challenge">
							{	!apiDatas.Participations
								//API non chargée
								?<div className="obj_challenge is_empty"/>
								:	apiDatas.Participations.length<1
									//pas de participations par l'API
									?<div className="obj_challenge is_empty">
										<div className="ctn_text">
											<p className="t_title" dangerouslySetInnerHTML={{__html: "Votre équipe L'Oréal<br /> Beauté Dermatologique<br /> prépare avec soin vos<br /> prochains événements<br /><br />A très vite !"}} />
										</div>
										<picture className="ctn_background">
											<source srcSet={require('../../assets/images/v_empty_challenge_list_mob.jpg')} media="(max-width:767px)"/>
											<img src={require('../../assets/images/v_empty_challenge_list.jpg')} alt=""/>
										</picture>
									</div>
									:	this.state.participationsFiltered.length < 1
										//pas de participations par le filtrage
										?<div className="obj_challenge is_empty">
											<div className="ctn_text">
												<p className="t_title" dangerouslySetInnerHTML={{__html: "Aucun résultat"}} />
											</div>
											<picture className="ctn_background">
												<source srcSet={require('../../assets/images/v_empty_challenge_list_mob.jpg')} media="(max-width:767px)"/>
												<img src={require('../../assets/images/v_empty_challenge_list.jpg')} alt=""/>
											</picture>
										</div>
										//liste filtrée
										:<>
											{this.state.participationsFiltered.slice(0, this.state.pushsToShow).map((participation, index) => (
												<ObjPushChallenge key={index} participation={participation} isManager={apiDatas.User.ChallengeManager} pageChallengeDetail={PageChallengeDetail} />
											))}
											{this.state.participationsFiltered.length > this.state.pushsToShow  && 
												<div className="ctn_cta">
													<button className="obj_cta" type="button" onClick={() => this.showMorePushs()}>{this.state.showMoreText}</button>
												</div>
											}
										</>
							}
						</section>

						<section className="ctn_aside">
							{apiDatas.User &&
								<ObjAsideChallengeGains user={apiDatas.User}/>
							}
						</section>
					</div>
				</div>
			</section>
		</>)
	}
}

export default withTranslation()(PageChallenges)