import React, { Component } from 'react'
import { Field } from 'formik'

class PasswordToggle extends Component {
	constructor(props) {
		super(props)
		this.state = {
			visible: false,
			eyeMode: this.props.eyeMode ? this.props.eyeMode : false,
			level: 0,
			placeholder: this.props.placeholder ? this.props.placeholder : '',
			minCharacters: this.props.minCharacters ? this.props.minCharacters : 8,
			maxCharacters: this.props.maxCharacters ? this.props.maxCharacters : 524288,
			regexNumeric: /[0-9]/,
			regexLowerCase: /[a-z]/,
			regexUpperCase: /[A-Z]/,
			regexSpecialCharacters: this.props.regexSpecialCharacters ? this.props.regexSpecialCharacters : /[!@#$%^&*)(+=._-]/,
			strengthLevel: this.props.strengthLevel ? this.props.strengthLevel : false, 
		}
		this.handleChangePassword = this.handleChangePassword.bind(this)
		this.handlePasswordVisible = this.handlePasswordVisible.bind(this)
		this.destroy = this.destroy.bind(this)
	}
	componentDidMount() {
		this.props.onRef(this)
	}
	componentWillUnmount() {
		this.props.onRef(null)
	}
	handleChangePassword(e, setFieldValue, fieldName) {
		var inputValue = e.target.value
		this.setState({ level: 0 })

		if (this.state.regexNumeric.test(inputValue)) this.setState(prevState => ({ level: prevState.level + 1 }))
		if (this.state.regexLowerCase.test(inputValue)) this.setState(prevState => ({ level: prevState.level + 1 }))
		if (this.state.regexUpperCase.test(inputValue)) this.setState(prevState => ({ level: prevState.level + 1 }))
		if (this.state.regexSpecialCharacters.test(inputValue)) this.setState(prevState => ({ level: prevState.level + 1 }))
		if (inputValue.length >= this.state.minCharacters) this.setState(prevState => ({ level: prevState.level + 1 }))
				
		setFieldValue(fieldName, inputValue)
	}
	handlePasswordVisible() {
		this.setState({ visible: !this.state.visible })
	}
	destroy() {
		this.setState({ level: 0 })
	}
	render() {
		const { setFieldValue, handleBlur, errors, touched, fieldName } = this.props
		const { visible, eyeMode, level, maxCharacters, strengthLevel, placeholder } = this.state
 
		return(
			<div className="ctn_input">
				<Field
					className={(errors[fieldName] && touched[fieldName]) ? 'error' : ''}
					type={visible ? 'text' : 'password'}
					name={fieldName}
					placeholder={placeholder}
					onChange={(e) => this.handleChangePassword(e, setFieldValue, fieldName)}
					onBlur={handleBlur}
					maxLength={maxCharacters} 
				/>
				{eyeMode &&
					<button className="btn_password-toggle" type="button" aria-pressed={visible} onClick={this.handlePasswordVisible}>
						<svg viewBox="0 0 28 16">
							<path d="M14,0C6.3,0,0,8,0,8s6.3,8,14,8c7.7,0,14-8,14-8S21.7,0,14,0z M14,14.8c-3.8,0-6.9-3.1-6.9-6.8s3.1-6.8,6.9-6.8c3.8,0,6.9,3.1,6.9,6.8S17.8,14.8,14,14.8z"/><ellipse cx="14" cy="8" rx="3" ry="3"/>
						</svg>
					</button>
				}
				{strengthLevel && <div className="obj_strength-level" data-level={level}></div>}
			</div>
		)
	}
}

PasswordToggle.defaultProps = {
	onRef: () => {}
}

export default PasswordToggle