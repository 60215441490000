import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'; 

import { formatDate, throttle, isMobile } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions"
import { urlToHost } from "../../utilities/FrontFunctions/AoxFrontFunctions"

import ObjConditionChallenge from './ObjConditionChallenge';

class ObjChallengeDetail extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isMobile: isMobile()
		}
		this.handleWindowResizeMainHeader = this.handleWindowResizeMainHeader.bind(this)
		this.throttleWindowResizeMainHeader = throttle(this.handleWindowResizeMainHeader, 200)
	}
	handleWindowResizeMainHeader() {
		this.setState({
			isMobile: isMobile()
		})
	}
	render() {
		const {participation, isManager} = this.props;

		return (
			<div className="obj_challenge-detail">
				{!this.state.isMobile &&
					<div className="ctn_row">
						<div className="ctn_image">
							<div className="ctn_visuel">
								<img src={urlToHost(participation.Challenge.Challenge_MediaUrl)} alt={participation.Challenge.Brand.Brand_Name}/>
							</div>

							<div className="ctn_logo">
								<img src={urlToHost(participation.Challenge.Brand.Brand_LogoUrl)} alt={participation.Challenge.Brand.Brand_Name}/>
							</div>
						</div>

						<div className="ctn_text">
							<p className="t_title">Détail du challenge</p>
							<p className="t_date">Du {formatDate(participation.Challenge.Challenge_StartDate)} au {formatDate(participation.Challenge.Challenge_EndDate)}</p>
							<p className="t_desc" dangerouslySetInnerHTML={{__html: participation.Challenge.Challenge_Description}}/>
							<ObjConditionChallenge participation={participation} isManager={isManager} showProduct={true}/>
						</div>
					</div>
				}
				
				{this.state.isMobile &&
					<div className={`ctn_challenge-detail${this.state.activeDetailChallenge ? ' is-expanded' : ''}`}>
						<button className="obj_btn_challenge-detail" onClick={ () => this.setState({activeDetailChallenge: !this.state.activeDetailChallenge}) }>
							<span>Détail du challenge</span>
						</button>
						<div className="expend_challenge-detail">
							<div className="ctn_row">
								<div className="ctn_image">
									<div className="ctn_visuel">
										<img src={urlToHost(participation.Challenge.Challenge_MediaUrl)} alt={participation.Challenge.Brand.Brand_Name}/>
									</div>

									<div className="ctn_logo">
										<img src={urlToHost(participation.Challenge.Brand.Brand_LogoUrl)} alt={participation.Challenge.Brand.Brand_Name}/>
									</div>
								</div>

								<div className="ctn_text">
									<p className="t_title">Détail du challenge</p>
									<p className="t_date">Du {formatDate(participation.Challenge.Challenge_StartDate)} au {formatDate(participation.Challenge.Challenge_EndDate)}</p>
									<p className="t_desc" dangerouslySetInnerHTML={{__html: participation.Challenge.Challenge_Description}}/>
								</div>
							</div>
							<ObjConditionChallenge participation={participation} showProduct={true}/>
						</div>
					</div>
				}
			</div>
		)
	}
}

export default withTranslation()(ObjChallengeDetail)