import React, { Component } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Text, RichText, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { ReCaptcha } from "react-recaptcha-v3";
import { withTranslation } from "react-i18next";
import queryString from "query-string";
import axios from "axios";

import config from "../../temp/config";
import CustomSelect from "../../utilities/FrontFunctions/FormElements/CustomSelect";
import { trackGA4Event, trackGA4PageView } from "../../utilities/FrontFunctions/AoxTrackingFunctions";
import { getApiDatas } from "../../utilities/FrontFunctions/AoxFrontFunctions";

class PageContact extends Component {
	constructor(props) {
		super(props);
		this.state = {
			serverError: false,
			formSubmitted: false,
			UrlContact: `${config.sitecoreApiHost}/apiaox/Call/Contact`,
			apiDatas: [],
			initDataOK: false,
		};
		this.bPushTrackPageResult_Done = false;
	}
	handleClickSubmit() {
		trackGA4Event(window.trackDatas, "submit_contact_form", "form", "submit", "customer_contact_form");
	}
	componentDidMount() {
		getApiDatas(`/apiaox/Call/GetDataPageContact`).then((apiDatas) => {
			this.setState({ apiDatas: apiDatas });
			if (apiDatas?.Brands) {
				this.setState({ initDataOK: true });
			}
		});

		window.scrollTo(0, 0);
	}
	render() {
		
		const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
		const apiDatas = this.state.apiDatas;
		const optionsSujet = [
			{ value: "Données personnelles", label: "Données personnelles" },
			{ value: "Mes challenges", label: "Mes challenges" },
			{ value: "Mes formations", label: "Mes formations" },
			{ value: "Mes évènements", label: "Mes évènements" },
			{ value: "Mon expertise", label: "Mon expertise" },
			{ value: "Master Santé", label: "Master Santé" },
			{ value: "site ecom (commandes)", label: "site ecom (commandes)" },
			{ value: "Autre", label: "Autre" },
		];

		if (this.state.formSubmitted && !this.bPushTrackPage_Done) {
			if (typeof window.trackDatas !== "undefined") {
				var trackData = window.trackDatas;
				trackData.pageCategory = "contact form submitted";
				trackGA4PageView(trackData).then((result) => {
					this.bPushTrackPage_Done = result;
				});
			}
		}

		return (
			<section className="section_form">
				<div className="str_container">
					<div className="ctn_top">{oCurrentItemFields && <RichText tag="h1" field={oCurrentItemFields["Page Title"]}></RichText>}</div>
					<div className="ctn_form">
						<div className="ctn_col col_left">
							<div className="ctn_contact">
								<h3>{this.props.t("Register_contactQuestion")}</h3>
								<p className="t_tel" dangerouslySetInnerHTML={{ __html: this.props.t("Register_contactPhone") }}></p>
								<p className="t_mention">{this.props.t("Register_contactByPhonePrice")}</p>
							</div>
						</div>
						<div className="ctn_col col_right">
							{!this.state.formSubmitted && oCurrentItemFields && <Text tag="h2" field={oCurrentItemFields["Page SubTitle"]}></Text>}
							<div className="obj_form">
								{this.state.formSubmitted ? (
									<>
										<p className="t_mention mb_30" dangerouslySetInnerHTML={{ __html: this.props.t("Contact_PriseEnCompte") }}></p>
										<div className="ctn_cta">
											<a className="obj_cta" href="/">
												{this.props.t("mdppage_retour")}
											</a>
										</div>
									</>
								) : !this.state.initDataOK ? (
									<>
										{/* TODO SGU Msg */}
										<p className="t_error mb_30 fade-in">Error init Form </p>
									</>
								) : (
									<>
										<Formik
											initialValues={{
												codeSAP: "",
												lastname: "",
												firstname: "",
												email: "",
												marque: "",
												sujet: "",
												message: "",
												recaptcha: "",
											}}
											validationSchema={Yup.object().shape({
												lastname: Yup.string().required(),
												firstname: Yup.string().required(),
												email: Yup.string().email().required(),
												marque: Yup.string().required(),
												sujet: Yup.string().required(),
												message: Yup.string().required(),
												recaptcha: Yup.string().required(),
											})}
											onSubmit={(values, actions) => {
												// add loader
												document.body.classList.add("is-loading");

												axios
													.post(this.state.UrlContact, queryString.stringify(values), {
														headers: {
															Accept: "application/json",
															"Content-Type": "application/x-www-form-urlencoded",
														},
													})
													.then((result) => {
														this.setState({
															formSubmitted: result.data.IsValid,
															serverError: !result.data.IsValid,
															serverErrorMessage: result.data.ExceptionMessage,
														});

														if (this.recaptchaContact) {
															this.recaptchaContact.execute();
														}

														document.body.classList.remove("is-loading");
													})
													.catch((err) => {
														console.log(err);

														this.setState({
															serverError: true,
															serverErrorMessage: this.props.t("DefaultServerError"),
														});

														if (this.recaptchaContact) {
															this.recaptchaContact.execute();
														}

														document.body.classList.remove("is-loading");
													});
											}}
										>
											{({ handleSubmit, setFieldValue, setFieldTouched, errors, touched }) => (
												<form onSubmit={handleSubmit}>
													<div className="ctn_input mb_30">
														<Field className="" as="input" type="text" name="codeSAP" id="codeSAP" placeholder={this.props.t("Contact_codeSAP")} autoComplete="off" />
													</div>
													<div className="ctn_input mb_30">
														<Field
															className={errors.lastname && touched.lastname && "error"}
															as="input"
															type="text"
															name="lastname"
															id="lastname"
															placeholder={this.props.t("Contact_nom")}
															autoComplete="off"
														/>
													</div>
													<div className="ctn_input mb_30">
														<Field
															className={errors.firstname && touched.firstname && "error"}
															as="input"
															type="text"
															name="firstname"
															id="firstname"
															placeholder={this.props.t("Contact_prenom")}
															autoComplete="off"
														/>
													</div>
													<div className="ctn_input mb_30">
														<Field
															className={errors.email && touched.email && "error"}
															as="input"
															type="email"
															name="email"
															id="email"
															placeholder={this.props.t("Contact_email")}
															autoComplete="off"
														/>
													</div>

													<div className="mb_30">
														<CustomSelect
															fieldName={"marque"}
															placeholder={this.props.t("Contact_Marque")}
															options={apiDatas.Brands.map((brand) => ({
                                                                value: brand.Brand_Code,
                                                                label: brand.Brand_Name,
                                                            }))}
															setFieldValue={setFieldValue}
															setFieldTouched={setFieldTouched}
															errors={errors}
															touched={touched}
														/>
													</div>

													<div className="mb_30">
														<CustomSelect
															fieldName={"sujet"}
															placeholder={this.props.t("Contact_Sujet")}
															options={optionsSujet}
															setFieldValue={setFieldValue}
															setFieldTouched={setFieldTouched}
															errors={errors}
															touched={touched}
														/>
													</div>

													<div className="ctn_textarea mb_30">
														<Field className={errors.message && touched.message && "error"} as="textarea" name="message" placeholder={this.props.t("Contact_Message")} />
													</div>

													<p className="t_mandatory mb_30">{this.props.t("Contact_mandatory")}</p>

													<p className="t_mention mb_30" dangerouslySetInnerHTML={{ __html: this.props.t("Contact_PPDP") }}></p>

													<ReCaptcha
														ref={(ref) => (this.recaptchaContact = ref)}
														sitekey={this.props.t("recaptcha_sitekey")}
														action="contact"
														verifyCallback={(recaptchaToken) => {
															setFieldValue("recaptcha", recaptchaToken);
														}}
													/>

													{this.state.serverError && <p className="t_error mb_30 fade-in">{this.state.serverErrorMessage}</p>}

													{((errors.marque && touched.marque) || (errors.sujet && touched.sujet) || (errors.message && touched.message) || errors.recaptcha) && (
														<p className="t_error mb_30 fade-in">
															{this.props.t("DefaultErrorMessage")}
															{console.log(errors)}{" "}
														</p>
													)}

													<div className="ctn_cta mb_40">
														<button className="obj_cta" type="submit" onClick={this.handleClickSubmit}>
															{this.props.t("Contact_Envoyer")}
														</button>
														<a className="obj_cta reverse" href="/">
															{this.props.t("Contact_Annuler")}
														</a>
													</div>

													<p className="t_info mb_30" dangerouslySetInnerHTML={{ __html: this.props.t("Contact_Telephone") }}></p>

													<p className="t_mandatory var_grey" dangerouslySetInnerHTML={{ __html: this.props.t("Contact_Telephone_mandatory") }}></p>
												</form>
											)}
										</Formik>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default withTranslation()(withSitecoreContext()(PageContact));
