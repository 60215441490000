import React, { Component } from 'react'

import { badges } from '../../data/badges';
import ExpertiseBadgeModal from '../Modal/ExpertiseBadgeModal'

class ObjExpertiseBadge extends Component {
	render() {
		const { badge, userBadge } = this.props;
		const badgeDataTheme = badges.find((item) => item.type === badge.Badge_Type)?.theme.find((item) => item.theme === badge.Badge_Theme);

		return (
			<>{badge &&
				<div className={`obj_item_expertise fade-in`}>
					<button
						type="button" 
						onClick={() => this["ExpertiseBadgeModal" + badge.Badge_Id].openModal()}
					>
						<picture className="ctn_image">
							{	userBadge
								?<img src={badgeDataTheme?.image} alt={badge.Badge_Title}/>
								:<img src={badgeDataTheme?.imageDisabled} alt={badge.Badge_Title}/>
							}
						</picture>
						<div className="ctn_text">
							<p className="t_tag"><span>{badge.UserRewardAction_Points} pts</span></p>
						</div>
					</button>

					<ExpertiseBadgeModal
						onRef={ref => (this["ExpertiseBadgeModal" + badge.Badge_Id] = ref)}
						badge={badge}
						userBadge={userBadge}
						badgeDataTheme={badgeDataTheme}
						bodyOpenClassName="ReactModal__Body--open_blur"
					/>
				</div>
			}</>
		)
	}
}

export default ObjExpertiseBadge