import { withSitecoreContext,  Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'; 


class PageProductRanges extends Component {
	render() {
		const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
	
		const { LstProduits } = this.props.fields.data; 
		return (
			<section className="section_product-liste">
				<div className="str_container">

					<section className={`obj_banner-product${oCurrentItemFields && oCurrentItemFields["Banner WhiteMode"] ? ' var_white' : ''}`}>
						<div className="ctn_text">
							{ oCurrentItemFields && !oCurrentItemFields["Hide Title"]?.value && <RichText field={oCurrentItemFields["Page Title"]} tag="h1"></RichText>}
							{ oCurrentItemFields && <RichText field={oCurrentItemFields["Page SubTitle"]} tag="h2"></RichText>}
						</div>
						{ oCurrentItemFields && <figure>
							<picture>
							<source srcSet={oCurrentItemFields["Banner Image Mobile"]?.value} media="(max-width:767px)"/>
							<Image field={oCurrentItemFields["Banner Image"]}></Image>
							</picture>
						</figure>}
					</section>
								
					<section className="section_range-liste">
						<div  className="ctn_range-liste">
								{LstProduits && LstProduits.children.map((productItem, index) => ( 
									<article key={index} className="obj_push-product">
										<a href={productItem.url}>
											<figure>
                        						<Image field={productItem.pageImageSmall.jss}></Image>
											</figure>
											<RichText field={productItem.pageTitle.jss} tag="h4" className="t_title"></RichText>
                     						<RichText field={productItem.pageSubTitle.jss} tag="p" className="t_desc"></RichText>
										</a>
									</article>
								))}							 		
                		</div>
					</section>
				</div>
		</section>
		)
	}
}

export default withTranslation()(withSitecoreContext()(PageProductRanges));