import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'; 
import { strDateToFr } from "../../utilities/FrontFunctions/AoxFrontFunctions"

class ObjAsideChallengeGains extends Component {
	constructor(props) {
		super(props)
		this.tabYears = [new Date().getFullYear(), new Date().getFullYear()-1, new Date().getFullYear()-2]
		this.state = {
			isReady: false,
			current: 0,
			length: null,
		}
		this.showTab = this.showTab.bind(this)
		this.setMaxHeight = this.setMaxHeight.bind(this)
		this.handleKeyDown = this.handleKeyDown.bind(this)
		this.goNextTab = this.goNextTab.bind(this)
		this.goPrevTab = this.goPrevTab.bind(this)
	}
	componentDidMount(){
		this.setMaxHeight()
		this.showTab(this.state.current)
		this.navTabs.querySelectorAll('li').forEach(element => element.querySelector('button').blur())
		this.setState({
			length: this.navTabs.querySelectorAll('li').length
		})
	}
	switchTab(e){
		var tabsList = Array.prototype.slice.call(this.navTabs.children)
    	var currentTab = e.currentTarget.parentElement
    	var currentIndex = tabsList.indexOf(currentTab)
		this.setState({
			current: currentIndex,
		}, () => {this.showTab(this.state.current);})
	}
	showTab(index){
		this.navTabs.querySelectorAll('li').forEach(element => {
			element.querySelector('button').classList.remove('active')
			element.querySelector('button').blur()
			element.querySelector('button').setAttribute('aria-selected', false)
			element.querySelector('button').setAttribute('tabindex', -1)
		})
		this.navTabs.children[index].querySelector('button').classList.add('active')
		this.navTabs.children[index].querySelector('button').focus()
		this.navTabs.children[index].querySelector('button').setAttribute('aria-selected', true)
		this.navTabs.children[index].querySelector('button').setAttribute('tabindex', 0)
		this.ctnTabs.querySelectorAll('.tab-panel').forEach(element => { 
			element.classList.remove('fade-in')
			element.classList.remove('active') 
		})
		this.ctnTabs.children[index].classList.add('fade-in')
		this.ctnTabs.children[index].classList.add('active')
	}
	goNextTab() {
		this.setState({
			current: (this.state.current + 1) >= this.state.length ? 0 : this.state.current + 1
		}, () => this.showTab(this.state.current))
	}
	goPrevTab() {
		this.setState({
			current: this.state.current <= 0 ? this.state.length - 1 : this.state.current - 1
		}, () => this.showTab(this.state.current))
	}
	setMaxHeight(){
		var maxHeight = 0
		this.ctnTabs.querySelectorAll('.tab-panel').forEach(element => {
			if (element.offsetHeight > maxHeight) maxHeight = element.offsetHeight
		})
		this.setState({ isReady: true })
	}
	handleKeyDown(e){
		e.key === 'ArrowRight' && this.goNextTab()
		e.key === 'ArrowLeft' && this.goPrevTab()
	}
	handleFocus(){
		window.addEventListener('keydown', this.handleKeyDown)
	}
	handleBlur(){
		window.removeEventListener('keydown', this.handleKeyDown)
	}
	render() {
		return (
			<div className={`obj_table-gains${this.state.activeMenuGain ? ' is-expanded' : ''}`}>
				<div className="obj_table-gains-header">
					<h3 className="t_title">Mes Gains cumulés {this.tabYears[this.state.current]}</h3>
					<button className={`obj_table-gains-detail${this.state.activeMenuGain ? ' is-expanded' : ''}`} onClick={ () => this.setState({activeMenuGain: !this.state.activeMenuGain}) }>
						<span>voir le Détail des gains</span>
					</button>
					<div className="obj_table-gains-total">
						{this.props.user["Repartitions"+this.tabYears[this.state.current]].reduce((sum, item) => sum + item.Repartition_ValueRaw, 0)}
						<sup>€</sup>
					</div>
				</div>

				<div className={`obj_table-gains-content${this.state.isReady ? ' is-ready' : ''}`}>
					<ul ref={ref => (this.navTabs = ref)} className="obj_tabs-list-gains" role="tablist">
						{this.tabYears.map((year, index) => (
							<li key={`onglet-${index + 1}`}>
								<button
									type="button"
									className="btn_tab_year_gain"
									role="tab"
									id={`onglet-${index + 1}`}
									tabIndex={index === 0 ? 0 : -1}
									aria-selected={index === 0}
									aria-controls={`panneau-${index + 1}`}
									onClick={(e) => this.switchTab(e)}
									onFocus={() => this.handleFocus()}
									onBlur={() => this.handleBlur()}
								>
									{year}
								</button>
							</li>
						))}
					</ul>

					<div className="obj_table-list-gains tab-panels">
						<div className="ctn_gains_panels" ref={ref => (this.ctnTabs = ref)}>
							{this.tabYears.map((year, index) => (
								<div key={index} className="tab-panel fade-in" role="tabpanel" id={`panneau-${index + 1}`} aria-labelledby="onglet-1" tabIndex="0">
									{	(this.props.user["Repartitions"+year].length > 0)
										?this.props.user["Repartitions"+year].map((repartition, index) => (
											<div key={index} className="obj_table-item-gains">
												<p className="t_date">{strDateToFr(repartition.Repartition_GainDate)}</p>
												<p className="t_title">{repartition.Challenge_Title}</p>
												<p className="t_desc">{repartition.Brand_Name}</p>
												<span className="t_total">{repartition.Repartition_ValueRaw}<sup>€</sup></span>
											</div>
										))
										:<div className="obj_table-item-gains is_empty">
											<p className="t_title">Aucun gain remporté.</p>
											<p className="t_desc">Vous n'avez pas remporté de gain de challenge sur la période sélectionnée.</p>
										</div>
									}
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslation()(ObjAsideChallengeGains)