import React, { Component } from "react";
import { Text, Image, withSitecoreContext, RichText } from "@sitecore-jss/sitecore-jss-react";
import { ConditionalWrapper } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions";
import { GetFriendlyUrl } from "../../utilities/FrontFunctions/AoxFrontFunctions";
import IconSVG from "../../utilities/FrontFunctions/IconSVG";
import { withTranslation } from "react-i18next";

class PageListe extends Component {
	handleClickPush(articleItem) {
		if (articleItem.template.id === "E65891FDEBCB4538B030451CFA6216A5") {
			document.location.href = GetFriendlyUrl(articleItem.trainingUrl);
		} else if (articleItem.template.id === "00D014A9524641EAADE72A3CD621DEBC" || articleItem.template.id === "C71142FF447946B2B7EA4306CBA526E7") {
			//Download & Link
			window.open(GetFriendlyUrl(articleItem.pageLink));
		} else if (articleItem.template.id === "412FE69C129E4E6294116A162F3D4CC3" && articleItem.videoOnly && articleItem.videoOnly.boolValue) {
			//article video only
			window.open(articleItem.url);
		} else {
			//autre
			window.open(articleItem.url, "_self");
		}
	}

	render() {
		const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
		const { CurrentPageList } = this.props.fields.data;

		return (
			<>
				{oCurrentItemFields && <Text className="obj_title" tag="h2" field={oCurrentItemFields["Page Title"]}></Text>}
				<section className="section_page-liste">
					<div className="str_container">
						{CurrentPageList && (
							<ConditionalWrapper
								condition={CurrentPageList.FirstArticles}
								wrapper={(children) => (
									<section className="ctn_row">
										<div className="ctn_sub-row">{children}</div>
									</section>
								)}
							>
								{CurrentPageList.FirstArticles &&
									CurrentPageList.FirstArticles.map((articleItem, index) => (
										<article
											key={index}
											className={`ctn_page-liste${articleItem.videoUrl && articleItem.videoUrl.jss && articleItem.videoUrl.jss.value !== "" ? " var_video" : ""}${
												articleItem.trainingPushPlayButton && articleItem.trainingPushPlayButton.boolValue ? " var_video" : ""
											}`}
										>
											<button
												type="button"
												onClick={() => {
													this.handleClickPush(articleItem);
												}}
											>
												<figure>
													{/* //article brand & subList */}
													{(articleItem.template.id === "412FE69C129E4E6294116A162F3D4CC3" || articleItem.template.id === "5EF6591272674915B2AFBB6022C9BAEA") && (
														<Image field={articleItem.pageImagePush.jss}></Image>
													)}
													{articleItem.template.id !== "412FE69C129E4E6294116A162F3D4CC3" && articleItem.template.id !== "5EF6591272674915B2AFBB6022C9BAEA" && (
														<Image field={articleItem.pageImage.jss}></Image>
													)}
												</figure>
												<RichText field={articleItem.pageTitle.jss} tag="h3"></RichText>
												{articleItem.template.id === "412FE69C129E4E6294116A162F3D4CC3" && !(articleItem.videoOnly && articleItem.videoOnly.boolValue) && (
													<ul className="obj_likes-comments">
														<li>
															<IconSVG icon="heart" width="19" height="16" />
															{articleItem.likes.numberValue ? articleItem.likes.numberValue : 0} <span>{this.props.t("ArticleLike")}</span>
														</li>
														{articleItem.userCanAddReviews.boolValue && (
															<li>
																<IconSVG icon="dialog" width="20" height="17" />
																{articleItem.reviewsCount}{" "}
																<span>{articleItem.reviewsCount >= 2 ? this.props.t("ArticleCommentaires") : this.props.t("ArticleCommentaire")}</span>
															</li>
														)}
													</ul>
												)}
												{articleItem.template.id === "00D014A9524641EAADE72A3CD621DEBC" && (
													<div className="ctn_cta">
														<div className="obj_cta-download">
															<IconSVG icon="download" width="18" height="18" />
															{articleItem.pageLink.jss.value.text}
														</div>
													</div>
												)}
											</button>
										</article>
									))}
							</ConditionalWrapper>
						)}
						{CurrentPageList &&
							CurrentPageList.Categories &&
							CurrentPageList.Categories.map((oCurrentItem, index) => (
								<section key={index} className="ctn_row">
									<h2>
										<strong>
											<Text field={oCurrentItem.pageTitle.jss}></Text>
										</strong>
										{oCurrentItem.pageSubTitle.jss.value !== "" ? " - " : ""}
										<Text field={oCurrentItem.pageSubTitle.jss}></Text>
									</h2>

									<div className="ctn_sub-row">
										{oCurrentItem.children.map((articleItem, index) => (
											<article
												key={index}
												className={`ctn_page-liste${articleItem.videoUrl && articleItem.videoUrl.jss && articleItem.videoUrl.jss.value !== "" ? " var_video" : ""}${
													articleItem.trainingPushPlayButton && articleItem.trainingPushPlayButton.boolValue ? " var_video" : ""
												}${articleItem.pushPlayButton && articleItem.pushPlayButton.boolValue ? " var_video" : ""}`}
											>
												<button
													type="button"
													onClick={() => {
														this.handleClickPush(articleItem);
													}}
												>
													<figure>
														{/* //article brand & subList */}
														{(articleItem.template.id === "412FE69C129E4E6294116A162F3D4CC3" || articleItem.template.id === "5EF6591272674915B2AFBB6022C9BAEA") && (
															<Image field={articleItem.pageImagePush.jss}></Image>
														)}
														{articleItem.template.id !== "412FE69C129E4E6294116A162F3D4CC3" && articleItem.template.id !== "5EF6591272674915B2AFBB6022C9BAEA" && (
															<Image field={articleItem.pageImage.jss}></Image>
														)}
													</figure>
													<Text field={articleItem.pageTitle.jss} tag="h3"></Text>
													{articleItem.template.id === "412FE69C129E4E6294116A162F3D4CC3" && !(articleItem.videoOnly && articleItem.videoOnly.boolValue) && (
														<ul className="obj_likes-comments">
															{articleItem.likes && articleItem.likes.numberValue && (
																<li>
																	<IconSVG icon="heart" width="19" height="16" />
																	{articleItem.likes.numberValue ? articleItem.likes.numberValue : 0} <span>{this.props.t("ArticleLike")}</span>
																</li>
															)}
															{articleItem.userCanAddReviews && articleItem.userCanAddReviews.boolValue && (
																<li>
																	<IconSVG icon="dialog" width="20" height="17" />
																	{articleItem.reviewsCount}{" "}
																	<span>{articleItem.reviewsCount >= 2 ? this.props.t("ArticleCommentaires") : this.props.t("ArticleCommentaire")}</span>
																</li>
															)}
														</ul>
													)}
													{articleItem.template.id === "00D014A9524641EAADE72A3CD621DEBC" && (
														<div className="ctn_cta">
															<div className="obj_cta-download">
																<IconSVG icon="download" width="18" height="18" />
																{articleItem.pageLink.jss.value.text}
															</div>
														</div>
													)}
												</button>
											</article>
										))}
									</div>
								</section>
							))}
					</div>
				</section>
			</>
		);
	}
}
export default withTranslation()(withSitecoreContext()(PageListe));
