import React, { Component } from 'react'
import { isAppMobile } from '../../utilities/FrontFunctions/AoxFrontFunctions'

function reverseArray(arr) {
	var newArray = [];
	for (var i = arr.length - 1; i >= 0; i--) {
		newArray.push(arr[i]);
	}
	return newArray;
}

class ObjBreadcrumb extends Component {
	constructor(props) {
		super(props)
		
		this.state = {
			isAppMobile: false,
		}
	}

	componentDidMount() {
		this.setState({
			isAppMobile: isAppMobile()
		});
	}

	render() { 
		if (this.state.isAppMobile){
			return <></>
		}

		var GetBreadCrumb = null;
		var iBreadCrumbLenght = 0;

		if(this.props.fields){
			GetBreadCrumb = this.props.fields.data.GetBreadCrumb;
		}
		
		if(GetBreadCrumb && GetBreadCrumb.ancestors){
			iBreadCrumbLenght = GetBreadCrumb.ancestors.length;
		}
		//YFL : La fonction reverse() ne marche pas test
		var oBreadCrumbReverse = reverseArray(GetBreadCrumb.ancestors);

		return (
			<ul className="obj_breadcrumb">
				{oBreadCrumbReverse.map((oBreadCrumbItem, index) => (
					oBreadCrumbItem.pageTitle.jss?.value &&
					<li key={index}>
						{index !== iBreadCrumbLenght-1 ? 
							<a href={oBreadCrumbItem.url}>
								{oBreadCrumbItem.pageTitle.jss.value.replace(/<[^>]*>?/gm, '')}
								{/*<RichText field={oBreadCrumbItem.pageTitle.jss}></RichText>*/}
							</a>
						: 
							<span>
								{/*<RichText field={oBreadCrumbItem.pageTitle.jss} tag="span"></RichText>*/}
								{oBreadCrumbItem.pageTitle.jss.value.replace(/<[^>]*>?/gm, '')}
							</span>
						}
					</li>
				))}
			</ul> 
		)
	}
}
export default ObjBreadcrumb;