import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'; 

import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { CircularProgressbar } from 'react-circular-progressbar'
import AnimatedProgressProvider from '../../utilities/FrontFunctions/Provider/AnimatedProgressProvider'

class ObjAsideChallengeVerificationRepartition extends Component {
	constructor(props) {
		super(props)

		let formList = [];
		if(this.props.participation?.Repartitions){
			let cumulPercent = 0;
			this.props.participation.Repartitions.forEach( (repartition, index) => {
				cumulPercent += (repartition.Repartition_ValueRaw * 100) / this.props.participation.Participation_Gain;
				formList.push({	value: 'user_'+index, 
								label: repartition.User_Name, 
								gain: repartition.Repartition_ValueRaw, 
								percent: cumulPercent, 
								gains_cumules: repartition.User_GainYearTotal, 
								isDisabled: true
				});
			});
		}

		this.state = {
			initialValues: {},
			validationSchema: {},
			formList : formList,
			colors: ['#86469E', '#CA93DE', '#035797', '#79BDF2', '#1EA5A5', '#66CEDA', '#7AAE5E', '#ACDE93'],
			montant_total : this.props.participation.Participation_Gain
		};
	}

	componentDidMount() {
		document.body.classList.add('pg_challenge-detail')
		window.scrollTo(0,0)
	}
	componentWillUnmount() {
		document.body.classList.remove('pg_challenge-detail')
	}

	render() {
		return (
			<div className="obj_repartition-gains">
				<div className="ctn_form">
					<Formik
						initialValues={this.state.initialValues}
						validationSchema={Yup.object().shape(this.state.validationSchema)}
						onSubmit={(values, actions) => { }}
					>
						{ ({ handleSubmit, handleBlur, setFieldValue, setFieldTouched, errors, touched, values }) => (
							<>
								<form onSubmit={handleSubmit}>
									<div className="obj_form">
										<h3 className="t_title">Participants et répartition des gains</h3>

										<div className="ctn_graph_gains">
											<div className="obj_circle-progress-gains">
												<AnimatedProgressProvider valueEnd={100}>
													{value => {
														return (
															<CircularProgressbar
																value={value}
																circleRatio={0.5}
																strokeWidth={10}
																styles={{
																	root: {zIndex: 1},
																	path: {stroke: '#000'},
																}}
															/>
														)
													}}
												</AnimatedProgressProvider>
												{this.state.formList.map((userItem, index) => (
													<AnimatedProgressProvider valueEnd={userItem.percent} key={index}>
														{value => {
															return (
																<CircularProgressbar
																	value={value}
																	circleRatio={0.5}
																	strokeWidth={10}
																	styles={{
																		root: {zIndex: this.state.formList.length + 1 - index},
																		path: {stroke: this.state.colors[index % 8]},
																	}}
																/>
															)
														}}
													</AnimatedProgressProvider>
												))}
											</div>
											<div className="ctn_graph_infos_gains">
												<span className="t_total">Montant total : {this.state.montant_total}€</span>
											</div>
										</div>

										<div className="ctn_list_participants_gains">
											{this.state.formList.map((formItem, index) => (
												<div className="item_participants_gains" key={index}>
													<picture className="participant_picture" style={{borderColor: this.state.colors[index % 8]}}>
														<img src={require('../../assets/images/v_avatar-xl.png')} alt=""/>
													</picture>
													<div className="participant_infos">
														<span className="t_name">{formItem.label}</span>
														<span className="t_desc">Gains cumulés : {formItem.gains_cumules}€</span>
													</div>
													<div className="participant_gain">
														<Field
															className=''
															as="input"
															type="text"
															name={formItem.value}
															placeholder={formItem.gain}
															disabled={true}
														/>
													</div>
												</div>
											))}
										</div>
									</div>
								</form>
							</>
						)}
					</Formik>
				</div>
			</div>
		)
	}
}

export default withTranslation()(ObjAsideChallengeVerificationRepartition);