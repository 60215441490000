import React, { Component } from 'react'

import ObjExpertiseBadge from './ObjExpertiseBadge'

class ObjExpertiseBadges extends Component {
	constructor() {
		super()
		this.state = {
		}
	}
	componentDidMount(){
	}

	render() {
		const { categories, badges, userBadges } = this.props

		return (
			<div className="ctn_row">
				{categories && categories.map((category, index) => (

					<div key={index} className="bloc_items_exp">
						<p className="t_desc"><strong>{category.BadgeCategory_Label}</strong></p>

						<div className="lst_items_exp">
							{badges.filter(o => o.BadgeCategory_Id === category.BadgeCategory_Id)
								.map((badge, subindex) => (
								<ObjExpertiseBadge
									key={`Categorie-${index}-${subindex}`}
									badge={badge}
									userBadge={userBadges.find(o => o.UserBadge_Badge_Id === badge.Badge_Id)}
								/>
							))}
						</div>
					</div>
				))}
			</div>
		)
	}
}

export default ObjExpertiseBadges