import { Image , withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import React, { Component } from 'react'

class SectionBadgeElite extends Component {
	render() { 
		const oCurrentItemFields = this.props.sitecoreContext.route?.fields;

		return (
			<div class="obj_badge-elite">
				{oCurrentItemFields && 
				<Image field={oCurrentItemFields["Logo Privilege"]}></Image>}
			</div>
		)
	}
}

export default withSitecoreContext()(SectionBadgeElite);