import React, { Component } from 'react'
import LazyLoad from 'react-lazyload'
import Slider from "react-slick"

import { isMobile } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions"

class ObjNewsCarouselHeroHome extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	render() {
		const {scDatas} = this.props;
		const settings = {
			lazyLoad: true,
			autoplay: false,
			autoplaySpeed: 3500,
			fade: false,
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
		}
		return (
			<div className="obj_news_carousel_hero_home">
				{ scDatas.Banners?.length!==0 && 
					<Slider className="obj_carousel carousel_news_hero" {...settings}>
						{ scDatas.Banners.children.map((BannerItem, index) => (
							<div key={index} className="obj_item fade-in">
								{BannerItem.bannerLink.jss.value.href!="" && 
									<a href={BannerItem.bannerLink.jss.value.href} target="_blank" rel="noopener noreferrer">
										<picture className="ctn_image">
                                            <LazyLoad>
                                                <img src={isMobile() ? BannerItem.bannerImageMobile.jss.value?.src : BannerItem.bannerImage.jss.value?.src} alt="" />
                                            </LazyLoad>
										</picture>
									</a>
								}
								{BannerItem.bannerLink.jss.value.href=="" && 
									<picture className="ctn_image">
										<LazyLoad>
                                            <img src={isMobile() ? BannerItem.bannerImageMobile.jss.value?.src : BannerItem.bannerImage.jss.value?.src} alt="" />
										</LazyLoad>
									</picture>
								}
							</div>
						))}
					</Slider>                
				}
			</div>
		)
	}
}

export default ObjNewsCarouselHeroHome