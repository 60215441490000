import React, { Component } from 'react' 
import { Formik, Field } from 'formik'
import { withTranslation } from 'react-i18next';
import { withSitecoreContext, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import * as Yup from 'yup'
import axios from 'axios'; 
import { ReCaptcha } from 'react-recaptcha-v3'
import config from '../../temp/config';
import queryString from 'query-string';
import { GetLogoList } from '../../utilities/FrontFunctions/AoxFrontFunctions'
import { getGA4WindowDatas, trackGA4PageView, addGA4SessionData } from '../../utilities/FrontFunctions/AoxTrackingFunctions'

class MotDePasse extends Component {
	constructor(props) {
		super(props)
		this.state = {
			serverError: false,
			formSubmitted: false,
			logoList: GetLogoList()
		}
	}

	componentDidMount() { 
		window.scrollTo(0,0)

		//page sans header mais avec tracking => init nécessaire de window.trackDatas et appel de trackGA4PageView
		getGA4WindowDatas(undefined, undefined, undefined, undefined).then((resultDatas) => {
			if(resultDatas){
				resultDatas.pageCategory = "forgot password";
				resultDatas.screen_name = "forgot password";
				window.trackDatas = resultDatas;
				addGA4SessionData(resultDatas);
				if(!this.bPushTrackPage_Done){
					trackGA4PageView(window.trackDatas).then((resultTracked) => { 	
						this.bPushTrackPage_Done = resultTracked;
					});
				}
			}
		});
	}

	render() {

		const oCurrentItemFields = this.props.sitecoreContext.route?.fields;

		return (
			<section className="section_login">
				<div className="str_container">

					<header>
						<h6 className="logo-caf">
							<img src={require("../../assets/images/v_logo-pa.svg")} alt={this.props.t('AltLogo')}/>
						</h6>
						{ oCurrentItemFields && <RichText field={oCurrentItemFields["Page Title"]} tag="h1" /> }
					</header>

					<div className="ctn_login">
						<div className="ctn_col col_100">
							{ oCurrentItemFields && <Text field={oCurrentItemFields["Page SubTitle"]} tag="h2" /> }

							<div className="obj_form">
								<Formik
									initialValues={{
										email: '',
										recaptcha: ''
									}}
									validationSchema={
										Yup.object().shape({
											email: Yup
												.string()
												.email()
												.required(),
											recaptcha: Yup.string().required()
										})
									}
									onSubmit={(values, actions) => {
										// add loader
                    					document.body.classList.add('is-loading')
                    
                    					axios.post(`${config.sitecoreApiHost}/apiaox/Call/RequestNewPassword`, 
											queryString.stringify({ 
												email: values.email,
												recaptcha: values.recaptcha
											}), 
											{
												headers : {
													Accept: 'application/json',
													'Content-Type': 'application/x-www-form-urlencoded',
												}
										  }).then((result) => {
												this.setState({
													formSubmitted: result.data.IsValid,
													serverError: !result.data.IsValid,
													serverErrorMessage: result.data.Message,
												})
												
												if(this.recaptchaMotDePasse){
													this.recaptchaMotDePasse.execute()
												}

												document.body.classList.remove('is-loading')
										  })
										  .catch((err) => {
											console.log(err)
											this.setState({
												serverError: true,
												serverErrorMessage : this.props.t('DefaultServerError'),
											}) 
											
											if(this.recaptchaMotDePasse){
												this.recaptchaMotDePasse.execute()
											}

											document.body.classList.remove('is-loading')
										  }); 
									}}
								>
									{ ({ handleSubmit, setFieldValue, errors, touched }) => (
										<form onSubmit={handleSubmit}>
											{this.state.formSubmitted ?
												<>
													<p className="t_mandatory mb_30">{this.props.t('mdppage_recevoir')}</p>
													<div className="ctn_cta">
														<a className="obj_cta" href="/">{this.props.t('mdppage_retour')}</a>
													</div>
												</>
												:
												<>
													<p className="t_mandatory mb_30">{this.props.t('mdppage_saisie')}</p>
													<div className="ctn_input mb_30">
														<Field className={errors.email && touched.email && 'error'} as="input" type="text" name="email" placeholder={this.props.t('Connexion_emailPlaceholder')} />
													</div>
													<p className="t_mandatory mb_30">{this.props.t('Connexion_mandatoryText')}</p>
													<ReCaptcha
														ref={ref => this.recaptchaMotDePasse = ref}
														sitekey={this.props.t('recaptcha_sitekey')}
														action='password'
														verifyCallback={(recaptchaToken) => { setFieldValue("recaptcha", recaptchaToken) }}
													/>
													{( (errors.email && touched.email) || (errors.recaptcha) ) && <p className="t_error mb_20 fade-in">{this.props.t('Connexion_errorMessage')}{console.log(errors)} </p>}
													{this.state.serverError && <p className="t_error mb_30 fade-in">{this.state.serverErrorMessage}</p>}
													<div className="ctn_cta mb_20">
														<button className="obj_cta" type="submit">{this.props.t('Connexion_submitText')}</button>
													</div>
													<p className="ctn_cta">
														<a className="obj_cta-txt" href="/">{this.props.t('mdppage_Annuler')}</a>
													</p>
												</>
											}
										</form>
									)}
								</Formik>
							</div>
						</div>
					</div>

					<ul className="ctn_logos">
						{this.state.logoList.map((logoItem, index) => (
							<li key={index} className={"var_" + logoItem.id}>
								<img src={logoItem.image} alt={logoItem.label}/>
							</li>	
						))}
					</ul>

				</div>
			</section>
		)
		
	}
}
 
export default withTranslation()(withSitecoreContext()(MotDePasse));