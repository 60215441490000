import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'; 

import ModalProduitConcernes from '../Modal/ModalProduitConcernes'

import IconSVG from "../../utilities/FrontFunctions/IconSVG"
import { urlToHost } from "../../utilities/FrontFunctions/AoxFrontFunctions"

class ObjConditionChallenge extends Component {
	constructor(props) {
		super(props)
		this.state = {
		}
	}

	render() {
		const {participation, isManager, showProduct} = this.props;
		return (
			<div className="obj_condition_challenge">
				<div className="obj_condition_challenge-item">
					<div className="col-item">
						<div className="obj_condition_challenge-item-label">
							<span>Nombre d'unités<br/>minimum</span>
						</div>
						<div className="obj_condition_challenge-item-price">
							{participation.Challenge.Challenge_NbUnity ? <>{participation.Challenge.Challenge_NbUnity}</> : <>-</>}
						</div>
					</div>
					<div className="col-item">
						<div className="obj_condition_challenge-item-label">
							<span>% d'évolution<br/>minimum</span>
						</div>
						<div className="obj_condition_challenge-item-price">
							{participation.Challenge.Challenge_EvolutionPercent ? <>{participation.Challenge.Challenge_EvolutionPercent}<sup>%</sup></> : <>-</>}
							{participation.Challenge.Challenge_SalePointObjectiveNumber ? <><em>({participation.Challenge.Challenge_SalePointObjectiveNumber})</em></> : <></>}
						</div>
					</div>
                    {isManager &&
                        <div className="col-item">
                            <div className="obj_condition_challenge-item-label">
                                <span>Gains<br/>maximum</span>
                            </div>
                            <div className="obj_condition_challenge-item-price">
                                {participation.Challenge.Challenge_MaxGain ? <>{participation.Challenge.Challenge_MaxGain}<sup>€</sup></> : <>-</>}
                            </div>
                        </div>
					}
				</div>
				{(isManager && participation.Challenge.Challenge_EnableVariableMaxGain) &&
				<div className="obj_condition_challenge-item var_condition">
					<div className="obj_condition_challenge-item-label">
						<span>Gains maximum en cas d'évolution non atteinte</span>
					</div>
					<div className="obj_condition_challenge-item-price">{participation.Challenge.Challenge_MaxMinGain}<sup>€</sup></div>
				</div>
				}

				{participation.Challenge.Conditions?.map((condition, index) => (
					<div className="obj_condition_challenge-item" key={index} >
						<div className="obj_condition_challenge-item-label">
							<span>{condition.ChallengeCondition_Name} {condition.ChallengeCondition_EnableVariableGain && "( condition variable )"}</span>
							{showProduct && condition.Produits?.length > 0 && 
								<>
									<button 
										className="obj_btn-popin-detail" 
										type="button" 
										onClick={() => this["ModalProduitCondition"+condition.ChallengeCondition_Id].openModal()}>
										<span>Voir les produits concernés</span>
										<IconSVG icon="arrowDown" />
									</button>
									<a className="obj_btn-popin-detail" 
										href={`${urlToHost('')}/apiaox/Challenge/GetExportProduitsConcernes?id=${condition.ChallengeCondition_Id}`}
									>
										<span>Exporter la liste des produits</span>
										<IconSVG icon="arrowDown" />
									</a>

									<ModalProduitConcernes produits={condition.Produits} onRef={ref => (this["ModalProduitCondition"+condition.ChallengeCondition_Id] = ref)}/>
								</>
							}
						</div>
						<div className="obj_condition_challenge-item-price">
							{condition.ChallengeCondition_EnableVariableGain && <>{condition.ChallengeCondition_VariableGainMinPerUnity}<sup>€</sup> / </>}
							{condition.ChallengeCondition_MaxGainPerUnity}<sup>€</sup>
						</div>
					</div>
				))}
			</div>
		)
	}
}

export default withTranslation()(ObjConditionChallenge)