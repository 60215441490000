import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

import { getApiDatas, postApiDatas} from "../../utilities/FrontFunctions/AoxFrontFunctions"
import ObjSectionFormation from "../ObjExpertise/ObjSectionFormation"
import ModalMAJFormationsFav from '../Modal/ModalMAJFormationsFav'

class PageFormationFavorites extends Component {
  	constructor(props) {
		super(props)
		this.state = {
			apiDatas:[]
		}

		this.handleButtonFav = this.handleButtonFav.bind(this)
		this.handleDeleteFavori = this.handleDeleteFavori.bind(this)
	}
	componentDidMount() {
        getApiDatas(`/apiaox/Call/PageFavorites`)
        .then((apiDatas) => { this.setState({ apiDatas:apiDatas }) })

		document.body.classList.add('pg_favoris')
		window.scrollTo(0,0)
	}
	componentWillUnmount() {
		document.body.classList.remove('pg_favoris')
	}
	handleButtonFav(id) {
        this.setState({ selectedFormationId:id });
        this.ModalMAJFormationsFav.openModal();
    }
	handleDeleteFavori() {
        let id = this.state.selectedFormationId;
        
        //appel Api RemoveTrainingFavorite puis MAJ this.state.apiDatas
        document.body.classList.add('is-loading')
		postApiDatas(`/apiaox/Call/RemoveTrainingFavorite?sIdTraining=${id}`, {})
        .then((res) => {
			document.body.classList.remove('is-loading')
			if (!res.IsValid) { alert(res.Message) }
            else {
                let newApiDatas = this.state.apiDatas;
                const indexInTrainingFavorites = newApiDatas.TrainingsFavorites.findIndex(o => o.Training_ItemTraining_Id === id); // Copiez l'élément que vous souhaitez modifier
                newApiDatas.TrainingsFavorites.splice(indexInTrainingFavorites, 1);
                this.setState({ apiDatas: newApiDatas })
            }
		})
	}
    //Affichage d'une liste d'item à partir d'une liste de SitecoreData de training
	getSectionFormation(scDatasBrandTrainings){
		return scDatasBrandTrainings.map((scDataTraining, index) => {
            //regroupement des apiData du training ("Trainings", "UserBrandTrainings", "TrainingsFavorites") en 1 objet
            let apiDataTraining = Object.assign(this.state.apiDatas.Trainings.find(o => o.Training_ItemTraining_Id.replace(/-/gi, "").toUpperCase() === scDataTraining.id), //!! Id sitecore contracté
                                                this.state.apiDatas.UserBrandTrainings.find(o => o.Training_ItemTraining_Id === scDataTraining.Training_ItemTraining_Id), 
                                                {isFavorite:true}
            );
            return <ObjSectionFormation scDataTraining={scDataTraining} apiDataTraining={apiDataTraining} mini={false} handleButtonFav={this.handleButtonFav}/>
		}) 
	}
	render() {
		const scDatasBrands = this.props.fields.data.Accueil.Brands;
		const apiDatas = this.state.apiDatas;

		return (<>
            <section className="obj_section section_favoris section_page-liste">
                <div className="ctn_section">
                    <div className="ctn_title">
                        <h1 className="t_title">Mes favoris</h1>
                    </div>

                    <div className="section_list_formation">
                        {apiDatas.TrainingsFavorites && 
                        scDatasBrands.filter(brand => { return brand.Formations.length > 0 }).map((brand, brandIndex) => {
                            let scDatasBrandTrainings = brand.Formations[0].Trainings; brand.Formations[0].Segments.forEach( segment => { scDatasBrandTrainings = scDatasBrandTrainings.concat(segment.Trainings); })
                            let scDatasBrandTrainingsFavorite = scDatasBrandTrainings.filter((scDataTraining) => { return this.state.apiDatas.TrainingsFavorites.some(o => o.Training_ItemTraining_Id.replace(/-/gi, "").toUpperCase() === scDataTraining.id); })
                            return scDatasBrandTrainingsFavorite.length > 0 && 
                            <div key={brandIndex} className={`ctn_brand fade-in ${brand.brandCssClass.value}`}>
                                <div className="ctn_title">
                                    <h2 className="t_title">{brand.pageTitle.jss.value}</h2>
                                </div>
                                <div className="ctn_sub-row">
                                    { this.getSectionFormation(scDatasBrandTrainingsFavorite) }
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </section>

			<ModalMAJFormationsFav
				onRef={ref => (this.ModalMAJFormationsFav = ref)} 
				modalTitle="Supprimer le favori ?"
				modalCtaText="Voir la page des formations favorites"
				handleDeleteFavori={this.handleDeleteFavori}
			/>
		</>)
	}
}

export default withTranslation()(withSitecoreContext()(PageFormationFavorites));