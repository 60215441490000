import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Image, Link } from "@sitecore-jss/sitecore-jss-react";

class SectionPushBrand extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		const { scFields } = this.props;
        
		return (
			<>
				{scFields && scFields["Brands Image"] && scFields["Brands Image"].value && scFields["Brands Link"] && scFields["Brands Link"].value && (
					<section className="obj_section section_push_brand_home">
						<div className="ctn_section">
							<h4 className="t_title">{this.props.t("Home_PushBrandsTitle")}</h4>
							<Link field={scFields["Brands Link"].value} target="_blank" rel="noopener noreferrer">
								<picture className="ctn_background fade-in">
									<source srcSet={scFields["Brands Image"].value.src} media="(max-width:767px)" />
									<Image field={scFields["Brands Image"].value} />
								</picture>
							</Link>
						</div>
					</section>
				)}
			</>
		);
	}
}

export default withTranslation()(SectionPushBrand);
