import React, { Component } from 'react'
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'

import LazyLoad from 'react-lazyload'
import { withTranslation } from 'react-i18next';

import IconSVG from "../../utilities/FrontFunctions/IconSVG"
import FacebookPostModal from '../Modal/FacebookPostModal'

class PageListeFacebookPOST extends Component{

	render() {
		const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
		const { CurrentPageList } = this.props.fields.data;  

		return (
			<>
				{ oCurrentItemFields && <Text className="obj_title" tag="h1" field={oCurrentItemFields["Page Title"]}></Text>}
				<section className="section_page-liste"> 
					<div className="str_container">
						
						<section className="ctn_row">

							{ oCurrentItemFields && <Text className="obj_subtitle" tag="P" field={oCurrentItemFields["Page SubTitle"]}></Text>}

							<div className="ctn_sub-row">

								{ CurrentPageList.ListFacebookPOST && CurrentPageList.ListFacebookPOST.map((itemPOST, index) => (
									
									<article key={index} className="ctn_page-liste var-fb">

										<button
											type="button"
											onClick={() => this[`FacebookPostModal${index}`].openModal()}
										>

											<LazyLoad>
												<figure className="fade-in">
													<img src={itemPOST.pOSTImagePush.src} alt={itemPOST.pOSTImagePush.alt}/>
												</figure>
											</LazyLoad>

											<Text tag="h3" field={itemPOST.pOSTTitle.jss}></Text>

											<Text className="t_desc" tag="p" field={itemPOST.pOSTTextShort.jss}></Text>

											<ul className="obj_likes-fb">
												<li>
													<IconSVG icon="eye" width="20" height="13" />
													{(itemPOST.numberOfViews.value) ? itemPOST.numberOfViews.value : 0} vue(s)
												</li>
												<li>
													<IconSVG icon="share" width="20" height="17" />
													{(itemPOST.numberOfShares.value) ? itemPOST.numberOfShares.value : 0} partage(s)
												</li>
											</ul>

										</button>

										<FacebookPostModal
											onRef={ref => (this[`FacebookPostModal${index}`] = ref)}
											articleItem={itemPOST}
										/>	

									</article>

								))}

							</div>

						</section>

					</div>
				</section>
			</>
		)
	}
}

export default withTranslation()(withSitecoreContext()(PageListeFacebookPOST));