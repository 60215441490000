import React, { Component } from 'react'
import { Field } from 'formik'
import { throttle, isMobile } from '../../../utilities/FrontFunctions/AoxGlobalFrontFunctions'
import Select from 'react-select'

class CustomSelect extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isMobile: isMobile(),
			isLoading: true,
			options: this.props.options,
			placeholder: this.props.placeholder ? this.props.placeholder : '...',
			disabled: this.props.disabled ? this.props.disabled : false
		}
		this.handleWindowResizeCustomSelect = this.handleWindowResizeCustomSelect.bind(this)
		this.throttleWindowResizeCustomSelect = throttle(this.handleWindowResizeCustomSelect, 200)
	}
	componentDidMount() {
		if (typeof this.state.options !== 'object'){
			switch(this.state.options) {
				case 'AgeConfig': {
					this.setState({
						options: [...Array(82).keys()].map(i => ( {value: i + 18, label: i + 18} ))
					})
					break
				}
				case 'DayConfig': {
					this.setState({
						options: [...Array(31).keys()].map(i => ( {value: (i < 9 ? '0' : '') + (i + 1), label: (i < 9 ? '0' : '') + (i + 1)} ))
					})
					break
				}
				case 'MonthConfig': {
					this.setState({
						options: [...Array(12).keys()].map(i => ( {value: (i < 9 ? '0' : '') + (i + 1), label: (i < 9 ? '0' : '') + (i + 1)} ))
					})
					break
				}
				case 'YearConfig': {
					this.setState({
						options: [...Array(83).keys()].map(i => ( {value: (new Date().getFullYear() - 18) - i, label: (new Date().getFullYear() - 18) - i} ))
					})
					break
				}
				default: {
					this.setState({
						options: [{value: 0, label: 0}]
					})
					break
				}
			}
		}
		this.setState({
			isLoading: false
		})
		window.addEventListener('resize', this.throttleWindowResizeCustomSelect)

		// defaultValue => setFieldValue
		if( this.props.defaultValue !== undefined ){
			var defaultValueIndex = this.props.options.findIndex( value => value.value === this.props.defaultValue)
			this.props.setFieldValue(this.props.fieldName, defaultValueIndex >=0 ? this.state.options[defaultValueIndex].value : '')
		}
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.throttleWindowResizeCustomSelect)
	}
	handleWindowResizeCustomSelect() {
		this.setState({
			isMobile: isMobile()
		})
	}
	render() {
		const { fieldName, setFieldValue, setFieldTouched, errors, touched, values, defaultValue, onChange } = this.props
		const { isLoading, options, placeholder, disabled } = this.state
		return(
			<>
			{ !isLoading && isMobile() ?
				<div className={errors[fieldName] && touched[fieldName] && 'error'}>
					<div className={`ctn_native-select${disabled ? ' is-disabled' : ''}`}>
						<Field className="native-select" as="select" name={fieldName} disabled={disabled}>
							<option value="">{disabled ? values[fieldName] : placeholder}</option>
							{options.map( i => (
								<option key={i.value} value={i.value}>{i.label}</option>
							))}
						</Field>
					</div>
				</div>
				:
				<div className="ctn_select">
					<div className={errors[fieldName] && touched[fieldName] && 'error'}>
						<Select
							defaultValue={defaultValue && options[options.findIndex( value => value.value === defaultValue)]}
							name={fieldName}
							placeholder={disabled ? values[fieldName] : placeholder}
							className="react-select-container"
							classNamePrefix="react-select"
							options={options}
							onChange={(value) => {setFieldValue(fieldName, value.value.toString()); if(onChange!=undefined){onChange(value)};}}
							onBlur={() => {setFieldTouched(fieldName, true)}}
							error={errors[fieldName]}
							touched={touched[fieldName]}
							isDisabled={disabled}
						/>
					</div>
				</div>
			}
			</>
		)
	}	
}

export default CustomSelect