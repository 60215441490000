import React, { Component } from 'react' 
import { Formik, Field } from 'formik'
import { withTranslation } from 'react-i18next';
import { withSitecoreContext, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import * as Yup from 'yup'
import axios from 'axios'; 
import { ReCaptcha } from 'react-recaptcha-v3'
import config from '../../temp/config';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom'
import PasswordToggle from '../../utilities/FrontFunctions/FormElements/PasswordToggle'
import { GetLogoList } from '../../utilities/FrontFunctions/AoxFrontFunctions'
class PageNouveauCompte extends Component {
	constructor(props) {
		super(props)
		this.state = {
      redirect: false,
			serverError: false,
      formSubmitted: false,
      serverErrorMessage:"",
			logoList: GetLogoList()
		}
	}
 
  componentDidMount() { 
    window.scrollTo(0,0)
    var oParam = queryString.parse(window.location.search);
    
    if(oParam.length === 0){
      this.setState({
        redirect: true
      }); 
    }else{
      if(oParam.prt  === undefined){
        this.setState({
          redirect: true
        }); 
      }else{
        if(oParam.prt === null){
          this.setState({
            redirect: true
          }); 
        }
      }
    }     
	} 
	render() {
    const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
     
    if (this.state.redirect){
      return <Redirect to="/" />;
    }

    return (
     
      <section className="section_login">
				<div className="str_container">

        <header>
						<h6 className="logo-caf">
							<img src={require("../../assets/images/v_logo-caf_2022.svg")} alt={this.props.t('AltLogo')}/>
						</h6>
            { oCurrentItemFields && <RichText field={oCurrentItemFields["Page Title"]} tag="h1" /> }
					</header>

					<div className="ctn_login">
						<div className="ctn_col col_100">
                { oCurrentItemFields && <Text field={oCurrentItemFields["Page SubTitle"]} tag="h2" /> }

							<div className="obj_form">

              {this.state.formSubmitted ?
									<>
										<p className="t_mention mb_30">{this.props.t('nouveaumdp_PriseEnCompte')}</p>
										<div className="ctn_cta">
											<a className="obj_cta" href="/">{this.props.t('mdppage_retour')}</a>
										</div>
									</>
									:
									<>
								    <Formik
                      initialValues={{
                        password: '',
                        passwordconfirm: '', 
                        promo_checkbox: false,
                        recaptcha: ''
                      }}
                      validationSchema={
                        Yup.object().shape({
                          password: Yup
                            .string()
                            //.matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*)(+=._-])[0-9a-zA-Z!@#$%^&*)(+=._-]{8,30}$/, this.props.t('Register_passwordDetails'))
                            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*)(+=._-])[A-Za-z\d!@#$%^&*)(+=._-]{8,30}$/, this.props.t('Register_passwordDetails'))
                            .required(),
                          passwordconfirm: Yup
                            .string()
                            .test('passwords-match', this.props.t('Register_PasswordsMustMatch'), function(value){ return this.parent.password === value })
                            .required(),
                          recaptcha: Yup.string().required()
                        })
                      }
                       onSubmit={(values, actions) => {
                        // add loader
                        document.body.classList.add('is-loading')

                        //Paramètre
                        var oParam = queryString.parse(window.location.search);
                        axios.post(`${config.sitecoreApiHost}/apiaox/Call/RequestWelcomeNewAccount`, 
                          queryString.stringify({ 
                            PasswordResetToken : oParam.prt,
                            password: values.password,
                            passwordconfirm: values.passwordconfirm,
                            promo_checkbox: values.promo_checkbox,
                            recaptcha: values.recaptcha
                          }), 
                          {
                            headers : {
                              Accept: 'application/json',
                              'Content-Type': 'application/x-www-form-urlencoded',
                            }
                          }).then((result) => {
                            this.setState({
                              formSubmitted: result.data.IsValid,
                              serverError: !result.data.IsValid,
                              serverErrorMessage: result.data.Message,
                            })

                            if(this.recaptchaNouveauMotDePasse){
                              this.recaptchaNouveauMotDePasse.execute()
                            }
                            
                            document.body.classList.remove('is-loading')
                          })
                          .catch((err) => {
                            console.log(err)
                            this.setState({
                                serverError: true,
                                serverErrorMessage : this.props.t('DefaultServerError'),
                            }) 
                            
                            if(this.recaptchaNouveauMotDePasse){
                              this.recaptchaNouveauMotDePasse.execute()
                            }
                            
                            document.body.classList.remove('is-loading')
                          });  
                      }} 
                    >
                      { ({ handleSubmit, handleBlur, setFieldValue, errors, touched }) => (
                        <form onSubmit={handleSubmit}>
                          {this.state.formSubmitted ?
                            <>
                              <p className="t_mandatory mb_30">{this.props.t('nouveaumdp_PriseEnCompte')}</p>
                              <div className="ctn_cta">
                                <a className="obj_cta" href="/">{this.props.t('nouveaumdp_MeConnecter')}</a>
                              </div>
                            </>
                            :
                            <>
                              <p className="t_mandatory mb_30">{this.props.t('nouveaumdp_saisie')}</p>
                              <div className="ctn_input mb_30 var_password">
                                <PasswordToggle
                                  setFieldValue={setFieldValue}
                                  handleBlur={handleBlur}
                                  errors={errors}
                                  touched={touched}
                                  eyeMode={true} 
                                  fieldName="password"
                                  placeholder={this.props.t('Register_passwordPlaceholder')}
                                  strengthLevel={true}
                                />
                                <div className="obj_infobulle">
                                  <div className="info_picto">?</div>
                                  <div className="info_text"><span>{this.props.t('Register_passwordDetails')}</span></div>
                                </div>
                              </div>
                              <div className="ctn_input mb_30">
                                <PasswordToggle
                                  setFieldValue={setFieldValue}
                                  handleBlur={handleBlur}
                                  errors={errors}
                                  eyeMode={true} 
                                  touched={touched}
                                  fieldName="passwordconfirm"
                                  placeholder={this.props.t('Register_passwordConfirm')}
                                />
                              </div>
                              {errors.passwordconfirm && touched.passwordconfirm && <p className="t_error mb_20 fade-in">{this.props.t('Register_passwordError')}</p>}
                              
    
                              <div className="ctn_checkbox mb_30">
                                <Field type="checkbox" id="promo_checkbox" name="promo_checkbox" />
                                <label htmlFor="promo_checkbox">{this.props.t('Register_AcceptPromo')}</label>
                              </div>

													    <p className="t_mention mb_30" dangerouslySetInnerHTML={{__html: this.props.t('Register_CGU_mandatory')}}></p>													
                              
                              <p className="t_mandatory mb_30">{this.props.t('Connexion_mandatoryText')}</p>
                              
                              <ReCaptcha
                                ref={ref => this.recaptchaNouveauMotDePasse = ref}
                                sitekey={this.props.t('recaptcha_sitekey')}
                                action='NouveauCompte'
                                verifyCallback={(recaptchaToken) => { setFieldValue("recaptcha", recaptchaToken) }}
                              />

                              {( (errors.password && touched.password) || (errors.passwordconfirm && touched.passwordconfirm) || (errors.recaptcha) ) && <p className="t_error mb_20 fade-in">{this.props.t('Connexion_errorMessage')}{console.log(errors)} </p>}
                              {this.state.serverErrorMessage && <p className="t_error mb_30 fade-in">{this.state.serverErrorMessage}</p>}
                              <div className="ctn_cta mb_20">
                                <button className="obj_cta" type="submit">{this.props.t('Connexion_submitText')}</button>
                              </div>
                              <p className="ctn_cta">
                                <a className="obj_cta-txt" href="/">{this.props.t('mdppage_Annuler')}</a>
                              </p>
                            </>
                          }
                        </form>
                      )}
                    </Formik>
                  </>
                }
              </div>
						</div>
					</div>

					<ul className="ctn_logos">
						{this.state.logoList.map((logoItem, index) => (
							<li key={index} className={"var_" + logoItem.id}>
								<img src={logoItem.image} alt={logoItem.label}/>
							</li>	
						))}
					</ul>

				</div>
			</section>
		)
	}
}

export default withTranslation()(withSitecoreContext()(PageNouveauCompte));
