import { withSitecoreContext, Text } from "@sitecore-jss/sitecore-jss-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { ReCaptcha } from "react-recaptcha-v3";
import { Redirect } from "react-router-dom";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import CustomSelect from "../../utilities/FrontFunctions/FormElements/CustomSelect";
import { getApiDatas, postApiDatas } from "../../utilities/FrontFunctions/AoxFrontFunctions";
import IconSVG from "../../utilities/FrontFunctions/IconSVG";

class PageActivationCompte extends Component {
	constructor(props) {
		super(props);
		this.state = {
			serverError: false,
			serverErrorAccountActivate: false,
			serverErrorAccountActivateLabel: "",
			accountActivated: false,
			apiDatas: [],
			initDataOK: false,
		};
	}

	componentDidMount() {
		getApiDatas(`/apiaox/Call/GetDataPageRegister`).then((apiDatas) => {
			this.setState({ apiDatas: apiDatas });
			if (apiDatas?.Postes && apiDatas?.User) {
				this.setState({ initDataOK: true });
			}
		});
        /*
        var oCookiePdvBase64 = Cookies.get("aoxRegisterPdv");
		if (typeof oCookiePdvBase64 !== "undefined") {
			var sInfoPdv = atob(decodeURIComponent(oCookiePdvBase64));
			var oInfoPdv = JSON.parse(sInfoPdv);          

            getApiDatas(`/apiaox/Call/CheckSalePointFromCode?salepointCode=`+ oInfoPdv?.Code).then((resCheckSalePointFromCode)=>{
                this.setState({ salePoint: resCheckSalePointFromCode });
            })
        }
        */
	}

	render() {
		const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
		const { PageCAF, PageContact, CurrentUser } = this.props.fields.data;
		const apiDatas = this.state.apiDatas;

		if (this.state.accountActivated) {
			return <Redirect to={PageCAF != null && PageCAF.parameterValue.value} />;
		}

		return (
			<section className="section_form">
				<div className="str_container">
					<div className="ctn_top">
						{oCurrentItemFields && <Text field={oCurrentItemFields["Page Title"]} tag="h1" />}
						<br />
						{oCurrentItemFields && <Text field={oCurrentItemFields["Page Text"]} tag="p" />}
					</div>

					<div className="ctn_form">
						<div className="grid_container">
							<div className="grid_item grid_mdp">
								<div className="ctn_contact">
									<h3>{this.props.t("Register_contactQuestion")}</h3>
									<h4>{this.props.t("Register_contactByMail")}</h4>
									<p className="ctn_cta">
										<a className="obj_cta var_picto var_email" href={PageContact && PageContact.url}>
											<IconSVG icon="email" width="21" height="17" />
											{this.props.t("Register_contactSubmitText")}
										</a>
									</p>
									<h4>{this.props.t("Register_contactByPhone")}</h4>
									<p className="t_tel" dangerouslySetInnerHTML={{ __html: this.props.t("Register_contactPhone") }}></p>
									<p className="t_mention">{this.props.t("Register_contactByPhonePrice")}</p>
								</div>
							</div>

							<div className="grid_item grid_form-perso">
								{!this.state.initDataOK ? (
									<div className="obj_form">
										{/* TODO SGU Msg */}
										<p className="t_error mb_30 fade-in">Error init Form </p>
									</div>
								) : (
                                    <>
                                        <Formik
                                            initialValues={{
                                                civilite: CurrentUser.gender,
                                                lastname: CurrentUser.lastname,
                                                firstname: CurrentUser.firstname,
                                                birthday: CurrentUser.birthday,
                                                birthmonth: CurrentUser.birthmonth,
                                                birthyear: CurrentUser.birthyear,
                                                email: CurrentUser.email,
                                                phone: CurrentUser.phone,
                                                address: CurrentUser.adress,
                                                address_bis: CurrentUser.adress2,
                                                postal_code: CurrentUser.postalcode,
                                                city: CurrentUser.city,

                                                poste: "",
                                                acceptNwl: false,
                                                acceptNotifications: false,

                                                securiteSociale: "",
                                                noSS: false,

                                                recaptcha: "",
                                            }}
                                            validationSchema={Yup.object().shape({
                                                civilite: Yup.string().required(),
                                                lastname: Yup.string().required(),
                                                firstname: Yup.string().required(),
                                                email: Yup.string().email().required(),
                                                phone: Yup.string().required(),
                                                poste: Yup.string().required(),
                                                securiteSociale: Yup.string().matches(
                                                    /^([1-37-8])([0-9]{2})(0[0-9]|[2-35-9][0-9]|[14][0-2])((0[1-9]|[1-8][0-9]|9[0-69]|2[abAB])(00[1-9]|0[1-9][0-9]|[1-8][0-9]{2}|9[0-8][0-9]|990)|(9[78][0-9])(0[1-9]|[1-8][0-9]|90))(00[1-9]|0[1-9][0-9]|[1-9][0-9]{2})(0[1-9]|[1-8][0-9]|9[0-7])$/,
                                                    ""
                                                ),

                                                recaptcha: Yup.string().required(),
                                            })}
                                            onSubmit={(values) => {
                                                document.body.classList.add("is-loading");
                                                postApiDatas(`/apiaox/Call/InfosPerso_AccountActivate`, values).then((res) => {
                                                    this.setState({
                                                        accountActivated: res.IsValid,
                                                        serverErrorAccountActivate: !res.IsValid,
                                                        serverErrorAccountActivateLabel: res.Message,
                                                    });

                                                    if (this.recaptchaInfoPerso) {
                                                        this.recaptchaInfoPerso.execute();
                                                    }

                                                    document.body.classList.remove("is-loading");
                                                });
                                            }}
                                        >
                                            {({ handleSubmit, handleBlur, setFieldValue, setFieldTouched, errors, touched, values }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <h2>{this.props.t("Register_InformationsPersonnelles")}</h2>
                                                    <div className="obj_form">
                                                        <div className="mb_30">
                                                            <CustomSelect
                                                                fieldName={"civilite"}
                                                                placeholder={this.props.t("Register_genderPlaceholder")}
                                                                options={[
                                                                    { value: "0", label: this.props.t("Register_genderFemale"), },
                                                                    { value: "1", label: this.props.t("Register_genderMale"), },
                                                                    { value: "2", label: this.props.t("Register_genderNotDefined") },
                                                                ]}
                                                                setFieldValue={setFieldValue}
                                                                setFieldTouched={setFieldTouched}
                                                                errors={errors}
                                                                touched={touched}
                                                                values={values}
                                                                defaultValue={CurrentUser.gender.toString()}
                                                            />
                                                        </div>
                                                        <div className="ctn_input mb_30">
                                                            <Field
                                                                autoComplete="off"
                                                                className={errors.lastname && touched.lastname && "error"}
                                                                as="input"
                                                                type="text"
                                                                name="lastname"
                                                                placeholder={this.props.t("Register_lastnamePlaceholder")}
                                                            />
                                                        </div>
                                                        <div className="ctn_input mb_30">
                                                            <Field
                                                                autoComplete="off"
                                                                className={errors.firstname && touched.firstname && "error"}
                                                                as="input"
                                                                type="text"
                                                                name="firstname"
                                                                placeholder={this.props.t("Register_firstnamePlaceholder")}
                                                            />
                                                        </div>
                                                        <div className="ctn_select-birthdate mb_30">
                                                            <label className="t_label">{this.props.t("Register_Birthdate")}</label>
                                                            <div className="ctn_select-wrapper">
                                                                <CustomSelect
                                                                    fieldName={"birthday"}
                                                                    placeholder={CurrentUser.birthday !== "-1" ? CurrentUser.birthday : this.props.t("Register_DateDeNaissanceJour")}
                                                                    options={"DayConfig"}
                                                                    setFieldValue={setFieldValue}
                                                                    setFieldTouched={setFieldTouched}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    values={values}
                                                                />
                                                                <CustomSelect
                                                                    fieldName={"birthmonth"}
                                                                    placeholder={CurrentUser.birthmonth !== "-1" ? CurrentUser.birthmonth : this.props.t("Register_DateDeNaissanceMois")}
                                                                    options={"MonthConfig"}
                                                                    setFieldValue={setFieldValue}
                                                                    setFieldTouched={setFieldTouched}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    values={values}
                                                                />
                                                                <CustomSelect
                                                                    fieldName={"birthyear"}
                                                                    placeholder={CurrentUser.birthyear !== "-1" ? CurrentUser.birthyear : this.props.t("Register_DateDeNaissanceAnnee")}
                                                                    options={"YearConfig"}
                                                                    setFieldValue={setFieldValue}
                                                                    setFieldTouched={setFieldTouched}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    values={values}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="ctn_input mb_30 var_info" id="GoToAddress">
                                                            <Field
                                                                autoComplete="off"
                                                                className={errors.email && touched.email && "error"}
                                                                as="input"
                                                                type="email"
                                                                name="email"
                                                                placeholder={this.props.t("Register_emailPlaceholder")}
                                                                disabled={true}
                                                            />
                                                            <div className="obj_infobulle">
                                                                <div className="info_picto">?</div>
                                                                <div className="info_text">
                                                                    <span>{this.props.t("Register_emailDetails")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ctn_input mb_30 var_info">
                                                            <Field autoComplete="off" as="input" type="text" name="phone" placeholder={this.props.t("Register_PhonePlaceholder")} />
                                                            <div className="obj_infobulle">
                                                                <div className="info_picto">?</div>
                                                                <div className="info_text">
                                                                    <span>{this.props.t("Register_PhoneDetail")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ctn_input mb_30 var_info">
                                                            <Field autoComplete="off" as="input" type="text" name="address" placeholder={this.props.t("Register_AdressPlaceholder")} />
                                                            <div className="obj_infobulle">
                                                                <div className="info_picto">?</div>
                                                                <div className="info_text">
                                                                    <span>{this.props.t("Register_AdressDetail")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ctn_input mb_30 var_info">
                                                            <Field autoComplete="off" as="input" type="text" name="address_bis" placeholder={this.props.t("Register_Adress2Placeholder")} />
                                                            <div className="obj_infobulle">
                                                                <div className="info_picto">?</div>
                                                                <div className="info_text">
                                                                    <span>{this.props.t("Register_Adress2Detail")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ctn_input mb_30 var_info">
                                                            <Field autoComplete="off" as="input" type="text" name="postal_code" placeholder={this.props.t("Register_CodePostalPlaceholder")} />
                                                            <div className="obj_infobulle">
                                                                <div className="info_picto">?</div>
                                                                <div className="info_text">
                                                                    <span>{this.props.t("Register_CodePostalDetail")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ctn_input mb_30 var_info">
                                                            <Field autoComplete="off" as="input" type="text" name="city" placeholder={this.props.t("Register_CityPlaceholder")} />
                                                            <div className="obj_infobulle">
                                                                <div className="info_picto">?</div>
                                                                <div className="info_text">
                                                                    <span>{this.props.t("Register_CityDetail")}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <br />

                                                    <h2>{this.props.t("Register_InformationsProfessionnelles")}</h2>
                                                    <div className="obj_form">
                                                        <div className="ctn_select-birthdate mb_30">
                                                            <label className="t_label">{this.props.t("Register_VotrePDV")}</label>
                                                            <p>
                                                                {apiDatas.User.Salepoint.Code} - {apiDatas.User.Salepoint.Name}
                                                            </p>
                                                        </div>

                                                        <div className="mb_30">
                                                            <CustomSelect
                                                                fieldName={"poste"}
                                                                placeholder={this.props.t("Register_postePlaceholder")}
                                                                options={apiDatas.Postes.map((poste) => ({
                                                                    value: poste.FrontOfficeUserPoste_Id,
                                                                    label: poste.FrontOfficeUserPoste_Name,
                                                                }))}
                                                                setFieldValue={setFieldValue}
                                                                setFieldTouched={setFieldTouched}
                                                                errors={errors}
                                                                touched={touched}
                                                                values={values}
                                                                defaultValue={CurrentUser.userkind.toUpperCase()}
                                                            />
                                                        </div>

                                                        <div className="ctn_checkbox_background mb_30">
                                                            <p className="t_title">{this.props.t("Register_AcceptNewsletter")}</p>
                                                            <div className="ctn_line">
                                                                <Field type="checkbox" id="acceptNwl" name="acceptNwl" className="switch_checkbox" />
                                                                <label className="switch_checkbox_label" htmlFor="acceptNwl">
                                                                    <span></span> {this.props.t("Register_acceptNwl")}
                                                                </label>
                                                            </div>
                                                            <p className="t_desc">{this.props.t("Register_undoConsent")}</p>
                                                        </div>
                                                    </div>

                                                    <br />

                                                   {this.state.apiDatas?.User?.IsChallenge && <>
                                                     <h2>{this.props.t("Register_Challenges")}</h2>
                                                     <div className="obj_form">
                                                        <p className="t_mention mb_30">{this.props.t("Register_NeedSS")}</p>

                                                        <div className="ctn_input mb_30 var_info">
                                                            <Field
                                                                className={errors.securiteSociale && touched.securiteSociale && "error"}
                                                                as="input"
                                                                type="text"
                                                                name="securiteSociale"
                                                                placeholder={this.props.t("Register_SecuriteSociale")}
                                                            />
                                                        </div>
                                                        {errors.securiteSociale && touched.securiteSociale && <p className="t_error mb_30 fade-in">{this.props.t("Register_SecuriteSocialeInvalide")}</p>}
                                                        <div className="ctn_checkbox mb_30">
                                                            <Field type="checkbox" id="noSS" name="noSS" />
                                                            <label className="" htmlFor="noSS">
                                                                {this.props.t("Register_NoSS")}
                                                            </label>
                                                        </div>
                                                     </div>
                                                    </>}

                                                    <div className="obj_form">
                                                        <p
                                                            className="t_mention mb_30"
                                                            dangerouslySetInnerHTML={{
                                                                __html: this.props.t("Register_CGU"),
                                                            }}
                                                        ></p>

                                                        <p className="t_mandatory mb_30">{this.props.t("Register_mandatory")}</p>

                                                        <ReCaptcha
                                                            ref={(ref) => (this.recaptchaInfoPerso = ref)}
                                                            sitekey={this.props.t("recaptcha_sitekey")}
                                                            action="PersoChangeInfoPerso"
                                                            id="recaptchaInfoPerso"
                                                            verifyCallback={(recaptchaToken) => {
                                                                setFieldValue("recaptcha", recaptchaToken);
                                                            }}
                                                        />

                                                        {this.state.serverErrorAccountActivate && <p className="t_error mb_30 fade-in">{this.state.serverErrorAccountActivateLabel}</p>}

                                                        {this.state.serverErrorAccountActivate && <p className="t_error mb_30 fade-in">{this.state.serverErrorAccountActivateLabel}</p>}

                                                        {   ((errors.civilite && touched.civilite) ||
                                                            (errors.lastname && touched.lastname) ||
                                                            (errors.firstname && touched.firstname) ||
                                                            (errors.email && touched.email) ||
                                                            (errors.poste && touched.poste) ||
                                                            errors.recaptcha) && <p className="t_error mb_30 fade-in">{this.props.t("DefaultErrorMessage")}</p>
                                                        }

                                                        <div className="ctn_cta">
                                                            <button className="obj_cta" type="submit">
                                                                Activer
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            )}
                                        </Formik>
                                        {/*Bug ReCaptcha, il en faut 1 dernier pour que les autres fonctionne !! */}
                                        <ReCaptcha ref={(ref) => (this.recaptchaAutre = ref)} sitekey={this.props.t("recaptcha_sitekey")} elementID="recaptchaAutre" />
                                    </>
								)}
							</div>
						</div>
					</div>
				</div>

			</section>
		);
	}
}

export default withTranslation()(withSitecoreContext()(PageActivationCompte));
