import React, { Component } from 'react' 
import Modal from 'react-modal'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { withTranslation } from 'react-i18next';
import config from '../../temp/config';
import axios from 'axios';
import SingleFileUpload from '../../utilities/FrontFunctions/FormElements/SingleFileUpload'

const customStyles = {
	overlay : {
		zIndex			: '99999',
		backgroundColor	: 'rgba(0,0,0,0.6)'
	},
	content : {
		position	: 'fixed',
		top			: '50%',
		left		: '50%',
		right		: 'auto',
		bottom		: 'auto',
		transform	: 'translate(-50%, -50%)',
		outline		: '0'
	}
}

Modal.setAppElement('#root')

class GalerieUploadModal extends Component {
	constructor() {
		super()
		this.state = {
			UrlSendReview: `${config.sitecoreApiHost}/apiaox/Call/AddPhotoContestReview?sc_apikey=${config.sitecoreApiKey}`, 
			modalIsOpen: false,
			serverError: false,
			formSubmitted: false,
			soloValues: {
				fileupload1: null,
				rgpd_checkbox: false,
			},
			duoValues: {
				fileupload1: null,
				fileupload2: null,
				rgpd_checkbox: false,
			},
			soloSchema: {
				fileupload1: Yup.mixed().required(),
				rgpd_checkbox: Yup.bool().oneOf([true]).required()
			},
			duoSchema: {
				fileupload1: Yup.mixed().required(),
				fileupload2: Yup.mixed().required(),
				rgpd_checkbox: Yup.bool().oneOf([true]).required()
			}
		}
		this.openModal = this.openModal.bind(this)
		this.closeModal = this.closeModal.bind(this)
	}
	componentDidMount() {
		this.props.onRef(this)
	}
	componentWillUnmount() {
		this.props.onRef(null)
	}
	openModal() {
		this.setState({
			modalIsOpen: true,
			formSubmitted: false
		})
	}
	closeModal() {
		this.setState({modalIsOpen: false})
	}
	render() {
		return (
			<div>
				<Modal
					isOpen={this.state.modalIsOpen}
					onRequestClose={this.closeModal}
					style={customStyles}
					className="obj_popin popin_galerie"
					closeTimeoutMS={300}
					contentLabel="Modal"
				>
					<button className="bt_close" onClick={this.closeModal}></button>
					<div className="ctn_popin">
						{this.props.soloMode &&
							<p className="t_title">{this.props.t('ArticleAjouterUnePhoto')}</p>
						}
						{!this.props.soloMode &&
							<p className="t_title">{this.props.t('ArticleAjouterdeuxPhotos')}</p>
						}

						{this.state.formSubmitted ?
							<p className="t_main">{this.props.soloMode ? this.props.t('ArticleVotrePhotoBienEteTelechargee'):this.props.t('ArticleVosPhotosBienEteTelechargees') } <br/>{this.props.t('ArticleNousPublieronsApresVerification')}</p>
							:
							<Formik
								initialValues={
									this.props.soloMode ? this.state.soloValues : this.state.duoValues
								}
								validationSchema={
									Yup.object().shape(this.props.soloMode ? this.state.soloSchema : this.state.duoSchema)
								}
								onSubmit={(values, actions) => {
									document.body.classList.add('is-loading')

                                    var oFormData = new FormData();	
                                    Object.entries(values).forEach(elt => oFormData.append(elt[0], elt[1]));
                                    oFormData.append("idref", document.getElementById("idref").value);
                                    axios({ 
                                        url:this.state.UrlSendReview,
                                        headers:  {
                                            'Accept': 'application/json',
                                            'Content-Type': 'multipart/form-data'
                                        },
                                        method: 'POST',
                                        data : oFormData,
                                        withCredentials: true, 
                                    }) 
                                    .then(res => {		
                                        this.setState({
                                            formSubmitted: true
                                        });
                                        document.body.classList.remove('is-loading')
                                    })
                                    .catch(err => {
                                        this.setState({serverError: true})
                                        document.body.classList.remove('is-loading')
                                    }); 
								}}
							>
								{ ({ handleSubmit, handleBlur, setFieldValue, setFieldTouched, errors, touched, values }) => (
									<form onSubmit={handleSubmit}>
										<div className="obj_form">

											<div className="col_left">
												<div className="ctn_row">
													<div className="ctn_file-upload mb_30 var_galerie">
														<SingleFileUpload
															setFieldValue={setFieldValue}
															setFieldTouched={setFieldTouched}
															errors={errors}
															touched={touched}
															fieldName={'fileupload1'} 
															labelText={this.props.t('AjouterUnePhoto')}
															ctaCancelText={this.props.t('ctaCancelText')}
															formatErrorText={this.props.t('formatErrorText')}
															sizeErrorText={this.props.t('sizeErrorText')}
															loadingErrorText={this.props.t('loadingErrorText')}
															thumbSize={240}
														/>
														{!this.props.soloMode && <p className="t_subtitle">{this.props.t('ArticlePhotoAvant')}</p> }
													</div>
													{!this.props.soloMode &&
														<div className="ctn_file-upload mb_30 var_galerie">
															<SingleFileUpload
																setFieldValue={setFieldValue}
																setFieldTouched={setFieldTouched}
																errors={errors}
																touched={touched}
																fieldName={'fileupload2'} 
																labelText={this.props.t('AjouterUnePhoto')}
																ctaCancelText={this.props.t('ctaCancelText')}
																formatErrorText={this.props.t('formatErrorText')}
																sizeErrorText={this.props.t('sizeErrorText')}
																loadingErrorText={this.props.t('loadingErrorText')}
																thumbSize={240}
															/>
															<p className="t_subtitle">{this.props.t('ArticlePhotoApres')}</p>
														</div>
													}
												</div>
												<p className="t_mention mb_30">{this.props.t('UploadMention')}</p>
											</div>

											<div className="col_right">
												<div className="ctn_checkbox mb_30">
													<Field type="checkbox" id="checkboxRGPD" name="rgpd_checkbox" />
													<label className={errors.rgpd_checkbox && touched.rgpd_checkbox && 'error'} htmlFor="checkboxRGPD">
														{this.props.t('ArticleJAiLu')} <a href={this.props.legalFileForContest.src}>{this.props.t('ArticleLeReglementDuJeu')}</a>
													</label>
												</div>

												<div className="ctn_label">
													<p className="t_label"  dangerouslySetInnerHTML={{ __html: this.props.t('ArticleGDPR_recueillie')}}></p>
												</div>
												
												<div className="ctn_cta fade-in">
													<button className="obj_cta" type="submit">{this.props.t('ArticleReviewPublier')}</button>
													{this.state.serverError && <p className="t_error fade-in">{this.props.t('DefaultServerError')}</p>}
													{this.props.soloMode ?
														<>
														{( (errors.fileupload1 && touched.fileupload1) || (errors.rgpd_checkbox && touched.rgpd_checkbox)  ) && <p className="t_error fade-in">{this.props.t('DefaultErrorMessage')}{console.log(errors)} </p>}
														</>
														:
														<>
														{( (errors.fileupload1 && touched.fileupload1) || (errors.fileupload2 && touched.fileupload2) || (errors.rgpd_checkbox && touched.rgpd_checkbox)  ) && <p className="t_error fade-in">{this.props.t('DefaultErrorMessage')}{console.log(errors)} </p>}
														</>
													}
												</div>
											</div>

										</div>
									</form>
								)}
							</Formik>
						}

					</div>
				</Modal>
			</div>
		)
	}
}

export default withTranslation()(GalerieUploadModal)