import { withSitecoreContext, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next'; 
import React, { Component } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import AnimatedProgressProvider from '../../utilities/FrontFunctions/Provider/AnimatedProgressProvider'
import { isAppMobile } from "../../utilities/FrontFunctions/AoxFrontFunctions"

import {GetStatusFromScore, ConvertToListProgressionBrands,GetListExpertise} from '../../utilities/FrontFunctions/AoxFrontFunctions'

class PageMonCompte extends Component {
	componentDidMount() {
		// add and dispatch window resize
		window.addEventListener('resize', this.throttleWindowResizeSectionCompte)
		window.dispatchEvent(new Event('resize'))
	}
	componentWillUnmount() {
		// remove window resize
		window.removeEventListener('resize', this.throttleWindowResizeSectionCompte)
	}	

	render() {
		const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
		const { CurrentUser  } = this.props.fields.data; 	
		
		var oListProgressionBrands = ConvertToListProgressionBrands(CurrentUser.listprogressionbrands);
		var oListExpertise = GetListExpertise(CurrentUser);

		return (
			<section className="section_compte"> 
				<div className="str_container">

					<div className="ctn_top">
						{ oCurrentItemFields && <Text field={oCurrentItemFields["Page Title"]} tag="h1"></Text> }
						{ oCurrentItemFields && <Text field={oCurrentItemFields["Page SubTitle"]} tag="h2"></Text> }
						{ oCurrentItemFields && <RichText field={oCurrentItemFields["Page Text"]} tag="p"></RichText> }
					</div>					

					<div className="ctn_dashboard">			

						<div className="ctn_col col_percent">
							<div className={"obj_circle-progress-expertise var_" + GetStatusFromScore(CurrentUser.levelexpert)}>

								<AnimatedProgressProvider valueEnd={CurrentUser.levelexpert}>
									{value => {
										return (
											<CircularProgressbar
												value={value}
												strokeWidth={10}
												circleRatio={0.72}
												styles={buildStyles({
													rotation: 0.64
												})}
											/>
										)
									}}
								</AnimatedProgressProvider>

								<div className="ctn_txt">
									<p className="t_percent">{CurrentUser.levelexpert}<span>%</span></p>
									<p className="t_title">{this.props.t('MonCompte_ExpertiseCAF')}</p>
								</div>
								<div className="ctn_avatar">
									<figure>
										{CurrentUser.userimg !== "" ?
											<img src={CurrentUser.userimg} alt={CurrentUser.firstname + " " + CurrentUser.lastname}/>
											:
											<img src={require("../../assets/images/v_avatar-xl.png")} alt={CurrentUser.firstname + " " + CurrentUser.lastname}/>
										}
									</figure>
								</div>
							</div>	

							<div className="ctn_progress-bar-expertise">

								{ oListProgressionBrands.m_oListProgressionBrands.map((oProgressionBrand, index) => {
				
									return (
										<div key={index} className={"obj_progress-bar-expertise " + oProgressionBrand.CssClass}>
											<p className="t_title">{oProgressionBrand.Label} ({oProgressionBrand.TrainingDoCount}/{oProgressionBrand.AllTrainingExpertCount}) <span>{oProgressionBrand.Status}</span></p>

											<div className={"obj_progress-bar " + oProgressionBrand.CssClass}>
												<div className="ctn_steps">
													{Array.from(Array(oProgressionBrand.AllTrainingExpertCount), (e, i) => {
														if (i < oProgressionBrand.TrainingDoCount) {
															return <div key={i} className="step is-complete"></div>
														}
														return <div key={i} className="step"></div>
													})}

													<div className="active_profil" style={{left: (( (oProgressionBrand.AllTrainingExpertCount !== 0 ) ? (oProgressionBrand.TrainingDoCount / oProgressionBrand.AllTrainingExpertCount) : 0) * 95) + '%'}}>
														{CurrentUser.userimg !== "" ?
															<img src={CurrentUser.userimg} alt={CurrentUser.firstname + " " + CurrentUser.lastname}/>
															:
															<img src={require("../../assets/images/v_avatar-xl.png")} alt={CurrentUser.firstname + " " + CurrentUser.lastname}/>
														}
													</div>
												</div>
											</div>

											{ !isAppMobile() &&
												<div className="ctn_cta">
													<div className="ctn_cta">
														<a className="obj_cta" href={oProgressionBrand.PageFormationUrl}>{this.props.t('MonCompte_LinkFormation')}</a>
													</div>	
												</div>
											}
										</div>
										)				
									}	
								)}

							</div>							
						</div>

						{ oListExpertise.length > 0 &&
							<div className="ctn_col col_expert">
								<h3>{this.props.t('MonCompte_EspaceVIP')}</h3>
								<div className="ctn_list-vip-expert">
									{oListExpertise.map((experteItem, index) => (
										<div key={index} className="item_vip-expert">
											<div className="ctn_logo">
												<img src={experteItem.image} alt={experteItem.label}/>
											</div>
											<p className="t_title">{experteItem.label}</p>
										</div>
									))}
								</div>
							</div>
						}
						
					</div>
				</div>
			</section>
		)
	}
}

export default withTranslation()(withSitecoreContext()(PageMonCompte));

 
