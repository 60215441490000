import React, { Component } from 'react'
import Modal from 'react-modal'

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import AnimatedProgressProvider from '../../utilities/FrontFunctions/Provider/AnimatedProgressProvider'

const customStyles = {
	overlay : {
		zIndex			: '99999',
		backgroundColor	: 'rgba(0,0,0,0.6)'
	},
	content : {
		position	: 'fixed',
		top			: '50%',
		left		: '50%',
		right		: 'auto',
		bottom		: 'auto',
		transform	: 'translate(-50%, -50%)',
		outline		: '0'
	}
}

Modal.setAppElement('#root')

class ExpertModal extends Component {
	constructor() {
		super()
		this.state = {
			modalIsOpen: false,
			progressValue: 51
		}
		this.openModal = this.openModal.bind(this)
		this.closeModal = this.closeModal.bind(this)
	}
	componentDidMount() {
		this.props.onRef(this)
	}
	componentWillUnmount() {
		this.props.onRef(null)
	}
	openModal() {
		this.setState({modalIsOpen: true})
	}
	closeModal() {
		this.setState({modalIsOpen: false})
	}
	render() {
		return (
			<>
				<Modal
					isOpen={this.state.modalIsOpen}
					onRequestClose={this.closeModal}
					style={customStyles}
					className="obj_popin popin_expert"
					closeTimeoutMS={300}
					contentLabel="Modal"
				>
					
					{/* <LinearGradient
						id={'gradient-circleprogress-popin'}
						x1={'100%'}
						y1={'100%'}
						startColor={'#d7dadb'}
						endColor={'#a9abac'}
					/>

					<LinearGradient
						id={'gradient-starscore-popin'}
						y2={'100%'}
						startColor={'#9fa0a1'}
						endColor={'#f2f6f7'}
					/> */}

					<button className="bt_close" onClick={this.closeModal}></button>
					<div className="ctn_popin">
						<p className="t_title">Votre expertise</p>
						<div className="ctn_row">
							<div className={"obj_circle-progress-expertise var_" + this.state.globalStatus}>

								<AnimatedProgressProvider valueEnd={this.state.progressValue}>
									{value => {
										return (
											<CircularProgressbar
												value={value}
												strokeWidth={10}
												circleRatio={0.72}
												styles={buildStyles({
													rotation: 0.64
												})}
											/>
										)
									}}
								</AnimatedProgressProvider>

								<div className="ctn_txt">
									<p className="t_percent">{this.state.progressValue}<span>%</span></p>
									<p className="t_title">Expertise CAF</p>
								</div>
								<div className="ctn_avatar">
									<figure>
										<img src={require("../../assets/images/v_avatar-xl.png")} alt="User"/>
									</figure>
								</div>
							</div>
							<p className="t_main">Effectuez toutes les formations de chacune des marques vous concernant pour obtenir 100% et devenir une Experte ou un Expert. <br/>Le % est la moyenne des % de progression de chacune des marques pour laquelle vous avez démarrée un parcours de formation.</p>
						</div>
						<p className="t_title">Votre statut</p>
						<p className="t_main">Changez de statut en effectuant toutes les formations disponibles. À chaque nouvelle formation, vous disposez de 30 jours pour la réaliser afin de conserver votre statut.</p>
						<div className="ctn_row var_star">
							<div className="obj_star-score var_bronze">
								<p className="t_status">Bronze</p>
							</div>
							<div className="obj_star-score var_argent">
								<p className="t_status">Argent</p>
							</div>
							<div className="obj_star-score var_or">
								<p className="t_status">Or</p>
							</div>
							<div className="obj_star-score var_experte">
								<p className="t_status">Experte</p>
							</div>
						</div>
						<div className="ctn_grey">
							<p className="t_main">Si vous n‘avez pas pu réaliser les formations à temps, vous pouvez toujours les effectuer et obtenir le statut ultérieurement.</p>
						</div>
					</div>
				</Modal>
			</>
		)
	}
}

export default ExpertModal