import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext, Image, RichText } from '@sitecore-jss/sitecore-jss-react'; 
import ObjPushCustomFull from '../AllPages/ObjPushCustomFull';
import GalerieUploadModal from '../Modal/GalerieUploadModal'
import GalerieDetailModal from '../Modal/GalerieDetailModal'

import IconSVG from "../../utilities/FrontFunctions/IconSVG"

class PagePhotoContest extends Component {
	render() {
      
    const oCurrentItemFields = this.props.sitecoreContext.route?.fields;     
    
    if (oCurrentItemFields == null)
    {
      return <></>;
    }

    const {CurrentArticleReview, CurrentArticle } = this.props.fields.data; 
    
    const pushCustomFull = {
                              pushUrl : {value : null},
                              darkMode: { boolValue  : true},
                              pushAlign: { value : "center"},
                              pushWidth : {value : '100'},
                              pushTitle: { jss : oCurrentItemFields["Page SubTitle"]},
                              imageMobile: { jss : { value : {src : oCurrentItemFields["Page Image Mobile"]?.value.src}}} ,
                              image: { jss : oCurrentItemFields["Page Image"]?.value } 
                            };
    const sMode = oCurrentItemFields["Type Contest"]?.value;
    
		return (
			 <> 
          {CurrentArticle && <input type="hidden" id="idref" value={CurrentArticle.idref}></input>}
          <RichText className="obj_title-big" field={oCurrentItemFields["Page Title"]} tag="h1"></RichText>

          <ObjPushCustomFull pushContent={pushCustomFull} />
          
			<section className="section_galerie-photo_intro">
				<div className="str_container">
          <div className="ctn_galerie-photo"> 
            <RichText field={oCurrentItemFields["Page Text"]}></RichText>
            
            {sMode === "Single" && 
              <div className="ctn_cta">
                  <button className="obj_cta" onClick={() => this.galerieUploadModal1.openModal()}>{this.props.t('ArticleAjouterUnePhoto')}</button>
              </div>
            }
            
            {sMode === "Double" && 
              <div className="ctn_cta">
                <button className="obj_cta" onClick={() => this.galerieUploadModal2.openModal()}>{this.props.t('ArticleAjouterdeuxPhotos')}</button>
              </div>
            }
             
            {sMode === "Single" && 
              <GalerieUploadModal
                onRef={ref => (this.galerieUploadModal1 = ref)}
                soloMode={true}
                legalFileForContest={oCurrentItemFields["Legal Contest"]?.value}
              />
            }
            {sMode === "Double" && 
              <GalerieUploadModal
                onRef={ref => (this.galerieUploadModal2 = ref)}
                soloMode={false}
                legalFileForContest={oCurrentItemFields["Legal Contest"]?.value}
              />
            }
          </div>
          </div>
          </section>

        <section className="section_galerie-photo_liste">
          <div className="str_container">

            <div className="ctn_galerie-photo_liste">
              
              {CurrentArticleReview && CurrentArticleReview.reviews && CurrentArticleReview.reviews.map((pushGalerieItem, index) => (
                <div key={index} className="obj_push-galerie">
                  <button
                    type="button"
                    onClick={() => this[`galerieDetailModal${index}`].openModal()}
                  > 
                    <div className="ctn_overlay">
                      <ul className="obj_likes-comments">
                        <li>
                          <IconSVG icon="heart" width="19" height="16" />
                          <span className={`nbLikes_${ pushGalerieItem.idrefReview}`}>{pushGalerieItem.likes.numberValue}</span>
                        </li>
                        <li>
                          <IconSVG icon="dialog" width="20" height="17" />
                          {pushGalerieItem.responseList && pushGalerieItem.responseList.length} 
                        </li>
                      </ul>
                    </div>
                    <div className="ctn_image">
                      {sMode === "Double" ?
                        <>
                        <div className="ctn_before">
                          <Image field={pushGalerieItem.image.jss} width="345" height="345" imageParams={{ w: 345, h: 345 }}></Image>
                          <p>{this.props.t('ArticlePhotoAvant')}</p>
                        </div>
                        <div className="ctn_after">
                          <Image field={pushGalerieItem.image2.jss} width="345" height="345" imageParams={{ w: 345, h: 345 }}></Image>
                          <p>{this.props.t('ArticlePhotoApres')}</p>
                        </div>
                        </>
                        :
                        <Image field={pushGalerieItem.image.jss} width="345" height="345" imageParams={{ w: 345, h: 345 }}></Image>
                      }
                    </div>
                  </button>
                  <GalerieDetailModal
                    onRef={ref => (this[`galerieDetailModal${index}`] = ref)}
                    soloMode={sMode === "Single" ? true : false}
                    ReviewItem={pushGalerieItem}
                  /> 
                </div>
              ))}
              
            </div>

          </div>
        </section>
	 
      </>
		)
	}
}

export default withTranslation()(withSitecoreContext()(PagePhotoContest));