import React, { Component } from 'react'
import Modal from 'react-modal'

const customStyles = {
	overlay : {
		zIndex			: '99999',
		backgroundColor	: 'rgba(0,0,0,0.6)'
	},
	content : {
		position	: 'fixed',
		top			: '50%',
		left		: '50%',
		right		: 'auto',
		bottom		: 'auto',
		transform	: 'translate(-50%, -50%)',
		outline		: '0'
	}
}

Modal.setAppElement('#root')

class ModalMAJFormationsFav extends Component {
	constructor() {
		super()
		this.state = {
			modalIsOpen: false
		}
		this.openModal = this.openModal.bind(this)
		this.closeModal = this.closeModal.bind(this)
		this.deleteFavori = this.deleteFavori.bind(this)
	}
	componentDidMount() {
		this.props.onRef(this)
	}
	componentWillUnmount() {
		this.props.onRef(null)
	}
	openModal() {
		this.setState({modalIsOpen: true})
	}
	closeModal() {
		this.setState({modalIsOpen: false})
	}
	deleteFavori() {
		this.props.handleDeleteFavori();
		this.closeModal();
	}

	render() {
		return (
			<div>
				<Modal
				isOpen={this.state.modalIsOpen}
				onRequestClose={this.closeModal}
				style={customStyles}
				className="obj_popin popin_small"
				closeTimeoutMS={300}
				contentLabel="Modal"
				>
					<button className="bt_close" onClick={this.closeModal}></button>
					<div className="ctn_popin">
						<p className="t_title">{this.props.modalTitle}</p>
						<p className="t_main">{this.props.modalText}</p>
						<p className="ctn_cta">
							<button className="obj_btn obj_cta" onClick={this.deleteFavori}>
								Supprimer
							</button>
							<button className="obj_btn light obj_cta" onClick={this.closeModal}>
								Annuler
							</button>
						</p>
					</div>
				</Modal>
			</div>
		)
	}
}

export default ModalMAJFormationsFav