import React, { Component } from 'react'
import Modal from 'react-modal'

import ReactPlayer from 'react-player'

const customStyles = {
	overlay : {
		zIndex			: '99999',
		backgroundColor	: 'rgba(0,0,0,0.6)'
	},
	content : {
		position	: 'fixed',
		top			: '50%',
		left		: '50%',
		right		: 'auto',
		bottom		: 'auto',
		transform	: 'translate(-50%, -50%)',
		outline		: '0'
	}
}

Modal.setAppElement('#root')

class VideoModalNoFS extends Component {
	constructor() {
		super()
		this.state = {
			modalIsOpen: false
		}
		this.openModal = this.openModal.bind(this)
		this.closeModal = this.closeModal.bind(this)
	}
	componentDidMount() {
		this.props.onRef(this)
	}
	componentWillUnmount() {
		this.props.onRef(null)
	}
	openModal() {
		this.setState({modalIsOpen: true})
	}
	closeModal() {
		this.setState({modalIsOpen: false})
	}
	render() {
		return (
			<>
				<Modal
					isOpen={this.state.modalIsOpen}
					onRequestClose={this.closeModal}
					style={customStyles}
					className="obj_popin popin_video-nofs"
					bodyOpenClassName="ReactModal__Body--open-nofs"
					closeTimeoutMS={300}
					contentLabel="Modal"
				>
					<button className="bt_close" onClick={this.closeModal}></button>
					<div className="ctn_popin">
						<p className="t_title">{this.props.videoTitle}</p>
						<div className="ctn_iframe">
							<ReactPlayer
								url={this.props.videoUrl}
								config={{
									youtube: {
										playerVars: { autoplay: this.props.videoAutoplay }
									},
									vimeo: {
										playerOptions: { autoplay: this.props.videoAutoplay }
									},
									file: {
										attributes: { autoplay: this.props.videoAutoplay }
									}
								}}
								width="1920"
								height="1080"
								controls
							/>
						</div>
					</div>
				</Modal>
			</>
		)
	}
}

VideoModalNoFS.defaultProps = {
	videoAutoplay: 0
}

export default VideoModalNoFS