const myQuery = `query GetArticlesInformations($contextItem: String) {
  
  CurrentArticleReview: item(path: $contextItem) {
    reviewsCount(templateOfReviews: "{1F37FA27-CB52-40B0-8840-D93BA7BE20E9}")
    reviews(templateOfReviews: "{1F37FA27-CB52-40B0-8840-D93BA7BE20E9}") {
      idrefReview: idref
      ...SearchReviewFOU
      ...SearchReviewArticleReview
      responseList: children(includeTemplateIDs: "{454A9CFD-9656-4A16-B4A8-1400C76538BF}") {
        ...SearchReviewFOU
        ...SearchReviewArticleReview
      }
    }
  }
}

fragment SearchReviewFOU on Item {
  reviewsFOU {
    firstname
    lastname
    username
    userimg
  }
}

fragment SearchReviewArticleReview on Item {
  ... on ArticleReview {
    comment {
      jss
    }
    rating {
      jss
      numberValue
    }
    image {
      jss
    }
    creationDate {
      jss
      dateValue
    }
    updateDate {
      jss
      dateValue
    }
    userId {
      jss
    }
    userName {
      jss
    }
  }
}`;

export default myQuery;