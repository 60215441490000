export const IconsSVG = {
	avatar: {
		viewBox: '0 0 11 14',
		width: '11',
		height: '14',
		svgCode: '<path d="M5.5,6.2c1.8,0,3.3-1.4,3.3-3.1S7.3,0,5.5,0S2.2,1.4,2.2,3.1S3.7,6.2,5.5,6.2z"/><path d="M7.4,6.7H3.6c-2,0-3.6,1.5-3.6,3.4V14h11v-3.9C11,8.2,9.4,6.7,7.4,6.7z"/>'
	},
	avatar_edit: {
		viewBox: '0 0 65 52',
		width: '65',
		height: '52',
		svgCode: '<path d="M37.2,28.5c3.2,0,5.8-2.6,5.8-5.9c0-3.3-2.6-5.9-5.8-5.9s-5.8,2.6-5.8,5.9C31.3,25.9,33.9,28.5,37.2,28.5z"/><path d="M28.3,44.6H46c0.5,0,0.8-0.3,0.9-0.8v-5.9c0-4-3.2-7.3-7.2-7.3h-5.1c-4,0-7.2,3.3-7.2,7.3v5.8C27.4,44.2,27.8,44.6,28.3,44.6z"/><path d="M46.2,5.4V3.9c0-2.2-1.8-3.9-3.9-3.9H3.9C1.8,0,0,1.8,0,3.9v34.8c0,2.2,1.8,3.9,3.9,3.9h1.6c0-0.4,0-0.8,0-1.1V12.8c0-1.4,0-3.7,0-5c0-1.3,0.9-2.4,2-2.4c1.1,0,3.1,0,4.6,0H46.2z"/><path d="M61.1,9.3H13.2c-2.1,0-3.9,1.8-3.9,3.9v34.8c0,2.2,1.8,3.9,3.9,3.9h47.9c2.1,0,3.9-1.8,3.9-3.9V13.3C65,11.1,63.2,9.3,61.1,9.3z M59.8,46.7H14.5V14.6h45.3V46.7z"/>'
	},
	email: {
		viewBox: '0 0 15 12',
		width: '15',
		height: '12',
		svgCode: '<path d="M0,0v1.7l7.5,4.8L15,1.7V0H0z"/><path d="M0,3.1V12h15V3.1L7.5,7.9L0,3.1z"/>'
	},
	heartstroke: {
		viewBox: '0 0 19 16',
		width: '19',
		height: '16',
		svgCode: '<path d="M13.6,1.2c2.4,0,4.3,2,4.3,4.7c0,3.8-5.8,7.7-8,8.8c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0l0,0l0,0l-0.1,0c0,0,0,0,0,0c-2.2-1.2-8-5.1-8-8.9c0-2.7,1.8-4.7,4.3-4.7c1.1,0,2.3,0.6,3.2,1.4l0.8,0.8l0.8-0.8C11.2,1.8,12.5,1.2,13.6,1.2 M9.6,14.8C9.6,14.8,9.6,14.8,9.6,14.8L9.6,14.8L9.6,14.8 M13.6,0c-1.4,0-2.9,0.7-4.1,1.8C8.4,0.7,6.8,0,5.4,0C2.3,0,0,2.5,0,5.9c0,5.3,8.5,9.9,8.6,9.9c0.1,0,0.1,0.1,0.2,0.1l0,0c0,0,0.1,0,0.1,0.1C9.2,16,9.4,16,9.5,16c0.1,0,0.3,0,0.5-0.1c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0.1,0,8.6-4.6,8.6-9.9C19,2.5,16.7,0,13.6,0L13.6,0z"/>'
	},
	heart: {
		viewBox: '0 0 19 16',
		width: '19',
		height: '16',
		svgCode: '<path d="M9.5,16c-0.1,0-0.3,0-0.5-0.1c-0.1,0-0.1,0-0.1-0.1l0,0c-0.1,0-0.1-0.1-0.2-0.1C8.5,15.7,0,11.1,0,5.9C0,2.5,2.3,0,5.4,0c1.4,0,2.9,0.7,4.1,1.8C10.6,0.7,12.2,0,13.6,0C16.7,0,19,2.5,19,5.9c0,5.3-8.5,9.9-8.6,9.9c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0,0c-0.1,0-0.1,0-0.1,0.1C9.8,16,9.6,16,9.5,16z"/>'
	},
	dialog: {
		viewBox: '0 0 20 17',
		width: '20',
		height: '17',
		svgCode: '<path d="M19.6,0H0.4C0.2,0,0,0.2,0,0.4v13.2C0,13.8,0.2,14,0.4,14H4l-1,2.4c-0.1,0.2,0,0.3,0.1,0.4C3.1,17,3.2,17,3.3,17c0.1,0,0.1,0,0.2,0L9.4,14h10.2c0.2,0,0.4-0.2,0.4-0.4V0.4C20,0.2,19.8,0,19.6,0z M5.3,8.4c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6C6.9,7.7,6.2,8.4,5.3,8.4z M10,8.4c-0.9,0-1.6-0.7-1.6-1.6C8.4,6,9.1,5.3,10,5.3s1.6,0.7,1.6,1.6C11.6,7.7,10.9,8.4,10,8.4z M14.7,8.4c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6C16.3,7.7,15.6,8.4,14.7,8.4z"/>'
	},
	phone: {
		viewBox: '0 0 20 29',
		width: '20',
		height: '29',
		svgCode: '<path d="M4.2,18c-1.5-2.7-6.5-14-3-16.1l3.2-1.9C4.7-0.1,5.1,0,5.3,0.3l3.6,6.3C9,6.9,8.9,7.3,8.6,7.5L5.7,9.2c1.3,4.2,3.9,8.7,6.8,12l2.9-1.7c0.3-0.2,0.7-0.1,0.9,0.2l3.6,6.3c0.2,0.3,0.1,0.7-0.3,0.9l-3.2,1.9C13,30.8,5.7,20.7,4.2,18L4.2,18z"/>'
	},
	facebook: {
		viewBox: '0 0 24 44',
		width: '24',
		height: '44',
		svgCode: '<path d="M23.1,0l-5.8,0C10.9,0,6.7,4.3,6.7,10.8v5H0.9c-0.5,0-0.9,0.4-0.9,0.9V24c0,0.5,0.4,0.9,0.9,0.9h5.8v18.3c0,0.5,0.4,0.9,0.9,0.9h7.6c0.5,0,0.9-0.4,0.9-0.9V24.8h6.8c0.5,0,0.9-0.4,0.9-0.9l0-7.2c0-0.2-0.1-0.5-0.3-0.6c-0.2-0.2-0.4-0.3-0.6-0.3h-6.8v-4.2c0-2,0.5-3.1,3.2-3.1l3.9,0c0.5,0,0.9-0.4,0.9-0.9V0.9C24,0.4,23.6,0,23.1,0z"/>'
	},
	facebook_circle: {
		viewBox: '0 0 24 24',
		width: '24',
		height: '24',
		svgCode: '<path d="M10.1,24L10.1,24c-6.5-1-11-7.2-10-13.8s7.2-11.1,13.7-10s11,7.2,10,13.8c-0.8,5.2-4.8,9.2-10,10v-8.4h2.8l0.5-3.5h-3.3V9.8c-0.1-1,0.6-1.8,1.6-1.9c0.1,0,0.3,0,0.4,0h1.5V5c-0.9-0.1-1.8-0.2-2.7-0.2c-2.8,0-4.5,1.8-4.5,4.7v2.7h-3v3.5h3L10.1,24L10.1,24z"/>'
	},
	instagram: {
		viewBox: '0 0 43 43',
		width: '43',
		height: '43',
		svgCode: '<path d="M21.5,3.9c5.7,0,6.4,0,8.7,0.1c2.1,0.1,3.2,0.4,4,0.7c1,0.4,1.7,0.9,2.5,1.6c0.8,0.8,1.2,1.5,1.6,2.5c0.3,0.8,0.6,1.9,0.7,4c0.1,2.3,0.1,2.9,0.1,8.7s0,6.4-0.1,8.7c-0.1,2.1-0.4,3.2-0.7,4c-0.4,1-0.9,1.7-1.6,2.5c-0.8,0.8-1.5,1.2-2.5,1.6c-0.8,0.3-1.9,0.6-4,0.7c-2.3,0.1-2.9,0.1-8.7,0.1s-6.4,0-8.7-0.1c-2.1-0.1-3.2-0.4-4-0.7c-1-0.4-1.7-0.9-2.5-1.6c-0.8-0.8-1.2-1.5-1.6-2.5c-0.3-0.8-0.6-1.9-0.7-4c-0.1-2.3-0.1-2.9-0.1-8.7s0-6.4,0.1-8.7c0.1-2.1,0.4-3.2,0.7-4c0.4-1,0.9-1.7,1.6-2.5c0.8-0.8,1.5-1.2,2.5-1.6c0.8-0.3,1.9-0.6,4-0.7C15.1,3.9,15.8,3.9,21.5,3.9 M21.5,0c-5.8,0-6.6,0-8.9,0.1c-2.3,0.1-3.9,0.5-5.2,1C6,1.7,4.8,2.4,3.6,3.6C2.4,4.8,1.7,6,1.1,7.4c-0.5,1.4-0.9,2.9-1,5.2C0,14.9,0,15.7,0,21.5c0,5.8,0,6.6,0.1,8.9c0.1,2.3,0.5,3.9,1,5.2c0.5,1.4,1.3,2.6,2.5,3.8c1.2,1.2,2.4,1.9,3.8,2.5c1.4,0.5,2.9,0.9,5.2,1c2.3,0.1,3,0.1,8.9,0.1s6.6,0,8.9-0.1c2.3-0.1,3.9-0.5,5.2-1c1.4-0.5,2.6-1.3,3.8-2.5c1.2-1.2,1.9-2.4,2.5-3.8c0.5-1.4,0.9-2.9,1-5.2c0.1-2.3,0.1-3,0.1-8.9s0-6.6-0.1-8.9c-0.1-2.3-0.5-3.9-1-5.2c-0.5-1.4-1.3-2.6-2.5-3.8c-1.2-1.2-2.4-1.9-3.8-2.5c-1.4-0.5-2.9-0.9-5.2-1C28.1,0,27.3,0,21.5,0L21.5,0z"/><path d="M21.5,10.5c-6.1,0-11,4.9-11,11s4.9,11,11,11s11-4.9,11-11S27.6,10.5,21.5,10.5z M21.5,28.7c-4,0-7.2-3.2-7.2-7.2s3.2-7.2,7.2-7.2s7.2,3.2,7.2,7.2S25.5,28.7,21.5,28.7z"/><circle cx="33" cy="10" r="2.6"/>'
	},
	youtube: {
		viewBox: '0 0 55 38',
		width: '55',
		height: '38',
		svgCode: '<path d="M45.7,0H9.3C4.2,0,0,4.2,0,9.3v19.4C0,33.8,4.2,38,9.3,38h36.4c5.1,0,9.3-4.2,9.3-9.3V9.3C55,4.2,50.8,0,45.7,0z M29.8,23.2l-8.3,4.2V10.6l8.3,4.2l8.3,4.2L29.8,23.2z"/>'
	},
	download: {
		viewBox: '0 0 18 18',
		width: '18',
		height: '18',
		svgCode: '<path d="M17.3,11.3c-0.4,0-0.7,0.3-0.7,0.7v4.4h-15v-4.4c0-0.4-0.3-0.7-0.7-0.7S0,11.7,0,12.1v5.2C0,17.7,0.3,18,0.7,18h16.5c0.4,0,0.7-0.3,0.7-0.7v-5.2C18,11.7,17.7,11.3,17.3,11.3z"/><path d="M8.5,12.6c0.4,0.4,0.9,0.2,1.1,0L14.1,8c0.3-0.3,0.3-0.8,0-1.1S13.3,6.7,13,7l-3.3,3.3V0.7C9.7,0.3,9.4,0,9,0S8.3,0.3,8.3,0.7v9.5L5,7C4.7,6.7,4.2,6.7,3.9,7C3.6,7.3,3.6,7.7,3.9,8L8.5,12.6L8.5,12.6z"/>'
	},
	tube: {
		viewBox: '0 0 19 36',
		width: '19',
		height: '36',
		svgCode: '<path d="M19,0c-0.2,1.6-0.5,3.3-0.9,4.9c-0.6,1.2-3,25.6-3.1,26.4H4C4,30.4,1.6,6.1,0.9,4.9C0.5,3.3,0.2,1.6,0,0H19zM4.3,36h10.4c0.2-0.3,0.3-2.6,0.3-3.8H4C4.1,33.4,4.2,35.7,4.3,36L4.3,36z"/>'
	},
	sun: {
		viewBox: '0 0 25 25',
		width: '25',
		height: '25',
		svgCode: '<path d="M12.5,7.8c2.6,0,4.7,2.1,4.7,4.7s-2.1,4.7-4.7,4.7s-4.7-2.1-4.7-4.7C7.8,9.9,9.9,7.8,12.5,7.8 M12.5,5.9c-3.6,0-6.6,2.9-6.6,6.6s3,6.6,6.6,6.6s6.6-2.9,6.6-6.6S16.1,5.9,12.5,5.9L12.5,5.9z"/><path d="M12.5,5.7c-0.5,0-1-0.4-1-1V1c0-0.5,0.4-1,1-1s1,0.4,1,1v3.8C13.4,5.3,13,5.7,12.5,5.7z"/><path d="M12.5,25c-0.5,0-1-0.4-1-1v-3.8c0-0.5,0.4-1,1-1s1,0.4,1,1V24C13.5,24.6,13.1,25,12.5,25z"/><path d="M4.7,13.5H1c-0.5,0-1-0.4-1-1c0-0.5,0.4-1,1-1h3.8c0.5,0,1,0.4,1,1C5.7,13,5.3,13.5,4.7,13.5z"/><path d="M24,13.4h-3.8c-0.5,0-1-0.4-1-1c0-0.5,0.4-1,1-1H24c0.5,0,1,0.4,1,1C25,13,24.6,13.4,24,13.4z"/><path d="M7,8C6.8,8,6.5,7.9,6.3,7.7L3.7,5c-0.4-0.4-0.4-1,0-1.3s1-0.4,1.3,0l2.7,2.7c0.4,0.4,0.4,1,0,1.3C7.5,7.9,7.2,8,7,8z"/><path d="M20.7,21.6c-0.2,0-0.5-0.1-0.7-0.3l-2.7-2.7c-0.4-0.4-0.4-1,0-1.3c0.4-0.4,1-0.4,1.3,0l2.7,2.7c0.4,0.4,0.4,1,0,1.3C21.2,21.5,20.9,21.6,20.7,21.6z"/><path d="M4.4,21.6c-0.2,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.3l2.7-2.7c0.4-0.4,1-0.4,1.3,0c0.4,0.4,0.4,1,0,1.3L5,21.4C4.8,21.5,4.6,21.6,4.4,21.6z"/><path d="M18,7.9c-0.2,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.3L20,3.7c0.4-0.4,1-0.4,1.3,0c0.4,0.4,0.4,1,0,1.3l-2.7,2.7C18.5,7.8,18.2,7.9,18,7.9z"/>'
	},
	moon: {
		viewBox: '0 0 13 17',
		width: '13',
		height: '17',
		svgCode: '<path d="M8.4,17C3.8,17,0,13.2,0,8.5S3.8,0,8.4,0c1.4,0,2.9,0.4,4.1,1.1C12.8,1.2,13,1.6,13,1.9s-0.2,0.7-0.5,0.8c-2.1,1.2-3.4,3.4-3.4,5.8s1.3,4.6,3.4,5.8c0.3,0.2,0.5,0.5,0.5,0.8c0,0.3-0.2,0.7-0.5,0.8C11.3,16.6,9.9,17,8.4,17z M8.4,1.9c-3.6,0-6.6,3-6.6,6.6s2.9,6.6,6.6,6.6c0.6,0,1.2-0.1,1.7-0.2C8.4,13.3,7.3,11,7.3,8.5s1.1-4.8,2.9-6.4C9.6,2,9,1.9,8.4,1.9z"/>'
	},
	star: {
		viewBox: '0 0 55 52',
		width: '55',
		height: '52',
		svgCode: '<path d="M27.5,0l9.1,16.3L55,19.9L42.2,33.5L44.5,52l-17-7.9l-17,7.9l2.3-18.5L0,19.9l18.4-3.5L27.5,0"/>'
	},
	eye: {
		viewBox: '0 0 20 12.98',
		width: '20',
		height: '12.98',
		svgCode: '<path d="M19.91,7.7c-.18-.25-4.34-6.19-9.91-6.19S.27,7.45.09,7.7a.55.55,0,0,0,0,.6c.18.25,4.34,6.19,9.91,6.19s9.73-5.94,9.91-6.19A.54.54,0,0,0,19.91,7.7ZM10,12.63A4.63,4.63,0,1,1,14.63,8,4.63,4.63,0,0,1,10,12.63Z" transform="translate(0 -1.51)"/><path d="M10,5.26A2.74,2.74,0,1,0,12.74,8,2.74,2.74,0,0,0,10,5.26Z" transform="translate(0 -1.51)"/>'
	},
	share: {
		viewBox: '0 0 20 16.84',
		width: '20',
		height: '16.84',
		svgCode: '<path d="M10.79.15a.29.29,0,0,0-.32,0,.31.31,0,0,0-.17.27V4.51A10.35,10.35,0,0,0,2.89,7.08C.72,9.17-.24,12.39.05,16.64a.29.29,0,0,0,.25.27h0a.33.33,0,0,0,.28-.17c0-.07,3.16-6.43,9.67-5.63v4.19a.31.31,0,0,0,.17.27.31.31,0,0,0,.32,0l9.1-7.46A.3.3,0,0,0,20,7.85a.3.3,0,0,0-.11-.24Z" transform="translate(0 -0.08)"/>'
	},
	arrowDown: {
		viewBox: '0 0 24 24',
		width: '24',
		height: '24',
		svgCode: '<g transform="translate(24 241) rotate(90)"><rect width="24" height="24" transform="translate(-241)" fill="rgba(0,0,0,0)"/><path d="M0,5H5V0" transform="translate(-227 13) rotate(180)" fill="rgba(0,0,0,0)"/><path d="M5.75,5.75H0V4.25H4.25V0h1.5Z" transform="translate(-227 13) rotate(180)"/><path d="M6.47,7.53l-7-7L.53-.53l7,7Z" transform="translate(-232 8)"/></g>'
	},
	importUpload: {
		viewBox: '0 0 27 20',
		width: '27',
		height: '20',
		svgCode: '<defs><clipPath id="a"><rect width="27" height="20" fill="none" stroke="#707070" stroke-width="2"/></clipPath></defs><g clip-path="url(#a)"><path d="M0,11.667a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1,1,1,0,0,1,1,0V10.667A1,1,0,0,1,0,11.667Z" transform="translate(13.5 1.333)"/><path d="M241.433,39.965a1,1,0,0,1-.7-.289l-3.347-3.306-3.347,3.306a1,1,0,0,1-1.405-1.423l3.933-3.884a1.171,1.171,0,0,1,1.641,0l3.932,3.884a1,1,0,0,1-.7,1.711Z" transform="translate(-223.883 -33.631)"/><path d="M54.933,275.667h-18.9a3.688,3.688,0,0,1-3.7-3.667v-5.333a1,1,0,0,1,2,0V272a1.686,1.686,0,0,0,1.7,1.667h18.9a1.686,1.686,0,0,0,1.7-1.667v-5.333a1,1,0,0,1,2,0V272A3.688,3.688,0,0,1,54.933,275.667Z" transform="translate(-31.983 -256)"/></g>'
	},
	file: {
		viewBox: '0 0 21.313 25.188',
		width: '21.313',
		height: '25.188',
		svgCode: '<path d="M3085.278,190.284l-7.75-7.75a.965.965,0,0,0-.685-.284h-7.75c-3.123,0-4.844,1.72-4.844,4.844v15.5c0,3.123,1.72,4.844,4.844,4.844h11.625c3.123,0,4.844-1.721,4.844-4.844V190.969A.966.966,0,0,0,3085.278,190.284Zm-7.466-4.726,4.442,4.442h-1.536c-2.037,0-2.906-.869-2.906-2.906Zm2.906,19.942h-11.625c-2.037,0-2.906-.869-2.906-2.906v-15.5c0-2.037.869-2.906,2.906-2.906h6.781v2.906c0,3.123,1.72,4.844,4.844,4.844h2.906v10.656C3083.625,204.631,3082.756,205.5,3080.719,205.5Z" transform="translate(-3064.25 -182.25)"/>'
	},
	calendar: {
		viewBox: '0 0 24 24',
		width: '24',
		height: '24',
		svgCode: '<rect width="24" height="24" fill="#fff"/><g transform="translate(5 5)" opacity="0.2"><path d="M16.188,12.765V11.346c0-.6,0-1.124-.009-1.6H2.009c-.009.472-.009,1-.009,1.6v1.419c0,2.675,0,4.013.831,4.844S5,18.44,7.675,18.44h2.838c2.675,0,4.013,0,4.844-.831S16.188,15.44,16.188,12.765Z" transform="translate(-2 -4.075)"/><path d="M6.1,2.282a.532.532,0,0,0-1.064,0V3.4a3.24,3.24,0,0,0-2.184.775,3.24,3.24,0,0,0-.775,2.184H16.154a3.239,3.239,0,0,0-.775-2.184A3.24,3.24,0,0,0,13.2,3.4V2.282a.532.532,0,0,0-1.064,0V3.355c-.472-.009-1-.009-1.6-.009H7.7c-.6,0-1.124,0-1.6.009Z" transform="translate(-2.023 -1.75)"/></g>'
	},
	pin: {
		viewBox: '0 0 24 24',
		width: '24',
		height: '24',
		svgCode: '<rect width="24" height="24" fill="#fff"/><g transform="translate(6 4)" opacity="0.2"><path d="M172.6,5246.376a1.6,1.6,0,1,1,1.6-1.6,1.6,1.6,0,0,1-1.6,1.6m0-7.376a5.6,5.6,0,0,0-5.6,5.6c0,3.1,5.6,10.4,5.6,10.4s5.6-7.3,5.6-10.4a5.6,5.6,0,0,0-5.6-5.6" transform="translate(-167 -5239)" fill-rule="evenodd"/></g>'
	},
	clock: {
		viewBox: '0 0 24 24',
		width: '24',
		height: '24',
		svgCode: '<g transform="translate(0 -300)"><rect width="24" height="24" transform="translate(0 300)" fill="#fff"/><circle cx="7" cy="7" r="7" transform="translate(5 305.113)" fill="#cbcbcb"/><path d="M12,308.469v4.3l3.271,1.176" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1.5"/></g>'
	},
	telecharger: {
		viewBox: '0 0 23.595 20.227',
		width: '23.595',
		height: '20.227',
		svgCode: '<g transform="translate(1 19.227) rotate(-90)"><path d="M5.328,12.777-1.414,6.035,5.328-.707,6.742.707,1.414,6.035l5.328,5.328Z" transform="translate(5.026 5.086)" /><rect width="13" height="2" transform="translate(6.227 9.891)" /><path d="M4.621,22.595H0a1,1,0,0,1-1-1V0A1,1,0,0,1,0-1H4.621V1H1V20.595H4.621Z" /></g>'
	}
}