import React, { Component } from 'react'

class OverlayTutorialHome extends Component {
	constructor(props) {
		super(props)
		this.state = {
			current: 0,
            step: 5,
            modalIsOpen: false
		}
        this.openModal = this.openModal.bind(this)
		this.closeModal = this.closeModal.bind(this)
		this.closeNoMore = this.closeNoMore.bind(this)
	}
	componentDidMount() {
		this.props.onRef(this)
        window.scrollTo(0,0)
		window.addEventListener('scroll', this.throttleWindowScrollBackToTop)
        // this.showStepTutorial(this.state.current)
	}
	componentWillUnmount() {
		this.props.onRef(null)
        // document.body.classList.remove('tuto')
		window.removeEventListener('scroll', this.throttleWindowScrollBackToTop)
	}
    nextStepTutorial(){
        this.setState((prevState) => ({
            current: Math.min(prevState.current + 1, this.state.step),
        }), () => {
            // Callback après que l'état ait été mis à jour
            const targetIds = {
                1: 'tutorial_step_1',
                2: 'tutorial_step_2',
                3: 'tutorial_step_3',
                4: 'tutorial_step_4',
                5: 'tutorial_step_5'
            };
        
            const { current } = this.state;
            const targetId = targetIds[current];
            const targetElement = document.getElementById(targetId);
        
            // Faites défiler la page jusqu'à l'élément avec un ID spécifique avec un scroll smooth
            if (targetElement) {
                const scrollOffset = -100; // Décalage de 100px avant la cible
                const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY;
                const finalScrollPosition = targetPosition + scrollOffset;

                if (current === 4) {
                    window.scrollTo({
                        top: 0, behavior: 'smooth',
                    });
                }else{
                    window.scrollTo({
                        top: finalScrollPosition, behavior: 'smooth',
                    });
                }
                
            }
        });
	}
    prevStepTutorial() {
        this.setState((prevState) => ({
            current: Math.max(prevState.current - 1, 1),
        }), () => {
            // Callback après que l'état ait été mis à jour
            const targetIds = {
                1: 'tutorial_step_1',
                2: 'tutorial_step_2',
                3: 'tutorial_step_3',
                4: 'tutorial_step_4',
                5: 'tutorial_step_5'
            };
        
            const { current } = this.state;
            const targetId = targetIds[current];
            const targetElement = document.getElementById(targetId);
        
            // Faites défiler la page jusqu'à l'élément avec un ID spécifique avec un scroll smooth
            if (targetElement) {
                const scrollOffset = -100; // Décalage de 100px avant la cible
                const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY;
                const finalScrollPosition = targetPosition + scrollOffset;
        
                if (current === 4) {
                    window.scrollTo({
                        top: 0, behavior: 'smooth',
                    });
                }else{
                    window.scrollTo({
                        top: finalScrollPosition, behavior: 'smooth',
                    });
                }
            }
        });
	}
	openModal() {
		this.setState({modalIsOpen: true})
        window.scrollTo(
            { top: 0, left: 0, behavior: 'smooth'}
        )
        document.body.classList.add('overlay_tuto_is-opened');
	}
    closeModal() {
		this.setState({modalIsOpen: false})
        window.scrollTo({
            top: 0, behavior: 'smooth',
        });
        document.body.classList.remove('overlay_tuto_is-opened');
	}
    closeNoMore(){
        this.closeModal()
        localStorage.setItem('dontShowAgainModalHomeTutorial', 'true')
    }

	render() {
		const {apiDatas} = this.props;

		return (<>
            {this.state.modalIsOpen &&
                <div className={`obj_overlay_tuto_home step_${this.state.current}`}>
                    
                    <div className="ctn_overlay_rect">
                        <div className="obj_overlay_rect_1"></div>
                        <div className="obj_overlay_rect_2"></div>
                        <div className="obj_overlay_rect_3"></div>
                        <div className="obj_overlay_rect_4"></div>
                    </div>

                    <div className="ctn_popin_tuto_home">
                        <div className="wrapper_popin_tuto_home">

                            <div className="obj_popin_tuto_home">

                                <div className="ctn_text step_0">
                                    <p className="t_title">Votre espace de formation se réinvente !</p>
                                    <p className="t_desc">Découvrez en 2 minutes et 5 étapes les grandes nouveautés de votre site Partner Academy.</p>

                                    <p className="ctn_cta">
                                        <button 
                                            className="obj_btn light" 
                                            type="button" 
                                            onClick={() => this.closeNoMore()}
                                        >
                                            Fermer
                                        </button>

                                        <button 
                                            className="obj_btn light" 
                                            type="button" 
                                            onClick={() => this.closeModal()}
                                        >
                                            Voir plus tard
                                        </button>

                                        <button 
                                            className="obj_btn" 
                                            type="button" 
                                            onClick={() => this.nextStepTutorial()}
                                        >
                                            Voir le didacticiel
                                        </button>
                                    </p>

                                </div>

                                <div className="ctn_text step_1 fade-in">
                                    <p className="t_step">Etape {this.state.current}/{this.state.step}</p>
                                    <p className="t_title">Retrouvez des modules et des contenus exclusifs catégorisés <strong>par marque.</strong></p>

                                    <p className="ctn_cta">
                                        <button 
                                            className="obj_btn" 
                                            type="button" 
                                            onClick={() => this.nextStepTutorial()}
                                        >
                                            &Eacute;tape suivante
                                        </button>
                                    </p>
                                </div>

                                <div className="ctn_text step_2 fade-in">
                                    <p className="t_step">Etape {this.state.current}/{this.state.step}</p>
                                    <p className="t_title">Retrouvez des modules et des contenus exclusifs catégorisés <strong>par pathologie</strong></p>

                                    <p className="ctn_cta">
                                        <button 
                                            className="obj_btn light" 
                                            type="button" 
                                            onClick={() => this.prevStepTutorial()}
                                        >
                                            Précédent
                                        </button>

                                        <button 
                                            className="obj_btn" 
                                            type="button" 
                                            onClick={() => this.nextStepTutorial()}
                                        >
                                            &Eacute;tape suivante
                                        </button>
                                    </p>
                                </div>

                                <div className="ctn_text step_3 fade-in">
                                    <p className="t_step">Etape {this.state.current}/{this.state.step}</p>
                                    {   apiDatas.User?.IsChallenge
                                        ? <p className="t_title">
                                            <strong>Inscrivez-vous</strong> à tous nos évènements de formation, <strong>déclarez</strong> et <strong>répartissez</strong> les gains de vos challenges. <strong>Accédez</strong> également à la progression de votre parcours d'expertise.
                                        </p>
                                        : <p className="t_title"><strong>Inscrivez-vous</strong> à tous nos évènements de formation et <strong>accédez</strong> à la progression de votre parcours d'expertise.</p>
                                    }
                                    <p className="ctn_cta">
                                        <button 
                                            className="obj_btn light" 
                                            type="button" 
                                            onClick={() => this.prevStepTutorial()}
                                        >
                                            Précédent
                                        </button>

                                        <button 
                                            className="obj_btn" 
                                            type="button" 
                                            onClick={() => this.nextStepTutorial()}
                                        >
                                            &Eacute;tape suivante
                                        </button>
                                    </p>
                                </div>

                                <div className="ctn_text step_4 fade-in">
                                    <p className="t_step">Etape {this.state.current}/{this.state.step}</p>
                                    <p className="t_title">Retrouvez toutes les informations relatives à votre compte ainsi que votre progression sur le parcours d'expertise</p>

                                    <p className="ctn_cta">
                                        <button 
                                            className="obj_btn light" 
                                            type="button" 
                                            onClick={() => this.prevStepTutorial()}
                                        >
                                            Précédent
                                        </button>

                                        <button 
                                            className="obj_btn" 
                                            type="button" 
                                            onClick={() => this.nextStepTutorial()}
                                        >
                                            &Eacute;tape suivante
                                        </button>
                                    </p>
                                </div>

                                <div className="ctn_text step_5 fade-in">
                                    <p className="t_step">Etape {this.state.current}/{this.state.step}</p>
                                    <p className="t_title">N'hésitez pas à revenir vers nous pour toute question</p>

                                    <p className="ctn_cta">
                                        <button 
                                            className="obj_btn light" 
                                            type="button" 
                                            onClick={() => this.prevStepTutorial()}
                                        >
                                            Précédent
                                        </button>

                                        <button 
                                            className="obj_btn light" 
                                            type="button" 
                                            onClick={() => this.closeNoMore()}
                                        >
                                            Fermer
                                        </button>
                                    </p>
                                </div>

                            </div>

                            <button className="btn_popin_tuto_home_close" type="button" onClick={() => this.closeModal()}></button>

                        </div>
                    </div>
                    
                </div>
            }
        </>)
	}
}

export default OverlayTutorialHome