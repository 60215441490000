import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext, Image, RichText } from '@sitecore-jss/sitecore-jss-react';  
import ObjPushCustomFull from '../AllPages/ObjPushCustomFull';
//import PageActiviteConseil from '../PageActiviteConseil';
class PageResultContest extends Component {
	render() {
         
    const oCurrentItemFields = this.props.sitecoreContext.route?.fields;

    if (oCurrentItemFields == null || oCurrentItemFields["Page Image Mobile"] == null)
    {
      return <></>;
    }
    
    const { GetWinners } = this.props.fields.data; 
    const pushCustomFull = {
      pushUrl : {value : null},
      darkMode: { boolValue  : true},
      pushAlign: { value : "left"},
      pushWidth : {value : '100'},
      pushTitle: { jss : oCurrentItemFields["Page SubTitle"]},
      pushContent: { jss : oCurrentItemFields["Page Text"]},
      imageMobile: { jss : { value : {src : oCurrentItemFields["Page Image Mobile"].value.src}}} ,
      image: { jss : oCurrentItemFields["Page Image"].value } 
    };

		return (
			 <> 
          <RichText className="obj_title-big" field={oCurrentItemFields["Page Title"]} tag="h1"></RichText>

          <ObjPushCustomFull pushContent={pushCustomFull} />

          <section className="section_resultat">
            <div className="str_container">
              {GetWinners && GetWinners.children &&  GetWinners.children.map((resultatItem, index) => (
                <div key={index} className={`ctn_resultat${resultatItem.resultGift.jss ? ' var_gift' : ''}`}>
                  <div className="ctn_top">
                    {resultatItem.resultGift.jss  &&
                      <figure>
                        <Image field={resultatItem.resultGift.jss}></Image>
                      </figure>
                    }
                    <div>
                    <RichText tag="h2"  field={resultatItem.resultTitle.jss} />
                    <RichText tag="p"  field={resultatItem.resultSubTitle.jss } /> 
                    </div>
                  </div>
                  <RichText tag="div" className="ctn_table"  field={resultatItem.resultWinner.jss  } /> 
                </div>
              ))}
            </div>
          </section>

      </>
		)
	}
}

export default withTranslation()(withSitecoreContext()(PageResultContest));