import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'; 
import IconSVG from "../../utilities/FrontFunctions/IconSVG"
import { isAppMobile } from "../../utilities/FrontFunctions/AoxFrontFunctions"
import { getGA4WindowDatas, trackGA4PageView, addGA4SessionData } from '../../utilities/FrontFunctions/AoxTrackingFunctions'

class MainHeaderLogin extends Component {
 
	constructor(props) {
		super(props)
  
		this.state = {
			isAppMobile: isAppMobile()			
		}
		this.bPushTrackPage_Done = false
	}
	componentDidMount() {
		const { GetBreadCrumb } = this.props.fields.data;
		getGA4WindowDatas(undefined, undefined, undefined, GetBreadCrumb).then((resultDatas) => { 	
			window.trackDatas = resultDatas;
			trackGA4PageView(window.trackDatas);
			addGA4SessionData(resultDatas);
		});
	}
	render() {

        if (this.state.isAppMobile)
		{
			return <></>
		}		

		const { PageContact } = this.props.fields.data;
		
		return (
			<>
				<header className="main_header-login">
					<div className="str_container">
						<h6 className="logo-caf">
							<a href="/">
								<img src={require("../../assets/images/v_logo-pa.svg")} alt={this.props.t('AltLogo')}/>
							</a>
						</h6>
						<nav className="second_nav-caf">
							<ul className="lvl_01">
								<li className="li_contact">
									{PageContact && 
										<a href={PageContact.url}>
											<IconSVG icon="email" width="21" height="17" />
										</a>
									}
								</li>
							</ul>
						</nav>
					</div>
				</header>
			</>
		)
		
	}
}

export default withTranslation()(MainHeaderLogin);