import { withSitecoreContext, Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'; 
import LazyLoad from 'react-lazyload'

class PageSegments extends Component {
	constructor(props) {
    super(props) 
    this.switchTab = this.switchTab.bind(this)
  }
  
	componentDidMount() {
    this.switchTab(0)
    window.dispatchEvent(new Event('scroll'))
  } 
  switchTab(index){
		var $listItem = document.querySelectorAll(".ctn_nav-tabs li"),
			$tabItem = document.querySelectorAll(".obj_tabs-list .ctn_row")

		$listItem.forEach(listItem => listItem.classList.remove("active"))
		$listItem[index].classList.add("active")
		$tabItem.forEach(tabItem => tabItem.classList.remove("active", "fade-in"))
    $tabItem[index].classList.add("active", "fade-in")
    
    window.dispatchEvent(new Event('scroll'))
	}
	render() {
    const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
    const {LstSegments, LstRoutines } = this.props.fields.data; 
		return (
			<> 
			{ oCurrentItemFields && <Text className="obj_title" field={oCurrentItemFields["Page Title"]} tag="h1"></Text> }
			<section className="section_page-liste">
				<div className="str_container">
        <div className="obj_tabs-list">

          <ul className="ctn_nav-tabs">
            <li><button type="button" onClick={() => this.switchTab(0)}>{this.props.t('Segment_Catalogue')}</button></li>
            <li><button type="button" onClick={() => this.switchTab(1)}>{this.props.t('Segment_Routines')}</button></li>
          </ul>

          <section className="ctn_row">
            <div className="ctn_sub-row">
              {LstSegments && LstSegments.children && LstSegments.children.map((catalogueItem, index) => (
                <article key={index} className="ctn_page-liste">
                  <a href={catalogueItem.url}>
                    <LazyLoad>
												<figure className="fade-in">
                          <Image field={catalogueItem.pageImage.jss}></Image>
                        </figure>
                    </LazyLoad>
                    <RichText tag="h3" field={catalogueItem.pageTitle.jss}></RichText>
                  </a>
                </article>
              ))}
            </div>
          </section>

          <section className="ctn_row">
            <div className="ctn_sub-row">
              {LstRoutines && LstRoutines.children && LstRoutines.children.map((routineItem, index) => (
                <article key={index} className="ctn_page-liste">
                  <a href={routineItem.url}>
                      <LazyLoad>
												<figure className="fade-in">
                          <Image field={routineItem.pageImage.jss}></Image>
                        </figure>
                      </LazyLoad>
                    <Text tag="h3" field={routineItem.pageTitlePush.jss}></Text>
                  </a>
                </article>
              ))}
            </div>
          </section>
          </div>
				</div>
			</section> 
			</>
		)
	}
}

export default withTranslation()(withSitecoreContext()(PageSegments));