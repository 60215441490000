import React, { Component } from 'react'
import { Animate } from 'react-move'

class AnimatedProgressProvider extends Component {
	state = {
		isAnimated: false
	}
	static defaultProps = {
		valueStart: 0,
		valueEnd: 100
	}
	componentDidMount() {
		this.setState({
			isAnimated: !this.state.isAnimated
		})
	}
	render() {
		return (
			<Animate
				start={() => ({
					value: this.props.valueStart
				})}
				update={() => ({
					value: [
						this.state.isAnimated ? this.props.valueEnd : this.props.valueStart
					]
				})}
			>
				{({ value }) => this.props.children(value)}
			</Animate>
		)
	}
}

export default AnimatedProgressProvider