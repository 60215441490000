import React, { Component } from 'react'
import Modal from 'react-modal'

const customStyles = {
	overlay : {
		zIndex			: '99999',
		backgroundColor	: 'rgba(0,0,0,0.6)'
	},
	content : {
		position	: 'fixed',
		top			: '50%',
		left		: '50%',
		right		: 'auto',
		bottom		: 'auto',
		transform	: 'translate(-50%, -50%)',
		outline		: '0'
	}
}

Modal.setAppElement('#root')

class DefaultModal2 extends Component {
	constructor() {
		super()
		this.state = {
			modalIsOpen: false
		}
		this.openModal = this.openModal.bind(this)
		this.closeModal = this.closeModal.bind(this)
		this.action = this.action.bind(this)
	}
	componentDidMount() {
		this.props.onRef(this)
	}
	componentWillUnmount() {
		this.props.onRef(null)
	}
	openModal() {
		this.setState({modalIsOpen: true})
	}
	closeModal() {
		this.setState({modalIsOpen: false})
	}
	action() {
		this.props.handleAction();
		this.closeModal();
	}

	render() {
		return (
			<div>
				<Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    className="obj_popin popin_small"
                    closeTimeoutMS={300}
                    contentLabel="Modal"
				>
					<button className="bt_close" onClick={this.closeModal}></button>
					<div className="ctn_popin">
						<p className="t_title">{this.props.titleText}</p>
						<p className="t_main">{this.props.mainText}</p>
                        <div className="ctn_cta">
                            <button className="obj_cta" type="button" onClick={() => this.action()}>{this.props.actionText}</button>
                        </div>
					</div>
				</Modal>
			</div>
		)
	}
}

export default DefaultModal2