import React, { Component } from 'react'
import Modal from 'react-modal'

const customStyles = {
	overlay : {
		zIndex			: '99999',
		backgroundColor	: 'rgba(0,0,0,0.6)'
	},
	content : {
		position	: 'fixed',
		top			: '50%',
		left		: '50%',
		right		: 'auto',
		bottom		: 'auto',
		transform	: 'translate(-50%, -50%)',
		outline		: '0'
	}
}

Modal.setAppElement('#root')

class CommentImageModal extends Component {
	constructor() {
		super()
		this.state = {
			modalIsOpen: false
		}
		this.openModal = this.openModal.bind(this)
		this.closeModal = this.closeModal.bind(this)
	}
	openModal() {
		this.setState({modalIsOpen: true})
	}
	closeModal() {
		this.setState({modalIsOpen: false})
	}
	render() {
		const { CommentImage } = this.props
		return (
			<>
				<button onClick={this.openModal} className="bt_comment-img" type="button">
					<img src={CommentImage} alt="" />
				</button>
				<Modal
					isOpen={this.state.modalIsOpen}
					onRequestClose={this.closeModal}
					style={customStyles}
					className="obj_popin"
					closeTimeoutMS={300}
					contentLabel="Modal"
				>
					<button className="bt_close" onClick={this.closeModal}></button>
					<div className="ctn_popin" style={{display:'flex', justifyContent:'center'}}>
						<img src={CommentImage} style={{maxWidth:'100%', maxHeight:'74vh'}} alt="" />
					</div>
				</Modal>
			</>
		)
	}
}

export default CommentImageModal