import React, { Component } from 'react' 
import Modal from 'react-modal'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { withTranslation } from 'react-i18next';
import config from '../../temp/config';
import { throttle } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions"
import IconSVG from '../../utilities/FrontFunctions/IconSVG'
import { Image, RichText } from '@sitecore-jss/sitecore-jss-react'; 

import BeforeAfterSlider from "./plugins/BeforeAfterSlider"
import Moment from 'react-moment';  
import axios from 'axios'; 

const customStyles = {
	overlay : {
		zIndex			: '99999',
		backgroundColor	: 'rgba(0,0,0,0.6)'
	},
	content : {
		position	: 'fixed',
		top			: '50%',
		left		: '50%',
		right		: 'auto',
		bottom		: 'auto',
		transform	: 'translate(-50%, -50%)',
		outline		: '0'
	}
}

Modal.setAppElement('#root')

class GalerieDetailModal extends Component {
	constructor() {
		super()
		this.state = {
			UrlCallLikes: `${config.sitecoreApiHost}/apiaox/Call/LikePost?sc_apikey=${config.sitecoreApiKey}`,  
			UrlSendReview: `${config.sitecoreApiHost}/apiaox/Call/AddPhotoContestReview?sc_apikey=${config.sitecoreApiKey}`, 
			modalIsOpen: false,
			serverError: false,
			formSubmitted: false,
			cguExpanded: false,
			ctnImageHeight: 'auto',  
		}
		this.openModal = this.openModal.bind(this)
		this.closeModal = this.closeModal.bind(this)
		this.handleButtonLike = this.handleButtonLike.bind(this)
		this.handleWindowResizeGalerieDetailModal = this.handleWindowResizeGalerieDetailModal.bind(this)
		this.throttleWindowResizeGalerieDetailModal = throttle(this.handleWindowResizeGalerieDetailModal, 100)
	}
	componentDidMount() {
		this.props.onRef(this)
		window.addEventListener('resize', this.throttleWindowResizeGalerieDetailModal)
	}
	componentWillUnmount() {
		this.props.onRef(null)
		window.removeEventListener('resize', this.throttleWindowResizeGalerieDetailModal)
	}
	openModal() {
		this.setState({
			modalIsOpen: true,
			formSubmitted: false, 
		})
	}
	closeModal() {
		this.setState({modalIsOpen: false})
	}
	toggleCGU(){
		this.setState({cguExpanded: !this.state.cguExpanded})
	}
	handleWindowResizeGalerieDetailModal(){
		if(window.innerWidth > 768 && this.ctnImage){
			this.setState({
				ctnImageHeight: this.ctnImage.querySelector('img').offsetHeight
			})
		}
	}
	handleButtonLike(idrefReview) {
		
		// submit
		this.setState({
			likeIsSubmitting: true
		})
 
		axios({ 
			url:this.state.UrlCallLikes,
			method: 'POST',
			data : {"idref": idrefReview},
			withCredentials: true, 
		  })
		.then(res => 
		 {	 										
			this.setState({
				likeIsSubmitting: false
			}) 
			
			if(res.data.Value){
				if(res.data.Value.CodeReturn > 0){
					document.getElementById("buttonlike").classList.add("is-checked")
				}else if(res.data.Value.CodeReturn < 0){
					document.getElementById("buttonlike").classList.remove("is-checked")
				}
				
				var oLstLikes = document.getElementsByClassName(`nbLikes_${ this.props.ReviewItem.idrefReview}`);
				for (var i = 0; i < oLstLikes.length; i++) {
					oLstLikes[i].innerHTML = res.data.Value.NewValue;
  				}
			}
		})
		.catch(err => {
			this.setState({
				likeIsSubmitting: false
			})
		}); 
	} 
	
	 
	render() {
		return (
			<div>
				<Modal
					isOpen={this.state.modalIsOpen}
					onRequestClose={this.closeModal}
					style={customStyles}
					className="obj_popin popin_galerie-comment"
					closeTimeoutMS={300}
					contentLabel="Modal"
				>
					<button className="bt_close" onClick={this.closeModal}></button>
					<div className="ctn_popin">
						
						<div className="ctn_image" ref={ref => (this.ctnImage = ref)}>
							{this.props.soloMode ?
								<div className="ctn_solo-img"> 
									<Image onLoad={this.handleWindowResizeGalerieDetailModal} 
									 width="560" height="560"
									 field={this.props.ReviewItem && this.props.ReviewItem.image.jss}
									 imageParams={{ w: 560, h: 560 }}
									></Image>
								</div>
								:
								<BeforeAfterSlider
									imageBefore={this.props.ReviewItem && this.props.ReviewItem.image}
									imageAfter={this.props.ReviewItem && this.props.ReviewItem.image2}
									onImageLoaded={() => this.handleWindowResizeGalerieDetailModal}
								/>
							}
						</div>

						<div className="ctn_text" style={{height:this.state.ctnImageHeight}}>

							<div className="ctn_scroll">
								<div className="ctn_top"> 									 
									<div className="ctn_avatar">
										<figure className="ctn_img">
											{this.props.ReviewItem.reviewsFOU.userimg ?
												<img src={this.props.ReviewItem.reviewsFOU.userimg} alt={this.props.ReviewItem.reviewsFOU.firstname + " " + this.props.ReviewItem.reviewsFOU.lastname}/>
												:
												<img src={require("../../assets/images/v_avatar.png")} 
													alt={this.props.ReviewItem.reviewsFOU.firstname + " " + this.props.ReviewItem.reviewsFOU.lastname}/>
											}
										</figure>
										<div className="ctn_txt">
											<p className="t_author">{this.props.ReviewItem.reviewsFOU.firstname + " " + this.props.ReviewItem.reviewsFOU.lastname}</p>
											<p className="t_date">{this.props.t('ArticleLe')} <Moment format={this.props.t('FormatDate')}>{this.props.ReviewItem.creationDate.dateValue}</Moment></p>
										</div>
									</div>
									 
									<ul className="obj_likes-comments">
										<li> 
											<button id="buttonlike" className={`bt_like-article${ this.props.ReviewItem.reviewsFOU.likeit  ? ' is-checked' : ''}`} 
													type="button" onClick={() => this.handleButtonLike(this.props.ReviewItem.idrefReview)} 
													disabled={this.state.likeIsSubmitting ? 'disabled' : ''}>
												<IconSVG className="i_heart" icon="heartstroke" width="24" height="20" />
												<IconSVG className="i_heart-hover" icon="heart" width="24" height="20" />
												<span className={`nbLikes_${ this.props.ReviewItem.idrefReview}`}>{this.props.ReviewItem.likes ? this.props.ReviewItem.likes.numberValue ? this.props.ReviewItem.likes.numberValue : 0  : 0}</span> {this.props.t('ArticleLike')}
											</button>											
										</li>
										<li>
											<IconSVG icon="dialog" width="25" height="20" />
											{this.props.ReviewItem.responseList && this.props.ReviewItem.responseList.length} {this.props.ReviewItem.responseList && this.props.ReviewItem.responseList.length > 1 ? this.props.t('ArticleCommentaires') : this.props.t('ArticleCommentaire') }
										</li>
									</ul>
								</div>
								<div className="ctn_comments">
									{this.props.ReviewItem.responseList && this.props.ReviewItem.responseList.map((commentItem, index) => (
										<div key={index} className="ctn_comment">
											<div className="ctn_avatar">
												<figure className="ctn_img">
													{commentItem.reviewsFOU.userimg ?
														<img src={commentItem.reviewsFOU.userimg} alt={commentItem.reviewsFOU.firstname + " " + commentItem.reviewsFOU.lastname}/>
														:
														<img src={require("../../assets/images/v_avatar.png")} 
															alt={commentItem.reviewsFOU.firstname + " " + commentItem.reviewsFOU.lastname}/>
													}
												</figure>
												<div className="ctn_txt">
													<p className="t_author">{commentItem.reviewsFOU.firstname + " " + commentItem.reviewsFOU.lastname}</p>
													<p className="t_date">{this.props.t('ArticleLe')} <Moment format={this.props.t('FormatDate')}>{commentItem.creationDate.dateValue}</Moment></p>
												</div> 
											</div>
											<RichText field={commentItem.comment.jss} className="t_comment" tag="p"></RichText>
										</div>
									))}
								</div>
							</div>

							{this.state.formSubmitted ?
								<p className="t_submitted fade-in">{this.props.t('ArticleVotreMessageSeraPublieApresModeration')}</p>
								:
								<Formik
									initialValues={{
										comment: '',
									}}
									validationSchema={
										Yup.object().shape({
											comment: Yup.string().required()
										})
									}
									onSubmit={(values, actions) => {
										document.body.classList.add('is-loading')

										var oFormData = new FormData();	
										Object.entries(values).forEach(elt => oFormData.append(elt[0], elt[1]));
										oFormData.append("idref", document.getElementById("idref").value);
										oFormData.append("idrefReview", this.props.ReviewItem.idrefReview);
										axios({ 
											url:this.state.UrlSendReview,
											headers:  {
												'Accept': 'application/json',
												'Content-Type': 'multipart/form-data'
											},
											method: 'POST',
											data : oFormData,
											withCredentials: true, 
										}) 
										.then(res => {		
												
											window.location.reload(false);
											
												actions.resetForm()
												//this.MainSingleFileUpload.destroy()
												//[`mainfileupload${index}`].current.destroy()
												this.addNewComment(values)
											document.body.classList.remove('is-loading')
										})
										.catch(err => {
											this.setState({serverError: true})
											document.body.classList.remove('is-loading')
										}); 
									}}
								>
									{ ({ handleSubmit, handleBlur, setFieldValue, setFieldTouched, errors, touched, values }) => (
										<form onSubmit={handleSubmit}>
											<div className={`obj_form${this.state.cguExpanded ? ' is-expanded' : ''}`}>
												<div className="ctn_textarea mb_5 no-border">
													<Field className={errors.comment && touched.comment && 'error'} as="textarea" name="comment" placeholder={this.props.t('VotreCommentaire')} />
												</div> 
												{this.state.serverError && <p className="t_error fade-in">{this.props.t('DefaultServerError')}</p>}
												{( (errors.comment && touched.comment) ) && <p className="t_error fade-in">{this.props.t('DefaultErrorMessage')}{console.log(errors)} </p>}
												<div className="ctn_cta">
													<button className="obj_cta reverse" type="submit">{this.props.t('ArticleReviewPublier')}</button>
												</div>
												<button className="obj_cta-txt" type="button" onClick={() => this.toggleCGU()}><span>{this.props.t('ArticleAfficherCGU')}</span> {this.props.t('ArticleAfficherCGUTitre')}</button>
												<div className="ctn_txt-mentions" dangerouslySetInnerHTML={{__html: this.props.t('ArticleConsent_ContestPhotoCommentaire')}}></div>
											</div>
										</form>
									)}
								</Formik>
							}
							
						</div>

					</div>
				</Modal>
			</div>
		)
	}
}

export default withTranslation()(GalerieDetailModal)