import React from 'react';
import { RichText, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';

class ObjBigTitle extends React.Component {
  render() {	
    const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
    
    if (oCurrentItemFields == null)
    {
      return <></>
    }

		return (
      <>
        { this.props && this.props.fields && this.props.fields["Push Title"] ? 
          <RichText className="obj_title-big" field={this.props.fields["Push Title"]} tag="h1"/>
          :
          <RichText className="obj_title-big" field={oCurrentItemFields["Page Title"]} tag="h1"/>
          }
      </>
    )
  }
}

export default withSitecoreContext()(ObjBigTitle);
