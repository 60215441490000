import React from "react";
import { withTranslation } from "react-i18next";
import { withSitecoreContext, RichText } from "@sitecore-jss/sitecore-jss-react";
import queryString from "query-string";
import config from "../../temp/config";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Redirect } from "react-router-dom";
import { ReCaptcha, loadReCaptcha  } from "react-recaptcha-v3";

import PasswordToggle from "../../utilities/FrontFunctions/FormElements/PasswordToggle";
import IconSVG from "../../utilities/FrontFunctions/IconSVG";
import { GetLogoList } from "../../utilities/FrontFunctions/AoxFrontFunctions";
import LoginCodeModal from "../Modal/LoginCodeModal";
import DefaultModal from '../Modal/DefaultModal'
import { postApiDatas } from "../../utilities/FrontFunctions/AoxFrontFunctions"
import { getGA4WindowDatas, trackGA4PageView, trackGA4Event, addGA4SessionData } from "../../utilities/FrontFunctions/AoxTrackingFunctions";

class Login extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			UrlRegister_Step1: `${config.sitecoreApiHost}/apiaox/Call/RegisterForm_Step1`,
			encValueParam: "",
			serversuccessConnection: false,
			serverRedirect: "",
			serverErrorConnection: false,
			serverErrorConnectionLabel: "",
			serversuccessRegistration: false,
			serverErrorRegistration: false,
			serverErrorMessageRegistration: "",
			logoList: GetLogoList(),
			isRecaptchaLoaded: false,
			errorMessage: "",
		};
		this.bPushTrackPage_Done = false;
	}
	componentDidMount() {
		document.body.classList.add('is-loading');
		loadReCaptcha(this.props.t("recaptcha_sitekey"));
		//page sans header mais avec tracking => init nécessaire de window.trackDatas et appel de trackGA4PageView
		getGA4WindowDatas(undefined, undefined, undefined, undefined).then((resultDatas) => {
			if (resultDatas) {
				resultDatas.pageCategory = "authentication";
				resultDatas.category = "identification";
				resultDatas.breadcrumb = "login";
				resultDatas.screen_name = "login";
				window.trackDatas = resultDatas;
				addGA4SessionData(resultDatas);
				//console.log("GA4Window before [done:" + this.bPushTrackPage_Done + "]");
				if (!this.bPushTrackPage_Done) {
					trackGA4PageView(window.trackDatas).then((resultTracked) => {
						this.bPushTrackPage_Done = resultTracked;
						//console.log("GA4Window after [done:" + this.bPushTrackPage_Done + "][result:" + resultTracked + "]");
					});
				}
			}
		});
		this.timerCtrlRecaptcha = setTimeout(() => this.checkRecaptcha(), 15000);
	}
	componentWillUnmount() {
		clearTimeout(this.timerCtrlRecaptcha);
	  }

	checkRecaptcha = () => {
		if (!this.state.isRecaptchaLoaded && document.body.classList.contains("is-loading")) {
			document.body.classList.remove("is-loading");
			this.setState({
				...this.state,
				errorMessage: 'Un problème de réseau a été détecté',
			})
		}
	};

	onSubmit = (values, actions) => {
		//console.log('onSubmit');
		//console.log(values);
		//console.log(actions);
		document.body.classList.add("is-loading");
		var oParam = queryString.parse(window.location.search);

		postApiDatas(`/apiaox/Call/Login`, {
			email: values.email,
			password: values.password,
			recaptcha: values.recaptcha,
			EncValue: oParam.encvalue,
		}).then((res) => {
			console.log(res);
			this.setState({
				serverErrorConnection: !res.IsValid,
				serverErrorConnectionLabel: res.Message,
			});

			if(res.IsValid){
				trackGA4Event(window.trackDatas, "login", "log in", "regular", "valider");
				this.setState({
					serversuccessConnection: true,
					serverRedirect: res.GoTo,
				});
			} else if (res.GoTo === "ErrorOldUser") {
				this.ModalOldUser.openModal();
			} else {
				this.setState({
					serversuccessConnection: false,
					serverRedirect: "",
				});
			}

			if (this.recaptchaConnection) {
				this.recaptchaConnection.execute();
			}

			document.body.classList.remove("is-loading");
		});
	}

	render() {
		const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
		const { PageContact, PageCAF, PageInscription, PageRequestPassword } = this.props.fields.data;

		if (this.state.serversuccessConnection) {
			if (this.state.serverRedirect === "") {
				return <Redirect to={PageCAF != null && PageCAF.parameterValue.value} />;
			} else {
				if (this.state.serverRedirect.startsWith("/formationcaf")) {
					window.location.href = this.state.serverRedirect;
					return true;
				} else {
					return <Redirect to={this.state.serverRedirect} />;
				}
			}
		}

		if (this.state.serversuccessRegister) {
			return <Redirect to={PageCAF != null && PageCAF.url} />;
		}

		if (this.state.serversuccessRegistration) {
			return <Redirect to={(PageInscription != null && this.state.code_client) && `${PageInscription.url}?code_client=${this.state.code_client}`} />;
		}

		return (
			<section className="section_login">
				<div className="str_container">
					<header>
						<h6 className="logo-caf">
							<img src={require("../../assets/images/v_logo-pa.svg")} alt={this.props.t("AltLogo")} />
						</h6>
						{oCurrentItemFields && <RichText field={oCurrentItemFields["Page Title"]} tag="h1" />}
					</header>

					<div className="ctn_login">
						<div className="ctn_col col_left">
							<h2>{this.props.t("Connexion_Title")}</h2>
							<div className="obj_form">
                                {/* TODO SGU : validateOnChange false sur autres forms ? */}
								<Formik
									
									initialValues={{
										email: "",
										password: "",
										recaptcha: "",
									}}
									validationSchema={Yup.object().shape({
										email: Yup.string().email().required(),
										password: Yup.string().required(),
										recaptcha: Yup.string().required(),
									})}
                                    validateOnChange={false}    
                                    validateOnBlur={false}
									onSubmit={this.onSubmit}
								>
									{({ handleSubmit, handleBlur, setFieldValue, errors, touched }) => (
										<form onSubmit={handleSubmit} name="LoginForm">
											<div className="ctn_input mb_30">
												<Field
													className={errors.email && "error"}
													as="input"
													type="text"
													name="email"
													placeholder={this.props.t("Connexion_emailPlaceholder")}
													autoComplete="off"
												/>
											</div>
											<div className="ctn_input mb_15">
												<PasswordToggle
													setFieldValue={setFieldValue}
													handleBlur={handleBlur}
													errors={errors}
													touched={touched}
													fieldName="password"
													eyeMode={true}
													placeholder={this.props.t("Connexion_passwordPlaceholder")}
												/>
											</div>
											<p className="t_mandatory mb_30">{this.props.t("Connexion_mandatoryText")}</p>
											<ReCaptcha
												ref={(ref) => (this.recaptchaConnection = ref)}
												sitekey={this.props.t("recaptcha_sitekey")}
												action="login"
												verifyCallback={(recaptchaToken) => {
										
													//console.log('recaptcha loaded');
													document.body.classList.remove('is-loading');
													if (recaptchaToken.length > 0) {
														this.setState({
															...this.state, 
															isRecaptchaLoaded: true, 
															errorMessage: ''
														});
														setFieldValue("recaptcha", recaptchaToken, false);
													}
													
												}}
											/>
											{(errors.email || errors.password) && (
												<p className="t_error mb_20 fade-in">
													{this.props.t("Connexion_errorMessage")}
													{console.log(errors)}
												</p>
											)}
											{(errors.recaptcha) && (
												<p className="t_error mb_20 fade-in">
													Le recaptcha n'a pas été initialisé correctement
													{console.log('recapcha', errors)}
												</p>
											)}
											{(this.state.errorMessage) && (
												<p className="t_error mb_20 fade-in">
													{this.state.errorMessage}
								
												</p>
											)}


											{this.state.serverErrorConnection && (
												<p className="t_error mb_20 fade-in">
													{this.state.serverErrorConnectionLabel !== "" ? this.state.serverErrorConnectionLabel : this.props.t("Connexion_Erreur")}
												</p>
											)}
											<div className="ctn_cta mb_20">
												<button name="submit_loginform" className="obj_cta" type="submit">
													{this.props.t("Connexion_submitText")}
												</button>
											</div>
											{PageRequestPassword && (
												<p className="ctn_cta">
													<a className="obj_cta-txt" href={PageRequestPassword.url}>
														{this.props.t("Connexion_forgotPasswordText")}
													</a>
												</p>
											)}
										</form>
									)}
								</Formik>

								<DefaultModal
									onRef={(ref) => (this.ModalOldUser = ref)}
									htmlContent={
										<>
											<p className="t_main">Vous êtes un.e utilisateur.trice des anciennes plateformes challenge et/ou inscription formation, veuillez renouveler votre mot de passe</p>
											<div className="ctn_cta">
												<button className="obj_cta" type="button" onClick={() => (window.location.href = PageRequestPassword.url)}>
                                                    renouveler
												</button>
											</div>
										</>
									}
								/>
							</div>
						</div>

						<div className="ctn_col col_right">
							<h2>{this.props.t("Inscription_title")}</h2>
							<div className="obj_form">
								<Formik
									initialValues={{
										code_client: "",
									}}
									validationSchema={Yup.object().shape({
										code_client: Yup.number().required(),
									})}
									onSubmit={(values, actions) => {
										document.body.classList.add("is-loading");

										fetch(this.state.UrlRegister_Step1, {
											headers: {
												Accept: "application/json",
												"Content-Type": "application/x-www-form-urlencoded",
											},
											method: "POST",
											body: queryString.stringify({
												code_client: values.code_client,
											}),
										})
											.then((res) => res.json())
											.then((res) => {
												if (res.IsValid) {
													trackGA4Event(window.trackDatas, "account_registration", "register", "account::signup::regular", "valider");
												}

												this.setState({
													serversuccessRegistration: res.IsValid,
													serverErrorRegistration: !res.IsValid,
													serverErrorMessageRegistration: res.ExceptionMessage,
													code_client: values?.code_client
												});

												document.body.classList.remove("is-loading");
											})
											.catch((err) => {
												console.log(err);
												this.setState({
													serverErrorRegistration: true,
													serverErrorMessageRegistration: "Server error",
												});

												document.body.classList.remove("is-loading");
											});
									}}
								>
									{({ handleSubmit, errors, touched }) => (
										<form onSubmit={handleSubmit}>
											<div className="ctn_input mb_15">
												<Field
													autoComplete="off"
													className={errors.code_client && touched.code_client && "error"}
													as="input"
													type="text"
													name="code_client"
													placeholder={this.props.t("Inscription_codePlaceholder")}
													maxLength={15}
												/>
											</div>
											<p className="t_mandatory mb_30">{this.props.t("Inscription_mandatoryText")}</p>
											{errors.code_client && touched.code_client && (
												<p className="t_error mb_20 fade-in">
													{this.props.t("Connexion_errorMessage")}
													{console.log(errors)}
												</p>
											)}
											{this.state.serverErrorRegistration && <p className="t_error mb_20 fade-in">{this.state.serverErrorMessageRegistration}</p>}
											<div className="ctn_cta mb_20">
												<button className="obj_cta" type="submit">
													{this.props.t("Inscription_submitText")}
												</button>
											</div>
											<p className="ctn_cta mb_15">
												<button className="obj_cta-txt" type="button" onClick={() => this.LoginCodeModal.openModal()}>
													{this.props.t("Inscription_findCodeText")}
												</button>
											</p>
											<LoginCodeModal onRef={(ref) => (this.LoginCodeModal = ref)} modalText={this.props.t("Inscription_modalText")} />
										</form>
									)}
								</Formik>
							</div>

							<h2>{this.props.t("Connexion_Contact_Title")}</h2>
							<div className="ctn_cta">
								<a className="obj_cta var_picto var_email" href={PageContact != null && PageContact.url}>
									<IconSVG icon="email" width="21" height="17" />
									{this.props.t("Connexion_Contact_submitText")}
								</a>
							</div>
						</div>
					</div>

					<ul className="ctn_logos">
						{this.state.logoList.map((logoItem, index) => (
							<li key={index} className={"var_" + logoItem.id}>
								<img src={logoItem.image} alt={logoItem.label} />
							</li>
						))}
					</ul>
				</div>
			</section>
		);
	}
}

export default withTranslation()(withSitecoreContext()(Login));