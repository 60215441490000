import React, { Component } from 'react'
import SingleFileUploadModal from './SingleFileUploadModal'
import { isImage, fileReader } from '../../../utilities/FrontFunctions/AoxGlobalFrontFunctions'

class SingleFileUpload extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isHover: false,
			isLoading: false,
			thumb: undefined,
			thumbName: undefined,
			thumbWeight: undefined,
			thumbType: undefined,
			file: false,
			defaultThumb: this.props.defaultThumb ? this.props.defaultThumb : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAIAAAABc2X6AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ4IDc5LjE2NDAzNiwgMjAxOS8wOC8xMy0wMTowNjo1NyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NjgyQUM2Nzc0MTE3MTFFQUFBNjlGNjU4NEI3Q0RBNzciIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NjgyQUM2NzY0MTE3MTFFQUFBNjlGNjU4NEI3Q0RBNzciIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjAgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OUY0NERBRTkzNzk3MTFFQTkyMjg4ODE0OEM2M0JFRjciIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OUY0NERBRUEzNzk3MTFFQTkyMjg4ODE0OEM2M0JFRjciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4VzaHsAAAB0ElEQVR42uybzapBURSAuY5EIUkxNJCxO1KUMvMOBp6AIZ5E5spPHoJSTCTl7zUI1ylhZ0/u4A5u5x/fGq0zWed8rbW/vU+d497v965Pii/XhwXAAAMMMMAAAwwwwAADDPC/QtFfYjKZ9Hq97XZ7uVyseehEIjEcDm0AVlW1Wq0KWou7pOcdXtdI12o162ltG+npdNrtdmWeTqcrlUo4HDb1WZfLZbvdtg14MBjIJJVKrdfr6/VqbmcUpd/v2wm82+1kUi6XBa1Yz2++LR0OB5nEYjH2YYDf6eDxOzabzXg8NrBgNpvNZDLOBR6NRs1m08CCjUbDWGBGWvcE1ut1AwsWCgVHA38/A2khLaSFtJAW0kJaSAtpIS2khbSQFtJCWkgLaSEtpIW0kBbSQlpIC2khLaSFtJwvrVca6fv97pQOezwen89nKq24xWw2k3k8HrcZ+Hw+m0qrqmqn02m1WvKyWCxqLuXW/GVqLpdbrVYi8fv9Xq/XVODj8Xi73WQeiUTm83k0GrWtwz/PsGYZBwIB0WrNtK8kLUVRSqWSONjk83k9dbSP9GKxOJ1O1tAGg8FkMhkKhfSXcvOHOMAAAwwwwAADDDDAAAMM8B/xEGAA9PriNpghZZ4AAAAASUVORK5CYII=',
			thumbSize: this.props.thumbSize ? this.props.thumbSize : 80,
			maxSize: this.props.maxSize ? this.props.maxSize : 10000000,
			sizeError: false,
			sizeErrorText: this.props.sizeErrorText ? this.props.sizeErrorText : 'Your file must be under',
			formatTypes: this.props.formatTypes ? this.props.formatTypes : ['image/jpeg', 'image/png', 'image/gif'],
			formatError: false,
			formatErrorText: this.props.formatErrorText ? this.props.formatErrorText : 'Only .jpg, .png, and .gif files.',
			loadingError: false,
			loadingErrorText: this.props.loadingErrorText ? this.props.loadingErrorText : 'Something went wrong...',
			labelText: this.props.labelText ? this.props.labelText : 'Choose a file',
			ctaCancelText: this.props.ctaCancelText ? this.props.ctaCancelText : 'Cancel',
			timeOutSimulation: this.props.timeOutSimulation ? this.props.timeOutSimulation : false
		}
		this.handleFileChange = this.handleFileChange.bind(this)
		this.handleFileReset = this.handleFileReset.bind(this)
		this.destroy = this.destroy.bind(this)
		this.drawThumb = this.drawThumb.bind(this)
		this.handleCanvas = this.handleCanvas.bind(this)
		this.handleMouseEnterSingleFileUpload = this.handleMouseEnterSingleFileUpload.bind(this)
		this.handleMouseLeaveSingleFileUpload = this.handleMouseLeaveSingleFileUpload.bind(this)
		this.canvasRef = React.createRef()
		this.inputRef = React.createRef()
	}
	componentDidMount() {
		this.props.onRef(this)
	}
	componentWillUnmount() {
		this.props.onRef(null)
	}
	drawThumb() {
		let canvas = this.canvasRef.current
		let ctx = canvas.getContext('2d')
		let image = new Image()
		if( this.state.thumbType === 'image/jpeg' || this.state.thumbType === 'image/png' || this.state.thumbType === 'image/gif' ){
			image.src = this.state.thumb
		} else {
			image.src = this.state.defaultThumb
		}
		image.onload = () => {
			let srcMinSize = Math.min(image.width, image.height)
			let srcPosX = (image.width / 2) - (srcMinSize / 2)
			let srcPosY = (image.height / 2) - (srcMinSize / 2)
			ctx.drawImage(image, srcPosX, srcPosY, srcMinSize, srcMinSize, 0, 0, this.state.thumbSize, this.state.thumbSize)
		}
	}
	handleFileChange(e, fieldName, setFieldValue) {
		let currentFile = e.currentTarget.files[0]
		if(currentFile === undefined) return
		this.setState(state => ({
			isLoading: true,
			thumb: undefined,
			thumbName: undefined,
			thumbWeight: undefined,
			thumbType: undefined,
			file: false,
			sizeError: false,
			formatError: false
		}), () => {
			this.props.onUnload()
		})
		fileReader(currentFile)
			.then(result => {
				setTimeout(() => {
					if( !this.state.formatTypes.includes(currentFile.type) ){
						this.inputRef.current.value  = ''
						setFieldValue(fieldName, null)
						this.setState({
							isLoading: false,
							formatError: true
						})
					}
					else if( currentFile.size > this.state.maxSize ){
						this.inputRef.current.value  = ''
						setFieldValue(fieldName, null)
						this.setState({
							isLoading: false,
							sizeError: true
						})
					}
					else{
						this.setState(state => ({
							isLoading: false,
							thumb: result,
							thumbName: currentFile.name,
							thumbWeight: currentFile.size,
							thumbType: currentFile.type,
							file: true
						}), () => {
							setFieldValue(fieldName, currentFile)
							this.drawThumb()
							this.props.onUpload()
						})
					}
				}, this.state.timeOutSimulation ? 1000 : 0 )
			})
			.catch(error => {
				this.inputRef.current.value  = ''
				setFieldValue(fieldName, null)
				this.setState({
					isLoading: false,
					loadingError: true
				})
			})
	}
	destroy() {
		this.inputRef.current.value  = ''
		this.setState(state => ({
			thumb: undefined,
			thumbName: undefined,
			thumbWeight: undefined,
			thumbType: undefined,
			file: false
		}), () => {
			this.props.onUnload()
		})
	}
	handleFileReset(e, fieldName, setFieldValue, setFieldTouched) {
		setFieldValue(fieldName, null)
		setFieldTouched(fieldName, false)
		this.destroy()
	}
	handleCanvas(){
		isImage(this.state.thumbType) && this.SingleFileUploadModal.openModal()
	}
	handleMouseEnterSingleFileUpload() {
		this.setState({
			isHover: true
		})
	}
	handleMouseLeaveSingleFileUpload() {
		this.setState({
			isHover: false
		})
	}
	render() {		
		const { setFieldValue, setFieldTouched, errors, touched, fieldName } = this.props
		const { isLoading, thumbType, thumbName, thumbSize, thumbWeight, file, maxSize, sizeError, sizeErrorText, formatError, formatErrorText, loadingError, loadingErrorText, labelText, ctaCancelText } = this.state
		return(
			<div className={`ctn_single-file-upload${this.state.isHover ? ' hover' : ''}`}>
				<div className={errors[fieldName] && touched[fieldName] ? 'error' : ''}>
					<div className="ctn_cta" onMouseEnter={this.handleMouseEnterSingleFileUpload} onMouseLeave={this.handleMouseLeaveSingleFileUpload}>
						<label className="obj_cta" htmlFor={fieldName}>{labelText}</label>
					</div>
				</div>
				<input
					ref={this.inputRef}
					type="file"
					id={fieldName}
					name={fieldName}
					onChange={(e) => this.handleFileChange(e, fieldName, setFieldValue)}
				/>
				{loadingError && <p className="t_error fade-in">{loadingErrorText}</p>}
				{formatError && <p className="t_error fade-in">{formatErrorText}</p>}
				{sizeError && <p className="t_error fade-in">{sizeErrorText} {maxSize / 1000000}Mo.</p>}
				{isLoading && <div className="obj_loader fade-in"></div>}
				{file &&
					<div>
						<div className="ctn_file-thumb fade-in">
							<canvas className={`canvas_thumb${isImage(thumbType) ? '' : ' no_cursor'}`} onClick={this.handleCanvas} ref={this.canvasRef} width={thumbSize} height={thumbSize}></canvas>
							<div className="ctn_infos">
								<p className="t_name">{thumbName}</p>
								<p className="t_weight">{(Math.round(thumbWeight / 10000)) / 100}Mo</p>
								<button className="bt_cancel" type="button" onClick={(e) => this.handleFileReset(e, fieldName, setFieldValue, setFieldTouched)}>{ctaCancelText}</button>
							</div>
						</div>
						{isImage(thumbType) &&
							<SingleFileUploadModal onRef={ref => (this.SingleFileUploadModal = ref)} previewThumb={this.state.thumb} previewThumbName={this.state.thumbName}/>
						}
					</div>
				}
			</div>
		)
	}
}

SingleFileUpload.defaultProps = {
	onUpload: () => {},
	onUnload: () => {},
	onRef: () => {}
}

export default SingleFileUpload