import React, { Component } from 'react'
import Modal from 'react-modal'
import { Link } from 'react-router-dom'

import { isAppMobile } from "../../utilities/FrontFunctions/AoxFrontFunctions"

const customStyles = {
	overlay : {
		zIndex			: '99999',
		backgroundColor	: 'rgba(0,0,0,0.6)'
	},
	content : {
		position	: 'fixed',
		top			: '50%',
		left		: '50%',
		right		: 'auto',
		bottom		: 'auto',
		transform	: 'translate(-50%, -50%)',
		outline		: '0'
	}
}

Modal.setAppElement('#root')

class ModalMAJFormationsFav extends Component {
	constructor() {
		super()
		this.state = {
			modalIsOpen: false,
			isAppMobile: isAppMobile()			
		}
		this.openModal = this.openModal.bind(this)
		this.closeModal = this.closeModal.bind(this)
	}
	componentDidMount() {
		this.props.onRef(this)
	}
	componentWillUnmount() {
		this.props.onRef(null)
	}
	openModal() {
		this.setState({modalIsOpen: true})
	}
	closeModal() {
		this.setState({modalIsOpen: false})
	}
	handleOKButtonClick = () => {
		this.closeModal()
	}

	render() {
		return (
			<div>
				<Modal
				isOpen={this.state.modalIsOpen}
				onRequestClose={this.closeModal}
				style={customStyles}
				className="obj_popin popin_small"
				closeTimeoutMS={300}
				contentLabel="Modal"
				>
					<button className="bt_close" onClick={this.closeModal}></button>
					<div className="ctn_popin">
						<p className="t_title">{this.props.modalTitle}</p>
						<p className="t_main">{this.props.modalText}</p>
						
						<div className="ctn_checkbox">
							<input type="checkbox" id="dontShowAgain" onChange={() => localStorage.setItem('dontShowAgainModalMAJFormationsFav', 'true')}/>
							<label htmlFor="dontShowAgain">Ne plus afficher ce message</label>
						</div>

						<p className="ctn_cta">
                            {!this.state.isAppMobile && 
                                <Link className='obj_btn' to={'/Accueil/Mes-formations-favorites'}>
                                    {this.props.modalCtaText}
                                </Link>
                            }
							<button className="obj_btn light" onClick={this.closeModal}>
								Fermer
							</button>
						</p>
					</div>
				</Modal>
			</div>
		)
	}
}

export default ModalMAJFormationsFav