import React, { Component } from 'react'
import Modal from 'react-modal'

import { throttle } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions"
import IconSVG from "../../utilities/FrontFunctions/IconSVG"

import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next';

const customStyles = {
	overlay : {
		zIndex			: '99999',
		backgroundColor	: 'rgba(0,0,0,0.6)'
	},
	content : {
		position	: 'fixed',
		top			: '50%',
		left		: '50%',
		right		: 'auto',
		bottom		: 'auto',
		transform	: 'translate(-50%, -50%)',
		outline		: '0'
	}
}

Modal.setAppElement('#root')

class FacebookPostModal extends Component {
	constructor() {
		super()
		this.state = {
			modalIsOpen: false,
			ctnImageHeight: 'auto'
		}
		this.openModal = this.openModal.bind(this)
		this.closeModal = this.closeModal.bind(this)
		this.handleWindowResizeFacebookPostModal = this.handleWindowResizeFacebookPostModal.bind(this)
		this.throttleWindowResizeFacebookPostModal = throttle(this.handleWindowResizeFacebookPostModal, 100)
		this.handleCopyText = this.handleCopyText.bind(this)
		this.handleCopyImage = this.handleCopyImage.bind(this)
	}
	componentDidMount() {
		this.props.onRef(this)
		window.addEventListener('resize', this.throttleWindowResizeFacebookPostModal)
	}
	componentWillUnmount() {
		this.props.onRef(null)
		window.removeEventListener('resize', this.throttleWindowResizeFacebookPostModal)
	}
	openModal() {
		this.setState({
			modalIsOpen: true
		})
	}
	closeModal() {
		this.setState({modalIsOpen: false})
	}

	handleWindowResizeFacebookPostModal(){
		if(window.innerWidth > 768 && this.ctnImage){
			this.setState({
				ctnImageHeight: this.ctnImage.querySelector('img').offsetHeight
			})
		}
	}
	
	handleCopyImage() {
		async function screenShot(image) {
		  try {
			const response = await fetch(
				image
			);
			const blob = await response.blob();

			await navigator.clipboard.write([
			  new window.ClipboardItem({ "image/png": blob }),
			]);
		  } catch (err) {
			console.error(err);
		  }
		}
		
		screenShot(this.props.articleItem.pOSTImagePopin.src);
	  }

	handleCopyText() {
		navigator.clipboard.writeText(this.props.articleItem.pOSTTitle.jss.value + "\n" + this.props.articleItem.pOSTTextLong.jss.value);	
	}	

	render() {

		return (
			<div>
				<Modal
					isOpen={this.state.modalIsOpen}
					onRequestClose={this.closeModal}
					style={customStyles}
					className="obj_popin popin_post-fb"
					closeTimeoutMS={300}
					contentLabel="Modal"
				>
					<button className="bt_close" onClick={this.closeModal}></button>
					<div className="ctn_popin">
						
						<div className="ctn_image" ref={ref => (this.ctnImage = ref)}>
							<div className="ctn_solo-img">
								<img onLoad={this.handleWindowResizeFacebookPostModal} src={this.props.articleItem.pOSTImagePopin.src} alt={this.props.articleItem.pOSTImagePopin.alt}/>
							</div>
						</div>

						<div className="ctn_text" style={{height:this.state.ctnImageHeight}}>	

							<div className="ctn_scroll">

								<div className="ctn_post-fb">
									<div className="ctn_top">

										<Text className="t_title" tag="p" field={this.props.articleItem.pOSTTitle.jss}></Text>
										
										<ul className="obj_likes-fb">
											<li>
												<IconSVG icon="eye" width="20" height="13" />
												{(this.props.articleItem.numberOfViews.value) ? this.props.articleItem.numberOfViews.value : 0} {this.props.t('FacebookViews')}
											</li>
											<li>
												<IconSVG icon="share" width="20" height="17" />
												{(this.props.articleItem.numberOfShares.value) ? this.props.articleItem.numberOfShares.value : 0} {this.props.t('FacebookShares')} 
											</li>
										</ul>
	
									</div>
									<div className="ctn_txt">
										<Text className="t_desc" tag="p" field={this.props.articleItem.pOSTTextLong.jss}></Text>
									</div>
								</div>
							</div>
							<div className="ctn_bottom">
								<div className="ctn_txt-partage">
									<ol>
										<li>Cliquez sur <strong>PARTAGER</strong></li>
										<li>{this.props.t('FacebookConnect')}</li>
									</ol>
								</div>
								<div class="ctn_cta">
									<button class="obj_cta" onClick={this.handleCopyImage}>Copier Image</button>
									<button class="obj_cta" onClick={this.handleCopyText}>Copier Texte</button>
									<button className="obj_cta reverse" onClick={this.closeModal}>{this.props.t('FacebookAnnuler')}</button>
								</div>
							</div>
						</div>


					</div>
				</Modal>
			</div>
		)
	}
}

export default withTranslation()(withSitecoreContext()(FacebookPostModal));