import React, { Component } from 'react'
import { Image } from '@sitecore-jss/sitecore-jss-react'; 
import { throttle } from "../../../utilities/FrontFunctions/AoxGlobalFrontFunctions"

class BeforeAfterSlider extends Component {
	constructor() {
		super()
		this.state = {
			maskWidth: 50,
			inputWidth: 100
		}
		this.getBaSliderWidth = this.getBaSliderWidth.bind(this)
		this.handleWindowResizeBeforeAfterSlider = this.handleWindowResizeBeforeAfterSlider.bind(this)
		this.throttleWindowResizeBeforeAfterSlider = throttle(this.handleWindowResizeBeforeAfterSlider, 100)
	}
	componentDidMount(){
		window.addEventListener('resize', this.throttleWindowResizeBeforeAfterSlider)
		this.handleWindowResizeBeforeAfterSlider()
	}
	componentWillUnmount(){
		window.removeEventListener('resize', this.throttleWindowResizeBeforeAfterSlider)
	}
	handleRangeChange(e){
		this.setState({
			maskWidth: e.currentTarget.value
		})
	}
	handleWindowResizeBeforeAfterSlider(){
		this.setState({
			inputWidth: this.getBaSliderWidth()
		})
	}
	getBaSliderWidth(){
		return ((100 * this.props.sliderThumbWidth) / this.objBaSlider.offsetWidth) + 100
	}
	render() {
		return(
			<div className="obj_ba-slider" ref={ref => (this.objBaSlider = ref)}>
				<figure className="ctn_before-img" style={{width: this.state.maskWidth + '%'}}>
					<Image onLoad={this.props.onImageLoaded()}  field={this.props.imageBefore.jss} width="560" height="560" imageParams={{ w: 560, h: 560 }}></Image>
					{/*<img src={this.props.imageBefore} alt={this.props.imageBeforeAlt} width="560" height="560"/>*/}
				</figure>
				<figure className="ctn_after-img">
				<Image onLoad={this.props.onImageLoaded()}  field={this.props.imageAfter.jss} width="560" height="560" imageParams={{ w: 560, h: 560 }}></Image>
					{/*<img onLoad={this.props.onImageLoaded()} src={this.props.imageAfter} alt={this.props.imageAfterAlt} width="560" height="560"/>*/}
				</figure>
				<div className="ba-slidebar" style={{left: this.state.maskWidth + '%'}}></div>
				<input type="range" min="0" max="100" defaultValue="50" onChange={(e) => this.handleRangeChange(e)} style={{width: this.state.inputWidth + '%'}}/>
			</div>
		)
	}	
}

BeforeAfterSlider.defaultProps = {
	imageBefore: '',
	imageBeforeAlt: '',
	imageAfter: '',
	imageAfterAlt: '',
	onImageLoaded: () => {},
	sliderThumbWidth: 38
}

export default BeforeAfterSlider