import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'; 
import LazyLoad from 'react-lazyload'

import { dayRemaining, strDateToFrLong, urlToHost } from "../../utilities/FrontFunctions/AoxFrontFunctions"
import { dataEventTypes } from "../../data/events"

class ObjPushEvent extends Component {
	constructor(props) {
		super(props)
		this.state = {
		}
	}
	render() {
		const {event, pageEventDetail} = this.props;
        let activeSession = undefined;
        if(event.Sessions.length === 1 && event.Sessions[0].UserRegistered){
            activeSession = event.Sessions[0]
        } else {
            activeSession = event.Sessions.find(o => 
                ((!this.state.activeStartDate) || (new Date(o.EventSession_StartDate) >= this.state.activeStartDate))
                && ((!this.state.activeEndDate) || (new Date(o.EventSession_StartDate) <= this.state.activeEndDate))
            );
        }
		const DayRemaining = activeSession ? dayRemaining(activeSession.EventSession_StartDate) : -1;
		const EventFinished = DayRemaining < 0;
        let SessionsInfos = event.EventType_Code === 'WEB'
                            ? event.Sessions.map(o => o.EventSession_StartHour)
                            : event.Sessions.map(o => o.Place?.EventPlace_City);
        SessionsInfos = SessionsInfos.filter((o, index) => SessionsInfos.indexOf(o) === index).sort();

		return (
			<>
				{ event && activeSession &&
					<div className={`obj_formation
						${' ' + dataEventTypes[event.EventType_Code].css}
						${' ' + event.Brand.Brand_CssClass}
						${activeSession.UserRegistered ?" is_registered" :""}
						${	EventFinished
							?" is_finished"
							:	activeSession.EventSession_Canceled
								? " is_canceled"
								:""
						}`
					}>
						<div className="ctn_header-title">
							<span>{event.EventType_Name}</span>
						</div>

						<div className="ctn_row">
							<div className="ctn_text">
								{activeSession.UserRegistered === true && (
									(DayRemaining < 0) 
									? <></>
									:	(DayRemaining===0)
										? <div className="obj_jours-restant">Dernier jour</div>
										: <div className="obj_jours-restant">J-{DayRemaining}</div>
								)}

								<p className="t_title">{event.Event_Title}</p>

								<ul className="list_infos">
									<li className="t_date">
										{	(activeSession.EventSession_EndDate!=null)
											? <>Du {strDateToFrLong(activeSession.EventSession_StartDate)}<br/>au {strDateToFrLong(activeSession.EventSession_EndDate)}</>
											: <>{event.Sessions.length>1 ? "à partir du " : ""}{strDateToFrLong(activeSession.EventSession_StartDate)}</>
										}
									</li>

									<li className="t_info">
										{SessionsInfos.length!==0 ? SessionsInfos.join(" - ") : "-"}
									</li>
								</ul>

								{	EventFinished
									?<p className="t_statut">Événement passé</p>
									:	activeSession.EventSession_Canceled
										? <p className="t_statut">Evènement annulé</p>
										:	activeSession.UserRegistered
											? <>
												<a href={`${pageEventDetail?.url}?id=${event.Event_Id}`} className="obj_btn light">gérer mon inscription</a>
												<p className="t_statut">Vous êtes inscrits</p>
											</>
											: <a href={`${pageEventDetail?.url}?id=${event.Event_Id}`} className="obj_btn">inscrivez-vous</a>
								}
							</div>

							<div className="ctn_image">
								<div className="ctn_visuel fade-in">
									<LazyLoad>
										<img src={urlToHost(event.Event_MediaUrl)} alt={event.Event_MediaAlt}/>
									</LazyLoad>
								</div>

								<div className="ctn_logo">
									<LazyLoad>
										<img src={urlToHost(event.Brand.Brand_LogoUrl)} alt={event.Brand.Brand_Name}/>
									</LazyLoad>
								</div>
							</div>
						</div>
					</div>
				}
			</>
		)
	}
}

export default withTranslation()(ObjPushEvent)