const colors = {
	caf: {
		modelColor1: '0x00ACC2',
		modelColor2: '0x2DC2E3',
		overlayColor1: '#00ACC2',
		overlayColor2: '#9FD6E3',
	},
	gold: {
		modelColor1: '0xCEAB65',
		modelColor2: '0xE6CE9C',
		overlayColor1: '#CEAB65',
		overlayColor2: '#E6CE9C',
	},
	silver: {
		modelColor1: '0x9AA3AA',
		modelColor2: '0xCAD5DF',
		overlayColor1: '#9AA3AA',
		overlayColor2: '#CAD5DF',
	},
	bronze: {
		modelColor1: '0xA36E57',
		modelColor2: '0xE9BEAA',
		overlayColor1: '#A36E57',
		overlayColor2: '#E9BEAA',
	},
	lrp: {
		logo: require('../assets/images/badge_logo/Badge_LogoLRP.png'),
		modelColor1: '0x008DD2',
		modelColor2: '0x7BCCF0',
		overlayColor1: '#008DD2',
		overlayColor2: '#7BCCF0',
	},
	cerave: {
		logo: require('../assets/images/badge_logo/Badge_LogoCerave.png'),
		modelColor1: '0x005EAA',
		modelColor2: '0x88BDE9',
		overlayColor1: '#005EAA',
		overlayColor2: '#88BDE9',
	},
	vichy: {
		logo: require('../assets/images/badge_logo/Badge_LogoVichy.png'),
		modelColor1: '0x161715',
		modelColor2: '0xCBCEDA',
		overlayColor1: '#161715',
		overlayColor2: '#CBCEDA',
	},
	skinc: {
		logo: require('../assets/images/badge_logo/Badge_LogoSkinCeuticals.png'),
		modelColor1: '0x009EB6',
		modelColor2: '0x9FD6E3',
		overlayColor1: '#009EB6',
		overlayColor2: '#9FD6E3',
	},
	biotherm: {
		logo: require('../assets/images/badge_logo/Badge_LogoBiotherm.png'),
		modelColor1: '0x17505E',
		modelColor2: '0x98CDD9',
		overlayColor1: '#17505E',
		overlayColor2: '#98CDD9',
	},
	sciencemd: {
		logo: require('../assets/images/badge_logo/Badge_LogoScienceMD.png'),
		modelColor1: '0x007AA2',
		modelColor2: '0x77C7DE',
		overlayColor1: '#007AA2',
		overlayColor2: '#77C7DE',
	},
}

const badges = [
	{
		type: 'site-bienvenue',
		model: require('../assets/models/site-bienvenue.glb'),
		theme: [
			{
				theme: 'caf',
				image: require('../assets/images/badge_svg/Badge_SvgSiteBienvenue.svg'),
				imageDisabled: require('../assets/images/badge_svg/Badge_SvgSiteBienvenue_disabled.svg'),
				...colors.caf,
			},
		],
	},
	{
		type: 'app-bienvenue',
		model: require('../assets/models/app-bienvenue.glb'),
		theme: [
			{
				theme: 'caf',
				image: require('../assets/images/badge_svg/Badge_SvgAppBienvenue.svg'),
				imageDisabled: require('../assets/images/badge_svg/Badge_SvgAppBienvenue_disabled.svg'),
				...colors.caf,
			},
		],
	},
	{
		type: 'site-connecte',
		model: require('../assets/models/site-connecte.glb'),
		theme: [
			{
				theme: 'gold',
				image: require('../assets/images/badge_svg/Badge_SvgSiteConnecte_Gold.svg'),
				imageDisabled: require('../assets/images/badge_svg/Badge_SvgSiteConnecte_disabled.svg'),
				...colors.gold,
			},
			{
				theme: 'silver',
				image: require('../assets/images/badge_svg/Badge_SvgSiteConnecte_Silver.svg'),
				imageDisabled: require('../assets/images/badge_svg/Badge_SvgSiteConnecte_disabled.svg'),
				...colors.silver,
				
			},
			{
				theme: 'bronze',
				image: require('../assets/images/badge_svg/Badge_SvgSiteConnecte_Bronze.svg'),
				imageDisabled: require('../assets/images/badge_svg/Badge_SvgSiteConnecte_disabled.svg'),
				...colors.bronze,
			},
		],
	},
	{
		type: 'app-connecte',
		model: require('../assets/models/app-connecte.glb'),
		theme: [
			{
				theme: 'gold',
				image: require('../assets/images/badge_svg/Badge_SvgAppConnecte_Gold.svg'),
				imageDisabled: require('../assets/images/badge_svg/Badge_SvgAppConnecte_disabled.svg'),
				...colors.gold,
			},
			{
				theme: 'silver',
				image: require('../assets/images/badge_svg/Badge_SvgAppConnecte_Silver.svg'),
				imageDisabled: require('../assets/images/badge_svg/Badge_SvgAppConnecte_disabled.svg'),
				...colors.silver,
			},
			{
				theme: 'bronze',
				image: require('../assets/images/badge_svg/Badge_SvgAppConnecte_Bronze.svg'),
				imageDisabled: require('../assets/images/badge_svg/Badge_SvgAppConnecte_disabled.svg'),
				...colors.bronze,
			},
		],
	},
	{
		type: 'expert-categoriel',
		model: require('../assets/models/expert-categoriel.glb'),
		theme: [
			{
				theme: 'demo',
				image: require('../assets/images/badge_png/Badge_SvgExpertCategoriel_Theme.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_SvgExpertCategoriel_Theme_disabled.png'),
				logo: require('../assets/images/badge_logo/Badge_Theme.png'),
				...colors.caf,
			},
			{
				theme: 'oncologie',
				image: require('../assets/images/badge_png/Badge_SvgExpertCategoriel_Oncologie.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_SvgExpertCategoriel_Oncologie_disabled.png'),
				logo: require('../assets/images/badge_logo/Badge_Oncologie.png'),
				...colors.caf,
			},
			{
				theme: 'dermatologie',
				image: require('../assets/images/badge_png/Badge_SvgExpertCategoriel_Dermatologie.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_SvgExpertCategoriel_Dermatologie_disabled.png'),
				logo: require('../assets/images/badge_logo/Badge_Dermatologie.png'),
				...colors.caf,
			},
			{
				theme: 'pediatrie',
				image: require('../assets/images/badge_png/Badge_SvgExpertCategoriel_Pediatrie.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_SvgExpertCategoriel_Pediatrie_disabled.png'),
				logo: require('../assets/images/badge_logo/Badge_Pediatrie.png'),
				...colors.caf,
			},
			{
				theme: 'plaies-cicatrisation',
				image: require('../assets/images/badge_png/Badge_SvgExpertCategoriel_PlaiesCicatrisation.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_SvgExpertCategoriel_PlaiesCicatrisation_disabled.png'),
				logo: require('../assets/images/badge_logo/Badge_PlaiesCicatrisation.png'),
				...colors.caf,
			},
			{
				theme: 'sante-femme',
				image: require('../assets/images/badge_png/Badge_SvgExpertCategoriel_SanteFemme.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_SvgExpertCategoriel_SanteFemme_disabled.png'),
				logo: require('../assets/images/badge_logo/Badge_SanteFemme.png'),
				...colors.caf,
			},
			{
				theme: 'cuir-chevelu',
				image: require('../assets/images/badge_png/Badge_SvgExpertCategoriel_CuirChevelu.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_SvgExpertCategoriel_CuirChevelu_disabled.png'),
				logo: require('../assets/images/badge_logo/Badge_CuirChevelu.png'),
				...colors.caf,
			},
			{
				theme: 'medecine-esthetique',
				image: require('../assets/images/badge_png/Badge_SvgExpertCategoriel_MedecineEsthetique.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_SvgExpertCategoriel_MedecineEsthetique_disabled.png'),
				logo: require('../assets/images/badge_logo/Badge_MedecineEsthetique.png'),
				...colors.caf,
			},
		],
	},
	{
		type: 'marque-parcours',
		model: require('../assets/models/marque-parcours.glb'),
		theme: [
			{
				theme: 'lrp',
				image: require('../assets/images/badge_png/Badge_PngMarqueParcours_LRP.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_PngMarqueParcours_LRP_disabled.png'),
				...colors.lrp,
			},
			{
				theme: 'cerave',
				image: require('../assets/images/badge_png/Badge_PngMarqueParcours_Cerave.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_PngMarqueParcours_Cerave_disabled.png'),
				...colors.cerave,
			},
			{
				theme: 'vichy',
				image: require('../assets/images/badge_png/Badge_PngMarqueParcours_Vichy.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_PngMarqueParcours_Vichy_disabled.png'),
				...colors.vichy,
			},
			{
				theme: 'skinc',
				image: require('../assets/images/badge_png/Badge_PngMarqueParcours_Skinc.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_PngMarqueParcours_Skinc_disabled.png'),
				...colors.skinc,
			},
			{
				theme: 'biotherm',
				image: require('../assets/images/badge_png/Badge_PngMarqueParcours_Biotherm.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_PngMarqueParcours_Biotherm_disabled.png'),
				...colors.biotherm,
			},
			{
				theme: 'sciencemd',
				image: require('../assets/images/badge_png/Badge_PngMarqueParcours_ScienceMd.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_PngMarqueParcours_ScienceMd_disabled.png'),
				...colors.sciencemd,
			},
		],
	},
	{
		type: 'marque-miniparcours',
		model: require('../assets/models/marque-miniparcours.glb'),
		theme: [
			{
				theme: 'lrp',
				image: require('../assets/images/badge_png/Badge_PngMarqueMiniParcours_LRP.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_PngMarqueMiniParcours_LRP_disabled.png'),
				...colors.lrp,
			},
			{
				theme: 'cerave',
				image: require('../assets/images/badge_png/Badge_PngMarqueMiniParcours_Cerave.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_PngMarqueMiniParcours_Cerave_disabled.png'),
				...colors.cerave,
			},
			{
				theme: 'vichy',
				image: require('../assets/images/badge_png/Badge_PngMarqueMiniParcours_Vichy.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_PngMarqueMiniParcours_Vichy_disabled.png'),
				...colors.vichy,
			},
			{
				theme: 'skinc',
				image: require('../assets/images/badge_png/Badge_PngMarqueMiniParcours_Skinc.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_PngMarqueMiniParcours_Skinc_disabled.png'),
				...colors.skinc,
			},
			{
				theme: 'biotherm',
				image: require('../assets/images/badge_png/Badge_PngMarqueMiniParcours_Biotherm.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_PngMarqueMiniParcours_Biotherm_disabled.png'),
				...colors.biotherm,
			},
			{
				theme: 'sciencemd',
				image: require('../assets/images/badge_png/Badge_PngMarqueMiniParcours_ScienceMd.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_PngMarqueMiniParcours_ScienceMd_disabled.png'),
				...colors.sciencemd,
			},
		],
	},
	{
		type: 'marque-privilege',
		model: require('../assets/models/marque-privilege.glb'),
		theme: [
			{
				theme: 'lrp',
				image: require('../assets/images/badge_png/Badge_PngMarquePrivilege_LRP.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_PngMarquePrivilege_LRP_disabled.png'),
				...colors.lrp,
			},
			{
				theme: 'cerave',
				image: require('../assets/images/badge_png/Badge_PngMarquePrivilege_Cerave.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_PngMarquePrivilege_Cerave_disabled.png'),
				...colors.cerave,
			},
			{
				theme: 'vichy',
				image: require('../assets/images/badge_png/Badge_PngMarquePrivilege_Vichy.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_PngMarquePrivilege_Vichy_disabled.png'),
				...colors.vichy,
			},
			{
				theme: 'skinc',
				image: require('../assets/images/badge_png/Badge_PngMarquePrivilege_Skinc.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_PngMarquePrivilege_Skinc_disabled.png'),
				...colors.skinc,
			},
			{
				theme: 'biotherm',
				image: require('../assets/images/badge_png/Badge_PngMarquePrivilege_Biotherm.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_PngMarquePrivilege_Biotherm_disabled.png'),
				...colors.biotherm,
			},
			{
				theme: 'sciencemd',
				image: require('../assets/images/badge_png/Badge_PngMarquePrivilege_ScienceMd.png'),
				imageDisabled: require('../assets/images/badge_png/Badge_PngMarquePrivilege_ScienceMd_disabled.png'),
				...colors.sciencemd,
			},
		],
	},
]

export {
    badges,
}