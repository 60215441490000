import React, { Component } from 'react';
import { withSitecoreContext,  RichText } from '@sitecore-jss/sitecore-jss-react';

class PageContent extends Component {
	render() {
		const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
	
		return (
			<section className="section_mentions">
				<div className="str_container">					
					<div className="ctn_top">
            			{ oCurrentItemFields && <RichText field={oCurrentItemFields["Page Title"]} tag="h3"></RichText>}
					</div>

					<div className="ctn_main">
            			{ oCurrentItemFields && <RichText field={oCurrentItemFields["Page Text"]}></RichText>}
					</div>
				</div>
			</section>
		)
	}
}

export default withSitecoreContext()(PageContent);
