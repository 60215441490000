import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'; 

import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import IconSVG from "../../utilities/FrontFunctions/IconSVG"
import ModalProduitConcernes from '../Modal/ModalProduitConcernes'

class ObjAsideChallengeVerificationDeclaration extends Component {
	constructor() {
		super()
		this.state = {
			initialValues: {},
			validationSchema: {},
		}
	}

	componentDidMount() {
	}

	render() {
		return (
			<div className="obj_saisie-vente">
				<div className="ctn_form">
					<Formik
						initialValues={this.state.initialValues}
						validationSchema={Yup.object().shape(this.state.validationSchema)}
						onSubmit={(values, actions) => { }}
					>
						{ ({ handleSubmit, handleBlur, setFieldValue, setFieldTouched, errors, touched, values }) => (
							<>
								<form onSubmit={handleSubmit}>
									<div className="obj_form">
										<h3 className="t_title">Déclaration de mes ventes</h3>

										{this.props.participation.Challenge.Conditions?.map((condition, index) => (
											<div className="obj_saisie-vente-item" key={index} >
												<p className="t_desc">{condition.ChallengeCondition_Name}{condition.ChallengeCondition_EnableVariableGain && " ( condition variable )"}</p>
												{(condition.Produits?.length > 0) && 
													<>
														<button type="button" className="obj_btn-popin-detail" onClick={() => this["ModalProduitCondition"+condition.ChallengeCondition_Id].openModal()}>
															<span>voir les produits concernés</span>
															<IconSVG icon="arrowDown" />
														</button>
														<ModalProduitConcernes produits={condition.Produits} onRef={ref => (this["ModalProduitCondition"+condition.ChallengeCondition_Id] = ref)} />
													</>
												}
												<div className="obj_saisie-vente-ctn_input">
													<div className="ctn_row">
														<div className="ctn_input">
															<p className="t_label">Année en cours</p>
															<Field className="" as="input" type="text" disabled={true}
																name={'Declarations_'+index+'_Current'} 
																placeholder={this.props.participation.Declaration?.Conditions.find(o => o.Id === condition.ChallengeCondition_Id).ValueCurrent}
															/>
														</div>
														{this.props.participation.Challenge.Challenge_EvolutionPercent && 
															<div className="ctn_input">
																<p className="t_label">Année précédente</p>
																<Field className="" as="input" type="text" disabled={true} 
																	name={'Declarations_'+index+'_Anterior'}
																	placeholder={this.props.participation.Declaration?.Conditions.find(o => o.Id === condition.ChallengeCondition_Id).ValueAnterior}
																/>
															</div>
														}
													</div>
												</div>
											</div>
										))}

										{/* <h3 className="t_title">mes listings</h3>

										<div class="ctn_file-upload">
											<div class="ctn_single-file-upload">
												<div>
													{this.props.participation.Declaration?.Proofs.map(o => ( 
														<div class="ctn_file-thumb">
															<div class="ctn_infos">
																<p class="t_name">{o.FileName}</p>
															</div>
														</div>
													))}
												</div>
											</div>
										</div> */}
									</div>
								</form>
							</>
						)}
					</Formik>
				</div>
			</div>
		)
	}
}

export default withTranslation()(ObjAsideChallengeVerificationDeclaration);