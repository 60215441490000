import { withSitecoreContext, Text, Image } from "@sitecore-jss/sitecore-jss-react";
import React, { Component } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";
import { scroller } from "react-scroll";

import { GetStatusFromScore, ConvertToListProgressionBrands } from "../../utilities/FrontFunctions/AoxFrontFunctions";
import { throttle, equalHeight, heightAuto, isMobile } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions";
import { trackGA4PageView } from "../../utilities/FrontFunctions/AoxTrackingFunctions";
import ExpertModal from "../Modal/ExpertModal";
import SectionFormation from "./SectionFormation";

class PageFormation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isInitialised: false,
		};
		this.handleWindowResizeTabsListFormation = this.handleWindowResizeTabsListFormation.bind(this);
		this.throttleWindowResizeTabsListFormation = throttle(this.handleWindowResizeTabsListFormation, 200);

		this.switchTab = this.switchTab.bind(this);
		this.hideLevel2 = this.hideLevel2.bind(this);
		this.showLevel2 = this.showLevel2.bind(this);
		this.refreshHeight = this.refreshHeight.bind(this);
		this.refreshTab = this.refreshTab.bind(this);
		this.scrollTo = this.scrollTo.bind(this);

		this.divCircleRef = React.createRef();
		this.divStarRef = React.createRef();
		this.bPushTrackPage0_Done = true;
		this.bPushTrackPage1_Done = false;
	}
	componentDidMount() {
		window.addEventListener("resize", this.throttleWindowResizeTabsListFormation);
		this.switchTab(0);
	}
	componentWillUnmount() {
		window.removeEventListener("resize", this.throttleWindowResizeTabsListFormation);
	}
	refreshHeight() {
		heightAuto(".section_page-liste .ctn_page-liste h3");
		if (document.body.clientWidth === 768) equalHeight(".section_page-liste .ctn_page-liste h3", 2);
		if (document.body.clientWidth > 768) equalHeight(".section_page-liste .ctn_page-liste h3", 3);
	}
	refreshTab() {
		this.refreshHeight();
		window.dispatchEvent(new Event("scroll"));

		this.state.isInitialised && this.scrollTo();
		this.setState({ isInitialised: true });
	}
	hideLevel2() {
		var $subTabItemLevel1 = document.querySelector(".obj_tabs-list .ctn_row .lvl-1"),
			$subTabItemLevel2 = document.querySelectorAll(".obj_tabs-list .ctn_row .lvl-2");

		$subTabItemLevel1.classList.add("active", "fade-in");
		$subTabItemLevel2.forEach((subTabItemLevel2) => {
			subTabItemLevel2.classList.remove("active");
		});

		this.refreshTab();
	}
	showLevel2(buttonId) {
		if (buttonId !== undefined) {
			var $subTabItemLevel1 = document.querySelector(".obj_tabs-list .ctn_row .lvl-1");

			$subTabItemLevel1.classList.remove("active");
			document.querySelector("#" + buttonId).classList.add("active", "fade-in");

			this.refreshTab();
		}
	}
	switchTab(index) {
		var $listItem = document.querySelectorAll(".ctn_nav-tabs li"),
			$tabItem = document.querySelectorAll(".obj_tabs-list .ctn_row");

		$listItem.forEach((listItem) => listItem.classList.remove("active"));
		$listItem[index].classList.add("active");
		$tabItem.forEach((tabItem) => tabItem.classList.remove("active", "fade-in"));
		$tabItem[index].classList.add("active", "fade-in");

		this.hideLevel2();

		if (typeof window.trackDatas !== "undefined") {
			var trackData = window.trackDatas;
			if (index === 0) {
				trackData.pageCategory = "training list first range trainings";
				trackGA4PageView(trackData).then((result) => {
					this.bPushTrackPage0_Done = result;
				});
			} else if (index === 1) {
				trackData.pageCategory = "training list other trainings";
				trackGA4PageView(trackData).then((result) => {
					this.bPushTrackPage1_Done = result;
				});
			}
		}
	}
	handleWindowResizeTabsListFormation() {
		this.refreshHeight();
	}
	scrollTo() {
		scroller.scrollTo("obj-tabs-list", {
			smooth: true,
			offset: isMobile() ? -140 : -90,
		});
	}
	render() {
		const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
		const { CurrentUser, CurrentTraining } = this.props.fields.data;
		const { bodyAttributes } = Helmet.peek();

		var oListProgressionBrands = ConvertToListProgressionBrands(CurrentUser.listprogressionbrands);
		var oProgressionBrand = null;

		if (bodyAttributes && bodyAttributes.class) {
			oProgressionBrand = oListProgressionBrands.getBrandFromBody(bodyAttributes.class);
		}

		return (
			<>
				{oCurrentItemFields && <Text className="obj_title" field={oCurrentItemFields["Page Title"]} tag="h1"></Text>}

				<section className="section_page-liste">
					<div className="str_container">
						<div className="obj_tabs-list" name="obj-tabs-list">
							<ul className="ctn_nav-tabs">
								{CurrentTraining.MisEnAvant && CurrentTraining.MisEnAvant.length > 0 && (
									<li>
										<button type="button" onClick={() => this.switchTab(0)}>
											{CurrentTraining.MisEnAvant[0].pageTitle.value}
										</button>
									</li>
								)}
								<li>
									<button type="button" onClick={() => this.switchTab(1)}>
										{this.props.t("Formation_AutresFormations")}
									</button>
								</li>
							</ul>

							<section className="section_formation-dashboard">
								<div className="str_container">
									<div className="ctn_formation-dashboard">
										<div className="ctn_formation-dashboard-profil">
											<div className="ctn_avatar">
												<figure>
													{CurrentUser.userimg !== "" ? (
														<img src={CurrentUser.userimg} alt={CurrentUser.firstname + " " + CurrentUser.lastname} />
													) : (
														<img src={require("../../assets/images/v_avatar-xl.png")} alt={CurrentUser.firstname + " " + CurrentUser.lastname} />
													)}
												</figure>
											</div>

											{oProgressionBrand && (
												<div className="ctn_formation-dashboard-expertise">
													<h2>
														{this.props.t("Formation_NiveauExpertise")} {oProgressionBrand.Label}
													</h2>
													<p className="t_status">
														{GetStatusFromScore(Number(oProgressionBrand.ExpertPourcentage))} ({oProgressionBrand.TrainingDoCount}/
														{oProgressionBrand.AllTrainingExpertCount})
													</p>

													<div className="obj_progress-bar-expertise">
														<div className={"obj_progress-bar var_lrp"}>
															<div className="ctn_steps">
																{Array.from(Array(oProgressionBrand.AllTrainingExpertCount), (e, i) => {
																	if (i < oProgressionBrand.TrainingDoCount) {
																		return <div key={i} className="step is-complete"></div>;
																	}
																	return <div key={i} className="step"></div>;
																})}
															</div>
														</div>
													</div>
												</div>
											)}
										</div>

										<div className="ctn_formation-popin">
											<div className="ctn_cta">
												<button className="obj_cta-txt" type="button" onClick={() => this.ExpertModal.openModal()}>
													Comment ça marche ?
												</button>
												<ExpertModal onRef={(ref) => (this.ExpertModal = ref)} />
											</div>
										</div>
									</div>
								</div>
							</section>

							<section className="ctn_row">
								<div className="ctn_sub-row">
									{CurrentTraining.MisEnAvant &&
										CurrentTraining.MisEnAvant[0].Trainings.map((encoursItem, index) => <SectionFormation key={index} FormationData={encoursItem}></SectionFormation>)}
								</div>
							</section>

							<section className="ctn_row">
								<div className="ctn_sub-row lvl-1">
									{CurrentTraining.TrainingSegment &&
										CurrentTraining.TrainingSegment.map((SegmentItem, index) => (
											<article key={index} className="ctn_page-liste">
												<button type="button" data-id={"sub_" + SegmentItem.id} onClick={() => this.showLevel2("sub_" + SegmentItem.id)}>
													<LazyLoad>
														<figure className="fade-in">
															<Image field={SegmentItem.pageImage.jss}></Image>
														</figure>
													</LazyLoad>
													<Text field={SegmentItem.pageTitle.jss} tag="h3"></Text>
												</button>
											</article>
										))}
									{CurrentTraining.Trainings.map((FormationItem, index) => (
										<SectionFormation key={index} FormationData={FormationItem}></SectionFormation>
									))}
								</div>

								{CurrentTraining.TrainingSegment &&
									CurrentTraining.TrainingSegment.map((SegmentItem, index) => (
										<div key={index} className="ctn_sub-row lvl-2" id={"sub_" + SegmentItem.id}>
											<div className="obj_back-page">
												<button className="bt_back-page" type="button" onClick={() => this.hideLevel2()}>
													{this.props.t("retour")}
												</button>
											</div>
											<Text field={SegmentItem.pageTitle.jss} tag="h3"></Text>

											{SegmentItem.Trainings && SegmentItem.Trainings.map((subItem, index) => <SectionFormation key={index} FormationData={subItem}></SectionFormation>)}
										</div>
									))}
							</section>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default withTranslation()(withSitecoreContext()(PageFormation));
