import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'; 
import LazyLoad from 'react-lazyload'

import { dayRemaining, dateProgression, strDateToFr, urlToHost } from "../../utilities/FrontFunctions/AoxFrontFunctions"
import { dataStateTypes } from "../../data/challenges"

import ObjProgressBar from "../Obj/ObjProgressBar";
import ObjConditionChallenge from './ObjConditionChallenge';

class ObjPushChallenge extends Component {
	constructor(props) {
		super(props)
		this.state = {
		}
	}
	render() {
		const {participation, isManager, pageChallengeDetail} = this.props;
		const DayRemainingToDeclare = dayRemaining(participation.Challenge.Challenge_ToDeclareDate);
		const DayRemainingToDispatch = dayRemaining(participation.Challenge.Challenge_ToDispatchDate);
		
		return (
			<div className={`obj_challenge${this.state.active ? ' is-expanded' : ''} ${dataStateTypes[participation.ParticipationStateType_Code].css}`}>
				{participation.ParticipationStateType_Code !== "STATE_TYPE_IN_PROGRESS" &&
					<div className="ctn_header-title">
						<span>{participation.ParticipationStateType_Name}</span>
					</div>
				}

				<div className="ctn_row">
					<div className="ctn_text">
						{(() => {switch (participation.ParticipationStateType_Code) {
							case 'STATE_TYPE_TO_DECLARE' : return DayRemainingToDeclare > -1 &&
								<div className="obj_temps_restant">
									<div className="obj_jours-restant">
										{	DayRemainingToDeclare===0
											?<p><strong>Dernier jour</strong></p>
											:<><span>{DayRemainingToDeclare}</span><p><strong>jours restants</strong> jusqu'au {strDateToFr(participation.Challenge.Challenge_ToDeclareDate)}</p></>
										}
									</div>
									{ 	DayRemainingToDeclare<=10
										? <div>
											<div className="obj_progress-bar-challenge var_attention">
												<ObjProgressBar pctProgress={dateProgression(participation.Challenge.Challenge_EndDate, participation.Challenge.Challenge_ToDeclareDate)}/>
											</div>
											<div className="obj_jours-restant-info">
												<p>Déclaration impossible une fois la date dépassée !</p>
											</div>
										</div>
										: <div className="obj_progress-bar-challenge">
											<ObjProgressBar pctProgress={dateProgression(participation.Challenge.Challenge_EndDate, participation.Challenge.Challenge_ToDeclareDate)}/>
										</div>
									}
								</div>
							case 'STATE_TYPE_TO_DISPATCH' : return DayRemainingToDispatch > -1 &&
								<div className="obj_temps_restant">
									<div className="obj_jours-restant">
										{	DayRemainingToDispatch===0
											?<p><strong>Dernier jour</strong></p>
											:<><span>{DayRemainingToDispatch}</span><p><strong>jours restants</strong> jusqu'au {strDateToFr(participation.Challenge.Challenge_ToDispatchDate)}</p></>
										}
									</div>
									{ 	DayRemainingToDispatch<=10
										? <div>
											<div className="obj_progress-bar-challenge var_attention">
												<ObjProgressBar pctProgress={dateProgression(participation.Challenge.Challenge_ToDeclareDate, participation.Challenge.Challenge_ToDispatchDate)}/>
											</div>
											<div className="obj_jours-restant-info">
												<p>Répartition impossible une fois la date dépassée !</p>
											</div>
										</div>
										: <div className="obj_progress-bar-challenge">
											<ObjProgressBar pctProgress={dateProgression(participation.Challenge.Challenge_ToDeclareDate, participation.Challenge.Challenge_ToDispatchDate)}/>
										</div>
									}
								</div>
							default : return <></>
						}})()}

						<p className="t_title">{participation.Challenge.Challenge_Title}</p>
						<p className="t_date">Du {strDateToFr(participation.Challenge.Challenge_StartDate)} au {strDateToFr(participation.Challenge.Challenge_EndDate)}</p>

						{isManager && (() => {switch (participation.ParticipationStateType_Code) {
							case 'STATE_TYPE_TO_DISPATCH' : return <p className="t_desc"><strong>{participation.Participation_Gain}€</strong> à répartir</p>
							case 'STATE_TYPE_FINISHED' : return <p className="t_desc"><strong>{participation.Participation_Gain}€</strong> remporté</p>
							default : return <></>
						}})()}

                        {(() => {switch (participation.ParticipationStateType_Code) {
                            case 'STATE_TYPE_TO_DECLARE' : return <a className="obj_btn_challenge" href={`${pageChallengeDetail?.url}?id=${participation.Challenge.Challenge_Id}`}>
                                {isManager?"Déclarer":"Voir le détail"}
                            </a>
                            case 'STATE_TYPE_TO_DISPATCH' : return <a className="obj_btn_challenge" href={`${pageChallengeDetail?.url}?id=${participation.Challenge.Challenge_Id}`}>
                                {isManager?"Répartir":"Voir le détail"}
                            </a>
                            case 'STATE_TYPE_FINISHED' : return isManager && <a className="obj_btn_challenge" href={`${pageChallengeDetail?.url}?id=${participation.Challenge.Challenge_Id}`}>
                                Voir le détail
                            </a>
                            default : return <a className="obj_btn_challenge" href={`${pageChallengeDetail?.url}?id=${participation.Challenge.Challenge_Id}`}>
                                Voir le détail
                            </a>
						}})()}

						<button className="obj_btn_condition_challenge-detail" onClick={ () => this.setState({active: !this.state.active}) }>
							<span>voir les Conditions du challenge</span>
						</button>

						<div className="ctn_conditions-expand">
							<ObjConditionChallenge participation={participation} isManager={isManager} showProduct={false}/>
						</div>
					</div>

					<div className="ctn_image">
						<div className="ctn_visuel fade-in">
							<LazyLoad>
								<img src={urlToHost(participation.Challenge.Challenge_MediaUrl)} alt={participation.Challenge.Challenge_MediaAlt}/>
							</LazyLoad>
						</div>

						<div className="ctn_logo">
							<LazyLoad>
								<img src={urlToHost(participation.Challenge.Brand.Brand_LogoUrl)} alt={participation.Challenge.Brand.Brand_Name}/>
							</LazyLoad>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslation()(ObjPushChallenge)