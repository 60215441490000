import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'; 
import queryString from 'query-string';

import { getApiDatas, dayRemaining, dateProgression, strDateToFr } from "../../utilities/FrontFunctions/AoxFrontFunctions"
import { dataStateTypes } from "../../data/challenges"

import ObjRetour from "../Obj/ObjRetour";
import ObjProgressBar from "../Obj/ObjProgressBar";
import ObjChallengeDetail from '../ObjChallenge/ObjChallengeDetail';
import ObjAsideChallengeADeclarer from './ObjAsideChallengeADeclarer';
import ObjAsideChallengeVerificationDeclaration from './ObjAsideChallengeVerificationDeclaration';
import ObjAsideChallengeARepartir from './ObjAsideChallengeARepartir';
import ObjAsideChallengeVerificationRepartition from './ObjAsideChallengeVerificationRepartition';

class PageChallengeDetail extends Component {
	constructor(props) {
		super(props)
		this.state = {
			apiDatas:[],
		}
	}
	componentDidMount() {
		var oParams = queryString.parse(window.location.search);
		if(oParams.id !== undefined){
			getApiDatas(`/apiaox/Challenge/GetDataPageChallengeDetail?id=${oParams.id}`)
			.then((apiDatas) => { this.setState({ apiDatas:apiDatas }) })
		}

		document.body.classList.add('pg_challenge-detail')
		window.scrollTo(0,0);
	}
	componentWillUnmount() {
		document.body.classList.remove('pg_challenge-detail')
	}
	render() {
		const apiDatas = this.state.apiDatas
		const DayRemainingToDeclare = dayRemaining(apiDatas.Participation?.Challenge.Challenge_ToDeclareDate);
		const DayRemainingToDispatch = dayRemaining(apiDatas.Participation?.Challenge.Challenge_ToDispatchDate);

		return (<>
			<ObjRetour/>
			
			<section className="obj_section section_challenges-detail">
				<div className="ctn_section">
					{apiDatas.Participation && <>
						<div className="ctn_title">
							<h1 className="t_title">
								{apiDatas.Participation.Challenge.Challenge_Title}
							</h1>
						</div>

						<div className="ctn_row">
							<ObjChallengeDetail user={apiDatas.User} participation={apiDatas.Participation} />

							<div className={`obj_aside_challenge ${dataStateTypes[apiDatas.Participation.ParticipationStateType_Code].css}`}>
								<div className="ctn_header">
									<div className="obj_temps_restant">
										<div className="tag_title"><span>{apiDatas.Participation.ParticipationStateType_Name}</span></div>

										{(() => {switch (apiDatas.Participation.ParticipationState_Code) {
											case 'STATE_TO_DECLARE' : return DayRemainingToDeclare > -1 && <>
												<div className="obj_jours-restant">
													{	DayRemainingToDeclare===0
														?<p><strong>Dernier jour</strong></p>
														:<><span>{DayRemainingToDeclare}</span><p><strong>jours restants</strong> jusqu'au {strDateToFr(apiDatas.Participation.Challenge.Challenge_ToDeclareDate)}</p></>
													}
												</div>
												<div className="obj_progress-bar-challenge">
													<ObjProgressBar pctProgress={dateProgression(apiDatas.Participation.Challenge.Challenge_EndDate, apiDatas.Participation.Challenge.Challenge_ToDeclareDate)}/>
												</div>
											</>
											case 'STATE_DECLARATION_VERIFICATION' : return <>
												<div className="obj_jours-restant-info">
													<p>Challenge en attente de validation par nos équipes suite à votre déclaration de ventes.</p>
												</div>
											</>
											case 'STATE_TO_DISPATCH' : return DayRemainingToDispatch > -1 && <>
												<div className="obj_jours-restant">
													{	DayRemainingToDispatch===0
														?<p><strong>Dernier jour</strong></p>
														:<><span>{DayRemainingToDispatch}</span><p><strong>jours restants</strong> jusqu'au {strDateToFr(apiDatas.Participation.Challenge.Challenge_ToDispatchDate)}</p></>
													}
												</div>
												<div className="obj_progress-bar-challenge">
													<ObjProgressBar pctProgress={dateProgression(apiDatas.Participation.Challenge.Challenge_ToDeclareDate, apiDatas.Participation.Challenge.Challenge_ToDispatchDate)}/>
												</div>
											</>
											case 'STATE_REPARTITION_VERIFICATION' : return <>
												<div className="obj_jours-restant-info">
													<p>Challenge en attente de validation par nos équipes<br /> suite à votre répartition des gains.</p>
												</div>
											</>
											default:return <></>
										}})()}
									</div>
								</div>

								{apiDatas.User.ChallengeManager && (() => {switch (apiDatas.Participation.ParticipationState_Code) {
									case 'STATE_TO_DECLARE' : return <ObjAsideChallengeADeclarer participation={apiDatas.Participation} />
									case 'STATE_DECLARATION_VERIFICATION' : return <ObjAsideChallengeVerificationDeclaration participation={apiDatas.Participation} />
									case 'STATE_TO_DISPATCH' : return <ObjAsideChallengeARepartir participation={apiDatas.Participation} membres={apiDatas.Membres} />
									case 'STATE_REPARTITION_VERIFICATION' : return <ObjAsideChallengeVerificationRepartition participation={apiDatas.Participation} />
									case 'STATE_WON' : return <ObjAsideChallengeVerificationRepartition participation={apiDatas.Participation} />
									case 'STATE_DISTRIBUTED' : return <ObjAsideChallengeVerificationRepartition participation={apiDatas.Participation} />
									default:return <></>
								}})()}
							</div>
						</div>
					</>}
				</div>
			</section>
		</>)
	}
}

export default withTranslation()(PageChallengeDetail)