import React, { Component } from 'react'
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import LazyLoad from 'react-lazyload'
import { withTranslation } from 'react-i18next';
import IconSVG from "../../utilities/FrontFunctions/IconSVG" 
import Moment from 'react-moment'; 
// eslint-disable-next-line
import localization from 'moment/locale/fr' //Import nécessaire en lien avec Moment.globalLocale

class ObjPushArticle extends Component {
    constructor(props) {
		super(props)
        Moment.globalLocale = 'fr'; 
	}
	render() {
		const { pushArticleItem, PushOnTitle, PushShowComment, PushCTAFull } = this.props 
        
		return ( 
			<>
			{pushArticleItem ? 
            <article className={`obj_push-article fade-in   ${PushShowComment ? ' var_comment' : ''}
                                                            ${pushArticleItem.getbrand.brandCssClass.value}`}>

				<div className="ctn_push-article">
					<figure className="ctn_img">
						<a href={pushArticleItem.url}>
							<LazyLoad>
                                <Image className="fade-in" field={pushArticleItem.pageImageHome && pushArticleItem.pageImageHome.jss} />
							</LazyLoad>
						</a>
					</figure>
					<div className="ctn_text">
                        {PushOnTitle === "" ?
                            <Text field={pushArticleItem.getbrand.pageTitle.jss} tag="h3" />
                            :
                            <h3>{PushOnTitle}</h3>
                        }
                        <Text field={pushArticleItem.pageTitle && pushArticleItem.pageTitle.jss} tag="h2" />
						{pushArticleItem.articleAuthor && pushArticleItem.articleAuthor.jss && pushArticleItem.articleAuthor.jss.value !== "" && 
							<p className="t_author-date"><strong>
                            	{this.props.t('ArticlePar')} <Text field={pushArticleItem.articleAuthor.jss} /></strong>, {this.props.t('ArticleLe')} {pushArticleItem.articleDate.formattedDateValue}
                            </p>
						}
                        <RichText className="t_main" field={pushArticleItem.abstract && pushArticleItem.abstract.jss} tag="p" />
						 
						<p className="ctn_cta">
							<a className={`${PushCTAFull ? 'obj_cta' : 'obj_cta-txt'}`} href={pushArticleItem.url}>
                                {this.props.t('ArticleLireLaSuite')}
                            </a>
						</p>
						<ul className="obj_likes-comments">
							<li>
								<IconSVG icon="heart" width="19" height="16" />
								{pushArticleItem.likes && pushArticleItem.likes.numberValue ? pushArticleItem.likes.numberValue : 0} <span>{this.props.t('ArticleLike')}</span>
							</li>
							{pushArticleItem.userCanAddReviews && pushArticleItem.userCanAddReviews.boolValue &&
								<li>
									<IconSVG icon="dialog" width="20" height="17" />
									{pushArticleItem.reviewsCount} <span>{pushArticleItem.reviewsCount > 2 ?this.props.t('ArticleCommentaires') : this.props.t('ArticleCommentaire') }</span>
								</li>
							}
						</ul>
					</div>
				</div>
				{ PushShowComment && pushArticleItem.reviews && pushArticleItem.reviews.length > 0 &&
					<div className="obj_comment-list">
						<h4>{this.props.t('ArticleDerniersCommentaires')}</h4>
						{ pushArticleItem.reviews.slice(0, 4).map((commentItem, index) => (
							<div key={index} className="ctn_comment-row">
								<figure className="ctn_avatar">
									{commentItem.reviewsFOU.userimg ?
										<img src={commentItem.reviewsFOU.userimg} alt={commentItem.reviewsFOU.firstname + " " + commentItem.reviewsFOU.lastname}/>
										:
										<img src={require("../../assets/images/v_avatar.png")} alt={commentItem.reviewsFOU.firstname + " " + commentItem.reviewsFOU.lastname}/>
									}
								</figure>
								<div className="ctn_comment">
									<p className="t_name-time">
										<strong>{commentItem.reviewsFOU.firstname + " " + commentItem.reviewsFOU.lastname}</strong>  <Moment fromNow>{commentItem.creationDate.jss.value}</Moment>
                                        {/*{this.props.t('ArticleLe')} <Text field={commentItem.creationDate.jss} /> */}
                                        </p>
									<p className="t_main">
                                        <Text field={commentItem.comment.jss} />
                                    </p>
								</div>
							</div>
						))}
						<div className="ctn_cta">
							<a className="obj_cta" href={pushArticleItem.url}>
                                {this.props.t('ArticleJeParticipe')}
                            </a>
						</div>
					</div>
				}
			</article>
			:
			<div>Error loading : ObjPushArticle - {PushOnTitle}</div>
			}
			</>
		)
	}
}


export default withTranslation()(ObjPushArticle);