import React, { Component } from 'react'
import { Link  } from 'react-router-dom'

import { getApiDatas, isAppMobile, urlToHost} from "../../utilities/FrontFunctions/AoxFrontFunctions"

import ObjProgressBarExpertise from './ObjProgressBarExpertise'
import ObjExpertiseClassement from './ObjExpertiseClassement'
import ObjExpertiseBadge from './ObjExpertiseBadge'

class ObjExpertiseSectionBrand extends Component {
	constructor() {
		super()
		this.state = {
			isReady: false,
			current: 0,
			length: null,
		}
		this.showTab = this.showTab.bind(this)
		this.setMaxHeight = this.setMaxHeight.bind(this)
		this.handleKeyDown = this.handleKeyDown.bind(this)
		this.goNextTab = this.goNextTab.bind(this)
		this.goPrevTab = this.goPrevTab.bind(this)
	}
	componentDidMount(){
		this.setMaxHeight()
		this.showTab(this.state.current)
		this.navTabs.querySelectorAll('li').forEach(element => element.querySelector('button').blur())

		this.setState({
			length: this.navTabs.querySelectorAll('li').length
		})
	}
	switchTab(e){
		var tabsList = Array.prototype.slice.call(this.navTabs.children)
    	var currentTab = e.currentTarget.parentElement
    	var currentIndex = tabsList.indexOf(currentTab)

		this.setState({
			current: currentIndex
		}, () => this.showTab(this.state.current))
	}
	showTab(index){
		this.navTabs.querySelectorAll('li').forEach(element => {
			element.querySelector('button').classList.remove('active')
			element.querySelector('button').blur()
			element.querySelector('button').setAttribute('aria-selected', false)
			element.querySelector('button').setAttribute('tabindex', -1)
		})
		this.navTabs.children[index].querySelector('button').classList.add('active')
		this.navTabs.children[index].querySelector('button').focus()
		this.navTabs.children[index].querySelector('button').setAttribute('aria-selected', true)
		this.navTabs.children[index].querySelector('button').setAttribute('tabindex', 0)

		this.ctnTabs.querySelectorAll('.tab-panel').forEach(element => { 
			// element.querySelector('.tab-panel').classList.remove('fade-in')
			element.classList.remove('fade-in')
			element.classList.remove('active') 
		})
		this.ctnTabs.children[index].classList.add('fade-in')
		this.ctnTabs.children[index].classList.add('active')
	}
	goNextTab() {
		this.setState({
			current: (this.state.current + 1) >= this.state.length ? 0 : this.state.current + 1
		}, () => this.showTab(this.state.current))
	}
	goPrevTab() {
		this.setState({
			current: this.state.current <= 0 ? this.state.length - 1 : this.state.current - 1
		}, () => this.showTab(this.state.current))
	}
	setMaxHeight(){
		var maxHeight = 0
		this.ctnTabs.querySelectorAll('.tab-panel').forEach(element => {
			if (element.offsetHeight > maxHeight) maxHeight = element.offsetHeight
		})
		// this.ctnTabs.style.height = maxHeight + "px"
		this.setState({ isReady: true })
	}
	handleKeyDown(e){
		e.key === 'ArrowRight' && this.goNextTab()
		e.key === 'ArrowLeft' && this.goPrevTab()
	}
	handleFocus(){
		window.addEventListener('keydown', this.handleKeyDown)
	}
	handleBlur(){
		window.removeEventListener('keydown', this.handleKeyDown)
	}
	handleLinkFormation(e, brandId){
		e.preventDefault();
		getApiDatas(`/apiaox/Call/GetBrandTrainingPage?nBrandId=${brandId}`)
		.then((apiDatas) => { window.location.href =  urlToHost(apiDatas.BrandTrainingPage); })
	}

	render() {
		const { ranking, user, badges, userBadges } = this.props

		return (
			<div className={`obj_bloc_expertise ${user.Brand_CssClass}${this.state.active ? ' is-expanded' : ''}`} >
				<button className="obj_header_exp" onClick={ () => this.setState({active: !this.state.active}) }>
					<h3 className="t_title">Mon Expertise <strong>{user.Brand_Name}</strong></h3>
					<div className="t_tag"><span>{user.Score<0 ? '-' : user.Score} <em>PTS</em></span></div>

					<div className={`ctn_classement_header${user.Evolution_Rank==='Up' ? ` var_up` : user.Evolution_Rank==='Down' ? ` var_down` : ``}`}>
						<p>Classement</p>
						<div className="t_place_exp">{user.Rank < 1 ? <>-</> : user.Rank === 1 ? (<>1<sup>er</sup></>) : (<>{user.Rank}<sup>è</sup></>)}</div>
					</div>

					<div className="obj_progress-bar-expertise">
					<span><strong><em>{user.UserTrainingsOnLine_Completed} </em></strong>{user.UserTrainingsOnLine_Completed>1 ? "Formations réalisées":"Formation réalisée"}</span>
						<ObjProgressBarExpertise step={user.UserTrainingsOnLine_Completed} stepstotal={user.Brand_NbTrainingsOnLine} />
					</div>
				</button>

				<div className={`obj_tabs${this.state.isReady ? ' is-ready' : ''}`}>
					<ul ref={ref => (this.navTabs = ref)} className="obj_tabs_list" role="tablist">
						<li><button type="button" className="btn_tab_exp" role="tab" id="onglet-1" tabIndex="0" aria-selected="true" aria-controls="panneau-1" onClick={(e) => this.switchTab(e)} onFocus={() => this.handleFocus()} onBlur={() => this.handleBlur()}>Classement</button></li>
						<li><button type="button" className="btn_tab_exp" role="tab" id="onglet-2" tabIndex="-1" aria-selected="false" aria-controls="panneau-2" onClick={(e) => this.switchTab(e)} onFocus={() => this.handleFocus()} onBlur={() => this.handleBlur()}>Expertise</button></li>
						<li><button type="button" className="btn_tab_exp" role="tab" id="onglet-3" tabIndex="-1" aria-selected="false" aria-controls="panneau-3" onClick={(e) => this.switchTab(e)} onFocus={() => this.handleFocus()} onBlur={() => this.handleBlur()}>Récompenses</button></li>
					</ul>

					<div className="tab-panels">
						<div className="ctn_bloc_exp" ref={ref => (this.ctnTabs = ref)}>
							<div className="ctn_classement_exp tab-panel" role="tabpanel" id="panneau-1" aria-labelledby="onglet-1" tabIndex="0">
								<div className="obj_classement_exp">
									<h3 className="t_title"><strong>Classement marque</strong></h3>
								</div>

								<ObjExpertiseClassement ranking={ranking} user={user}/>
							</div>

							<div className="ctn_points_exp tab-panel" role="tabpanel" id="panneau-2" aria-labelledby="onglet-2" tabIndex="0">
								<div className="obj_points_exp">
									<h3 className="t_title">
										<strong>Mon Expertise {user.Brand_Name}</strong>
									</h3>

									<div className="ctn_nbre_points_exp">
										<span>{user.Score<0 ? '-' : user.Score} <em>Pts</em></span>
									</div>

									<div className="obj_progress-bar-expertise">
									<span><strong><em>{user.UserTrainingsOnLine_Completed} </em></strong>{user.UserTrainingsOnLine_Completed>1 ? "Formations réalisées":"Formation réalisée"}</span>
                                        <ObjProgressBarExpertise step={user.UserTrainingsOnLine_Completed} stepstotal={user.Brand_NbTrainingsOnLine} />
									</div>

									{ !isAppMobile() &&
										<div className="ctn_cta">
											<Link onClick={(e) => this.handleLinkFormation(e, user.Brand_Id)} to="" className="obj_cta">
												Je complète ma formation
											</Link>
										</div>
									}
								</div>
							</div>

							<div className="ctn_recompences_exp tab-panel" role="tabpanel" id="panneau-3" aria-labelledby="onglet-3" tabIndex="0">
								<div className="obj_recompences_exp">
									<h3 className="t_title"><strong>Récompenses</strong></h3>
									
									<p className="t_desc">Cliquez sur vos récompenses<br />	pour en voir le détail</p>
			
									<div className="lst_items_exp">
										{badges.map((badge, index) => (
											<ObjExpertiseBadge
												key={index}
												badge={badge}
												userBadge={userBadges.find(o => o.UserBadge_Badge_Id === badge.Badge_Id)}
											/>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ObjExpertiseSectionBrand