import React, { Component } from 'react'
import { Formik, Field } from 'formik'
import * as Yup from 'yup' 
import { Link as ScrollLink } from 'react-scroll'
import { withTranslation } from 'react-i18next'; 
import SingleFileUpload from  '../../utilities/FrontFunctions/FormElements/SingleFileUpload'
import CommentImageModal from '../Modal/CommentImageModal' 
import config from '../../temp/config';  
 
import { throttle, isMobile, formatDate, fileReader } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions"
import Moment from 'react-moment';  
import axios from 'axios';

import QUERY_GET_REVIEWS from './GraphQL/GetCurrentArticleReviews.js';
class SectionComments extends Component {
	constructor(props) {
		super(props)
		this.state = {
			UrlCallLikes: `${config.sitecoreApiHost}/apiaox/Call/LikePost?sc_apikey=${config.sitecoreApiKey}`, 
			UrlSendReview: `${config.sitecoreApiHost}/apiaox/Call/AddArticleReview?sc_apikey=${config.sitecoreApiKey}`, 

			UrlCallGraphQL: `${config.graphQLEndpoint}`, 

			serverError: false, 
			likeIsSubmitting: false,
			
			reviewToShow: 2,
			reviewInterval: 2,
			responseToShow: 2,
			responseInterval: 2,

			CurrentArticleReview: props.CurrentArticleReview			
		} 
	 
		// window resize
		this.handleWindowResizeSectionArticle = this.handleWindowResizeSectionArticle.bind(this)
		this.throttleWindowResizeSectionArticle = throttle(this.handleWindowResizeSectionArticle, 100)
		// bg comment
		this.bgComment = React.createRef() 
		// toggle comment form
		this.toggleCommentForm = this.toggleCommentForm.bind(this)
		// set comment servor error
		this.setCommentServerError = this.setCommentServerError.bind(this)
		// show more review
		this.showMoreReview = this.showMoreReview.bind(this)
		// show more response
		this.showMoreResponse = this.showMoreResponse.bind(this)
		// add new comment
		this.addNewComment = this.addNewComment.bind(this)
		// add new response
		this.addNewResponse = this.addNewResponse.bind(this) 

		// AJAX call
		this.CurrentArticleReviewIsFetch = false
		this.CurrentArticleReviewFetchEnCours= false
	}
	componentDidMount(){
		// add and dispatch window resize
		window.addEventListener('resize', this.throttleWindowResizeSectionArticle)
		window.dispatchEvent(new Event('resize'))
	}
	componentWillUnmount(){
		// remove window resize
		window.removeEventListener('resize', this.throttleWindowResizeSectionArticle)
	}
	handleWindowResizeSectionArticle(){
		// bg comment
		var windowWidth = document.body.clientWidth,
			windowMargin = (windowWidth - 768) / 2
		if(windowWidth >= 768) {
			if(this.bgComment && this.bgComment.current){
				this.bgComment.current.style.width = windowWidth + "px"
				this.bgComment.current.style.transform = "translateX(-" + windowMargin + "px)"
			}
		} else {
			if(this.bgComment  && this.bgComment.current){
				this.bgComment.current.style.width = ""
				this.bgComment.current.style.transform = ""
			}
		}
	}
	 
	showMoreReview(){
		
		if (!this.CurrentArticleReviewIsFetch)
		{						
			if (this.state.CurrentArticleReview.id)
			{
				this.CurrentArticleReviewFetchEnCours = true;

				document.body.classList.add('is-loading')

				axios.post(this.state.UrlCallGraphQL, {
						query: QUERY_GET_REVIEWS,
						variables: {
							contextItem: this.state.CurrentArticleReview.id
						},
						withCredentials: true
						}, {
						headers: {
							'Content-Type': 'application/json'
						}
						})
						.then(res => {					
							this.setState(prevState => ({
								CurrentArticleReview: res.data.data.CurrentArticleReview,
								reviewToShow: prevState.reviewToShow + this.state.reviewInterval,		
							}))

							this.CurrentArticleReviewIsFetch = true;
							this.CurrentArticleReviewFetchEnCours = false;

							document.body.classList.remove('is-loading')
						})	
						.catch((err) => {
							console.log(err)
							document.body.classList.remove('is-loading')
						}); 
			}
			else
			{
				console.error("CurrentArticleReview ID not defined");
			}
		}
		else if (!this.CurrentArticleReviewFetchEnCours)
		{
			this.setState(prevState => ({
				reviewToShow: prevState.reviewToShow + this.state.reviewInterval
			}))
		}
	}
	showMoreResponse(){
		this.setState(prevState => ({
			responseToShow: prevState.responseToShow + this.state.responseInterval
		}))
	}
	toggleCommentForm(list, item, index) {
		list[index].formVisible = !item.formVisible
		this.setState({ list })
	}
	setCommentServerError(list, item, index) {
		list[index].serverError = true
		this.setState({ list })
	}
	addNewComment(values) {
		const reviewList = this.state.reviewList
		let fileValue = values[`mainfileupload`]
		const newItem = {
			formVisible: false,
			name: this.state.user,
			date: formatDate(new Date()),
			comment: values[`comment`],
			commentImage: null,
			commentImageError: false,
			responseList: []
		}
		if( fileValue === null ) {
			this.setState({ reviewList: [...reviewList, newItem] })
		} else {
			fileReader(fileValue)
				.then(result => {
					newItem.commentImage = result
				})
				.catch(error => {
					newItem.commentImageError = true
				})
				.finally(() => {
					this.setState({ reviewList: [...reviewList, newItem] })
				})
		}
	}
	addNewResponse(values, index) {
		const reviewList = this.state.reviewList
		let fileValue = values[`mainfileupload${index}`]
		const newItem = {
			name: this.state.user,
			date: formatDate(new Date()),
			comment: values[`comment${index}`],
			commentImage: null,
			commentImageError: false
		}
		reviewList[index].responseList.push(newItem)
		if( fileValue === null ) {
			this.setState({ reviewList })
		} else {
			fileReader(fileValue)
				.then(result => {
					newItem.commentImage = result
				})
				.catch(error => {
					newItem.commentImageError = true
				})
				.finally(() => {
					this.setState({ reviewList })
				})
		}
	} 
	render() {     

        const { UserCanAddReview, legalFileForContest, hideCommentContent } = this.props; 

		return (
			 
			<div className="ctn_comment" id="ctnComment">
			<div className="bg_comment" ref={this.bgComment} id="bgComment"></div>
			
				<div className="ctn_comments">
				<h3>{this.state.CurrentArticleReview && this.state.CurrentArticleReview.reviewsCount} {this.state.CurrentArticleReview && this.state.CurrentArticleReview.reviewsCount > 1 ? this.props.t('ArticleCommentaires') : this.props.t('ArticleCommentaire') }</h3>
				<div className="ctn_top">
					<Formik
						initialValues={{
							mainfileupload: null,
							comment: '',  
							legal_checkbox :legalFileForContest ? false: true,
						}}
						validationSchema={
							Yup.object().shape({
								comment: Yup.string().required(),  
								legal_checkbox: Yup.bool().oneOf([true]).required() 
							})
						}
						onSubmit={(values, actions) => {
							// add loader
							document.body.classList.add('is-loading')

							var oFormData = new FormData();	
							Object.entries(values).forEach(elt => oFormData.append(elt[0], elt[1]));
							oFormData.append("idref", document.getElementById("idref").value);
							axios({ 
								url:this.state.UrlSendReview,
								headers:  {
									'Accept': 'application/json',
									'Content-Type': 'multipart/form-data'
								  },
								method: 'POST',
								data : oFormData,
								withCredentials: true, 
							  }) 
							.then(res => {		
									window.location.reload(false);
									//actions.resetForm()
									//this.MainSingleFileUpload.destroy()
									//this.addNewComment(values)
									//document.body.classList.remove('is-loading')
							})
							.catch(err => {
								this.setState({serverError: true})
								document.body.classList.remove('is-loading')
							}); 
						}}
					>
						{ ({ handleSubmit, setFieldValue, setFieldTouched, errors, touched }) => (
							<form onSubmit={handleSubmit}>
								<div className="obj_form">
									<div className="ctn_textarea mb_30 no-border">
										<Field className={errors.comment && touched.comment && 'error'} 
										as="textarea" name="comment" placeholder={this.props.t('VotreCommentaire')} />
									</div>
									<div className="ctn_file-upload mb_20">
										<SingleFileUpload
											onRef={ref => (this.MainSingleFileUpload = ref)}
											setFieldValue={setFieldValue}
											setFieldTouched={setFieldTouched}
											errors={errors}
											touched={touched}
											fieldName={'mainfileupload'}
											labelText={this.props.t('AjouterUnePhoto')}
											ctaCancelText={this.props.t('ctaCancelText')}
											formatErrorText={this.props.t('formatErrorText')}
											sizeErrorText={this.props.t('sizeErrorText')}
											loadingErrorText={this.props.t('loadingErrorText')}
										/>
										<div className="i_add-plus"></div>
									</div>
									<p className="t_mention mb_30">{this.props.t('UploadMention')}</p>

									{legalFileForContest && 
										<div className="ctn_checkbox mb_30">
											<Field type="checkbox" id="checkboxGameLegal" name="legal_checkbox" />
											<label className={errors.legal_checkbox && touched.legal_checkbox && 'error'} htmlFor="checkboxGameLegal">
												{this.props.t('ArticleJAiLu')} <a href={legalFileForContest.src}>{this.props.t('ArticleLeReglementDuJeu')}</a>
											</label>
										</div>
									}

									<p className="t_mention mb_30" dangerouslySetInnerHTML={{__html: this.props.t('UploadPPDP')}}></p>
									 
									{this.state.serverError && <p className="t_error mb_30 fade-in">{this.props.t('DefaultServerError')}</p>}
									{( (errors.comment && touched.comment) || (errors.legal_checkbox && touched.legal_checkbox)  ) && <p className="t_error mb_30 fade-in">{this.props.t('DefaultErrorMessage')}</p>}
									<div className="ctn_cta">
										<button className="obj_cta" type="submit">{this.props.t('CommentaireValider')}</button>
									</div>
								</div>
							</form>
						)}
					</Formik>
				</div>

				
				{this.state.CurrentArticleReview && this.state.CurrentArticleReview.reviews && this.state.CurrentArticleReview.reviews
							.slice(0, this.state.reviewToShow)
							.map( (reviewItem, index) => ( 
					<div key={index} className="ctn_row fade-in">
						<div className="row_level-1">															
							<div className="ctn_message">
								
								<input type="hidden" id={`idrefReview${index}`} value={reviewItem.idrefReview}></input>
								<figure>
									{reviewItem.reviewsFOU.userimg ?
										<img src={reviewItem.reviewsFOU.userimg} alt={reviewItem.reviewsFOU.firstname + " " + reviewItem.reviewsFOU.lastname}/>
										:
										<img src={require("../../assets/images/v_avatar.png")} alt={reviewItem.reviewsFOU.firstname + " " + reviewItem.reviewsFOU.lastname}/>
									}
								</figure>
								<div className="ctn_text">
									<h4><strong>{reviewItem.reviewsFOU.firstname + " " + reviewItem.reviewsFOU.lastname}</strong> 
										&nbsp;  
										{hideCommentContent ? this.props.t('ArticleParticipation') : this.props.t('ArticleLe')} <Moment format={this.props.t('FormatDate')}>{reviewItem.creationDate.dateValue}</Moment></h4>
									{!hideCommentContent && reviewItem.comment.jss.value.split('\n').map( (i, index) => {
											return <p key={index}>{i}</p>
										})}
									{!hideCommentContent && reviewItem.commentImageError &&
										<p className="t_error">{this.props.t('ImageError')}</p>
									}
									{!hideCommentContent && reviewItem.image.jss.value &&
										<CommentImageModal CommentImage={reviewItem.image.jss.value.src} />
									}
								</div>
							</div>
												
							{!hideCommentContent && !reviewItem.formVisible &&
								<button type="button" className="bt_reply" onClick={() => this.toggleCommentForm(this.state.CurrentArticleReview.reviews, reviewItem, index)}>
									{this.props.t('CommentaireRepondre')}
								</button>
							}

							{!hideCommentContent && reviewItem.formVisible &&

								<Formik
									initialValues={{
										[`mainfileupload${index}`]: null,
										[`comment${index}`]: '', 
										[`legal_checkbox${index}`]: legalFileForContest ? false: true,
									}}
									validationSchema={
										Yup.object().shape({
											[`comment${index}`]: Yup.string().required(), 
											[`legal_checkbox${index}`]: Yup.bool().oneOf([true]).required() ,
										})
									}
									onSubmit={(values, actions) => {
										// add loader
										document.body.classList.add('is-loading')

										var oFormData = new FormData();	
										Object.entries(values).forEach(elt => oFormData.append(elt[0], elt[1]));
										oFormData.append("idref", document.getElementById("idref").value);
										oFormData.append("idrefReview", document.getElementById(`idrefReview${index}`).value);
										axios({ 
											url:this.state.UrlSendReview,
											headers:  {
												'Accept': 'application/json',
												'Content-Type': 'multipart/form-data'
											},
											method: 'POST',
											data : oFormData,
											withCredentials: true, 
										}) 
										.then(res => {		
												
											window.location.reload(false);
											
												//actions.resetForm()
												//this.MainSingleFileUpload.destroy()
												//[`mainfileupload${index}`].current.destroy()
												//this.addNewComment(values)
											document.body.classList.remove('is-loading')
										})
										.catch(err => {
											this.setState({serverError: true})
											document.body.classList.remove('is-loading')
										}); 

										/*setTimeout(() => {
											// remove loader
											document.body.classList.remove('is-loading')
											// return values
											// server error
											// this.setCommentServerError(this.state.reviewList, reviewItem, index)
											// success
											this.toggleCommentForm(this.state.reviewList, reviewItem, index)
											this.addNewResponse(values, index)
										}, 1000)*/
									}}
								>
									{ ({ handleSubmit, setFieldValue, setFieldTouched, errors, touched }) => (
										<form onSubmit={handleSubmit}>
											<div className="obj_form">
												<div className="ctn_textarea mb_30 no-border">
													<Field className={errors[`comment${index}`] && touched[`comment${index}`] && 'error'} as="textarea" 
													name={[`comment${index}`]} placeholder={this.props.t('VotreCommentaire')} />
												</div>
												<div className="ctn_file-upload mb_20">
													<SingleFileUpload
														onRef={ref => (this.SingleFileUpload = ref)}
														setFieldValue={setFieldValue}
														setFieldTouched={setFieldTouched}
														errors={errors}
														touched={touched}
														fieldName={[`mainfileupload${index}`]}
														labelText={this.props.t('AjouterUnePhoto')}
														ctaCancelText={this.props.t('ctaCancelText')}
														formatErrorText={this.props.t('formatErrorText')}
														sizeErrorText={this.props.t('sizeErrorText')}
														loadingErrorText={this.props.t('loadingErrorText')}
													/>
													<div className="i_add-plus"></div>
												</div>
												<p className="t_mention mb_30">{this.props.t('UploadMention')}</p>

												{legalFileForContest && 
													<div className="ctn_checkbox mb_30">
														<Field type="checkbox" id={[`legal_checkbox${index}`]} name={[`legal_checkbox${index}`]} />
														<label className={errors[`legal_checkbox${index}`] && touched[`legal_checkbox${index}`] && 'error'} htmlFor={[`legal_checkbox${index}`]}>
															{this.props.t('ArticleJAiLu')} <a href={legalFileForContest.src}>{this.props.t('ArticleLeReglementDuJeu')}</a>
														</label>
													</div>
												}

												<p className="t_mention mb_30" dangerouslySetInnerHTML={{__html: this.props.t('UploadPPDP')}}></p>
 
												{reviewItem.serverError && <p className="t_error mb_30 fade-in">{this.props.t('DefaultServerError')}</p>}
												{( (errors[`comment${index}`] && touched[`comment${index}`]) || (errors[`legal_checkbox${index}`] && touched[`legal_checkbox${index}`])  ) && <p className="t_error mb_30 fade-in">{this.props.t('DefaultErrorMessage')}</p>}
												<div className="ctn_cta">
													<button className="obj_cta" type="submit">{this.props.t('CommentaireValider')}</button>
													<button className="obj_cta grey" type="button" onClick={() => this.toggleCommentForm(this.state.reviewList, reviewItem, index)}>{this.props.t('CommentaireAnnuler')}</button>
												</div>
											</div>
										</form>
									)}
								</Formik>

									} 
						</div>

						{reviewItem.responseList.length > 0 &&  

							<div className="row_level-2">

								{reviewItem.responseList.slice(0, this.state.responseToShow).map( (responseItem, index) => (
									<div  key={index} className="ctn_message fade-in">
										<figure>
											{responseItem.reviewsFOU.userimg ?
												<img src={responseItem.reviewsFOU.userimg} alt={responseItem.reviewsFOU.firstname + " " + responseItem.reviewsFOU.lastname}/>
												:
												<img src={require("../../assets/images/v_avatar.png")} alt={responseItem.reviewsFOU.firstname + " " + responseItem.reviewsFOU.lastname}/>
											}
										</figure>
										<div className="ctn_text">
											<h4><strong>{responseItem.reviewsFOU.firstname + " " + responseItem.reviewsFOU.lastname}</strong> 
												&nbsp; 
												{hideCommentContent ? this.props.t('ArticleParticipation') : this.props.t('ArticleLe')} <Moment format={this.props.t('FormatDate')}>{responseItem.creationDate.dateValue}</Moment></h4>
											
											{!hideCommentContent && responseItem.comment.jss.value.split('\n').map( (i, index) => {
												return <p  key={index}>{i}</p>
											})}
											{!hideCommentContent && responseItem.commentImageError &&
												<p className="t_error"></p>
											}
											{!hideCommentContent && responseItem.image.jss.value &&
												<CommentImageModal CommentImage={responseItem.image.jss.value.src} />
											}
										</div>
									</div>
								))}

								{(reviewItem.responseList.length > this.state.responseToShow) && (
									<button type="button" className="bt_more" onClick={this.showMoreResponse}>Voir plus de réponses ({reviewItem.responseList.length - this.state.responseToShow})</button>
								)}
							</div>
				
							}
					</div>
				))}

				{(this.state.CurrentArticleReview && this.state.CurrentArticleReview.reviewsCount > this.state.reviewToShow) && (
					<div className="ctn_bt">
						<button type="button" className="bt_more" onClick={this.showMoreReview}>{this.props.t('ArticleCommentairesSuivants')} ({this.state.CurrentArticleReview.reviewsCount - this.state.reviewToShow})</button>
					</div>
				)}

				 {UserCanAddReview &&
					<div className="ctn_bt">
							<ScrollLink
							className="bt_more"
							to="bgComment"
							smooth={true}
							offset={isMobile() ? -110 : -60}
						>
							{this.props.t('ArticleVotreCommentaire')}
						</ScrollLink>
					</div>
				}
			</div>
			
		</div>
		
		)
	}
}

export default withTranslation()(SectionComments);