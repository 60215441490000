import React, { Component } from 'react'
import LazyLoad from 'react-lazyload'
import { withSitecoreContext, Image, Text, Link, RichText } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { ConditionalWrapper } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions"

class ObjPushCustomThumb extends Component {
	render() {
		return (
			this.props.fields 
			? <article className={`obj_push-custom var_thumb${
				this.props.fields["Push width"] ? ' var_' + this.props.fields["Push width"].value : ''}${
				this.props.fields["Push Align"] ? ' var_' + this.props.fields["Push Align"].value : ''}${
				(this.props.fields["Push url"] && this.props.fields["Push url"].value && this.props.fields["Push url"].value.href !=="") ? '' : ' is-not-available'}${
				(this.props.fields["Push Custom"] && this.props.fields["Push Custom"].value) ? ' var_' + this.props.fields["Push Custom"].value : ''}`}>
				{
					<>
						<figure>
							<ConditionalWrapper 
								condition={this.props.fields["Push url"] && this.props.fields["Push url"].value && this.props.fields["Push url"].value.href !==""}
								wrapper={children => 
								<Link field={this.props.fields["Push url"].value}>{children}</Link>}>
								<LazyLoad>
									<Image className="fade-in" media={this.props.fields["Image"].value}/>
								</LazyLoad>
							</ConditionalWrapper>
						</figure>
						
						<RichText field={this.props.fields["Push title"]} tag="h2"/>
						
						<div className="ctn_cta">
							{this.props.fields["Push url"] && this.props.fields["Push url"].value && this.props.fields["Push url"].value.href !=="" 
							? <Link className="obj_cta"  field={this.props.fields["Push url"].value}><Text className="obj_cta" field={this.props.fields["Push CTA"]} tag=""/></Link> 
							: <p>Prochainement disponible</p>}
						</div>
					</>
				}
			</article>
			: "ObjPushCustomThumb : " + this.props.t('NoContentToMap')
		)
	}
}

export default withTranslation()(withSitecoreContext()(ObjPushCustomThumb));