export const throttle = (callback, interval) => {
	var last = 0,
		timer
	interval = interval || 16
	return function(){
		var now 	= Date.now(),
			self 	= this,
			args	= arguments,
			fn 		= function(){callback.apply(self, args)}
		if(now - last >= interval){
			last = now
			fn()
			clearTimeout(timer)
			timer = setTimeout(fn, interval * 1.5)
		}
	}
}

export const matchHeight = (selector) => {
	var getMatchElt = document.querySelectorAll(selector)
	var arrayLength = getMatchElt.length
	var heights = []
	for (var i = 0; i < arrayLength; i++) {
		getMatchElt[i].style.height = ""
		heights.push(getMatchElt[i].offsetHeight)
	}
	function getHighest() {
		return Math.max(...heights)
	}
	var tallest = getHighest()
	for (i = 0; i < arrayLength; i++) {
		getMatchElt[i].style.height = tallest + "px"
	}
}

export const equalHeight = (selector, limit) => {

	var getMatchElt = document.querySelectorAll(selector),
		nMax = 0,
		nMin = 10000,
		lastIndex = 0,
		indexMin = 0,
		indexMax = 0,
		i = 0

	limit = limit > 0 ? limit : Infinity

	lastIndex = getMatchElt.length - 1
	indexMin = 0
	indexMax = (limit - 1 < lastIndex) ? limit - 1 : lastIndex

	while (indexMin <= lastIndex) {
		nMax = 0
		nMin = 10000

		for (i = indexMin; i <= indexMax; i++) {
			nMax = Math.max(getMatchElt[i].offsetHeight, nMax)
			nMin = Math.min(getMatchElt[i].offsetHeight, nMin)
		}

		if (nMax !== nMin) {
			for (i = indexMin; i <= indexMax; i++) {
				getMatchElt[i].style.height = nMax + "px"
			}
		}

		indexMin += limit
		indexMax = (indexMax + limit < lastIndex) ? indexMax + limit : lastIndex
	}
}

export const heightAuto = (selector) => {
	document.querySelectorAll(selector).forEach(element => {
		element.style.height = "auto"
	})
}

export const isMobile = () => {
	if(typeof window !== 'undefined'){
		return window.innerWidth <= 767 ? true : false
	}
}

export const isAtBottom = () => {
	if(typeof window !== 'undefined'){
		return (window.innerHeight + document.documentElement.scrollTop) >= (document.documentElement.offsetHeight - 400) ? true : false
	}
}

export const isNotAtTop = () => {
	if(typeof window !== 'undefined'){
		return window.scrollY > 0 ? true : false
	}
}

export const isOneScreenDown = () => {
	if(typeof window !== 'undefined'){
		return window.scrollY > window.innerHeight ? true : false
	}
}

export const scrollToTop = (scrollDuration) => {
	if(typeof window !== 'undefined'){
		var cosParameter = window.scrollY / 2,
			scrollCount = 0,
			oldTimestamp = performance.now()
		const step = (newTimestamp) => {
			scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp))
			if (scrollCount >= Math.PI) window.scrollTo(0, 0)
			if (window.scrollY === 0) return
			window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)))
			oldTimestamp = newTimestamp
			window.requestAnimationFrame(step)
		}
		window.requestAnimationFrame(step)
	}
}

export const scrollToTarget = (selector, shift, shiftMobile) => {
	var rect = selector.getBoundingClientRect(),
		scrollTop = window.pageYOffset || document.documentElement.scrollTop,
		shiftValue = window.innerWidth <= 767 ? shiftMobile : shift,
		top = rect.top + scrollTop - shiftValue
	window.scrollTo(0, top)
}

export const formatDate = (date) => {
	var d = new Date(date)
	var month = '' + (d.getMonth() + 1)
	var day = '' + d.getDate()
	var year = d.getFullYear()
	if (month.length < 2) month = '0' + month
	if (day.length < 2) day = '0' + day
	return [day, month, year].join('/')
}

export const isImage = (type) => {
	return (type === 'image/jpeg' || type === 'image/png' || type === 'image/gif')
}

export const fileReader = (file) => {
	return new Promise((resolve, reject) => {
		let filereader = new FileReader()
		filereader.onload = () => resolve(filereader.result)
		filereader.onerror = reject
		filereader.readAsDataURL(file)
	})
}

export const sectionFullWidth = (selector) => {
	var windowWidth = document.body.clientWidth,
		windowMargin = (windowWidth - 1280) / 2,
		sectionBrandSelection = selector,
		sectionBrandSelectionChild = sectionBrandSelection && sectionBrandSelection.querySelector("ul")
	if(windowWidth >= 1280) {
		sectionBrandSelectionChild.style.width = windowWidth + "px"
		sectionBrandSelectionChild.style.transform = "translateX(-" + windowMargin + "px)"
		setTimeout(() => {
			var sectionBrandSelectionChildHeight = sectionBrandSelectionChild.clientHeight
			sectionBrandSelection.style.height = sectionBrandSelectionChildHeight + "px"
		}, 10)
	} else {
		sectionBrandSelection.style.height = ""
		sectionBrandSelectionChild.style.width = ""
		sectionBrandSelectionChild.style.transform = ""
	}
}

export const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children

export default {
	throttle,
	matchHeight,
	equalHeight,
	heightAuto,
	isMobile,
	isAtBottom,
	isNotAtTop,
	isOneScreenDown,
	scrollToTop,
	scrollToTarget,
	formatDate,
	isImage,
	fileReader,
	sectionFullWidth,
	ConditionalWrapper
}