import { withSitecoreContext, Image, RichText, Text} from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import React, { Component } from 'react' 
import { matchHeight } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions"
import Slider from "react-slick"
import LazyLoad from 'react-lazyload'

class SectionProductCarousel extends Component {
 
	componentDidMount(){
		matchHeight('.obj_carousel.obj_push-carousel h4')
	}
	render() {
		const { CurrentProduct  } = this.props.fields.data;  
		const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
		
		const settings = {
			slidesToShow: 4,
			slidesToScroll: 1,
			infinite: false,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 3,
						infinite: false
					}
				},
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 1,
						infinite: true
					}
				}
			]
		}
    

		return (
			<>
			{CurrentProduct && CurrentProduct.complementaryProduct && CurrentProduct.complementaryProduct.targetItems && CurrentProduct.complementaryProduct.targetItems.length > 0 && 
				<section className="section_product-carousel">
				<div className="str_container">

					{ oCurrentItemFields && <Text field={oCurrentItemFields["Complementary product Title"]} tag="h2"></Text> }

					<Slider className="obj_carousel obj_push-carousel" {...settings}>
					{ CurrentProduct.complementaryProduct.targetItems.map((pushItem, index) => (
						<article key={index} className="obj_push-product">
						<a href={pushItem.url}>
							<LazyLoad>
								<figure className="fade-in">
									<Image field={pushItem.pageImageSmall.jss}></Image>
								</figure>
							</LazyLoad>
							<RichText className="t_title" tag="h4" field={pushItem.pageTitle.jss}></RichText>
							<RichText className="t_desc" tag="p" field={pushItem.pageSubTitle.jss}></RichText>
						</a>
						</article>
					))}
					</Slider>

				</div>
				</section>
				}
			</>
		)
	}
}

export default withTranslation()(withSitecoreContext()(SectionProductCarousel));