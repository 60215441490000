import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'; 
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazyload'

import { dayRemaining, strDateToFrLong, urlToHost } from "../../utilities/FrontFunctions/AoxFrontFunctions"
import { dataEventTypes } from "../../data/events"

class ObjPushEventSession extends Component {
	constructor(props) {
		super(props)
		this.state = {
		}
	}
	render() {
		const {eventSession, pageEventDetail} = this.props;
        
		const DayRemaining = eventSession ? dayRemaining(eventSession.EventSession_StartDate) : -1;
		const EventFinished = DayRemaining < 0;
		let SessionsInfos =  eventSession.EventType_Code === 'WEB' ? eventSession?.EventSession_StartHour : eventSession?.Place?.EventPlace_City


		return (
			<>
				{ eventSession &&
					<div className={`obj_formation
						${' ' + dataEventTypes[eventSession.EventType_Code].css}
						${' ' + eventSession.Brand.Brand_CssClass}
						${eventSession.UserRegistered ?" is_registered" :""}
						${	EventFinished
							?" is_finished"
							:	eventSession.EventSession_Canceled
								? " is_canceled"
								:""
						}`
					}>
						<div className="ctn_header-title">
							<span>{eventSession.EventType_Name}</span>
						</div>

						<div className="ctn_row">
							<div className="ctn_text">
								{eventSession.UserRegistered === true && (
									(DayRemaining < 0) 
									? <></>
									:	(DayRemaining===0)
										? <div className="obj_jours-restant">Dernier jour</div>
										: <div className="obj_jours-restant">J-{DayRemaining}</div>
								)}

								<p className="t_title">{eventSession.Event_Title}</p>

								<ul className="list_infos">
									<li className="t_date">
										{	(eventSession.EventSession_EndDate!=null)
											? <>Du {strDateToFrLong(eventSession.EventSession_StartDate)}<br/>au {strDateToFrLong(eventSession.EventSession_EndDate)}</>
											: <>{strDateToFrLong(eventSession.EventSession_StartDate)}</>
										}
									</li>

									<li className="t_info">
										{SessionsInfos ? SessionsInfos : "-"}
									</li>
								</ul>

								{	EventFinished
									?<p className="t_statut">Événement passé</p>
									:	eventSession.EventSession_Canceled
										? <p className="t_statut">Evènement annulé</p>
										:	eventSession.UserRegistered
											? <>
												<Link to={`${pageEventDetail?.url}?id=${eventSession.Event_Id}&idSession=${eventSession.EventSession_Id}`} className="obj_btn light">gérer mon inscription</Link>
												<p className="t_statut">Vous êtes inscrits</p>
											</>
											: <Link to={`${pageEventDetail?.url}?id=${eventSession.Event_Id}&idSession=${eventSession.EventSession_Id}`} className="obj_btn">inscrivez-vous</Link>
								}
							</div>

							<div className="ctn_image">
								<div className="ctn_visuel fade-in">
									<LazyLoad>
										<img src={urlToHost(eventSession.Event_MediaUrl)} alt={eventSession.Event_MediaAlt}/>
									</LazyLoad>
								</div>

								<div className="ctn_logo">
									<LazyLoad>
										<img src={urlToHost(eventSession.Brand.Brand_LogoUrl)} alt={eventSession.Brand.Brand_Name}/>
									</LazyLoad>
								</div>
							</div>
						</div>
					</div>
				}
			</>
		)
	}
}

export default withTranslation()(ObjPushEventSession)