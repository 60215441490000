import React, { Component } from 'react'
import Modal from 'react-modal'
import { Player } from '@lottiefiles/react-lottie-player';

import ObjCanvasBadgeExpertise from "../ObjExpertise/ObjCanvasBadgeExpertise"

const customStyles = {
	overlay : {
		zIndex			: '99999',
		backgroundColor	: 'rgba(0,0,0,0)'
	}
}

Modal.setAppElement('#root')

class ExpertiseBadgeModal extends Component {
	constructor() {
		super()
		this.state = {
			modalIsOpen: false
		}
		this.openModal = this.openModal.bind(this)
		this.closeModal = this.closeModal.bind(this)
	}
	componentDidMount() {
		this.props.onRef(this)
	}
	componentWillUnmount() {
		this.props.onRef(null)
	}
	openModal() {
		this.setState({modalIsOpen: true})
	}
	closeModal() {
		this.setState({modalIsOpen: false})
		if(this.props.handleClose){ this.props.handleClose() }
	}
	render() {
		const { badge, userBadge, badgeDataTheme } = this.props

		return (
			<>
				<Modal
					isOpen={this.state.modalIsOpen}
					onRequestClose={this.closeModal}
					style={customStyles}
					className="obj_popin popin_overlay_badge_detail"
					closeTimeoutMS={300}
					contentLabel="Modal"
					bodyOpenClassName={this.props.bodyOpenClassName ? this.props.bodyOpenClassName : 'ReactModal__Body--open'}
				>
					<button className="bt_close" onClick={this.closeModal}></button>
					<div className="ctn_popin">
						<div className="obj_section_badge_expertise">
							<div className="obj_badge_expertise">
								<ObjCanvasBadgeExpertise
									badge={badge}
									newBadge={this.props.newBadge}
								/>
								{!userBadge &&
									<div className="t_badge_collecter"><span>Badge à collecter</span></div>
								}
								{this.props.newBadge &&
									<div className="ctn_lottie">
										<Player
											autoplay
											src={require('../../assets/lottie/badge.json')}
										/>
									</div>
								}
							</div>

							<div className="ctn_text">
								<h3 className="t_title">{badge.Badge_Title}</h3>
								<p className="t_desc">{badge.Badge_Description}</p>
								<div className="t_tag"><span>{badge.UserRewardAction_Points} <em>PTS</em></span></div>
							</div>
						</div>
					</div>
					<div className="overlay-1" style={{backgroundColor: badgeDataTheme?.overlayColor2}}></div>
					<div className="overlay-2" style={{backgroundColor: badgeDataTheme?.overlayColor1}}></div>
				</Modal>
			</>
		)
	}
}

export default ExpertiseBadgeModal