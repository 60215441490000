import { withSitecoreContext, Text, Image, RichText } from "@sitecore-jss/sitecore-jss-react";
import { withTranslation } from "react-i18next";
import React, { Component } from "react";

import { throttle, isMobile, matchHeight, heightAuto } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions";
import IconSVG from "../../utilities/FrontFunctions/IconSVG";

class ProductSegmentRoutines extends Component {
	constructor(props) {
		super(props);
		this.handleWindowResizeRoutineList = this.handleWindowResizeRoutineList.bind(this);
		this.throttleWindowResizeRoutineList = throttle(this.handleWindowResizeRoutineList, 200);
	}
	componentDidMount() {
		window.addEventListener("resize", this.throttleWindowResizeRoutineList);
		this.handleWindowResizeRoutineList();
	}
	componentWillUnmount() {
		window.removeEventListener("resize", this.throttleWindowResizeRoutineList);
	}
	handleWindowResizeRoutineList() {
		if (!isMobile()) {
			matchHeight(".obj_push-product .t_title-routine");
			matchHeight(".obj_push-product .t_title");
		} else {
			heightAuto(".obj_push-product .t_title-routine");
			heightAuto(".obj_push-product .t_title");
		}
	}
	render() {
		const { item } = this.props.fields.data;
		const oCurrentItemFields = this.props.sitecoreContext.route?.fields;

		return (
			<section className="section_product-liste">
				<div className="str_container">
					<section className="obj_banner-routine">
						<div className="ctn_text">
							{oCurrentItemFields && <RichText tag="h1" field={oCurrentItemFields["Page Title"]} />}
							{oCurrentItemFields && <RichText tag="h2" field={oCurrentItemFields["Page SubTitle"]} />}
						</div>
					</section>

					<section className="section_range-liste">
						<div className="ctn_range-liste">
							{item &&
								item.Routines &&
								item.Routines.length > 0 &&
								item.Routines.map((elem, index) => (
									<article className="obj_push-product">
										<a href={elem.productRoutine && elem.productRoutine.targetItems && elem.productRoutine.targetItems.length > 0 && elem.productRoutine.targetItems[0].url}>
											<RichText tag="h3" className="t_title-routine" field={elem.titleRoutine.jss} />
											<p className="t_desc-routine">
												<Text field={elem.textRoutine.jss}></Text>
												{elem.morningRoutine.boolValue && <IconSVG icon="sun" />}
												{elem.eveningRoutine.boolValue && <IconSVG icon="moon" />}
											</p>
											<figure>
												<Image
													field={
														elem.productRoutine &&
														elem.productRoutine.targetItems &&
														elem.productRoutine.targetItems.length > 0 &&
														elem.productRoutine.targetItems[0].pageImageSmall.jss
													}
												></Image>
												<span className="fake_cta"></span>
											</figure>
											{elem.productRoutine && elem.productRoutine.targetItems && elem.productRoutine.targetItems.length > 0 && elem.productRoutine.targetItems[0].pageTitle && (
												<RichText tag="h4" className="t_title" field={elem.productRoutine.targetItems[0].pageTitle.jss} />
											)}
											{elem.productRoutine &&
												elem.productRoutine.targetItems &&
												elem.productRoutine.targetItems.length > 0 &&
												elem.productRoutine.targetItems[0].pageSubTitle && <RichText tag="p" className="t_desc" field={elem.productRoutine.targetItems[0].pageSubTitle.jss} />}
										</a>
									</article>
								))}
						</div>
					</section>
				</div>
			</section>
		);
	}
}

export default withTranslation()(withSitecoreContext()(ProductSegmentRoutines));
