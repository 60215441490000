import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import LazyLoad from "react-lazyload";
import moment from "moment";

class SectionFormation extends Component {
	constructor(props) {
		super(props);
		this.handleRunFormation = this.handleRunFormation.bind(this);
	}

	handleRunFormation() {
		if (this.props.FormationData.trainingUrl.url !== "" && this.props.FormationData.trainingDateAvailable.dateValue < new Date()) {
			document.location.href = this.props.FormationData.trainingUrl.url;
		}
	}

	render() {
		var bTrainingNotAvailable = this.props.FormationData.trainingDateAvailable.dateValue > new Date();

		var bTrainingDone = !bTrainingNotAvailable && this.props.FormationData.checkdoTraining;
		var bTrainingInProgress = !bTrainingNotAvailable && this.props.FormationData.checkTrainingInProgress;
		var bDoTraining = !bTrainingNotAvailable && !bTrainingInProgress && !bTrainingDone;

		var sDateTraining = moment(this.props.FormationData.trainingDateAvailable.dateValue).format(this.props.t("FormatDateFormation"));

		return (
			this.props.FormationData.trainingUrl.url !== "" && (
				<article className={`ctn_page-liste${bTrainingDone ? " is-done" : ""}${bTrainingInProgress ? " is-progress" : ""}${bTrainingNotAvailable ? " is-not-available" : ""}`}>
					<button type="button" onClick={this.handleRunFormation}>
						<div className="ctn_page-liste-visuel">
							<LazyLoad>
								<figure className="fade-in">
									<Image field={this.props.FormationData.pageImage.jss}></Image>
								</figure>
							</LazyLoad>
							<div className="obj_cta-formation">
								{bTrainingDone && this.props.t("Formation_RevoirLaFormation")}
								{bTrainingInProgress && this.props.t("Formation_FormationInProgress")}
								{bDoTraining && this.props.t("Formation_EffectuerLaFormation")}
								{bTrainingNotAvailable && (
									<>
										{this.props.t("Formation_DisponibleLe")} {sDateTraining}
									</>
								)}
								<div className="obj_cta-formation-picto"></div>
							</div>
						</div>
						<Text field={this.props.FormationData.pageTitle.jss} tag="h3"></Text>
					</button>
				</article>
			)
		);
	}
}

export default withTranslation()(SectionFormation);
