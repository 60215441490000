import React, { Component } from 'react' 
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'  
import { withTranslation } from 'react-i18next';
import ObjPushArticle from '../AllPages/ObjPushArticle';
import ObjPushCustomFull from '../AllPages/ObjPushCustomFull';
    
class PageConcoursHome extends Component {
  constructor(){
		super()
		this.state = { 
			pushsFullToShow: 5,
			pushsFullInterval: 5,
			pushsArticleToShow: 5,
			pushsArticleInterval: 5
		}
	}
  componentDidMount(){
		this.switchTab(0)
	}
	switchTab(index){
		var $listItem = document.querySelectorAll(".ctn_nav-tabs li"),
			$tabItem = document.querySelectorAll(".obj_tabs-list .ctn_row")

		$listItem.forEach(listItem => listItem.classList.remove("active"))
		$listItem[index].classList.add("active")
		$tabItem.forEach(tabItem => tabItem.classList.remove("active", "fade-in"))
		$tabItem[index].classList.add("active", "fade-in")

		window.dispatchEvent(new Event('scroll'))
	}
	showMorePushsArticle(){
		this.setState(prevState => ({
			pushsArticleToShow: prevState.pushsArticleToShow + this.state.pushsArticleInterval
		}))
	}
	showMorePushsFull(){
		this.setState(prevState => ({
			pushsFullToShow: prevState.pushsFullToShow + this.state.pushsFullInterval
		}))
	}
	render() {

	const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
	const { item } = this.props.fields.data; 
	
	var oLstpushContest;
	var oLstpushResult;
	if(item && item.children && item.children.length > 0){
		item.children.forEach(Folder =>{
			if(Folder.name === "__ContentForContest"){
				oLstpushContest = Folder.LstPush;
			}
			if(Folder.name === "__ContentForResults"){
				oLstpushResult = Folder.LstPush;
			}
		});
	}
  
	return (
		<>
			<div className="obj_badge-elite">
				<img src="images/v_badge-elite_lrp.png" alt="Élite"/>
			</div>

			{ oCurrentItemFields && <Text className="obj_title-big" field={oCurrentItemFields["Page Title"]} tag="h1"></Text> }

			<section className="section_page-liste">
				<div className="str_container">
					<div className="obj_tabs-list">

						<ul className="ctn_nav-tabs">
							{oLstpushContest.length > 0 && 
							<li><button type="button" onClick={() => this.switchTab(0)}>{this.props.t('ConcoursChallenge')}</button></li>}
							{oLstpushResult.length > 0 && 
							<li><button type="button" onClick={() => this.switchTab(1)}>{this.props.t('ResultatsDesConcours')}</button></li>}
						</ul>

						<section className="ctn_row">
							<div className="ctn_sub-row">

							{ oLstpushContest && oLstpushContest.slice(0, this.state.pushsArticleToShow).map((pushItem, index) => {
								return(
									<>
										{(pushItem.template.id === "A69E5AB47261487982639393B758E9F8") &&
											<ObjPushCustomFull pushContent={pushItem} key={index} /> }

										{(pushItem.template.id === "E31C15C8231947B0942E65ABE8F75C72") &&
											<ObjPushArticle  PushOnTitle={pushItem.pushTitle.value} 
															PushShowComment={pushItem.pushShowReviews.boolValue} 
															pushArticleItem={pushItem.pushArticle.articles[0] }
															PushCTAFull={true} key={index} />}
									</> 
									)} 
							)}
  
							{(oLstpushContest && oLstpushContest.length > this.state.pushsArticleToShow) && (
								<div className="ctn_cta">
								<button className="obj_cta" type="button" onClick={() => this.showMorePushsArticle()}>{this.props.t('AfficherPlusSujets')}</button>
								</div>
							)}

							</div>
						</section>

						<section className="ctn_row">
							<div className="ctn_sub-row">
 
							
							{ oLstpushResult && oLstpushResult.slice(0, this.state.pushsFullToShow).map((pushItem, index) => {
								return(
									<>
										{(pushItem.template.id === "A69E5AB47261487982639393B758E9F8") &&
											<ObjPushCustomFull pushContent={pushItem} key={index} /> }

										{(pushItem.template.id === "E31C15C8231947B0942E65ABE8F75C72") &&
											<ObjPushArticle  PushOnTitle={pushItem.pushTitle.value} PushShowComment={pushItem.pushShowReviews.boolValue} 
															pushArticleItem={pushItem.pushArticle.articles[0] }
															PushCTAFull={true} key={index} />}
									</> 
									)}
							)}

							{(oLstpushResult && oLstpushResult.length > this.state.pushsFullToShow) && (
								<div className="ctn_cta">
									<button className="obj_cta" type="button" onClick={() => this.showMorePushsFull()}>{this.props.t('AfficherPlusSujets')}</button>
								</div>
							)}

							</div>
						</section>

					</div>

					</div>
			</section>

			</>
		)
	}
}

export default withTranslation()(withSitecoreContext()(PageConcoursHome));