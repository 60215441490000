import React, { Component } from 'react'
import { Link as ScrollLink } from 'react-scroll'
import LazyLoad from 'react-lazyload' 
import { Text, Image, withSitecoreContext, RichText } from '@sitecore-jss/sitecore-jss-react'
import { withTranslation } from 'react-i18next';
import { isNotEmpty } from "../../utilities/FrontFunctions/AoxFrontFunctions"

class PageIngredientList extends Component {
	constructor(props) {
		super(props)
		
		this.state = {
			expandOpened: false
		}
	 
		// cta click event
		this.toggleExpandIngredients = this.toggleExpandIngredients.bind(this)
		// ref ctn_text
		this.containerExpandIngredients = React.createRef()
		// ref ctn_text div
		this.wrapperExpandIngredients = React.createRef()
	}
	toggleExpandIngredients() {
		// get expand height
		var expandHeight = this.wrapperExpandIngredients.current.clientHeight
		// set expand height
		if( !this.state.expandOpened ) {
			this.containerExpandIngredients.current.style.height = expandHeight + "px"
		} else {
			this.containerExpandIngredients.current.style.height = "0px"
		}
		// set bool state
		this.setState({
			expandOpened: !this.state.expandOpened
		})
	}

	getIngredientUrl(oIngredientItem) {

		if (oIngredientItem.urlIngredient == null || oIngredientItem.urlIngredient.jss == null)
		{
			return oIngredientItem.url;
		}		

		if (isNotEmpty(oIngredientItem.urlIngredient.jss.value.href)) 
		{
			return oIngredientItem.urlIngredient.jss.value.href;
		}  

		return oIngredientItem.url;
	}

	getIngredientUrlTarget(oIngredientItem) {

		if (oIngredientItem.urlIngredient == null || oIngredientItem.urlIngredient.jss == null)
		{
			return "_self";
		}		

		if (isNotEmpty(oIngredientItem.urlIngredient.jss.value.href)) 
		{
			return "_blank";
		}  

		return "_self";
	}

	render() {

    const oCurrentItemFields = this.props.sitecoreContext.route?.fields;
    const { LstIngredients } = this.props.fields.data;  

    
    let LstIngredientsGroup = LstIngredients.children.reduce((r, e) => {
      let group = e.pageTitle.value[0].toUpperCase();      
      if(!r[group]) r[group] = {group, children: [e]}
      else r[group].children.push(e);
      return r;
    }, {});
    let LstIngredientsGroupArray = Object.values(LstIngredientsGroup)
    
		return (
			<> 
			
			{ oCurrentItemFields && oCurrentItemFields["External Title"] === false && 
              <Text className="obj_title" field={oCurrentItemFields["Page Title"]} tag="h1"></Text>}
			

			<section className="section_header-ingredients">
				<div className="str_container">

					<div className="ctn_header-ingredients">
						{ oCurrentItemFields && oCurrentItemFields["Header Text 1"] && 
							<div>
								<figure>
									<picture>
										<source srcSet={oCurrentItemFields["Header Image Mob 1"].value.src} media="(max-width:767px)"/>
										<Image field={oCurrentItemFields["Header Image 1"]}></Image>
									</picture>
									<figcaption><Text field={oCurrentItemFields["Header Text 1"]}></Text></figcaption>
								</figure>
							</div>
						} 
						{ oCurrentItemFields && oCurrentItemFields["Header Text 2"] && 
							<div>
								<figure>
									<picture>
										<source srcSet={oCurrentItemFields["Header Image Mob 2"].value.src} media="(max-width:767px)"/>
										<Image field={oCurrentItemFields["Header Image 2"]}></Image>
									</picture>
									<figcaption><Text field={oCurrentItemFields["Header Text 2"]}></Text></figcaption>
								</figure>
							</div>
						} 
						{ oCurrentItemFields && oCurrentItemFields["Header Text 3"] && 
							<div>
								<figure>
									<picture>
										<source srcSet={oCurrentItemFields["Header Image Mob 3"].value.src} media="(max-width:767px)"/>
										<Image field={oCurrentItemFields["Header Image 3"]}></Image>
									</picture>
									<figcaption><Text field={oCurrentItemFields["Header Text 3"]}></Text></figcaption>
								</figure>
							</div>
						} 
						{ oCurrentItemFields && oCurrentItemFields["Header Text 4"] && 
							<div>
								<figure>
									<picture>
										<source srcSet={oCurrentItemFields["Header Image Mob 4"].value.src} media="(max-width:767px)"/>
										<Image field={oCurrentItemFields["Header Image 4"]}></Image>
									</picture>
									<figcaption><Text field={oCurrentItemFields["Header Text 4"]}></Text></figcaption>
								</figure>
							</div>
						} 
					</div>

					{ oCurrentItemFields && oCurrentItemFields["Page Text"] && oCurrentItemFields["Page Text"].value &&
						<div className="ctn_expand-ingredients">
							<Text field={oCurrentItemFields["Page SubTitle"]} tag="h2"></Text>
							<div className="ctn_text" ref={this.containerExpandIngredients}>
								<div ref={this.wrapperExpandIngredients}> 
								<RichText field={oCurrentItemFields["Page Text"]} ></RichText>            
								</div>
							</div>
							<div className="ctn_cta">
								<button className={`obj_cta${this.state.expandOpened ? ' light-grey' : ''}`} type="button" onClick={this.toggleExpandIngredients}>
									{this.state.expandOpened ? this.props.t('Ingredient_Fermer') : this.props.t('Ingredient_EnSavoirPlus')}
								</button>
							</div>
						</div>
					}

				</div>
			</section>

			<section className="section_liste-ingredients">
				<div className="str_container">

					<ul className="obj_nav-ingredients">
						{[...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'].map((alpha, index) => (
              				<li key={index}>
								<ScrollLink
									activeClass="current"
									className={`${LstIngredientsGroupArray.find(i => i.group ===  alpha)  ? '' : 'inactive'}`}
									to={alpha}
									spy={true}
									smooth={true}
									offset={-140}
								>
									{alpha}
								</ScrollLink>
							</li>
						))}
					</ul>

					{LstIngredientsGroupArray
						.filter(categoryItem => categoryItem.children.length > 0)
						.map((categoryItem, index) => (

							<div key={index} className="ctn_liste-ingredients" name={categoryItem.group}>

								<p className="t_title-letter">{categoryItem.group}</p>

								{categoryItem.children.map((ingredientItem, index) => (

									<div key={index} className="obj_liste-ingredients">
										<LazyLoad>
											<figure>
												<Image field={ingredientItem.pageImage.jss}></Image>
											</figure>
										</LazyLoad>
										<div className="ctn_text">
                      						<Text field={ingredientItem.pageTitle.jss} tag="h2"></Text>
											<Text field={ingredientItem.pageSubTitle.jss} tag="p"></Text>
											<div className="ctn_cta">
												<a className="obj_cta" target={this.getIngredientUrlTarget(ingredientItem)} href={this.getIngredientUrl(ingredientItem)}>{this.props.t('Ingredient_EnSavoirPlus')}</a>
											</div>
										</div>
									</div> 
                				))} 
							</div> 
					))}

				</div>
			</section>

			</>
		)
	}
}

export default withTranslation()(withSitecoreContext()(PageIngredientList));