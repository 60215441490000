import React from 'react';
import { useHistory } from 'react-router-dom';

const ObjRetour = () => {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
	<div className="obj_back-page">
		<button className="bt_back-page" onClick={handleGoBack}>Retour</button>
    </div>
  );
};

export default ObjRetour;