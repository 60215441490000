import React, { Component } from 'react' 
import { RichText, Image, Link } from '@sitecore-jss/sitecore-jss-react';
import { throttle } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions"

import Slider from "react-slick"

class SectionWideCarousel extends Component {
	constructor(props) {
		super(props)
	 
		this.refreshCarouselHeight = this.refreshCarouselHeight.bind(this)
		this.handleWindowResizeWideCarousel = this.handleWindowResizeWideCarousel.bind(this)
		this.throttleWindowResizeWideCarousel = throttle(this.handleWindowResizeWideCarousel, 100)
	}
	componentDidMount(){
		window.addEventListener('resize', this.throttleWindowResizeWideCarousel)
		window.dispatchEvent(new Event('resize'))
	}
	componentWillUnmount(){
		window.removeEventListener('resize', this.throttleWindowResizeWideCarousel)
	}
	refreshCarouselHeight(){
		var wideCarousel = document.querySelector('.wide-carousel'),
			slickList = wideCarousel.querySelector('.slick-list'),
			carouselHeight = wideCarousel.clientHeight
		slickList.style.height = carouselHeight + "px"
	}
	handleWindowResizeWideCarousel() {
		this.refreshCarouselHeight()
	}
	render() {
		const settings = {
			lazyLoad: true,
			autoplay: true,
			autoplaySpeed: 5000,
			dots: true,
			infinite: true,
			speed: 750,
			slidesToShow: 1,
			slidesToScroll: 1
    }
     
    const { Banners } = this.props.fields.data 

		return (
			<section className="section_wide-carousel">
				<div className="str_container">
					<Slider className="obj_carousel wide-carousel" {...settings}>
						{ Banners && Banners.FolderContentForPage[0].FolderBanners[0].Banners.map((bannerItem, index) => (
							<div key={index}> 
                				<div className={`ctn_banner fade-in${bannerItem.darkMode.boolValue ? ' var_dark' : ''}
                                                  ${bannerItem.styleBrand.targetItem ? bannerItem.styleBrand.targetItem.brandCssClass.value : ''}
                                                  ${bannerItem.align  ? ' var_' + bannerItem.align.value :''}`} bannerid={bannerItem.id}>
									{ bannerItem.template.id === "31C956382BE1472095B3E8D7A9E8C60D" &&
											<div className="ctn_text">
											{bannerItem.ontitle.jss && 
											<RichText field={bannerItem.ontitle.jss} tag="h4" />}
											{bannerItem.title.jss && 
											<RichText field={bannerItem.title.jss} tag="h2" />}
											{bannerItem.posttitle.jss && 
											<RichText field={bannerItem.posttitle.jss} tag="h3" />}
											{bannerItem.content.jss && 
											<RichText field={bannerItem.content.jss} tag="p" />}
										</div>
									}
									<picture>
										<source srcSet={bannerItem.imageMobile.jss.value.src} media="(max-width:767px)"/>
										<Image field={bannerItem.image.jss}></Image>
									</picture>
									{bannerItem.link.jss && bannerItem.link.jss.value && bannerItem.link.jss.value.href !== "" &&								
                  						<Link className={`obj_cta${bannerItem.darkMode.boolValue ? ' reverse' : ''}`} field={bannerItem.link.jss} />
									}
								</div>
							</div>
						))}
					</Slider>
				</div>
			</section>
		)
	}
}

export default SectionWideCarousel