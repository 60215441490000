import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'; 

import { getApiDatas } from "../../utilities/FrontFunctions/AoxFrontFunctions"

import ObjPushEvent from '../ObjEvent/ObjPushEvent';

class PageEventMyRegistrations extends Component {
	constructor(props) {
		super(props)
		this.state = {
			apiDatas:[],
		}
	}
	componentDidMount() {
		getApiDatas(`/apiaox/Event/GetDataPageMyRegistrations`)
		.then((apiDatas) => { this.setState({ apiDatas:apiDatas }) })

		document.body.classList.add('pg_formation_inscription')
		window.addEventListener('resize', this.throttleWindowResizeMainHeader)
		window.addEventListener('scroll', this.throttleWindowScrollMainHeader)
		window.scrollTo(0,0)
  	}

	render() {
		const apiDatas = this.state.apiDatas
		const { PageEventDetail } = this.props.fields.data;

		return (<>
			<section className="obj_section section_formation_list">
				<div className="ctn_section">

					<div className="ctn_title">
						<h1 className="t_title">Mes inscriptions</h1>
					</div>

					<div className="ctn_row">
						{ 	!apiDatas.Events 
							//API non chargée
							?<div className="obj_challenge is_empty"/>
							:	apiDatas.Events.length<1
								//pas de participations par l'API
								?<div className="obj_formation is_empty">
									<div className="ctn_text">
										<p className="t_title" dangerouslySetInnerHTML={{__html: "Votre équipe L'Oréal<br /> Beauté Dermatologique<br /> prépare avec soin vos<br /> prochains événements<br /><br />A très vite !"}} />
									</div>
									<picture className="ctn_background">
										<source srcSet={require('../../assets/images/v_empty_list_mob.jpg')} media="(max-width:767px)"/>
										<img src={require('../../assets/images/v_empty_list.jpg')} alt=""/>
									</picture>
								</div>
								:<>
									{apiDatas.Events.map((event, index) => (
										<ObjPushEvent key={index} 
                                            event={event} 
                                            pageEventDetail={PageEventDetail}
                                        />
									))}
								</>
						}
					</div>
				</div>
			</section>
		</>)
	}
}

export default withTranslation()(PageEventMyRegistrations)