import React, { Component } from 'react'
import ObjPushArticle from '../AllPages/ObjPushArticle'

class SectionPushArticle extends Component {
	render() {	
		var pushArticleItem = null;
	
		if(this.props.fields.data){
			pushArticleItem = this.props.fields.data.item
		} 
		return (
				pushArticleItem && 
					pushArticleItem.pushArticle && 
					pushArticleItem.pushArticle.articles && 
					<ObjPushArticle PushOnTitle={pushArticleItem.pushTitle.value} 
							PushShowComment={pushArticleItem.pushShowReviews.boolValue} 
							pushArticleItem={pushArticleItem.pushArticle.articles[0]} key={pushArticleItem.id} />     
		)
	}
}
export default SectionPushArticle;
