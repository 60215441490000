import React from "react";

function ObjProgressBar({pctProgress}) {
  const style = {
    width: `${pctProgress}%`
  };
  return (
    <div className="obj_progress-bar">
      <div className="ctn_steps">
        <div className="step" style={style}></div>
      </div>
    </div>
  );
}

export default ObjProgressBar;