import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { Link } from '@sitecore-jss/sitecore-jss-react';  
import { isAppMobile } from "../../utilities/FrontFunctions/AoxFrontFunctions"

class MainFooterLogin extends Component {

	constructor(props) {
		super(props)
  
		this.state = {
			isAppMobile: isAppMobile()			
		}
	}
	
	render() {
		if (this.state.isAppMobile)
		{
			return <></>
		}		

		var loading = null;
		var SecondNavMobile = null;       
        
        if(this.props.fields){
            SecondNavMobile = this.props.fields.data.SecondNavMobile;
		}
		
        if(this.props.fields){
            loading = this.props.fields.data.loading;
        }
        
		//const { loading, SecondNavMobile } = this.props.fields.data;
		return (
			<> 
				<footer className="main_footer-login">
					<div className="str_container">
						<div className="ctn_bottom">
							<nav className="second_nav-footer">
								<ul>
									{!loading && SecondNavMobile && SecondNavMobile.children.map((secondNavFooterItem, index) => (
										<li key={index} className="var_bold">
											<Link field={secondNavFooterItem.destination.jss}></Link>
										</li>
									))}									
									<li>
										<button id="ot-sdk-btn" className="ot-sdk-show-settings">Gestion des cookies</button>
									</li>
								</ul>
							</nav>
							<p className="t_mentions">{this.props.t('FooterMention')}</p>
						</div>
					</div>
				</footer>
			</>
			
		)
	}
}

export default withTranslation()(MainFooterLogin);