import React, { Component } from 'react'

import { throttle, isOneScreenDown, scrollToTop } from "../../utilities/FrontFunctions/AoxGlobalFrontFunctions"

class ObjBackToTop extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isVisible: isOneScreenDown()
		}
		this.handleWindowScrollBackToTop = this.handleWindowScrollBackToTop.bind(this)
		this.throttleWindowScrollBackToTop = throttle(this.handleWindowScrollBackToTop, 200)
		this.handleBackToTop = this.handleBackToTop.bind(this)
	}
	componentDidMount() {
		window.addEventListener('scroll', this.throttleWindowScrollBackToTop)
	}
	componentWillUnmount() {
		window.removeEventListener('scroll', this.throttleWindowScrollBackToTop)
	}
	handleWindowScrollBackToTop() {
		this.setState({
			isVisible: isOneScreenDown()
		})
	}
	handleBackToTop() {
		scrollToTop(750)
	}
	render() {
		return (
			<button type="button" className={this.state.isVisible ? "obj_back-to-top visible" : "obj_back-to-top"} onClick={this.handleBackToTop}></button>
		)
	}
}

export default ObjBackToTop